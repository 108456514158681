import React from 'react';
import { createSvgIcon } from '@mui/material';

const AttendanceIcon = createSvgIcon(
  <svg width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <path id="8w5ubttpta" d="M0 0h9.32v6.757H0z" />
    </defs>
    <g fill="none" fillRule="evenodd">
      <path d="M.682 0h16v16h-16z" />
      <path
        d="M11.946 13.57c.182-.01.365-.006.544-.035.44-.07.872-.179 1.284-.358.077-.033.156-.085.234-.084.339.003.668-.049.991-.141.281-.082.567-.164.775-.39a.269.269 0 0 0 .071-.175 9.408 9.408 0 0 0-.064-.673 3.673 3.673 0 0 0-.351-1.175 3.42 3.42 0 0 0-.85-1.106 3.609 3.609 0 0 0-1.087-.634 3.946 3.946 0 0 0-.971-.219 3.741 3.741 0 0 0-1.028.034c-.48.082-.933.247-1.348.506a3.274 3.274 0 0 0-.892.802 3.673 3.673 0 0 0-.456.776c-.138.323-.23.658-.28 1.004-.03.204-.046.41-.068.616a.273.273 0 0 0 .077.233c.145.153.33.237.517.31.397.154.809.238 1.238.23.043-.001.09.023.13.045.074.04.141.093.215.132.412.221.855.308 1.32.301zm-.424.644c-.117-.023-.233-.048-.35-.068a2.914 2.914 0 0 1-.99-.368.257.257 0 0 0-.11-.036 3.962 3.962 0 0 1-1.324-.293c-.263-.11-.51-.243-.712-.45-.208-.214-.249-.478-.233-.76.008-.143.03-.286.042-.43.04-.441.149-.866.312-1.278a4.085 4.085 0 0 1 2.34-2.32 3.89 3.89 0 0 1 1.08-.271c.338-.03.681-.016 1.022-.005.387.012.758.113 1.121.245.452.164.862.4 1.232.709.49.41.866.905 1.126 1.49.157.353.275.719.329 1.102.032.23.047.463.07.695.002.012.015.023.022.034v.315c-.007.011-.02.022-.022.034a.788.788 0 0 1-.32.561c-.502.385-1.09.534-1.707.6-.173.019-.344.026-.515.097-.422.175-.86.306-1.32.352-.08.008-.16.03-.241.045h-.852zM12.222 1.898C10.815 1.885 9.754 3.04 9.7 4.288a2.537 2.537 0 0 0 2.456 2.669c1.474.03 2.54-1.141 2.596-2.418.066-1.501-1.18-2.674-2.531-2.641m.003-.654c1.72-.042 3.283 1.43 3.182 3.36A3.172 3.172 0 0 1 12.11 7.61a3.17 3.17 0 0 1-3.064-3.364c.068-1.577 1.395-3.025 3.179-3.002"
        stroke="#000"
        strokeWidth=".5"
        fill="#231F20"
      />
      <path
        d="M11.988 13.592a2.629 2.629 0 0 1-1.315-.304c-.074-.04-.141-.092-.215-.133a.282.282 0 0 0-.13-.045 3.176 3.176 0 0 1-1.235-.232c-.186-.074-.371-.158-.515-.313a.277.277 0 0 1-.077-.234c.022-.207.038-.415.068-.621a4.06 4.06 0 0 1 .278-1.011c.12-.281.274-.54.456-.783.246-.327.546-.592.89-.808a3.68 3.68 0 0 1 1.344-.51c.338-.058.68-.07 1.025-.033.333.035.657.103.97.22.396.15.757.36 1.084.639.363.312.637.688.847 1.115.183.373.298.77.35 1.184.029.225.052.451.064.678a.273.273 0 0 1-.07.176c-.208.228-.493.31-.774.392-.322.094-.65.146-.988.143-.078 0-.157.05-.234.084-.41.181-.84.291-1.28.362-.179.028-.362.023-.543.034M12.183 1.942c1.35-.032 2.593 1.138 2.527 2.636a2.536 2.536 0 0 1-2.591 2.412 2.532 2.532 0 0 1-2.452-2.662c.053-1.246 1.112-2.398 2.516-2.386"
        fill="#FEFEFE"
      />
      <g>
        <path
          d="M5.586.855c-1.892-.024-3.3 1.513-3.372 3.186a3.363 3.363 0 0 0 3.25 3.57c2.029.054 3.41-1.508 3.499-3.19C9.07 2.373 7.413.811 5.586.855"
          fill="#FEFEFE"
        />
        <path
          d="M5.915 13.951a5.608 5.608 0 0 0 1.376-.385c.082-.036.167-.09.251-.09.362.003.716-.052 1.061-.152.302-.087.608-.175.83-.418a.29.29 0 0 0 .077-.188 10.092 10.092 0 0 0-.069-.723 3.952 3.952 0 0 0-.376-1.262A3.67 3.67 0 0 0 6.99 8.864a4.224 4.224 0 0 0-1.041-.235 3.999 3.999 0 0 0-1.102.036 3.97 3.97 0 0 0-1.444.543c-.369.23-.69.513-.955.862a3.943 3.943 0 0 0-.49.834 4.303 4.303 0 0 0-.298 1.078c-.032.22-.05.44-.073.662a.294.294 0 0 0 .082.25c.155.165.353.254.554.332.425.166.866.257 1.326.248.046-.001.097.025.14.049.079.043.151.099.23.141.442.238.916.33 1.414.324.194-.011.39-.006.582-.037zm-1.037.729c-.125-.025-.25-.051-.375-.073a3.119 3.119 0 0 1-1.062-.395.273.273 0 0 0-.118-.04 4.235 4.235 0 0 1-1.417-.313c-.282-.119-.547-.261-.763-.484-.223-.23-.267-.514-.25-.815.009-.155.031-.308.045-.462.044-.475.16-.931.334-1.374A4.388 4.388 0 0 1 3.78 8.232a4.16 4.16 0 0 1 1.158-.29c.362-.032.73-.018 1.094-.006.415.013.814.121 1.202.263.484.177.924.43 1.32.762.526.44.928.971 1.206 1.6.168.379.295.772.353 1.184.035.247.05.497.075.746.002.013.015.024.023.037v.338c-.008.012-.021.024-.023.036a.847.847 0 0 1-.343.603c-.538.413-1.168.574-1.83.645-.184.02-.368.028-.55.103a4.98 4.98 0 0 1-1.414.38c-.087.008-.173.03-.26.047h-.912zM5.583 1.55C4.091 1.535 2.965 2.76 2.908 4.085a2.692 2.692 0 0 0 2.607 2.83c1.563.032 2.694-1.21 2.754-2.564.07-1.593-1.252-2.837-2.686-2.803m.003-.694C7.413.811 9.07 2.373 8.963 4.421c-.088 1.682-1.47 3.244-3.498 3.19a3.363 3.363 0 0 1-3.251-3.57C2.286 2.368 3.694.83 5.586.855"
          stroke="#000"
          strokeWidth=".5"
          fill="#231F20"
        />
        <path
          d="M5.336 13.98a2.85 2.85 0 0 1-1.413-.322c-.08-.042-.152-.098-.23-.141a.305.305 0 0 0-.14-.049c-.46.01-.9-.081-1.326-.246-.2-.078-.399-.167-.553-.331a.292.292 0 0 1-.083-.25c.024-.22.041-.44.073-.659.054-.37.151-.728.3-1.073.127-.298.293-.573.488-.83.264-.348.586-.63.955-.859.445-.276.93-.453 1.444-.54.363-.063.73-.075 1.1-.036.358.037.706.11 1.04.234.427.158.815.38 1.165.678.39.33.685.73.91 1.183.197.396.32.818.377 1.257.03.24.055.48.068.72a.288.288 0 0 1-.076.187c-.223.242-.529.33-.83.416-.346.1-.699.155-1.061.152-.084-.001-.17.054-.251.09-.44.191-.903.308-1.375.383-.192.03-.388.025-.582.036M5.545 1.476c1.453-.035 2.792 1.226 2.72 2.838-.06 1.372-1.205 2.63-2.79 2.598a2.726 2.726 0 0 1-2.64-2.867c.057-1.341 1.198-2.582 2.71-2.569"
          fill="#FEFEFE"
        />
      </g>
    </g>
  </svg>,
  'Tl'
);

export default AttendanceIcon;
