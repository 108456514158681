import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';
import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../Common/styling';
import RegionForm from './components/RegionForm';
import { RegionInterface } from './types';
import { validationSchema, initialValues } from './regionSchema';

import { createRegion } from './regionApi';
import { getUsersMinimalList } from '../User/usersApi';

import { getResidencesMinimalList } from '../Residence/residenceApi';
import { OptionsInterface } from '../Common/types';
import { MinimalUser } from '../User/types';

const CreateRegion: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);
  const [loadingResidences, setLoadingResidences] = useState(false);
  const [loadingRC, setLoadingRC] = useState(false);
  const [loadingVA, setLoadingVA] = useState(false);

  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [rcMinimalList, setRcMinimalList] = useState<MinimalUser[]>([]);
  const [vaMinimalList, setVaMinimalList] = useState<MinimalUser[]>([]);

  // functions
  useEffect(() => {
    fetchVAs();
    fetchRCs();
    fetchResidences();
  }, []); // eslint-disable-line

  const fetchRCs = (): void => {
    setLoadingRC(true);
    getUsersMinimalList({ hide_banned: true, filter_by_role_name: 'regional_manager' })
      .then(({ data }) => setRcMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över regionala chefer'))
      .finally(() => setLoadingRC(false));
  };

  const fetchVAs = (): void => {
    setLoadingVA(true);
    getUsersMinimalList({ hide_banned: true, filter_by_role_name: 'operational_manager' })
      .then(({ data }) => setVaMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över verksamhetschefer'))
      .finally(() => setLoadingVA(false));
  };

  const fetchResidences = (): void => {
    setLoadingResidences(true);
    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => {
        setResidenceMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över boende'))
      .finally(() => setLoadingResidences(false));
  };

  const handleFormSubmit = (
    { regional_manager, operational_manager, residences, name }: typeof initialValues,
    actions: FormikHelpers<any>
  ): void => {
    setLoading(true);
    createRegion({
      name,
      regional_manager_id: regional_manager?.id as string,
      operational_manager_id: operational_manager?.id as string,
      residence_ids: residences.map((r) => r.id as number),
    })
      .then(() => notifySuccess('Regionen har skapats'))
      .then(() => navigate(`/regions`))
      .catch((error: any) => {
        notifyError(`Det gick inte att skapa regionen: \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Lägg till region</h1>
        <CancelLink link={`/regions`} />
      </div>
      {(loading || loadingResidences || loadingVA || loadingRC) && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading || loadingResidences || loadingVA || loadingRC} />
        </div>
      )}
      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <RegionForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                isCreate={true}
                setFieldValue={setFieldValue}
                submitDisabled={isSubmitting || !isValid || !dirty}
                residenceMinimalList={residenceMinimalList}
                rcMinimalList={rcMinimalList}
                vaMinimalList={vaMinimalList}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateRegion;
