import { RestoreTwoTone as RestoreIcon } from '@mui/icons-material';
import { Fab } from '@mui/material';
import React from 'react';
import { commonStyles, css, StyleSheet } from '../../Common/styling';

const styles = StyleSheet.create({
  resetIcon: {
    marginRight: '10px',
    fontSize: '1rem',
  },
});

type Props = {
  onClick: any;
  text: string;
};

const ResetFab: React.FC<Props> = (props) => {
  return (
    <Fab variant="extended" aria-label="Reset" className={css(commonStyles.selectionFabStyle2)} onClick={props.onClick}>
      <RestoreIcon className={css(styles.resetIcon)} />
      {props.text}
    </Fab>
  );
};

export default ResetFab;
