import React, { ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';

import { createFaq } from './FaqApi';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../../Common/styling';
import { useNotify } from '../../Common/snackbarHooks';
import FaqForm from './FaqForm';
import { CancelLink } from '../../Common/ButtonLinks';
import { FaqInterface } from './types';

const initialFaqValues = {
  question: '',
  answer: '',
  ranking: 1,
  show: true,
  id: 0,
};
const CreateFaq: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();

  // functions
  const handleFormSubmit = (values: FaqInterface, actions: FormikHelpers<any>): void => {
    createFaq(values)
      .then(() => notifySuccess('FAQ har skapats'))
      .then(() => navigate('/faq'))
      .catch((error) => {
        notifyError(`Det gick inte att skapa FAQ : ${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      });
  };
  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Lägg till FAQ</h1>
        <CancelLink link={'/faq'} />
      </div>

      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialFaqValues}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, dirty }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <FaqForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                isCreate={true}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateFaq;
