import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteRenderGetTagProps,
  Chip,
  CircularProgress,
  TextField,
} from '@mui/material';
import React, { ReactNode, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { createFilterOptions } from '@mui/material/Autocomplete';

// Redecalare forwardRef
declare module 'react' {
  // Required since regular forwardRef declaration doesn't allow generic type
  function forwardRef<T, P = Record<string, never>>(
    render: (props: P, ref: React.Ref<T> | null) => JSX.Element
  ): (props: P & React.RefAttributes<T>) => JSX.Element;
}

interface FilterAutocompleteBoxProps<T extends { id: any }> {
  multiple?: boolean;
  options: T[];
  value?: T[] | T | null;
  onChange?: (
    event: React.SyntheticEvent,
    option: T[] | T | null,
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void;
  onInputChange?: (event: React.SyntheticEvent, value: string, reason: string) => void;
  groupBy?: (option: T) => string;
  getOptionLabel: (option: T) => string;
  placeholder?: string;
  loading?: boolean;
  disabled?: boolean;
  isOpen?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
  startAdornment?: ReactNode;
  clearable?: boolean;
  className?: string;
  renderTags?: ((values: T[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode) | 'simple' | 'chips';
}

function FilterAutocompleteBoxInner<T extends { id: any }>(
  {
    multiple,
    options,
    value,
    onChange,
    onInputChange,
    getOptionLabel,
    groupBy,
    placeholder,
    loading = false,
    disabled = false,
    isOpen,
    setIsOpen,
    startAdornment,
    clearable,
    className,
    renderTags,
  }: FilterAutocompleteBoxProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  return (
    <Autocomplete
      options={options}
      multiple={multiple}
      filterOptions={createFilterOptions({ ignoreAccents: false })}
      value={value} // Requires it not to be null, but still accepts it
      getOptionLabel={getOptionLabel}
      renderTags={
        renderTags === 'simple'
          ? (values: T[]) => (
              <p className="ml-2">
                {values.length} {values.length === 1 ? 'vald' : 'valda'}
              </p>
            )
          : (values: T[], getTagProps: AutocompleteRenderGetTagProps) =>
              // eslint-disable-next-line react/jsx-key
              values.map((value, index) => <Chip label={getOptionLabel(value)} {...getTagProps({ index })} />)
      }
      groupBy={groupBy}
      className={twMerge('w-56', className)}
      disableClearable={!clearable}
      disabled={disabled}
      onChange={onChange}
      onInputChange={onInputChange}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getOptionLabel(option)}
        </li>
      )}
      open={isOpen}
      onOpen={setIsOpen && (() => setIsOpen(true))}
      onClose={setIsOpen && (() => setIsOpen(false))}
      loading={loading}
      renderInput={({ InputProps, ...params }) => (
        <div className="transition hover:bg-gray-100/70 h-full flex items-center cursor-text px-2">
          <TextField
            {...params}
            inputRef={ref}
            InputProps={{
              ...InputProps,
              disableUnderline: true,
              placeholder: Array.isArray(value) && value.length ? '' : placeholder,
              endAdornment: loading ? (
                <span className="pr-2 h-[1em]">
                  <CircularProgress size="1em" />
                </span>
              ) : (
                InputProps.endAdornment
              ),
            }}
            variant="standard"
            sx={{
              '& .MuiAutocomplete-input': {
                flexGrow: 1,
                textOverflow: 'ellipsis',
              },
            }}
          />
        </div>
      )}
    />
  );
}

const FilterAutocompleteBox = React.forwardRef(FilterAutocompleteBoxInner);

export default FilterAutocompleteBox;
