import { AxiosResponse } from 'axios';

import { DetailedMonth, ListParams, MinimalMonth, UserShift } from '../Common/types';
import API from '../Common/axios';

export const getMinimalMonths = (team_id: string | number | undefined, params: ListParams = {}) => {
  return API.get<{ data: MinimalMonth[] }>(`v1/teams/${team_id}/schedule/months`, { params });
};

export const getDetailedMonth = (team_id: string | number | undefined, month: string) => {
  return API.get<DetailedMonth>(`v1/teams/${team_id}/schedule/months/${month}`);
};

export const createUsersWorkShift = (
  work_shift_id: number,
  scheduled_participant: {
    user_id: string;
    work_shift_type: UserShift['work_shift_type'];
  }
) => {
  return API.post<{ data: UserShift }>(`v1/work_shifts/${work_shift_id}/scheduled_participants`, {
    scheduled_participant,
  });
};

export const deleteUsersWorkShift = (
  work_shift_id: number,
  scheduled_participant_id: number
): Promise<AxiosResponse> => {
  return API.delete(`v1/work_shifts/${work_shift_id}/scheduled_participants/${scheduled_participant_id}`);
};
