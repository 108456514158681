import React from 'react';
import BugReportIcon from '@mui/icons-material/BugReport';
import { theme } from '../styling';

export default function ReportBugs() {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    return (
      <div
        style={{
          paddingRight: '12px',
          paddingTop: '16px',
          paddingBottom: '12px',
          alignItems: 'center',
          color: theme.redLight,
          backgroundColor: 'white',
          display: 'flex',
          justifyContent: 'start',
          fontSize: 14,
        }}
      >
        <BugReportIcon style={{ paddingBottom: '1px' }} />
        <a
          style={{ color: theme.redLight, paddingLeft: '5px' }}
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.REACT_APP_BUG_REPORTER_URL}
        >
          Rapportera fel
        </a>
      </div>
    );
  } else return <div />;
}
