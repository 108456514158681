import React, { useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { UserListInterface } from '../types';
import { setPasswordQuickAction } from '../usersApi';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';

type Props = {
  user: UserListInterface | undefined;
  setModalState: (modalState: boolean) => void;
};

const SetPasswordModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);
  const [newValue, setNewValue] = useState('');

  const handleSubmit = (): void => {
    setLoading(true);
    if (props.user) {
      setPasswordQuickAction(props.user.id, newValue)
        .then(() => {
          notifySuccess('Lösenord uppdaterat');
        })
        .catch(() => notifyError('Det gick inte att uppdatera lösenordet.'))
        .finally(() => {
          setLoading(false);
          props.setModalState(false);
        });
    }
  };

  return (
    <div>
      <DialogTitle>Sätt nytt lösenord för {props.user?.name}</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent style={{ padding: '20px' }}>
            <TextField
              error={newValue.length != 0 && (newValue.length < 8 || newValue.length > 20)}
              helperText={
                newValue.length != 0 && (newValue.length < 8 || newValue.length > 20)
                  ? 'Lösenord måste vara mellan 8 och 20 tecken'
                  : ''
              }
              fullWidth
              disabled={false}
              name={'new_password'}
              label={'Nytt lösenord för användaren'}
              placeholder={'Skriv nytt lösenord'}
              variant="outlined"
              onChange={(value) => {
                setNewValue(value.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              color="error"
              onClick={(): void => {
                props.setModalState(false);
              }}
            >
              AVBRYT
            </Button>
            <Button
              fullWidth
              color="primary"
              onClick={(): void => {
                handleSubmit();
              }}
            >
              SPARA
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </div>
  );
};

export default SetPasswordModal;
