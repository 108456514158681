import React, { Fragment, useMemo } from 'react';
import ResidencePageCard from './ResidencePageCard';
import { Domain, HelpOutline, HowToVote } from '@mui/icons-material';
import { Residence, ResidenceUser } from '../types';
import DriveIcon from '../../Dashboard/components/DriveIcon';
import TableBox, { TableBoxColumn } from '../../Common/Table/TableBox';
import { twMerge } from 'tailwind-merge';
import { Link } from 'react-router-dom';

const tableColumns: TableBoxColumn[] = [
  { id: 'team', label: 'Team', width: '2xl' },
  { id: 'start_date', label: 'Startdatum', width: 'sm' },
  { id: 'end_date', label: 'Slutdatum', width: 'sm' },
  { id: 'hours', label: 'Timpott', width: 'xs' },
  { id: 'invoiced', label: 'Fakturerat', width: 'xs' },
  { id: 'worked', label: 'Arbetat', width: 'xs' },
];

type Props = {
  residence: Residence;
};

export default function ResidenceInfo({ residence }: Props) {
  const ambassadors = useMemo(() => {
    const vals: string[] = [];
    residence.teams.forEach(({ ambassadors }) => {
      ambassadors.forEach((ambassador) => {
        if (!vals.includes(ambassador)) vals.push(ambassador);
      });
    });

    return vals;
  }, [residence]);

  return (
    <ResidencePageCard>
      <div className="flex items-center gap-2 mb-6">
        <Domain sx={{ fontSize: 32 }} className="!text-black/55" />
        <h1 className="text-2xl font-semibold">{residence.name}</h1>
      </div>

      <div className="flex gap-12">
        <div className="w-full max-w-md">
          <p className="text-sm mb-8">
            Välkommen till Ung Omsorgs boendesida för {residence.name}. Här kan du se de senaste samt kommande pass på
            boendet.
            <br />
            <br />
            Här hittar du även kontaktuppgifter till våra ledare, samt en länk till vårt filrum där du kan ladda ner
            informationsblad om oss.
          </p>

          <div className="text-sm mb-4">
            <p className="font-bold">Era utsedda ambasadörer:</p>
            <p>{ambassadors.join(', ')}</p>
          </div>

          <Link to="https://drive.google.com/drive/folders/0B6pvkAU79FrKVUdKSlBSM1Rlb1E?resourcekey=0-_7zSCu1m6NwXFThTt0Bv6g">
            <button
              className={twMerge(
                'rounded-full bg-neutral-50 border border-neutral-300 px-6 py-3.5 flex items-center gap-3',
                'transition-all hover:bg-neutral-100 hover:shadow-sm active:bg-neutral-200'
              )}
            >
              <DriveIcon sx={{ fontSize: 20 }} />
              <span className="font-semibold text-sm">Informationsblad</span>
            </button>
          </Link>
        </div>

        <div className="flex-grow grid grid-cols-2 auto-rows-equal justify-end gap-x-8 gap-y-6">
          {residence.teams.map((team, index, { length }) => (
            <div key={team.name} className={twMerge('text-sm', index === length - 1 && 'col-end-edge')}>
              <p className="font-bold">{team.name}</p>
              {team.team_leaders.length ? (
                <p>Teamledare: {team.team_leaders.map(({ name }) => name).join(', ')}</p>
              ) : (
                <p>Teamledare: Ej tilldelad</p>
              )}
              <p className="font-semibold">
                {team.team_leaders.map(({ email }, index) => (
                  <Fragment key={email}>
                    {index !== 0 && <span>, </span>}
                    <a href={`mailto:${email}`}>{email}</a>
                  </Fragment>
                ))}
              </p>
              <p>{team.team_leaders.map(({ mobile }) => mobile).join(', ')}</p>
            </div>
          ))}
        </div>
      </div>

      <hr className="w-full h-px bg-black/85 mt-8" />

      <div className={twMerge('relative flex items-center gap-3 my-4', !residence.team_agreements.length && '-mb-4')}>
        <HowToVote sx={{ fontSize: 24 }} className="!text-black/55" />
        <h3 className="text-lg text-black/60 font-semibold">Avtal</h3>
        {!residence.team_agreements.length && (
          <div className="absolute top-0 bottom-0 left-1/2 flex items-center justify-center">
            <p className="relative text-sm font-medium -left-1/2">Avtal saknas</p>
          </div>
        )}
      </div>

      {!!residence.team_agreements.length && (
        <TableBox
          variant="residence"
          columns={tableColumns}
          content={residence.team_agreements}
          rowDefinition={(row) => ({
            key: row.team + '_' + row.start_date,
            cols: [
              <p className="font-semibold" key="team">
                {row.team}
              </p>,
              row.start_date,
              row.end_date,
              row.budgeted_hours,
              row.invoiced_hours,
              row.worked_hours,
            ],
          })}
        />
      )}

      <hr className="w-full h-px bg-black/85 mt-8" />

      <div className="flex items-center gap-3 mt-4 mb-6">
        <HelpOutline sx={{ fontSize: 24 }} className="!text-black/55" />
        <h3 className="text-lg text-black/60 font-semibold">Övriga frågor & info</h3>
      </div>
      <div className="flex gap-12">
        <div className="w-full max-w-md text-sm">
          <p>
            Frågor gällande den dagliga verksamheten riktas bäst till teamledaren i respektive team.
            <br />
            <br />
            För övriga frågor får du gärna vända dig till vår regionchef eller verksamhetsansvarig.
          </p>
        </div>
        <div className="flex-grow grid grid-cols-2 gap-x-8 gap-y-3">
          <InfoContact
            title="Regionchef"
            subtitle="Ifall du inte får kontakt med vår teamledare."
            person={residence.region?.regional_manager}
          />
          <InfoContact
            title="Verksamhetsansvarig"
            subtitle="Kontakt för övergripande frågor."
            person={residence.region?.operational_manager}
          />

          {!residence.region && (
            <div className="col-span-full text-center text-sm">
              <p className="font-bold mb-3">Kontaktpersoner saknas</p>
              <p>Varken regionchef eller verksamhetsansvarig har tilldelats</p>
            </div>
          )}
        </div>
      </div>
    </ResidencePageCard>
  );
}

type InfoContactProps = { title: string; subtitle: string; person?: ResidenceUser };

function InfoContact({ person, title, subtitle }: InfoContactProps) {
  return (
    person && (
      <>
        <div className="text-sm row-start-1">
          <p className="font-bold">{title}</p>
          <p className="italic">{subtitle}</p>
        </div>
        <div className="text-sm row-start-2">
          <p>{person.name}</p>
          <p>{person.email}</p>
          <p>{person.mobile}</p>
        </div>
      </>
    )
  );
}
