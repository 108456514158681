import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { Table, TableBody, TableCell, Dialog, Checkbox, Grid, Tooltip, MenuItem } from '@mui/material';
import { TableHead, TableRow, LoadMoreButton, MenuTableCell } from '../Common/Table';
import { paginationDefaults, filterSortDefaults } from './residenceClusterSchema';
import { ListParams, ListPagination, OptionsInterface } from '../Common/types';
import { formatDate, isEmpty, isNotEmpty } from '../Common/utilities';
import { StyleSheet, commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar';
import { ResidenceClusterInterface } from './types';
import { getResidenceClusterList, batchDeleteResidenceClusters } from './residenceClusterApi';
import DeleteResidenceClusterModal from './components/DeleteResidenceClusterModal';
import { ResidenceInterface } from '../Residence/types';
import { CreateFab, TaBortFab } from '../Common/ButtonLinks';

const styles = StyleSheet.create({
  tableCell: {
    paddingTop: '2px',
    paddingBottom: '2px',
    paddingLeft: '20px',
    align: 'left',
  },
  paddingCheckbox: {
    padding: '2px',
  },
  travelTimes: {
    display: 'block',
    width: '120px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

const ResidenceClusters: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyInfo, notifyError, notifySuccess } = useNotify();
  const [pagination, setPagination] = useState(paginationDefaults as ListPagination);
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [selectedResidenceClusters, setSelectedResidenceClusters] = useState([] as ResidenceClusterInterface[]);
  const [residenceClusterList, setResidenceClusterList] = useState<Array<ResidenceClusterInterface>>([]);
  const [quickActionResidenceCluster, setQuickActionResidenceCluster] = useState<Array<ResidenceClusterInterface>>([]);
  const [residenceClustersCount, setResidenceClustersCount] = useState<number>(0);
  const [loading, setLoading] = useState(true);
  const [taBortModalState, setTaBortModalState] = useState(false);

  useEffect(() => {
    setPagination(paginationDefaults);
    fetchResidenceClustersList();
  }, [listParams]); // eslint-disable-line

  // functions
  const fetchResidenceClustersList = (): void => {
    setLoading(true);
    getResidenceClusterList({ ...listParams, ...paginationDefaults })
      .then(({ data }) => {
        setResidenceClusterList(data.data);
        setResidenceClustersCount(data.meta.count);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över grupper'))
      .finally(() => setLoading(false));
  };

  const deleteResidenceClusters = (residenceClusterIds: (number | undefined)[]): void => {
    setLoading(true);
    if (residenceClusterIds) {
      batchDeleteResidenceClusters(residenceClusterIds)
        .then(() => notifySuccess('Grupperna har tagits bort'))
        .then(() => {
          setLoading(false);
          fetchResidenceClustersList();
          setSelectedResidenceClusters([]);
          setQuickActionResidenceCluster([]);
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera grupperna: ${error}`));
    }
  };

  const rowIsSelected = (id: number | undefined): boolean => {
    return selectedResidenceClusters.some((element) => element.id === id);
  };

  const selectRow = (event: any, row: ResidenceClusterInterface): void => {
    // Avoid to navigate to job application detail view
    event.stopPropagation();

    // Push or pop element from list
    const newSelectedList = event.target.checked
      ? [...selectedResidenceClusters, row]
      : selectedResidenceClusters.filter((a: any) => a.id !== row.id);

    setSelectedResidenceClusters(newSelectedList);
  };

  const getResidencesString = (residences: OptionsInterface[] | undefined): string => {
    if (residences) {
      return residences
        .sort((a, b) => (a.name > b.name ? 1 : -1))
        .map((r: OptionsInterface) => r.name)
        .join(', ');
    } else {
      return '';
    }
  };

  const SelectionFabs: React.FC = () => {
    return (
      <Grid container className={css(commonStyles.selectionFabContainerStyle2)}>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <TaBortFab onClick={(): void => setTaBortModalState(true)} text="Ta bort" />
        </Grid>
      </Grid>
    );
  };

  // render
  const rows = [
    { id: 'select', label: 'Välj', notSortable: true },
    { id: 'name', label: 'Grupp' },
    { id: 'residences', label: 'Boenden', notSortable: true },
    { id: 'residences_count', label: 'Antal boenden' },
    { id: 'created_at', label: 'Datum' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Grupper</h1>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <p style={{ textAlign: 'center', fontSize: '14px', color: 'rgba(0, 0, 0, 0.6)' }}>
              {residenceClustersCount} Grupper
            </p>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <SearchBar setGetListParams={setGetListParams} listParams={listParams} />
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(residenceClusterList) ? (
              <EmptyList />
            ) : (
              residenceClusterList.map((row: ResidenceClusterInterface, idx: number) => (
                <TableRow
                  customRoot={rowIsSelected(row.id) ? css(commonStyles.greenRow) : ''}
                  row={row}
                  idx={idx}
                  key={row.id}
                  onClick={(): void => navigate(`/residence-clusters/${row.id}`)}
                >
                  <TableCell className={css(styles.paddingCheckbox)}>
                    <Checkbox onClick={(e): void => selectRow(e, row)} checked={rowIsSelected(row.id)} />
                  </TableCell>
                  <TableCell
                    scope="row"
                    style={{ paddingTop: '2px', paddingBottom: '2px', marginRight: '10px', paddingLeft: '0px' }}
                  >
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell className={css(styles.tableCell)}>
                    <Tooltip
                      title={<p style={{ fontSize: '1.25em' }}>{getResidencesString(row.residences)}</p>}
                      placement="top-start"
                    >
                      <div className={css(styles.travelTimes)}>{getResidencesString(row.residences)}</div>
                    </Tooltip>
                  </TableCell>
                  <TableCell className={css(styles.tableCell)}>
                    <b>{row.residences.length}</b>
                  </TableCell>
                  <TableCell className={css(styles.tableCell)}>{formatDate(row.created_at)}</TableCell>
                  <MenuTableCell>
                    <MenuItem>
                      <TaBortFab
                        onClick={(): void => {
                          deleteResidenceClusters([row.id]);
                        }}
                        text="Ta bort"
                      />
                    </MenuItem>
                  </MenuTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {taBortModalState && (
          <Dialog
            open={taBortModalState}
            onClose={(): void => {
              setTaBortModalState(false);
              setQuickActionResidenceCluster([]);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <DeleteResidenceClusterModal
              residenceClusters={
                quickActionResidenceCluster[0] ? quickActionResidenceCluster : selectedResidenceClusters
              }
              setModalState={setTaBortModalState}
              fetchResidenceClustersList={fetchResidenceClustersList}
              setSelectedResidenceClusters={setSelectedResidenceClusters}
              setQuickActionResidenceCluster={setQuickActionResidenceCluster}
            />
          </Dialog>
        )}

        <LoadMoreButton
          loadList={getResidenceClusterList}
          state={residenceClusterList}
          setState={setResidenceClusterList}
          params={listParams}
          pagination={pagination}
          setPagination={setPagination}
        />

        {!isNotEmpty(selectedResidenceClusters) && <CreateFab link={`/residence-clusters/create`} />}
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}

        <Grid
          container
          spacing={2}
          style={{ justifyContent: 'flex-start', position: 'sticky', bottom: '20px', zIndex: '2' }}
        >
          <Grid item xs={1} sm={1} md={1} lg={2}></Grid>
          <Grid item xs={8} sm={8} md={8} lg={8}>
            {isNotEmpty(selectedResidenceClusters) && <SelectionFabs />}
          </Grid>
          <Grid item xs={3} sm={3} md={2} lg={2}></Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default ResidenceClusters;
