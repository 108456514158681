import React from 'react';
import { ListParams } from '../types';
import { StyleSheet, css } from '../styling';
import { paginationDefaults } from '../commonSchema';
import { QueryBuilderSharp as ReserveIcon } from '@mui/icons-material';
import { FormControlLabel, Switch } from '@mui/material';

const styles = StyleSheet.create({
  reserveIcon: {
    position: 'relative',
    bottom: '3px',
    paddingRight: '10px',
    verticalAlign: 'middle',
  },
});

type Props = {
  fieldName: string;
  label: string;
  listParams: ListParams;
  setGetListParams: (p: ListParams) => void;
  setSelectedRowItems: (p: []) => void;
  setQuickActionItem: (p: []) => void;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
};

const ReservedFilterSwitch: React.FC<Props> = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <p>
        <ReserveIcon className={css(styles.reserveIcon)} />
        {props.label}
      </p>

      <FormControlLabel
        control={
          <Switch
            checked={!props.listParams[props.fieldName as keyof ListParams]}
            onChange={(e): void => {
              if (props.setPagination) {
                props.setPagination({
                  page: 1,
                  page_size: props.paginationDefaults
                    ? props.paginationDefaults.page_size
                    : paginationDefaults.page_size,
                });
              }
              props.setGetListParams({ ...props.listParams, [props.fieldName]: !e.target.checked });
              props.setSelectedRowItems([]);
              props.setQuickActionItem([]);
            }}
          />
        }
        label=""
        labelPlacement="start"
      />
    </div>
  );
};

export default ReservedFilterSwitch;
