import { AxiosResponse } from 'axios';
import API from '../../Common/axios';
import { FutureInterface } from './types';

export const createFuture = (future: FutureInterface): Promise<AxiosResponse> => {
  return API.post('v1/futures', { future });
};

export const getFutures = (): Promise<AxiosResponse> => {
  return API.get('v1/futures');
};

export const getFuture = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/futures/${id}`);
};

export const updateFuture = (id: number, future: FutureInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/futures/${id}`, { future });
};

export const deleteFuture = (id: number): Promise<AxiosResponse> => {
  return API.delete(`v1/futures/${id}`);
};
