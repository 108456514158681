import React from 'react';

import { Close as CloseIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';

type Props = {
  showTermsNoteModal: boolean;
  setShowTermsNoteModal: (showTermsNoteModal: boolean) => void;
};

const TermsNoteModal: React.FC<Props> = (props) => {
  return (
    <div>
      <Dialog
        open={props.showTermsNoteModal}
        onClose={(): void => {
          props.setShowTermsNoteModal(false);
        }}
        fullWidth={true}
        maxWidth="md"
      >
        <DialogTitle>
          Vänligen läs och acceptera integritetspolicy
          <IconButton
            aria-label="close"
            onClick={(): void => props.setShowTermsNoteModal(false)}
            size="medium"
            style={{ position: 'absolute', left: '94.5%', top: '4%', color: 'gray' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              {' '}
              <span style={{ color: 'darkred' }}>Obs!</span> Du måste läsa och acceptera integritetspolicy innan du kan
              skicka anmälan.{' '}
            </DialogContentText>
          </DialogContent>
        </React.Fragment>
      </Dialog>
    </div>
  );
};

export default TermsNoteModal;
