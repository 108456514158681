import React from 'react';

import { Button, Checkbox, Divider, FormControl, Grid } from '@mui/material';
import { FormikErrors } from 'formik';
import { FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import logo from '../../Common/logo.png';
import { commonStyles, css } from '../../Common/styling';
import { initialValues } from '../jobApplicationSchema';
import { LanguageInterface } from '../types';

type Props = {
  type: string;
  values: typeof initialValues;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: FormikErrors<typeof initialValues>;
  submitDisabled: boolean;
  languageMinimalList: LanguageInterface[];
  isCreate: boolean;
  termsAccepted: boolean;
  setTermsAccepted: (p: boolean) => void;
  setFieldValue: any;
  removal?: () => void;
};

const JobApplicationFormPublic: React.FC<Props> = (props) => {
  return (
    <div>
      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <img className={css(commonStyles.centerImage)} src={logo} alt="Logo" width={'390px'} />
        </Grid>
        <Grid item sm={12} lg={12} xs={12}>
          <p className={css(commonStyles.publicFormTitle)}>
            {props.type === 'participant' ? <b>Ansökningssida deltagare</b> : <b>Ansökningssida teamledare</b>}
          </p>
        </Grid>
        <Grid item sm={12} lg={12} xs={12}>
          Fyll i nedanstående fält, uppgifterna sprids inte utanför Ung Omsorg. När det blir platser lediga på ett
          äldreboende i din närhet kommer vi att kalla till intervjuer utifrån längst kötid.
        </Grid>
        <Grid item sm={12} lg={12} xs={12} style={{ marginBottom: '12px' }}>
          Om du bytt mobilnummer eller flyttat efter att du ansökt behöver du lämna in en ny ansökan för att uppdatera
          dina uppgifter (du behåller din köplats).
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid item sm={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            required={true}
            size={12}
            fieldName="first_name"
            label="Förnamn"
            placeholder="Förnamn"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item sm={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            required={true}
            size={12}
            fieldName="surname"
            label="Efternamn"
            placeholder="Efternamn"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item sm={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            required={true}
            size={12}
            fieldName="person_nr"
            label="Personnummer (ÅÅMMDDXXXX)"
            placeholder="Personnummer (ÅÅMMDDXXXX)"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item sm={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            required={true}
            size={12}
            fieldName="email"
            label="E-post"
            placeholder="E-post"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid item sm={12} md={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            required={true}
            size={12}
            fieldName="address"
            label="Gatuadress"
            placeholder="Gatuadress"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item sm={6} md={3} lg={3} xs={6} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            required={true}
            size={12}
            fieldName="zip_code"
            label="Postnummer"
            placeholder="Postnummer"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item sm={6} md={3} lg={3} xs={6} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            required={true}
            size={12}
            fieldName="city"
            label="Postort"
            placeholder="Postort"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid item sm={12} md={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            required={true}
            size={12}
            fieldName="mobile"
            label="Mobiltelefon"
            placeholder="Mobiltelefon"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        justifyContent="flex-start"
        className={css(commonStyles.publicFormContentContainer)}
        style={{
          display: 'none',
        }}
      >
        <Grid item sm={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            size={12}
            fieldName="type"
            label="type"
            placeholder="Type"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            type="hidden"
          />
        </Grid>
        <Grid item sm={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
          <FormTextField
            size={2}
            fieldName="status"
            label="Status"
            placeholder="Status"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            type="hidden"
          />
        </Grid>
      </Grid>

      {props.type === 'team_leader' && (
        <Grid
          container
          spacing={2}
          justifyContent="flex-start"
          className={css(commonStyles.publicFormContentContainer)}
        >
          <Grid item sm={12} md={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}>
            Vilket år förväntas du ta/tog du studenten? (fyra siffror)
            <FormTextField
              required
              size={12}
              fieldName="graduate_year"
              label="Gymnasieexamensår"
              placeholder="Gymnasieexamensår"
              values={props.values}
              errors={props.errors}
              onChange={props.handleChange}
            />
          </Grid>
          <Grid item sm={6} lg={6} xs={12} className={css(commonStyles.publicFormTextField)}></Grid>
        </Grid>
      )}

      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid item sm={12} lg={12} xs={12}>
          <Divider className="!my-5" />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid
          item
          sm={12}
          lg={6}
          xs={12}
          style={{
            maxWidth: '1000px',
            marginBottom: '1px',
            marginTop: '8px',
            textAlign: 'left',
          }}
        >
          <Grid item xs={12}>
            <FormControl variant="standard" margin="normal" required fullWidth>
              <FormAutocompleteBox
                type="multiple"
                id="languages"
                name="language_ids"
                label="Språk"
                options={props.languageMinimalList}
                values={props.values.languages as LanguageInterface[]}
                getOptionLabel={(opt) => opt.name}
                onChange={(_, values) => props.setFieldValue('languages', values)}
                error={props.errors.languages as string}
                optionEquality={(option, value) => option.id === value.id}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          item
          sm={12}
          lg={6}
          xs={12}
          style={{ maxWidth: '1000px', marginBottom: '12px', marginTop: '9px', textAlign: 'left' }}
        >
          <p style={{ fontSize: '14px', color: 'rgb(0, 0, 0, 0.6)' }}>
            Hos Ung Omsorg krävs det att du kan föra en konversation på svenska. Ibland finns det dock äldre som inte
            talar svenska och vi undrar därför om du kan föra en konversation på något/några ytterligare språk?
          </p>
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid item sm={12} lg={12} xs={12} style={{ marginBottom: '12px' }}>
          <Divider className="!mt-4 !mb-7" />
          Vi tar inte emot CV eller personligt brev från er som söker jobb hos oss, utan alla ansökningar kommer
          behandlas efter kötid och närhet till de boenden vi är verksamma på.
        </Grid>
      </Grid>

      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid item sm={12} lg={10} xs={12} style={{ marginBottom: '12px' }}>
          <Checkbox
            checked={props.termsAccepted}
            color="primary"
            value="confirmed"
            onChange={(e) => props.setTermsAccepted(e.target.checked)}
          />
          Jag har läst och accepterar{' '}
          <a target="_blank" href="integritetspolicy">
            integritetspolicy
          </a>
          .
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        alignItems="center"
        className={css(commonStyles.publicFormContentContainer)}
        style={{ paddingBottom: '100px' }}
      >
        <Grid item lg={4} sm={4} xs={4} />
        {(props.isCreate || props.values.editable) && (
          <Grid item lg={4} sm={4} xs={4} style={{ marginTop: '12px' }}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button type="submit" variant="contained" disabled={props.submitDisabled}>
                Skicka ansökan
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default JobApplicationFormPublic;
