import React from 'react';
import { createRoot } from 'react-dom/client';

import { BrowserRouter as Router } from 'react-router-dom';
import App from './App';
import { AuthProvider } from './Common/AuthContext';
import './index.css';

// Inject App component and start app rendering
const container = document.getElementById('root');
if (!container) throw new Error('No container found to render react app to');
createRoot(container).render(
  <AuthProvider>
    <Router>
      <App />
    </Router>
  </AuthProvider>
);
