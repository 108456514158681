import * as React from 'react';
import { useNotify } from '../snackbarHooks';
import { StyleSheet, css } from '../styling';
import { EmailOutlined as EmailIcon } from '@mui/icons-material';
import { Popover } from '@mui/material';

type Props = {
  email: string;
  icon?: boolean;
};

const styles = StyleSheet.create({
  emailText: {
    color: '#00b278',
    fontWeight: 'bold',
    justifyContent: 'space-evenly',
    textDecoration: 'none',
  },
  emailIcon: {
    color: 'black',
    position: 'relative',
    bottom: '1px',
    paddingLeft: '10px',
    verticalAlign: 'middle',
  },
  popover: {
    pointerEvents: 'none',
  },
  popoverContent: {
    pointerEvents: 'auto',
  },
});

const EmailPopover: React.FC<Props> = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { notifyInfo } = useNotify();

  const handleClick = (event: any) => {
    event.stopPropagation();
    navigator.clipboard.writeText(props.email);
    notifyInfo('Epostadress har kopierats', {
      variant: 'default',
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center',
      },
    });
  };

  const handleOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div id="email-icon-link" onMouseEnter={handleOpen} onMouseLeave={handleClose}>
      <a
        aria-describedby={id}
        onClick={(e): void => {
          e.stopPropagation();
          handleClick(e);
        }}
      >
        {props.icon ? <EmailIcon className={css(styles.emailIcon)} /> : props.email}
      </a>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        onClose={handleClose}
        className={css(styles.popover)}
        classes={{
          paper: css(styles.popoverContent),
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div style={{ padding: '12px' }}>
          {' '}
          <a
            className={css(styles.emailText)}
            onClick={(e): void => e.stopPropagation()}
            href={`mailto:${props.email}`}
            target="_blank"
            rel="noreferrer"
          >
            {props.email}
          </a>
        </div>
      </Popover>
    </div>
  );
};

export default EmailPopover;
