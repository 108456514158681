import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import API from '../Common/axios';

export const getMissedWorkShifts = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/work_shifts/missed_work_shifts', { params });
};

export const hideMissedWorkShifts = (work_shift_ids: number[]): Promise<AxiosResponse> => {
  return API.patch('v1/work_shifts/batch_update', { work_shift_ids, hidden: true });
};
