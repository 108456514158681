import { Checkbox } from '@mui/material';
import React from 'react';

type Props = {
  checked: boolean;
  id: number;
  fieldName: string;
  label: string;
  handleChange: any;
  allUnchecked: boolean;
};

const LanguageFilterCheckbox: React.FC<Props> = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {props.label}
      <Checkbox
        color="primary"
        checked={props.checked}
        onChange={(e): void => {
          props.handleChange(e.target.checked, props.label, props.id);
        }}
      />
    </div>
  );
};

export default LanguageFilterCheckbox;
