import * as Yup from 'yup';

import { ListPagination, ListParams, sort_order } from '../Common/types';

export const initialValues = {
  name: '',
  notes: '',
  default_only_for_locals: false,
  is_active: true,
  invoice_detail: {
    recipient: '',
    address_line_1: '',
    address_line_2: '',
    zip_code: '',
    city: '',
    reference: '',
    // gln_number: '',
  },
  residences_with_own_invoice_details: [],
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('* Namn är ett obligatoriskt fält'),
  invoice_detail: Yup.object({
    recipient: Yup.string().test('recipient', '* Fakturamottagare är ett obligatoriskt fält', function (value) {
      if (!value) return false;
      else return true;
    }),
    zip_code: Yup.string().test(
      'zip_code',
      '* Postnumret skrivs på formatet XXXXX och enbart siffror',
      function (value) {
        if (!value) return true;
        return /^(^$|[1-9]\d{2}[ ]?\d{2})$/.test(value);
      }
    ),
    org_number: Yup.string().test(
      'org_number',
      '* Orginisationsnummer skrivs på formatet XXXXXX-XXXX och enbart siffror',
      function (value) {
        if (!value) return true;
        return /^(^$|[1-9]\d{5}[-]\d{4})$/.test(value);
      }
    ),
    // gln_number: Yup.string().test('gln_number', '* GLN-numret måste vara 13 siffror', function (value) {
    //   if (!value) return true;
    //   return /^(^$|\d{13})+$/.test(value);
    // }),
  }),
});

export const filterSortDefaults: ListParams = {
  query: '',
  sort_by: 'name',
  sort_order: sort_order.asc,
  created_at_start: null,
  created_at_end: null,
  is_active: true,
};
