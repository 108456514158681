import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  Link,
  Paper,
  TextField,
  Typography,
} from '@mui/material';
import React, { useContext, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '../Common/AuthContext';
import { StyleSheet, css, theme } from '../Common/styling';
import { AuthContextValue } from '../Common/types';
import { requestResetPassword } from './loginApi';

const styles = StyleSheet.create({
  loginView: {
    backgroundColor: theme.themeGreenGreyLight,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loginCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    maxWidth: '340px',
  },

  avatar: {
    margin: '10px',
  },

  loginButton: {
    marginTop: '40px',
  },
});

const Login: React.FC = () => {
  // hooks
  const location = useLocation();
  const [loginEmail, setEmail] = useState('');
  const [loginPassword, setPassword] = useState('');
  const [open, setOpen] = useState(false);
  const [passwordResetEmail, setPasswordResetEmail] = useState('');
  const { login, isAuth } = useContext(AuthContext) as AuthContextValue;

  // functions
  const handleClose = (): void => {
    setOpen(false);
  };

  const sendRequestEmail = (): void => {
    requestResetPassword({ email: passwordResetEmail })
      .then(() => {
        setOpen(false);
        alert('Du kommer nu få ett e-post med instruktioner hur lösenordet återställs!');
      })
      .catch(() => {
        alert('Det gick inte att återställa lösenordet');
      });
  };

  // render
  return (
    <>
      {!isAuth ? (
        <div className={css(styles.loginView)}>
          <Paper className={css(styles.loginCard)}>
            <Avatar className={css(styles.avatar)}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography variant="h5">Sign in</Typography>
            <div>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <InputLabel htmlFor="email">E-postadress</InputLabel>
                <Input
                  id="email"
                  name="email"
                  value={loginEmail}
                  onChange={(e): void => setEmail(e.target.value)}
                  autoComplete="email"
                  autoFocus
                />
              </FormControl>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <InputLabel htmlFor="password">Lösenord</InputLabel>
                <Input
                  name="password"
                  type="password"
                  id="password"
                  value={loginPassword}
                  onChange={(e): void => setPassword(e.target.value)}
                  autoComplete="current-password"
                />
              </FormControl>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={css(styles.loginButton)}
                onClick={(): void =>
                  login({
                    email: loginEmail,
                    password: loginPassword,
                  })
                }
              >
                Logga in
              </Button>
            </div>
            <Link
              component="button"
              variant="body2"
              onClick={(): void => {
                setOpen(true);
              }}
              color="error"
              underline="hover"
              style={{ marginTop: '12px' }}
            >
              Glömt lösenordet?
            </Link>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
              <DialogTitle id="form-dialog-title">Glömt lösenordet?</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  För att återställa lösenordet, fyll i emailadress och följ sedan instruktionerna som kommer på mail.
                </DialogContentText>
                <TextField
                  autoFocus
                  value={passwordResetEmail}
                  onChange={(e): void => {
                    setPasswordResetEmail(e.target.value);
                  }}
                  size="small"
                  id="name"
                  label="Email"
                  type="email"
                  variant="standard"
                  color="secondary"
                  className="!mt-4"
                  fullWidth
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="error">
                  Cancel
                </Button>
                <Button onClick={sendRequestEmail} color="error">
                  Återställ lösenordet
                </Button>
              </DialogActions>
            </Dialog>
          </Paper>
        </div>
      ) : (
        <React.Fragment>
          <Navigate
            to={location.state?.from || process.env.REACT_APP_FEATURE_DASHBOARD === 'true' ? '/dashboard' : '../teams'}
            replace
          />
        </React.Fragment>
      )}
    </>
  );
};

export default Login;
