import React, { useEffect, useState } from 'react';
import logo from '../Common/logo.png';
import banner from './login-banner.jpg';
import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
} from '@mui/material';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { authenticateResidencePage } from './residencePageApi';
import { useNotify } from '../Common/snackbarHooks';
import { RotateLoader } from 'react-spinners';
import { baseURL, cookieManager, useQuery } from '../Common/utilities';
import { setResidenceLoginCookie } from './residenceLoginCookie';

type Props = {
  setAuthenticated: (val: boolean) => void;
};

export default function ResidenceLogin({ setAuthenticated }: Props) {
  const [hidePassword, setHidePassword] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const { notifySuccess, notifyError } = useNotify();

  useEffect(() => {
    if (cookieManager.get('residence_token')) setAuthenticated(true);
    setLoaded(true);
  }, []);

  async function signIn({ password }: { password: string }) {
    try {
      const { data } = await authenticateResidencePage(password);
      setResidenceLoginCookie(data.auth_token);
      notifySuccess('Inloggad', { autoHideDuration: 2_000 });
      setAuthenticated(true);
    } catch {
      notifyError('Ogiltigt lösenord');
    }
  }

  return (
    <main className="bg-subtleGreen min-h-dvh w-full py-11 md:pb-20 px-4 flex flex-col md:justify-center items-center gap-7">
      {loaded ? (
        <>
          <img src={logo} alt="Logo" width="200px" />
          <div className="bg-white rounded-3xl shadow-xl overflow-hidden max-w-full w-108">
            <img src={banner} className="h-48 w-full object-cover" />
            <div className="p-4">
              <h1 className="text-2xl font-semibold mb-2">Logga in på boendesidan</h1>

              <p className="text-sm text-black/60 mb-6">
                För att logga in behöver du det lösenord vi har skickat till dig.
              </p>

              <Formik
                initialValues={{ password: '' }}
                validationSchema={Yup.object({ password: Yup.string().required() })}
                onSubmit={signIn}
              >
                {({ values, handleChange, handleSubmit, isValid, isSubmitting }) => (
                  <form onSubmit={handleSubmit}>
                    <TextField
                      id="password"
                      name="password"
                      label="Fyll i lösenord"
                      variant="outlined"
                      value={values.password}
                      onChange={handleChange}
                      type={hidePassword ? 'password' : 'text'}
                      fullWidth
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setHidePassword(!hidePassword)}
                            >
                              {hidePassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <div className="mt-10 mb-6 flex justify-center">
                      <Button
                        type="submit"
                        className="!w-2/3"
                        variant={isValid ? 'outlined' : 'contained'}
                        disabled={!isValid || isSubmitting}
                      >
                        {!isSubmitting ? 'Logga in' : <CircularProgress size={25} color="inherit" />}
                      </Button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </>
      ) : (
        <div className="fixed top-1/2 left-1/2">
          <RotateLoader />
        </div>
      )}
    </main>
  );
}
