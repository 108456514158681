import React, { useEffect, useState } from 'react';
import ResidencePageCard from './ResidencePageCard';
import { CalendarViewWeek } from '@mui/icons-material';
import { CircularProgress, Fade, MenuItem, Select } from '@mui/material';
import { ResidenceMonthData, Residence } from '../types';
import ResidenceReportedMonth from './ResidenceReportedMonth';
import dayjs from 'dayjs';
import { getTeamReportedWorkshifts } from '../residencePageApi';

type Props = {
  residence: Residence;
};

export default function ResidenceReported({ residence }: Props) {
  const [selectedTeam, setSelectedTeam] = useState(residence.teams[0].id);
  const [monthData, setMonthData] = useState<ResidenceMonthData[]>();
  const [loadingMonthData, setLoadingMonthData] = useState(true);

  useEffect(() => {
    setLoadingMonthData(true);
    getTeamReportedWorkshifts(selectedTeam)
      .then(({ data }) => setMonthData(data.data))
      .finally(() => setLoadingMonthData(false));
  }, [selectedTeam]);

  return (
    <ResidencePageCard>
      <div className="flex justify-between items-start mb-6">
        <div>
          <div className="flex items-center gap-3 mb-4">
            <CalendarViewWeek sx={{ fontSize: 24 }} className="!text-black/55" />
            <h2 className="text-lg text-black/60 font-semibold">Inrapporterade pass</h2>
          </div>
          <p className="text-sm">Här kan du se de pass vi har rapporterat in hos er de senaste 3 mån.</p>
        </div>
        <div className="flex items-center gap-3">
          <p className="text-sm font-bold">Välj team</p>
          <div className="w-80 max-w-full">
            <Select value={selectedTeam} onChange={(event) => setSelectedTeam(event.target.value as number)} fullWidth>
              {residence.teams.map((team) => (
                <MenuItem key={team.id} value={team.id}>
                  {team.name}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="relative min-h-52">
        <div className="space-y-4">
          {monthData
            ? monthData.map(({ month, ...data }) => (
                <ResidenceReportedMonth key={month} month={dayjs(month, 'YYYY-MM')} data={data} />
              ))
            : Array.from({ length: 3 }).map((_, index) => (
                <ResidenceReportedMonth key={index} month={dayjs().subtract(2 - index, 'months')} />
              ))}
        </div>
        <Fade in={loadingMonthData}>
          <div className="absolute -inset-1 backdrop-blur-xs flex items-center justify-center">
            <CircularProgress size={50} />
          </div>
        </Fade>
      </div>
    </ResidencePageCard>
  );
}
