import React from 'react';

import { Divider, Grid, Paper, Typography } from '@mui/material';

type Props = {
  rows: any;
};

export const InvoiceRowsDetails: React.FC<Props> = (props) => {
  return props.rows.map((title: any, index: number) => (
    <div key={index}>
      {title.map((row: any, indexRow: number) => (
        <div key={indexRow}>
          <Grid container style={{ padding: '16px', backgroundColor: 'rgba(245, 245, 245, 1)' }}>
            {indexRow === 0 ? (
              <Grid item xs={2}>
                <Paper elevation={0} square>
                  <Typography style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>{row.title}</Typography>
                </Paper>
              </Grid>
            ) : (
              <Grid item xs={2} />
            )}

            <Grid item xs={2}>
              <Paper elevation={0} square>
                <Typography style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>{row.description}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1}>
              <Paper elevation={0} square>
                <Typography style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>{row.amount}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1}>
              <Paper elevation={0} square>
                <Typography style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>
                  {row.unit === 'hours' ? 'timme' : ''}
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1}>
              <Paper elevation={0} square>
                <Typography style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>{row.unit_price}</Typography>
              </Paper>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1}>
              <Paper elevation={0} square>
                <Typography style={{ backgroundColor: 'rgba(245, 245, 245, 1)' }}>
                  {row.amount * row.unit_price}
                </Typography>
              </Paper>
            </Grid>
          </Grid>
          <Divider />
        </div>
      ))}
    </div>
  ));
};
