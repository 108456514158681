import { Button, Grid } from '@mui/material';
import { StyleSheet, css } from 'aphrodite';
import { AxiosResponse } from 'axios';
import React from 'react';
import { ListParams } from '../../Common/types';
import { isEmpty } from '../utilities';

const styles = StyleSheet.create({
  buttonWrapper: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

type Props = {
  loadList: (params: ListParams) => Promise<AxiosResponse>;
  state: any;
  setState: any;
  params: any;
  pagination: any;
  setPagination: any;
};

const LoadMoreButton: React.FC<Props> = (props) => {
  const hasMoreThanOnePage = (): boolean => {
    return !isEmpty(props.state) && props.state.length >= props.pagination.page_size * props.pagination.page;
  };

  return (
    <React.Fragment>
      {hasMoreThanOnePage() && (
        <Grid container justifyContent="center" className={css(styles.buttonWrapper)}>
          <Button
            variant="outlined"
            onClick={(): void => {
              props
                .loadList({
                  ...props.params,
                  ...props.pagination,
                  page: props.pagination.page + 1,
                })
                .then((result: { data: { data: any[] } }) => {
                  props.setState(props.state.concat(result.data.data));
                  props.setPagination({
                    ...props.pagination,
                    page: props.pagination.page + 1,
                  });
                });
            }}
          >
            LADDA FLER
          </Button>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default LoadMoreButton;
