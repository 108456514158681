import React, { useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, List, ListItem } from '@mui/material';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { WorkShiftInterface } from '../../WorkShift/types';
import { hideMissedWorkShifts } from '../missedWorktimesApi';

type Props = {
  selectedMissedWorktimes: WorkShiftInterface[];
  fetchMissedWorktimesList: () => void;
  setModalState: React.Dispatch<React.SetStateAction<boolean>>;
};

const BatchHideMissedWorktimesModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (): void => {
    setLoading(true);

    const ids: number[] = [];
    props.selectedMissedWorktimes.forEach((missedWorkTime: WorkShiftInterface) => {
      if (missedWorkTime.id) ids.push(missedWorkTime.id);
    });
    hideMissedWorkShifts(ids)
      .then(() => notifySuccess('Arbetspassen har dolts'))
      .then(() => setLoading(false))
      .then(() => props.fetchMissedWorktimesList())
      .catch((error) => notifyError(`Arbetspassen gick inte att dölja: ${error}`))
      .then(() => props.setModalState(false));
  };

  const ListRow: React.FC<{ missedWorktime: WorkShiftInterface }> = (props) => {
    return (
      <div className={css(commonStyles.batchRemoveListElement)}>
        {props.missedWorktime.team?.name}
        <div>{props.missedWorktime.date}</div>
      </div>
    );
  };

  return (
    <div>
      <DialogTitle>Dölj arbetspass</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <DialogContent>
          <DialogContentText>{props.selectedMissedWorktimes.length} arbetspass valda</DialogContentText>
          <List>
            {props.selectedMissedWorktimes.map((missedWorktime: WorkShiftInterface, idx: number) => (
              <ListItem key={idx}>
                <ListRow missedWorktime={missedWorktime} />
              </ListItem>
            ))}
          </List>
          <DialogContentText>Dölj de valda arbetspassen?</DialogContentText>
          <DialogActions>
            <Button fullWidth color="error" onClick={(): void => props.setModalState(false)}>
              NEJ
            </Button>
            <Button fullWidth color="primary" onClick={(): void => handleSubmit()}>
              JA
            </Button>
          </DialogActions>
        </DialogContent>
      )}
    </div>
  );
};

export default BatchHideMissedWorktimesModal;
