import * as Yup from 'yup';

import { OptionsInterface, sort_order } from '../Common/types';

export const initialValues = {
  first_name: '',
  surname: '',
  email: '',
  person_nr: '',
  mobile: '',
  address: '',
  zip_code: '',
  city: '',
  graduate_year: undefined,
  birth_date: null as string | null,
  languages: [] as OptionsInterface[],
  manually_tagged_residences: [] as OptionsInterface[],
  deletable: false,
  editable: false,
  status: 'default',
  team: null as OptionsInterface | null,
  automatic_zip_code: false,
};

export const initialEditValues = {
  first_name: '',
  surname: '',
  email: '',
  person_nr: '',
  mobile: '',
  address: '',
  zip_code: '',
  city: '',
  graduate_year: undefined,
  residences: [],
  deletable: false,
  editable: false,
};

export const publicValidationSchemaD = Yup.object().shape({
  first_name: Yup.string().required('* Förnamn är ett obligatoriskt fält'),
  surname: Yup.string().required('* Efternamn är ett obligatoriskt fält'),
  person_nr: Yup.string().required('* Personnummer är ett obligatoriskt fält'),
  email: Yup.string()
    .matches(/^\S+@.+\.\S+$/, 'E-postadressen måste vara i formatet namn@address.com')
    .required('* E-post address är ett obligatoriskt fält'),
  address: Yup.string().required('* Gatuaddress är ett obligatoriskt fält'),
  city: Yup.string()
    .matches(/^[a-zA-ZäöåÄÖÅ\s]+$/, 'Endast bokstäver är tillåtna')
    .required('* Postort är ett obligatoriskt fält'),
  zip_code: Yup.string()
    .matches(/^[1-9]\d{2}[ ]?\d{2}$/, '* Postnumret skrivs på formatet XXXXX och enbart siffror')
    .required('* Postnummer är ett obligatoriskt fält'),
  mobile: Yup.string().required('* Mobiltelefon är ett obligatoriskt fält'),
});

export const publicValidationSchemaTL = Yup.object().shape({
  first_name: Yup.string().required('* Förnamn är ett obligatoriskt fält'),
  surname: Yup.string().required('* Efternamn är ett obligatoriskt fält'),
  person_nr: Yup.string().required('* Personnummer är ett obligatoriskt fält'),
  email: Yup.string()
    .matches(/^\S+@.+\.\S+$/, 'E-postadressen måste vara i formatet namn@address.com')
    .required('* E-post address är ett obligatoriskt fält'),
  address: Yup.string().required('* Gatuaddress är ett obligatoriskt fält'),
  city: Yup.string()
    .matches(/^[a-zA-ZäöåÄÖÅ\s]+$/, 'Endast bokstäver är tillåtna')
    .required('* Postort är ett obligatoriskt fält'),
  zip_code: Yup.string()
    .matches(/^[1-9]\d{2}[ ]?\d{2}$/, '* Postnumret skrivs på formatet XXXXX och enbart siffror')
    .required('* Postnummer är ett obligatoriskt fält'),
  mobile: Yup.string().required('* Mobiltelefon är ett obligatoriskt fält'),
  graduate_year: Yup.number()
    .typeError('* Måste vara årtal (fyra siffror)')
    .integer('* Måste vara årtal (fyra siffror)')
    .required('* Gymnasieexamensår är ett obligatoriskt fält')
    .min(1950, '* Kan inte vara mindre än 1950')
    .max(2099, '* Kan inte vara större än 2099'),
});

export const validationSchema = Yup.object().shape({
  first_name: Yup.string().required('* Förnamn är ett obligatoriskt fält'),
  surname: Yup.string().required('* Efternamn är ett obligatoriskt fält'),
});

export const editValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('* Förnamn är ett obligatoriskt fält'),
  surname: Yup.string().required('* Efternamn är ett obligatoriskt fält'),
});

export const employValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('* Förnamn är ett obligatoriskt fält'),
  surname: Yup.string().required('* Efternamn är ett obligatoriskt fält'),
  email: Yup.string()
    .matches(/^\S+@.+\.\S+$/, 'E-postadressen måste vara i formatet namn@address.com')
    .required('* E-post address är ett obligatoriskt fält'),
  team: Yup.object().typeError('* Team är ett obligatoriskt fält').required('* Team är ett obligatoriskt fält'),
});

export const updatePhoneValidationSchema = Yup.object().shape({
  mobile: Yup.string().required('* Mobiltelefon är ett obligatoriskt fält').nullable(),
});

export const paginationDefaults = { page: 1, page_size: 30 };

export const filterSortDefaultsParticipant = {
  query: '',
  sort_order: sort_order.asc,
  hide_deleted: true,
  hide_reserved: false,
  hide_called: false,
  hide_plus2years: true,
  show_only_for_locals: true,
  hide_plus16old: true,
  hide_graduating_within_year: true,
  type: 'participant',
  filter_languages: [],
  filter_residence: null,
};

export const filterSortDefaultsTeamLeader = {
  query: '',
  sort_order: sort_order.asc,
  hide_deleted: true,
  hide_reserved: false,
  hide_called: false,
  hide_plus2years: true,
  show_only_for_locals: true,
  hide_plus16old: false,
  hide_graduating_within_year: true,
  type: 'team_leader',
  filter_languages: [],
  filter_residence: null,
};
