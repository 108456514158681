import React, { useState, useEffect } from 'react';
import { RotateLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, Grid } from '@mui/material';

import { formatDate, isEmpty, monthTranslator } from '../Common/utilities';
import { filterSortDefaults } from './SentInvoicesSchema';
import { paginationDefaults } from '../Common/commonSchema';
import { TableHead, TableRow, LoadMoreButton } from '../Common/Table';
import { ListParams, ListPagination } from '../Common/types';
import { FilterPopover } from './components/FilterPopover';
import { commonStyles, css } from '../Common/styling';
import { getSentInvoicesList } from './SentInvoicesApi';
import { useNotify } from '../Common/snackbarHooks';
import EmptyList from '../Common/Table/EmptyList';

import { SearchBar } from '../Common/SearchBar';
import { InvoiceInterface } from './types';

const SentInvoices: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [pagination, setPagination] = useState(paginationDefaults as ListPagination);
  const [invoiceList, setInvoiceList] = useState([] as InvoiceInterface[]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPagination(paginationDefaults);
    fetchSentInvoicesList();
  }, [listParams]); // eslint-disable-line

  // functions
  const fetchSentInvoicesList = (): void => {
    setLoading(true);
    getSentInvoicesList({ ...listParams, ...paginationDefaults })
      .then(({ data }) => setInvoiceList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över fakturor'))
      .finally(() => setLoading(false));
  };

  // render
  const rows = [
    { id: 'residence_name', label: 'Boende' },
    { id: 'residence_group_name', label: 'Avtalspart' },
    { id: 'type', label: 'Faktueringsrutin' },
    { id: 'start_date', label: 'Månad' },
    { id: 'total_value', numeric: true, label: 'Summa (kr exkl. moms)' },
    { id: 'fortnox_transfer_date', label: 'Skickad' },
  ];

  interface TypeObject {
    ContinuousAgreement: string;
    FixedAgreement: string;
    [key: string]: string;
  }
  const type: TypeObject = {
    ContinuousAgreement: 'Löpande',
    FixedAgreement: 'Anpassad',
  };

  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Skickade fakturor</h1>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <SearchBar setGetListParams={setGetListParams} listParams={listParams} />
          </Grid>
          <Grid item sx={{ display: 'flex', justifyContent: 'flex-end' }} xs={1} sm={1} md={1} lg={1}>
            <FilterPopover setGetListParams={setGetListParams} listParams={listParams} />
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(invoiceList) ? (
              <EmptyList />
            ) : (
              invoiceList.map((row: InvoiceInterface, idx: number) => (
                <TableRow row={row} idx={idx} key={row.id} onClick={(): void => navigate(`/sent-invoices/${row.id}`)}>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <b>{row.residence_name}</b>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <p>{row.residence_group_name}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <p>{type[row.agreement_type]}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{monthTranslator(row.start_date.substring(5, 7)) + ' ' + row.start_date.substring(0, 4)}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCell)}>
                    <p>{row.total_value}</p>
                  </TableCell>
                  <TableCell className={css(commonStyles.tableCellSmall)}>
                    <p>{formatDate(row.fortnox_transfer_date)}</p>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <LoadMoreButton
          loadList={getSentInvoicesList}
          state={invoiceList}
          setState={setInvoiceList}
          params={listParams}
          pagination={pagination}
          setPagination={setPagination}
        />
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SentInvoices;
