import { useEffect } from 'react';

type PopstateHandler = (event: PopStateEvent) => void;

export default function useGlobalPopstateListener(handlePopstate: PopstateHandler) {
  useEffect(() => {
    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, [handlePopstate]);
}
