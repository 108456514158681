import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import { createTeam } from './teamApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { validationSchema, initialValues } from './teamSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';

import TeamForm from './components/TeamForm';
import { TeamInterface } from './types';
import { OptionsInterface } from '../Common/types';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';

const CreateTeam: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);

  useEffect(() => {
    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (values: TeamInterface, actions: FormikHelpers<any>): void => {
    createTeam(values)
      .then(() => notifySuccess('Teamet har skapats'))
      .then(() => navigate('/teams'))
      .catch((error) => {
        notifyError(`Det gick inte att skapa teamet: \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      });
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Lägg till team</h1>
        <CancelLink link={'/teams'} />
      </div>

      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({
            initialValues,
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <TeamForm
                values={values}
                initialValues={initialValues}
                handleChange={handleChange}
                errors={errors}
                setFieldValue={setFieldValue}
                residenceMinimalList={residenceMinimalList}
                handleDelete={undefined}
                isCreate={true}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateTeam;
