import * as Yup from 'yup';
import dayjs from 'dayjs';

import { RoleOptionsInterface, sort_order } from '../Common/types';

export const initialNewsValues = {
  title: '',
  published_at: dayjs().format('YYYY-MM-DD'),
  content: '',
  raw_content: '',
  residence_ids: [],
  residences: [],
  roles: [] as RoleOptionsInterface[],
  deletable: false,
  editable: false,
};

export const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required('* Titel är ett obligatoriskt fält'),
  published_at: Yup.date().nullable().required('* Publiceringsdatum är ett obligatoriskt fält'),
  raw_content: Yup.string().trim().required('* Nyhetstext är ett obligatoriskt fält'),
  residences: Yup.array().of(Yup.object()).min(1, '* Boende är ett obligatoriskt fält'),
  roles: Yup.array().of(Yup.object()).min(1, '* Minst en roll måste väljas'),
});

export const paginationDefaults = { page: 1, page_size: 30 };

export const filterSortDefaults = {
  query: '',
  sort_by: 'published_at',
  sort_order: sort_order.desc,
  hide_deleted: true,
  show_others: true,
};
