import React from 'react';
import { commonStyles, css } from '../../Common/styling';
import { VisibilityOff as HideIcon } from '@mui/icons-material';
import { Fab } from '@mui/material';

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  text: string;
};

const HideFab: React.FC<Props> = (props) => {
  return (
    <Fab
      variant="extended"
      color="error"
      aria-label="Hide"
      className={css(commonStyles.selectionFab)}
      onClick={props.onClick}
    >
      <HideIcon style={{ marginRight: '10px' }} />
      {props.text}
    </Fab>
  );
};

export default HideFab;
