import { MenuItem, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { stringToPositiveInt, useQuery } from '../Common/utilities';
import { CreateFab, QuickAction } from '../Common/ButtonLinks';
import { commonStyles } from '../Common/styling';
import { RotateLoader } from 'react-spinners';
import { deleteInterview, findInterviews } from './interviewApi';
import { Interview } from './types';
import dayjs from 'dayjs';
import FilterDatePicker from '../Common/FilterPopover/FilterDatePicker';
import { FilterAutocompleteBox } from '../Common/FilterPopover';
import InterviewsFilterPopover from './components/FilterPopover';
import { MenuTableCell } from '../Common/Table';
import { DeleteOutline } from '@mui/icons-material';
import ConfirmationDialog from '../Common/Dialogs/ConfirmationDialog';
import { useNotify } from '../Common/snackbarHooks';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import TableBox, { TableBoxColumn } from '../Common/Table/TableBox';
import { MinimalTeam } from '../Team/types';
import { getTeamMinimalList } from '../Team/teamApi';
import InterviewsSearchBar from './components/SearchBar';

const tableColumns: TableBoxColumn[] = [
  { id: 'team', label: 'Team', width: 'lg', lineStyle: 'compress' },
  { id: 'date', label: 'Datum', width: 'xs' },
  { id: 'type', label: 'Typ', width: 'xs' },
  { id: 'amount', label: 'Antal', width: 'xs' },
  { id: 'attending_tl', label: 'Närvarande TL', width: 'md', lineStyle: 'compress' },
  { id: 'attending_rc', label: 'Närvarande RC', width: 'md', lineStyle: 'compress' },
  { id: 'locked', label: 'Exporterad', width: 'xs' },
  { id: 'created_at', label: 'Skapad', width: 'xs' },
];

const PAGE_SIZE = 25;

export default function Interviews() {
  const { notifySuccess, notifyError } = useNotify();
  const [interviews, setInterviews] = useState<Interview[]>([]);
  const [totalInterviews, setTotalInterviews] = useState(0);
  const [loading, setLoading] = useState(true);
  const [teams, setTeams] = useState<MinimalTeam[]>();
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [query, setQuery] = useQuery(
    {
      search: (val) => val,
      fromDate: (val) => (dayjs(val, 'YYYY-MM-DD').isValid() ? dayjs(val, 'YYYY-MM-DD') : undefined),
      toDate: (val) => (dayjs(val, 'YYYY-MM-DD').isValid() ? dayjs(val, 'YYYY-MM-DD') : undefined),
      team: (val) => stringToPositiveInt(val),
      exported: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      sortBy: (val) => val,
      sortOrder: (val) => (val === 'asc' ? ('asc' as const) : val === 'desc' ? ('desc' as const) : undefined),
      page: (val) => stringToPositiveInt(val),
    },
    { backConverter: { fromDate: (val) => val?.format('YYYY-MM-DD'), toDate: (val) => val?.format('YYYY-MM-DD') } }
  );

  useEffect(() => {
    getInterviews();
  }, [query]);

  useEffect(() => {
    getTeamMinimalList({ is_active: true }).then(({ data }) => setTeams(data.data));
  }, []);

  function getInterviews() {
    setLoading(true);
    findInterviews({
      query: query.search,
      filter_team: query.team,
      filter_from_date: query.fromDate?.format('YYYY-MM-DD'),
      filter_to_date: query.toDate?.format('YYYY-MM-DD'),
      hide_exported: query.exported === false,
      sort_by: query.sortBy,
      sort_order: query.sortOrder,
      page: query.page ?? 1,
    })
      .then(({ data }) => {
        setInterviews(data.data);
        setTotalInterviews(data.meta.count);
      })
      .finally(() => setLoading(false));
  }

  function updateSorting(col: string) {
    return setQuery({ sortBy: col, sortOrder: query.sortBy !== col || query.sortOrder === 'desc' ? 'asc' : 'desc' });
  }

  function archiveInterview() {
    if (!deleteId) return;
    deleteInterview(deleteId)
      .then(() => {
        notifySuccess('Intervjun har tagits bort.');
        getInterviews();
        setDeleteId(null);
      })
      .catch((error) => notifyError(`Det gick inte att ta bort intervjun: ${HandleError(error.response.data)}`));
  }

  return (
    <div className="min-h-full">
      {/* Title */}
      <div className="h-16 p-4 flex items-center justify-between bg-gray-100">
        <h1 className="text-2xl font-semibold">Intervjuer</h1>
      </div>
      {/* Filters */}
      <div className="bg-white flex h-13">
        <InterviewsSearchBar value={query.search} onChange={(value) => setQuery({ search: value || undefined })} />
        <div className="flex">
          <FilterAutocompleteBox
            options={teams ?? []}
            value={teams?.find(({ id }) => query.team === id) ?? null}
            onChange={(_, option) => {
              if (!Array.isArray(option)) {
                setQuery({ team: option?.id, page: undefined });
              }
            }}
            placeholder="Team"
            getOptionLabel={(u) => u.name}
            clearable
            loading={!teams}
          />
          <FilterDatePicker
            views={['year', 'month', 'day']}
            value={query.fromDate ?? null}
            onChange={(date) => setQuery({ fromDate: date?.format('YYYY-MM-DD') ?? undefined, page: undefined })}
            placeholder="Från datum"
            className="w-40"
            actions={['clear']}
          />
          <FilterDatePicker
            views={['year', 'month', 'day']}
            value={query.toDate ?? null}
            onChange={(date) => setQuery({ toDate: date?.format('YYYY-MM-DD') ?? undefined, page: undefined })}
            placeholder="Till datum"
            className="w-40"
            actions={['clear']}
          />
          <InterviewsFilterPopover
            showExported={query.exported}
            setShowExported={(val) => setQuery({ exported: val, page: undefined })}
          />
        </div>
      </div>
      {/* Content */}
      <TableBox
        columns={tableColumns}
        content={interviews}
        rowDefinition={(interview) => ({
          key: interview.id,
          defaultLink: `/interviews/${interview.id}`,
          cols: [
            interview.team.name,
            interview.date,
            {
              padding: 'none',
              content: (
                <Tooltip title={interview.training ? <div>Upplärningstillfälle</div> : ''} placement="bottom-start">
                  <div className="w-full inline-flex items-center p-4">
                    <span className="mr-2.5">
                      {interview.type === 'ParticipantInterview' ? 'D' : 'TL'}
                      {interview.training && '-U'}
                    </span>
                  </div>
                </Tooltip>
              ),
            },
            interview.amount,
            { content: interview.tl?.name ?? '-', link: interview.tl && `/users/${interview.tl.id}` },
            { content: interview.rc?.name ?? '-', link: interview.rc && `/users/${interview.rc.id}` },
            interview.locked ? 'JA' : 'NEJ',
            dayjs(interview.created_at).format('YYYY-MM-DD'),
            {
              content: (
                <MenuTableCell>
                  <MenuItem className="!p-0">
                    <QuickAction
                      onClick={() => setDeleteId(interview.id)}
                      text="Ta bort"
                      icon={<DeleteOutline className="!mr-2.5" />}
                      disabled={interview.locked}
                      label="delete_hours"
                      styles={commonStyles.selectionFabStyle3}
                    />
                  </MenuItem>
                </MenuTableCell>
              ),
              customCell: true,
            },
          ],
        })}
        sorting={{ sortBy: query.sortBy, sortOrder: query.sortOrder, handleSortChange: updateSorting }}
        pagination={{
          totalEntries: totalInterviews ?? 0,
          pageSize: PAGE_SIZE,
          page: query.page ?? 1,
          onChange: (_, page) => setQuery({ page }),
        }}
        fullWidth
        moreActionsCol
      />

      {loading && (
        <div className="fixed top-1/2 left-1/2">
          <RotateLoader />
        </div>
      )}
      <ConfirmationDialog
        isVisible={deleteId !== null}
        title="Ta bort intervju"
        message="Är du säker på att du vill ta bort denna intervjun?"
        onClose={() => setDeleteId(null)}
        onConfirm={archiveInterview}
      />

      <CreateFab link="/interviews/create" />
    </div>
  );
}
