import React, { ReactElement } from 'react';

import { FormDateField, FormTextField, FormCheckbox } from '../../Common/FormItems';
import { commonStyles, css } from '../../Common/styling';
import { Divider, Grid, Paper } from '@mui/material';

import { AgreementDetails, AgreementTeam } from '../types';
import { AgreementValues } from '../agreementSchema';
import ResidencesIcon from '@mui/icons-material/Business';
import { FieldArray, FieldArrayRenderProps, FormikErrors, FormikHandlers } from 'formik';
import FormFreeTextField from '../../Common/FormFreeItems/FormFreeTextField';
import FormFreeDateField from '../../Common/FormFreeItems/FormFreeDateField';
import FormFreeCheckbox from '../../Common/FormFreeItems/FormFreeCheckbox';
import { getValue } from '../../Common/utilities';
import dayjs from 'dayjs';

type Props = {
  agreement: AgreementDetails;
  errors: FormikErrors<AgreementValues>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export default function ResidenceTeams({ agreement, errors, handleChange, setFieldValue }: Props) {
  return (
    <FieldArray
      name="residences"
      render={(): ReactElement => (
        <div className="space-y-8 mb-8">
          {agreement?.residences?.map((residence, index) => (
            <div key={index}>
              <h2 className="flex space-x-2 items-center text-xl text-neutral-600 font-bold">
                <ResidencesIcon />
                <span>{residence.name}</span>
              </h2>
              {residence.teams.map((team, i) => (
                <div key={i}>
                  <ResidenceTeamAgreement
                    residenceIndex={index}
                    teamIndex={i}
                    agreement={agreement}
                    team={team}
                    errors={errors}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                  />
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    />
  );
}

type ResidenceTeamAgreementProps = {
  residenceIndex: number;
  teamIndex: number;
  agreement: AgreementDetails;
  team: AgreementTeam;
  errors: FormikErrors<AgreementValues>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

function ResidenceTeamAgreement({
  residenceIndex,
  teamIndex,
  agreement,
  team,
  errors,
  handleChange,
  setFieldValue,
}: ResidenceTeamAgreementProps) {
  return (
    <div className="rounded-md border border-neutral-400 bg-neutral-100 mb-2">
      <div className="flex justify-between p-4 gap-6">
        <div className="flex gap-6 xl:gap-8">
          <div className="!w-4 mt-[5px]">
            <FormFreeCheckbox
              name={`residences.${residenceIndex}.teams.${teamIndex}.checked`}
              label=""
              values={agreement}
              onChange={handleChange}
            />
          </div>
          <div className="flex mt-[16px] 2xl:mt-[11px] text-sm text-neutral-600 2xl:text-lg">
            <span className="font-bold mx-2">Team:</span>
            <span>{team.name}</span>
          </div>
        </div>
        <div className="flex gap-6 xl:gap-8">
          <div className="flex flex-col !w-52">
            <FormFreeDateField
              label="Avtalsperiod från"
              onlyValid={false}
              size={null}
              value={
                getValue(agreement, `residences.${residenceIndex}.teams.${teamIndex}.start_date`) === null
                  ? null
                  : getValue(agreement, `residences.${residenceIndex}.teams.${teamIndex}.start_date`)
                  ? dayjs(getValue(agreement, `residences.${residenceIndex}.teams.${teamIndex}.start_date`))
                  : null
              }
              InputLabelProps={{ shrink: true }}
              onChange={(day) =>
                setFieldValue(`residences.${residenceIndex}.teams.${teamIndex}.start_date`, day?.format('YYYY-MM-DD'))
              }
              error={getValue(errors, `residences.${residenceIndex}.teams.${teamIndex}.start_date`)}
            />
          </div>
          <div className="border-t border-neutral-400 w-4 -mx-4 mt-[26px] xl:-mx-6" />
          <div className="flex flex-col !w-52">
            <FormFreeDateField
              label="Avtalsperiod till"
              onlyValid={false}
              size={null}
              value={
                getValue(agreement, `residences.${residenceIndex}.teams.${teamIndex}.end_date`) === null
                  ? null
                  : getValue(agreement, `residences.${residenceIndex}.teams.${teamIndex}.end_date`)
                  ? dayjs(getValue(agreement, `residences.${residenceIndex}.teams.${teamIndex}.end_date`))
                  : null
              }
              InputLabelProps={{ shrink: true }}
              onChange={(day) =>
                setFieldValue(`residences.${residenceIndex}.teams.${teamIndex}.end_date`, day?.format('YYYY-MM-DD'))
              }
              error={getValue(errors, `residences.${residenceIndex}.teams.${teamIndex}.end_date`)}
            />
          </div>
          <div className="flex flex-col !w-28 2xl:!w-36">
            <FormFreeTextField
              size={null}
              name={`residences.${residenceIndex}.teams.${teamIndex}.budgeted_hours`}
              label="Timpott (tim)"
              placeholder="Timpott (tim)"
              values={agreement}
              errors={errors}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
            />
          </div>
          <div className="flex flex-col !w-28 2xl:!w-36">
            <FormFreeTextField
              size={null}
              name={`residences.${residenceIndex}.teams.${teamIndex}.startup_fee`}
              label="Uppstart (kr)"
              placeholder="Uppstart (kr)"
              values={agreement}
              errors={errors}
              InputLabelProps={{ shrink: true }}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
