import React, { useContext, useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import NotificationContext from '../../Common/NotificationContext';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { NotificationContextValue } from '../../Common/types';
import { batchDeleteJobOpening } from '../jobOpeningApi';
import { JobOpeningInterface } from '../types';

type Props = {
  jobOpening: JobOpeningInterface[];
  setModalState: (modalState: boolean) => void;
  fetchJobOpeningList: () => void;
  setSelectedJobOpening: (jobOpening: JobOpeningInterface[]) => void;
  setQuickActionJobOpening: (jobOpening: JobOpeningInterface[]) => void;
};

const DeletejobOpeningModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [jobOpeningIds, setjobOpeningIds] = useState<number[]>();
  const [loading, setLoading] = useState(true);
  const { refreshJobOpeningsNotification } = useContext(NotificationContext) as NotificationContextValue;

  /// Get the jobOpening ids into a list
  useEffect(() => {
    setLoading(true);
    const ids: number[] = [];
    props.jobOpening.forEach((jobOpening) => {
      if (jobOpening.id) ids.push(jobOpening.id);
    });
    setjobOpeningIds(ids);
    setLoading(false);
  }, [props.jobOpening]); // eslint-disable-line

  const handleSubmit = (): void => {
    setLoading(true);
    if (jobOpeningIds) {
      batchDeleteJobOpening(jobOpeningIds)
        .then(() => notifySuccess('Annonserna har uppdaterats'))
        .then(() => {
          setLoading(false);
          props.fetchJobOpeningList();
          props.setSelectedJobOpening([]);
          props.setQuickActionJobOpening([]);
          refreshJobOpeningsNotification();
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera annonserna: ${error}`))
        .then(() => props.setModalState(false));
    }
  };

  return (
    <div>
      <DialogTitle>Arkivera annons</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              Vill du arkivera de {props.jobOpening.length} annons/annonser som du valt?{' '}
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogActions>
              <Button
                fullWidth
                color="error"
                onClick={(): void => {
                  props.setModalState(false);
                  props.setQuickActionJobOpening([]);
                }}
              >
                NEJ
              </Button>
              <Button fullWidth onClick={(): void => handleSubmit()}>
                JA
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </div>
  );
};

export default DeletejobOpeningModal;
