import { TableCell, TableRow } from '@mui/material';
import React from 'react';

type Props = {
  alternativeText?: string;
};

const EmptyList: React.FC<Props> = (props) => {
  return (
    <TableRow>
      <TableCell colSpan={12} align="center">
        {props.alternativeText ? props.alternativeText : 'Listan är tom'}
      </TableCell>
    </TableRow>
  );
};

export default EmptyList;
