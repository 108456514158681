import React, { ReactNode } from 'react';

interface ConditionalParentProps {
  on: any;
  parent: (children: JSX.Element) => JSX.Element;
  children: JSX.Element;
}

export default function ConditionalParent({ on, parent, children }: ConditionalParentProps) {
  if (on) {
    return parent(children);
  }

  return children;
}
