import React from 'react';
import { createSvgIcon } from '@mui/material';

const DriveIcon = createSvgIcon(
  <svg width="24px" height="24px" viewBox="0 0.5 24 24" version="1.1">
    <g id="UX-3" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="AA-Dash-Desktop" transform="translate(-878, -170)">
        <g id="Group-5" transform="translate(282, 16)">
          <g id="Group-38" transform="translate(572, 108)">
            <g id="Group-36" transform="translate(0, 34)">
              <g id="GDrive" transform="translate(24, 12)">
                <path
                  d="M1.81443299,19.1410256 L2.87285223,20.8461538 C3.09278351,21.2051282 3.40893471,21.4871795 3.78006873,21.6923077 L7.56013746,15.5897436 L0,15.5897436 C0,15.9871795 0.109965636,16.3846154 0.329896907,16.7435897 L1.81443299,19.1410256 Z"
                  id="Path"
                  fill="#386C58"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M12,8.41025641 L8.21993127,2.30769231 C7.84879725,2.51282051 7.53264605,2.79487179 7.31271478,3.15384615 L0.329896907,14.4358974 C0.114010447,14.7871303 0.000287439982,15.1848874 0,15.5897436 L7.56013746,15.5897436 L12,8.41025641 Z"
                  id="Path"
                  fill="#55A083"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M20.2199313,21.6923077 C20.5910653,21.4871795 20.9072165,21.2051282 21.1271478,20.8461538 L21.5670103,20.1410256 L23.6701031,16.7435897 C23.8900344,16.3846154 24,15.9871795 24,15.5897436 L16.4393127,15.5897436 L18.04811,18.5384615 L20.2199313,21.6923077 Z"
                  id="Path"
                  fill="#386C58"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M12,8.41025641 L15.7800687,2.30769231 C15.4089347,2.1025641 14.9828179,2 14.5429553,2 L9.45704467,2 C9.01718213,2 8.59106529,2.11538462 8.21993127,2.30769231 L12,8.41025641 Z"
                  id="Path"
                  fill="#386C58"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M16.4398625,15.5897436 L7.56013746,15.5897436 L3.78006873,21.6923077 C4.15120275,21.8974359 4.57731959,22 5.01718213,22 L18.9828179,22 C19.4226804,22 19.8487973,21.8846154 20.2199313,21.6923077 L16.4398625,15.5897436 Z"
                  id="Path"
                  fill="#55A083"
                  fillRule="nonzero"
                ></path>
                <path
                  d="M20.1786942,8.79487179 L16.6872852,3.15384615 C16.467354,2.79487179 16.1512027,2.51282051 15.7800687,2.30769231 L12,8.41025641 L16.4398625,15.5897436 L23.9862543,15.5897436 C23.9862543,15.1923077 23.8762887,14.7948718 23.6563574,14.4358974 L20.1786942,8.79487179 Z"
                  id="Path"
                  fill="#55A083"
                  fillRule="nonzero"
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>,
  'Drive'
);

export default DriveIcon;
