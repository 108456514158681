import { baseURL, cookieManager } from '../Common/utilities';

function getParentDomain() {
  const splitHostname = baseURL?.hostname.split('.');
  return splitHostname && (splitHostname.length === 1 ? baseURL?.hostname : splitHostname.slice(1).join('.'));
}

export function setResidenceLoginCookie(authToken: string) {
  cookieManager.set('residence_token', authToken, { domain: getParentDomain() });
}

export function removeResidenceLoginCookie() {
  cookieManager.remove('residence_token', { domain: getParentDomain() });
}
