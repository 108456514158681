import { Grid, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import React from 'react';

import { getValue } from '../utilities';

type Props = {
  size?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  fieldName: string;
  label: string;
  values: any;
  onChange: any;
  options: any;
  errors: any;
  disabled?: any;
  style?: any;
};

const FormSelect: React.FC<Props> = (props) => {
  // render
  return (
    <Grid item xs={props.size}>
      <FormControl fullWidth disabled={props.disabled} margin="normal">
        <InputLabel>{props.label}</InputLabel>
        <Select
          value={getValue(props.values, props.fieldName) ? getValue(props.values, props.fieldName) : ''}
          name={props.fieldName}
          label={props.label}
          onChange={props.onChange}
          error={!!getValue(props.errors, props.fieldName)}
          style={props.style ? props.style : {}}
        >
          {props.options.map((e: { id: number | string; name: string; disabled?: boolean }) => (
            <MenuItem value={e.id} key={e.id} disabled={e.disabled !== undefined ? e.disabled : false}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
        {getValue(props.errors, props.fieldName) ? (
          <FormHelperText error>{getValue(props.errors, props.fieldName)}</FormHelperText>
        ) : (
          <FormHelperText> </FormHelperText>
        )}
      </FormControl>
    </Grid>
  );
};

export default FormSelect;
