import { Button, FormControl, Grid } from '@mui/material';
import React, { useContext } from 'react';
import AuthContext from '../../Common/AuthContext';
import { FormTextField } from '../../Common/FormItems';
import RichTextEditor from '../../Common/TextEditor/RichTextEditor';
import { AuthContextValue } from '../../Common/types';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  submitDisabled: boolean;
  isCreate: boolean;
  removal?: () => void;
};

const EmployeeAgreementForm: React.FC<Props> = (props) => {
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={12}
          fieldName="type_of"
          label="Typ"
          placeholder="type_of"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={true}
          disabled
        />
      </Grid>
      <Grid container spacing={0} style={{ marginBottom: '20px' }}>
        <Grid item xs={12}>
          <RichTextEditor
            handleChange={props.handleChange}
            initialValue={props.values.content}
            fieldName="content"
            subheader="Avtalstext"
            label="Skriv avtalstext här"
            controls={['bold', 'italic', 'underline', 'strikethrough', 'link', 'numberList', 'bulletList']}
          />
        </Grid>
      </Grid>

      <div className="flex justify-center mb-5 p-2">
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className="w-full max-w-40"
          disabled={props.submitDisabled || !hasPermissions(['admin', 'operational_leader'])}
        >
          Spara
        </Button>
      </div>
    </div>
  );
};

export default EmployeeAgreementForm;
