import React from 'react';

import { BaseFilterPopover, FilterDateField, FilterCheckbox } from '../../Common/FilterPopover';
import { ListParams } from '../../Common/types';

type Props = {
  setGetListParams: (p: ListParams) => void;
  listParams: ListParams;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
};

const FilterPopover: React.FC<Props> = (props) => {
  return (
    <BaseFilterPopover>
      <FilterDateField
        fieldName="created_at_start"
        label="Skapad efter"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />

      <FilterDateField
        fieldName="created_at_end"
        label="Skapad före"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />

      <FilterCheckbox
        fieldName="is_active"
        label="Visa inaktiva"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />
    </BaseFilterPopover>
  );
};

export default FilterPopover;
