import React, { Dispatch, MutableRefObject, SetStateAction } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { encode } from 'base-64';
import { Grid, Pagination, Stack } from '@mui/material';
import { StyleSheet, css } from 'aphrodite';
import { ListPagination } from '../../Common/types';

const styles = StyleSheet.create({
  buttonWrapper: {
    marginTop: '20px',
    marginBottom: '20px',
  },
});

type Props = {
  pageTopRef: MutableRefObject<HTMLDivElement | null>;
  pagination: ListPagination;
  setPagination: Dispatch<SetStateAction<ListPagination>>;
  totalCount: number;
};

const CustomPagination: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    props.pageTopRef?.current?.scrollIntoView();
    const query = searchParams.get('query');
    const page = encode(JSON.stringify({ page_size: props.pagination.page_size, page: value }));
    // push a new entry to history stack with the new page number
    navigate(`${location.pathname}?query=${query}&page=${page}`);
    props.setPagination({
      ...props.pagination,
      page: value,
    });
  };

  return (
    <React.Fragment>
      <Grid container justifyContent="center" className={css(styles.buttonWrapper)}>
        <Stack spacing={2}>
          <Pagination
            variant="outlined"
            count={Math.ceil(props.totalCount / props.pagination.page_size)}
            showFirstButton
            showLastButton
            onChange={handleChange}
            page={props.pagination.page}
          />
        </Stack>
      </Grid>
    </React.Fragment>
  );
};

export default CustomPagination;
