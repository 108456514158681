import React, { useState } from 'react';
import { CancelLink } from '../Common/ButtonLinks';
import { Formik, FormikHelpers } from 'formik';
import { InterviewValues, interviewInitialValues, interviewValidation } from './form/formValues';
import InterviewForm from './form/InterviewForm';
import { createInterview, trainingsPerformed } from './interviewApi';
import { useNotify } from '../Common/snackbarHooks';
import { useNavigate } from 'react-router-dom';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import ConfirmationModal from '../Participants/ConfirmationModal';
import { MAX_TRAININGS } from './form/interviewConfig';

export default function CreateInterview() {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();
  const [showTrainingModal, setShowTrainingModal] = useState(false);
  const [previousTrainings, setPreviousTrainings] = useState<number | null>(null);
  const [performOverflowSubmit, setPerformOverflowSubmit] = useState(false);

  async function handleSubmit({ team, date, amount, type, attending_tl, attending_rc, training }: InterviewValues) {
    let previousAmount: number | null = null;
    if (training && !performOverflowSubmit) {
      setPreviousTrainings(null);
      const trainingUser = type === 'ParticipantInterview' ? attending_tl!.id : attending_rc!.id;
      previousAmount = await trainingsPerformed({
        type: type === 'ParticipantInterview' ? 'tl' : 'rc',
        user: trainingUser,
      }).then(({ data }) => data);
      if (previousAmount! >= MAX_TRAININGS || Number(amount) + previousAmount! > MAX_TRAININGS) {
        setPreviousTrainings(previousAmount);
        setShowTrainingModal(true);
        return;
      }
    }
    if (previousTrainings) {
      previousAmount = previousTrainings;
      setPreviousTrainings(null);
    }
    setPerformOverflowSubmit(false);
    if (training && previousAmount! < MAX_TRAININGS && Number(amount) + previousAmount! > MAX_TRAININGS) {
      await createInterview({
        team_id: team!.id,
        date: date!.format('YYYY-MM-DD'),
        amount: Number(amount),
        type: type as 'ParticipantInterview' | 'TeamLeaderInterview',
        attending_tl: attending_tl?.id,
        attending_rc: attending_rc?.id,
        training,
        training_overflow: performOverflowSubmit,
      })
        .then(() => notifySuccess('Intervjutillfällen skapade'))
        .then(() => navigate('/interviews'))
        .catch((error) =>
          notifyError(`Det gick inte att skapa intervjutillfällena: ${HandleError(error.response.data)}`)
        );
    } else {
      await createInterview({
        team_id: team!.id,
        date: date!.format('YYYY-MM-DD'),
        amount: Number(amount),
        type: type as 'ParticipantInterview' | 'TeamLeaderInterview',
        attending_tl: attending_tl?.id,
        attending_rc: attending_rc?.id,
        training: training && previousAmount! < MAX_TRAININGS,
      })
        .then(() => notifySuccess('Intervju har skapats'))
        .then(() => navigate('/interviews'))
        .catch((error) => notifyError(`Det gick inte att skapa intervjun: ${HandleError(error.response.data)}`));
    }
  }

  return (
    <div>
      {/* Title */}
      <div className="flex justify-between items-center h-18 pt-3 mx-8 mb-5">
        <h1 className="text-2xl font-semibold">Lägg till intervju</h1>
        <CancelLink link="/interviews" />
      </div>
      {/* Form */}
      <Formik initialValues={interviewInitialValues} onSubmit={handleSubmit} validationSchema={interviewValidation}>
        {({ values, errors, handleChange, setFieldValue, handleSubmit, isSubmitting, isValid, dirty, submitForm }) => (
          <>
            <form onSubmit={handleSubmit}>
              <InterviewForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                isValid={isValid}
                dirty={dirty}
              />
            </form>
            <ConfirmationModal
              isVisible={showTrainingModal}
              title="Max antal upplärningstillfällen uppnått"
              message={
                previousTrainings! >= MAX_TRAININGS
                  ? `Maximal mängd upplärningstillfällen för ${
                      values.type === 'ParticipantInterview' ? values.attending_tl?.name : values.attending_rc?.name
                    } redan uppnått (${MAX_TRAININGS}). Om du klickar fortsätt kommer "Upplärningstillfälle" avmarkeras.`
                  : `Maximal mängd upplärningstillfällen för ${
                      values.type === 'ParticipantInterview' ? values.attending_tl?.name : values.attending_rc?.name
                    } kommer överstigas. Just nu har hen ${previousTrainings!} av ${MAX_TRAININGS} tillåtna. Ifall du fortsätter kommer intervjuerna delas in i ${
                      MAX_TRAININGS - previousTrainings!
                    } upplärningstillfällen och ${
                      Number(values.amount) + previousTrainings! - MAX_TRAININGS
                    } vanliga intervjuer.`
              }
              closeButtonText="Avbryt"
              confirmButtonText="Fortsätt"
              onClose={() => setShowTrainingModal(false)}
              onConfirm={() => {
                if (previousTrainings! >= MAX_TRAININGS) {
                  setFieldValue('training', false);
                } else {
                  setPerformOverflowSubmit(true);
                  submitForm();
                }
                setShowTrainingModal(false);
              }}
            />
          </>
        )}
      </Formik>
    </div>
  );
}
