import React from 'react';
import { commonStyles, css, StyleSheet, theme } from '../styling';
import { RadioButtonChecked as CallMadeIcon } from '@mui/icons-material';
import { Fab } from '@mui/material';

const styles = StyleSheet.create({
  employedIcon: {
    position: 'relative',
    bottom: '1px',
    paddingRight: '10px',
    verticalAlign: 'middle',
    color: theme.themeGreen,
  },
});

type Props = {
  onClick: any;
  text: string;
};

const EmployFab: React.FC<Props> = (props) => {
  return (
    <Fab
      variant="extended"
      color="inherit"
      aria-label="Employ"
      className={css(commonStyles.selectionFabStyle2)}
      onClick={props.onClick}
    >
      <CallMadeIcon className={css(styles.employedIcon)} />
      {props.text}
    </Fab>
  );
};

export default EmployFab;
