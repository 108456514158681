import { capitalizeFirstLetter } from '../utilities';

/**
 * Formats date according to locale standards
 *
 * @param errorData
 */
export const HandleError = (errorData: any): string => {
  const errorObject = errorData.errors;
  if (typeof errorObject === 'string') {
    return capitalizeFirstLetter(errorDictionary[errorObject] ?? errorObject);
  } else {
    if (Array.isArray(errorObject)) {
      const errorArray: string[] = [];
      errorObject.forEach((element) => {
        if (typeof element === 'string') {
          errorArray.push(`${capitalizeFirstLetter(errorDictionary[element])}`);
        } else {
          Object.entries(element).map(([key, value]: [string, any]) => {
            errorArray.push(`${capitalizeFirstLetter(errorDictionary[key])} ${errorDictionary[value]}`);
          });
        }
      });
      return errorArray.join('\n');
    } else {
      const errorArray = Object.entries(errorObject).map(([key, value]: [string, any]) => {
        return capitalizeFirstLetter(errorDictionary[key]) + ' ' + errorDictionary[value[0]] ?? value[0];
      });
      return errorArray.join('\n');
    }
  }
};

interface ErrorDictionary {
  [index: string]: string;
}
const errorDictionary: ErrorDictionary = {
  // Field names (ordered by name)
  address: 'Gatuaddress',
  birth_date: 'Födelsedatum',
  city: 'Postort',
  create: 'skapande',
  date: 'datum',
  date_range: 'tidsperiod',
  delete: 'borttagning',
  edison_id: 'edison-ID',
  email: 'E-post adressen',
  first_name: 'Förnamn',
  fortnox_id: 'fortnox_id',
  invoice_detail: 'fakturauppgift',
  invoice_detail_errors: 'faktura felmeddelande: ',
  mobile: 'Mobilnummer',
  name: 'Namn',
  org_number: 'org-nummer',
  electronic_reference: 'elektroniskreferens',
  password: 'lösenord',
  password_change: 'lösenordbyte',
  person: 'Vårdnadshavare/nödkontakt',
  'person.mobile': 'Telefon för vårdnadshavare/nödkontakt',
  'people.mobile': 'Något telefon',
  'people.email': 'Något epostadress',
  person_nr: 'Personnummer',
  price_periods: 'prisperioder',
  recipient: 'mottagare',
  'invoice_detail.org_number': 'org-nummer',
  residence_group_errors: 'avtalspart felmeddelande: ',
  salary_detail: 'Clearingnummer eller kontonummer',
  'salary_detail.clearing_number': 'Clearingnummer',
  'salary_detail.account_number': 'Kontonummer',
  surname: 'Efternamn',
  tele: 'Hemtelefon',
  ung_intra_id: 'ung_intra_id',
  update: 'updatering',
  visiting_address: 'adress till boendet',
  zip_code: 'Postnummer',
  title: 'Titeln',
  team: 'Teamet',
  should_be_personnummer: 'skulle vara personnummer',
  'Token expired or invalid':
    "Din förfrågan för att återställa lösenord har gått ut. \r\nVänligen tryck på 'Glömt lösenord' igen och följ instruktionerna.",
  // UngIntra errors (proxied by the backend directly from UngIntra)
  'Duplicate email': 'E-post finns redan i systemet',
  'Duplicate personNr.':
    'En person med angiven personnummer finns redan i databasen och arbetar i ett annat team. OBS. Lägg inte till personen igen utan kontakta info@ungomsorg.se',
  'E-mail already in use.': 'e-post finns redan i systemet',
  // Backend specific errors
  "can't be blank": 'kan inte vara tom(t)',
  'must exist': 'kan inte vara tom(t)',
  'has already been taken': 'finns redan i systemet',
  'is invalid': 'är ogiltig(t)',
  'is an invalid number': 'är ogiltig(t)',
  'must be within the agreements date range': 'Datumet måste ligga inom start- och slutdatum',
  'invalid organisation number': 'är ogiltigt',
  'do not match': 'matchar inte',
  'Zip code invalidated by ung intra': 'ogiltigt postnummer',
  'Invoice detail is invalid': 'faktura har ogiltiga uppgifter',
  'not successfull': 'misslyckades',
  'Validation failed:': 'misslyckades:',
  unknown_clearing_number: 'är ogiltig',
  too_short: 'är för kort',
  too_long: 'är för lång(t)',
  invalid_characters: 'har ogiltiga tecken',
  bad_checksum: 'har fel kontrollsumma',
  not_in_future: 'kan inte vara i framtiden',
  'is too short (minimum is 8 characters)': 'behöver vara mellan 8 och 20 tecken långt.',
  'is too long (maximum is 20 characters)': 'behöver vara mellan 8 och 20 tecken långt.',
  'Invalid e-mail.': 'Ogiltig e-post',
  '#<ActiveRecord::RecordInvalid: Validation failed: Zip code invalidated by ung intra>': 'Felaktigt postnummer.',
  '#<ActiveRecord::RecordInvalid: Validation failed: Invoice detail is invalid>':
    'Något är inte korrekt med fakturauppgifterna, kanske är postnumret inte giltigt.',
  'invalidated by ung intra': 'är ogiltigt',
  'cannot overlap with another agreement of the same residence':
    'kan inte överlappa med ett annat avtal för samma boende',
  email_duplicated:
    'kan inte läggas till då användaren redan finns  i systemet. Lägg inte till personen igen, utan återaktivera dennes gamla profil. Vid frågor kontakta info@ungomsorg.se',
  interview: 'intervju',
  more_than_max_trainings: 'kan inte ge en användare mer än maximal mängd tillåtna upplärningstillfällen',
};
