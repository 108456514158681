import React from 'react';
import { FormControl, TextField, Grid, FilledInputProps, OutlinedInputProps, InputProps } from '@mui/material';

import { getValue } from '../utilities';

type Props = {
  size?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  fieldName: string;
  label: string;
  placeholder: string;
  values: any;
  onChange: any;
  errors: any;
  multiline?: boolean;
  rows?: number;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  className?: string;
  InputProps?: Partial<FilledInputProps> | Partial<OutlinedInputProps> | Partial<InputProps>;
};

const FormTextField: React.FC<Props> = (props) => {
  return (
    <Grid item xs={props.size} className={props.className}>
      <FormControl margin="normal" required fullWidth>
        <TextField
          InputProps={props.InputProps}
          required={props.required}
          InputLabelProps={{ shrink: true }}
          disabled={props.disabled}
          error={!!getValue(props.errors, props.fieldName)}
          name={props.fieldName}
          label={props.label}
          multiline={props.multiline}
          rows={props.rows}
          placeholder={props.placeholder}
          value={getValue(props.values, props.fieldName) ? getValue(props.values, props.fieldName) : ''}
          variant="outlined"
          onChange={props.onChange}
          helperText={getValue(props.errors, props.fieldName) ? getValue(props.errors, props.fieldName) : ' '}
          type={props.type ? props.type : ''}
        />
      </FormControl>
    </Grid>
  );
};

export default FormTextField;
