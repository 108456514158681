import React, { useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { batchDeleteNews } from './../newsApi';
import { NewsInterface } from './../types';

type Props = {
  news: NewsInterface[];
  setModalState: (modalState: boolean) => void;
  fetchNewsList: () => void;
  setSelectedNews: (news: NewsInterface[]) => void;
  setQuickActionNews: (news: NewsInterface[]) => void;
};

const DeleteNewsModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [newsIds, setNewsIds] = useState<number[]>();
  const [loading, setLoading] = useState(true);

  /// Get the news ids into a list
  useEffect(() => {
    setLoading(true);
    const ids: number[] = [];
    props.news.forEach((news) => {
      if (news.id) ids.push(news.id);
    });
    setNewsIds(ids);
    setLoading(false);
  }, [props.news]); // eslint-disable-line

  const handleSubmit = (): void => {
    setLoading(true);
    if (newsIds) {
      batchDeleteNews(newsIds)
        .then(() => notifySuccess('Nyheterna har uppdaterats'))
        .then(() => {
          setLoading(false);
          props.fetchNewsList();
          props.setSelectedNews([]);
          props.setQuickActionNews([]);
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera nyheterna: ${error}`))
        .then(() => props.setModalState(false));
    }
  };

  return (
    <div>
      <DialogTitle>Arkivera nyhet</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>Vill du arkivera de {props.news.length} nyheter som du valt? </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogActions>
              <Button
                fullWidth
                color="error"
                onClick={(): void => {
                  props.setModalState(false);
                  props.setQuickActionNews([]);
                }}
              >
                NEJ
              </Button>
              <Button fullWidth color="primary" onClick={(): void => handleSubmit()}>
                JA
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </div>
  );
};

export default DeleteNewsModal;
