import React, { useState, useEffect, ReactElement, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import {
  getLanguagesMinimalList,
  updateJobApplication,
  getJobApplication,
  batchUpdateJobApplicationStatus,
} from './jobApplicationApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { validationSchema, initialValues } from './jobApplicationSchema';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import JobApplicationForm from './components/JobApplicationForm';
import { BackLink } from '../Common/ButtonLinks';
import { JobApplicationInterface } from './types';
import { getDefaultsWithActualValues } from '../Common/utilities';
import { OptionsInterface } from '../Common/types';
import { getResidenceGroupsMinimalList } from '../ResidenceGroup/residenceGroupApi';

type Props = {
  type: string;
};

const EditJobApplication: React.FC<Props> = (props) => {
  // hooks
  const navigate = useNavigate();
  const params = useParams();
  const id = useMemo(() => {
    const id = Number(params.id);
    return Number.isInteger(id) ? id : null;
  }, [params]);
  const { notifyError, notifySuccess } = useNotify();
  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [residenceGroupsMinimalList, setResidenceGroupsMinimalList] = useState([]);
  const [languageMinimalList, setLanguageMinimalList] = useState([]);
  const [jobApplicationData, setJobApplicationData] = useState<JobApplicationInterface>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (id === null) return;
    setLoading(true);
    fetchLanguagesMinimalList();
    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boenden'));

    getResidenceGroupsMinimalList({ is_active: true })
      .then(({ data }) => setResidenceGroupsMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över avtalspart'));

    getJobApplication(id)
      .then(({ data }) => {
        setJobApplicationData(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över ansökan'))
      .finally(() => {
        setLoading(false);
      });
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (values: typeof initialValues, actions: FormikHelpers<any>): void => {
    handleUpdateJobApplication(values, actions.setSubmitting);
  };

  const fetchLanguagesMinimalList = (): void => {
    getLanguagesMinimalList()
      .then(({ data }) => {
        setLanguageMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över språk'));
  };

  const handleUpdateJobApplication = (
    values: typeof initialValues,
    setSubmitting: (isSubmitting: boolean) => void
  ): void => {
    updateJobApplication(Number(id), {
      first_name: values.first_name,
      surname: values.surname,
      type: props.type === 'participant' ? 'ParticipantJobApplication' : 'TeamLeaderJobApplication',
      address: values.address,
      birth_date: values.birth_date ?? undefined,
      city: values.city,
      zip_code: values.zip_code,
      email: values.email,
      graduate_year: values.graduate_year ?? undefined,
      mobile: values.mobile,
      person_nr: values.person_nr,
      status: values.status,
      team_id: values.team?.id as number | undefined,
      language_ids: values.languages.map((language) => language.id as number),
      manually_tagged_residence_ids: values.manually_tagged_residences.map((residence) => residence.id as number),
    })
      .then(() => notifySuccess('Ansökan är uppdaterad'))
      .then(() => navigate(`/job_applications/${props.type}`))
      .catch((error) => {
        notifyError(`Det gick inte att uppdatera ansökan: ${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  const handleDeleteJobApplication = (): void => {
    if (id === null) return;
    batchUpdateJobApplicationStatus([id], 'deleted')
      .then(() => notifySuccess('Ansökan har arkiverats'))
      .then(() => navigate(`/job_applications/${props.type}`))
      .catch((error: any) => notifyError(`Det gick inte att arkivera ansökan: ${error}`));
  };

  // render
  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={`/job_applications/${props.type}`} />

            <h1 className={css(commonStyles.headerTextStyle)}>
              {props.type === 'participant' ? 'Redigera deltagaransökan' : 'Redigera teamledaransökan'}
            </h1>
          </div>

          <div className={css(commonStyles.formContainer)}>
            {jobApplicationData && (
              <Formik
                enableReinitialize
                validateOnMount={true}
                initialValues={getDefaultsWithActualValues(initialValues, jobApplicationData)}
                validationSchema={validationSchema}
                onSubmit={(values, actions): void => {
                  handleFormSubmit(values, actions);
                }}
              >
                {({
                  values,
                  errors,
                  handleChange,
                  handleSubmit,
                  setFieldValue,
                  isSubmitting,
                  isValid,
                }): ReactElement => (
                  <form onSubmit={handleSubmit}>
                    <JobApplicationForm
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      residenceMinimalList={residenceMinimalList}
                      residenceGroupsMinimalList={residenceGroupsMinimalList}
                      languageMinimalList={languageMinimalList}
                      jobApplication={jobApplicationData}
                      type={props.type}
                      isCreate={false}
                      setFieldValue={setFieldValue}
                      submitDisabled={isSubmitting || !isValid}
                      removal={handleDeleteJobApplication}
                    />
                  </form>
                )}
              </Formik>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditJobApplication;
