import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { Formik, FormikHelpers } from 'formik';
import { Grid } from '@mui/material';

import EditConfirmationModal from './components/EditConfirmationModal';
import { initialEditValues, editValidationSchema } from './teamSchema';
import { getTeam, updateTeam, deleteTeam } from './teamApi';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { BackLink } from '../Common/ButtonLinks';

import TeamForm from './components/TeamForm';
import { TeamInterface } from './types';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { PersonInterface } from '../Common/types';

const EditTeam: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notifyError, notifySuccess, notifyInfo } = useNotify();
  const [teamState, setTeamState] = useState(initialEditValues as TeamInterface);
  const [oneTimeValues, setOneTimeValues] = useState({} as TeamInterface);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);

    getTeam(id)
      .then(({ data }) => setTeamState(data.data))
      .catch(() => notifyError('Det gick inte att hämta teamet'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (values: TeamInterface, actions: FormikHelpers<any>): void => {
    if (!teamState.editable) {
      actions.setSubmitting(false);
      return;
    }

    if (values.is_active) {
      handleUpdateTeam(values, actions.setSubmitting);
    } else {
      setOneTimeValues(values);
      setConfirmDialog(true);
    }
  };

  const handleEditConfirm = (values: TeamInterface, setSubmitting: any): void => {
    setConfirmDialog(false);
    handleUpdateTeam(values, setSubmitting);
  };

  const handleUpdateTeam = (values: TeamInterface, setSubmitting: any): void => {
    updateTeam(teamState.id, values)
      .then(() => notifySuccess('Teamet har uppdaterats'))
      .then(() => navigate('/teams'))
      .catch((error) => {
        notifyError(`Det gick inte att uppdatera teamet: ${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  const handleDeleteTeam = (): void => {
    if (teamState.deletable) {
      deleteTeam(teamState.id)
        .then(() => notifyInfo('Teamet har tagits bort'))
        .then(() => navigate('/teams'))
        .catch(() => notifyError('Det gick inte att ta bort teamet'));
    }
  };

  // render
  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/teams'} />
            <h1 className={css(commonStyles.headerTextStyle)}>Redigera team: {teamState.name}</h1>
          </div>
          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={teamState}
              validationSchema={editValidationSchema}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({
                initialValues,
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                isValid,
                setSubmitting,
              }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <TeamForm
                    team={teamState}
                    values={values}
                    initialValues={initialValues}
                    handleChange={handleChange}
                    errors={errors}
                    setFieldValue={setFieldValue}
                    handleDelete={handleDeleteTeam}
                    isCreate={false}
                    submitDisabled={isSubmitting || !isValid}
                  />
                  <EditConfirmationModal
                    onConfirm={(): void => handleEditConfirm(oneTimeValues, setSubmitting)}
                    onClose={(): void => {
                      setConfirmDialog(false);
                      setSubmitting(false);
                    }}
                    isVisible={confirmDialog}
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditTeam;
