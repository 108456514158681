import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';
import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../Common/styling';
import RegionForm from './components/RegionForm';
import { RegionInterface } from './types';
import { validationSchema, initialValues } from './regionSchema';
import { updateRegion, getRegion, deleteRegion } from './regionApi';
import { getUsersMinimalList } from '../User/usersApi';
import { getResidencesMinimalList } from '../Residence/residenceApi';
import { OptionsInterface } from '../Common/types';
import { getDefaultsWithActualValues } from '../Common/utilities';
import { MinimalUser } from '../User/types';

const EditRegion: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyInfo, notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);
  const [loadingResidences, setLoadingResidences] = useState(false);
  const [loadingRC, setLoadingRC] = useState(false);
  const [loadingVA, setLoadingVA] = useState(false);

  const [residenceMinimalList, setResidenceMinimalList] = useState<OptionsInterface[]>([]);
  const [rcMinimalList, setRcMinimalList] = useState<MinimalUser[]>([]);
  const [vaMinimalList, setVaMinimalList] = useState<MinimalUser[]>([]);
  const { id } = useParams() as { id: string };
  const [region, setRegion] = useState<RegionInterface>(initialValues);

  // functions
  useEffect(() => {
    fetchRegion();
    fetchVAs();
    fetchResidences();
  }, []); // eslint-disable-line

  useEffect(() => {
    if (region.id !== 0) {
      fetchRCs();
    }
  }, [region]); // eslint-disable-line

  const fetchRCs = (): void => {
    setLoadingRC(true);
    getUsersMinimalList({ hide_banned: true, filter_by_role_name: 'regional_manager' })
      .then(({ data }) => {
        const rc_list = data.data;
        const regional_manager = region.regional_manager;

        if (regional_manager) {
          const found = rc_list.find((rm: OptionsInterface) => rm.id === regional_manager.id);
          // Manually add the region's RC if it's not in the minimal list
          if (!found && region.regional_manager) {
            rc_list.push(region.regional_manager);
          }
        }
        setRcMinimalList(rc_list);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över regionala chefer'))
      .finally(() => setLoadingRC(false));
  };

  const fetchVAs = (): void => {
    setLoadingVA(true);
    getUsersMinimalList({ hide_banned: true, filter_by_role_name: 'operational_manager' })
      .then(({ data }) => setVaMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över verksamhetschefer'))
      .finally(() => setLoadingVA(false));
  };

  const fetchResidences = (): void => {
    setLoadingResidences(true);
    getResidencesMinimalList({ is_active: true })
      .then(({ data }) => setResidenceMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över boende'))
      .finally(() => setLoadingResidences(false));
  };

  const fetchRegion = (): void => {
    setLoading(true);
    getRegion(id)
      .then(({ data }) => setRegion(data.data))
      .catch(() => notifyError('Det gick inte att hämta regionen'))
      .finally(() => setLoading(false));
  };

  const archiveRegion = (): void => {
    setLoading(true);
    deleteRegion(region.id)
      .then(() => notifyInfo('Nu kommer regionen att arkiveras. Du får ett mejl så snart det är klart.'))
      .then(() => navigate(`/regions`))
      .catch((error: any) => notifyError(`Det gick inte att arkivera regionen: ${error}`))
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateRegion = (values: Partial<RegionInterface>, actions: FormikHelpers<any>): void => {
    setLoading(true);
    updateRegion(region.id, {
      name: values.name,
      operational_manager_id: (values.operational_manager?.id as string) || '',
      regional_manager_id: (values.regional_manager?.id as string) || '',
      residence_ids: values.residences?.map(({ id }) => id as number),
    })
      .then(() => notifySuccess('Regionen är uppdaterad'))
      .then(() => navigate(`/regions`))
      .catch((error: any) => {
        notifyError(`Det gick inte att uppdatera regionen: \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // render
  return (
    <React.Fragment>
      {(loading || loadingResidences || loadingVA || loadingRC) && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading || loadingResidences || loadingVA || loadingRC} />
        </div>
      )}{' '}
      {!loading && !loadingResidences && !loadingVA && !loadingRC && (
        <div>
          <div className={css(commonStyles.createViewHeader)}>
            <h1 className={css(commonStyles.headerTextStyle)}>Redigera region</h1>
            <CancelLink link={`/regions`} />
          </div>

          <div className={css(commonStyles.formContainer)}>
            <Formik
              initialValues={getDefaultsWithActualValues(initialValues, region)}
              validationSchema={validationSchema}
              onSubmit={(values, actions): void => {
                handleUpdateRegion(values, actions);
              }}
            >
              {({
                values,
                errors,
                handleChange,
                handleSubmit,
                setFieldValue,
                isSubmitting,
                isValid,
                dirty,
              }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <RegionForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    isCreate={false}
                    setFieldValue={setFieldValue}
                    submitDisabled={isSubmitting || !isValid || !dirty}
                    removal={archiveRegion}
                    residenceMinimalList={residenceMinimalList}
                    rcMinimalList={rcMinimalList}
                    vaMinimalList={vaMinimalList}
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditRegion;
