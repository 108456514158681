import React, { ReactNode } from 'react';
import { Collapse, Divider, List, ListItem, ListItemButton } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { twMerge } from 'tailwind-merge';

type Props = {
  label: string;
  expandKey: number;
  expanded: boolean[];
  open: boolean;
  onTrigger: (expandedAreas: boolean[]) => void;
  condition?: boolean;
  children: ReactNode;
};

export default function MenuCollapseControl({
  label,
  expandKey,
  expanded,
  open,
  onTrigger,
  condition = true,
  children,
}: Props) {
  if (!condition) return null;

  return (
    <List disablePadding>
      <ListItem disablePadding className="bg-menuGreen text-white h-7">
        <ListItemButton
          className={twMerge(
            'text-sm leading-7 h-7 px-4 font-semibold !flex',
            open ? '!justify-between' : '!justify-end'
          )}
          onClick={() =>
            onTrigger([...expanded.slice(0, expandKey), !expanded[expandKey], ...expanded.slice(expandKey + 1)])
          }
        >
          {open && <p>{label}</p>}
          <span className={twMerge('transition-all', !open && 'pr-1')}>
            {expanded[expandKey] ? <ExpandLess /> : <ExpandMore />}
          </span>
        </ListItemButton>
      </ListItem>
      <Collapse in={!!expanded[expandKey]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {children}
        </List>
      </Collapse>
    </List>
  );
}
