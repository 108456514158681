import React from 'react';

import { Grid, Paper, Typography } from '@mui/material';

type Props = {
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  value: string;
};

const TextLabel: React.FC<Props> = (props) => {
  return (
    <Grid item xs={props.size}>
      <Paper elevation={0} square>
        <Typography
          style={{
            padding: '16px',
            fontWeight: 'normal' as const,
            fontSize: '1em',
          }}
        >
          {props.value}
        </Typography>
      </Paper>
    </Grid>
  );
};

export default TextLabel;
