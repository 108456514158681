import * as React from 'react';
import { Dayjs } from 'dayjs';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { UseDateFieldProps } from '@mui/x-date-pickers/DateField';
import { BaseSingleInputFieldProps, DateValidationError, DateView, FieldSection } from '@mui/x-date-pickers/models';
import { twMerge } from 'tailwind-merge';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { PickersActionBarAction } from '@mui/x-date-pickers';

interface ButtonFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  placeholder?: string;
  showArrow?: boolean;
}

function ButtonField({
  setOpen,
  label,
  placeholder,
  showArrow = true,
  id,
  disabled,
  value,
  InputProps: { ref } = {},
  inputProps: { 'aria-label': ariaLabel } = {},
  className,
}: ButtonFieldProps) {
  return (
    <Button
      variant="text"
      id={id}
      sx={{
        textTransform: 'none',
        fontSize: '16px',
        fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
        paddingTop: '2px',
      }}
      disabled={disabled}
      ref={ref}
      aria-label={ariaLabel}
      onClick={() => setOpen?.((prev) => !prev)}
      className={twMerge('h-full !rounded-none relative !py-0', className)}
    >
      <div
        style={{ color: value ? '#202020' : '#a2a2a2' }}
        className={twMerge('text-left whitespace-nowrap text-ellipsis w-full pr-7', value && 'first-letter:uppercase')}
      >
        {value ? label : placeholder}
      </div>
      {showArrow && <ArrowDropDownIcon className="absolute right-0" sx={{ color: '#808080' }} />}
    </Button>
  );
}

type Props = {
  placeholder?: string;
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  views: readonly DateView[];
  format?: string;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  actions?: PickersActionBarAction[];
  showArrow?: boolean;
  disabled?: boolean;
  className?: string;
};

const FilterDatePicker: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  views,
  format,
  minDate,
  maxDate,
  actions,
  showArrow,
  disabled,
  className,
}) => {
  const [open, setOpen] = React.useState(false);

  const buttonProps: ButtonFieldProps = { setOpen, placeholder, showArrow, className };

  if (!format) {
    const tempFormat = [];
    if (views.includes('day')) tempFormat.push('D');
    if (views.includes('month')) tempFormat.push('MMMM');
    if (views.includes('year')) tempFormat.push('YYYY');
    format = tempFormat.join(' ');
  }

  return (
    <Stack className="h-full">
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slots={{ field: ButtonField }}
        slotProps={{
          field: buttonProps as any,
          actionBar: { actions },
        }}
        label={value?.format(format)}
        views={views}
        value={value}
        onChange={onChange}
        sx={{ color: '#A0A0A0' }}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Stack>
  );
};

export default FilterDatePicker;
