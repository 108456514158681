import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { PersonInterface } from '../../Common/types';

interface Props {
  isVisible: boolean;
  person: PersonInterface | undefined;
  onConfirm: () => void;
  onClose: () => void;
}

const ConfirmDeletePrtsonModal: React.FC<Props> = (props: Props) => {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose}>
      <DialogTitle>Ta bort personen?</DialogTitle>
      <DialogContent>
        <DialogContentText>Vill du ta bort {props?.person?.name}?</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} fullWidth color="error">
          Avbryt
        </Button>
        <Button onClick={props.onConfirm} fullWidth color="primary">
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDeletePrtsonModal;
