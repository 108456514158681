import React from 'react';
import TableSortLabel from '@mui/material/TableSortLabel/TableSortLabel';
import { Tooltip, TableCell, TableHead, TableRow } from '@mui/material';
import { commonStyles, css } from '../styling';
import { sort_order } from '../types';
import ConditionalParent from '../ConditionalParent';

export type CustomTableHeadColumn = {
  id: string;
  label: string;
  tooltip?: string;
  notSortable?: boolean;
  show?: boolean;
  minWidth?: string | number;
};

type CustomTableHeadProps = {
  rows: CustomTableHeadColumn[];
  maxWidth?: string;
  listParams: any;
  setGetListParams: any;
  checkboxComponent?: React.ReactNode;
};

const CustomTableHead: React.FC<CustomTableHeadProps> = (props) => {
  const sortList = (sortBy: string): void => {
    if (props.listParams.sort_by === sortBy) {
      const order = props.listParams.sort_order === sort_order.asc ? sort_order.desc : sort_order.asc;
      props.setGetListParams({ ...props.listParams, sort_order: order });
    } else {
      props.setGetListParams({ ...props.listParams, sort_by: sortBy });
    }
  };

  return (
    <TableHead>
      <TableRow classes={{ root: css(commonStyles.themeGreenGreyLightRow) }}>
        {props.checkboxComponent && <TableCell padding="checkbox">{props.checkboxComponent}</TableCell>}

        {props.rows
          .filter((row: CustomTableHeadColumn) => row.show === undefined || row.show === true)
          .map((row: CustomTableHeadColumn) => (
            <ConditionalParent
              key={row.id}
              on={row.tooltip}
              parent={(children) => (
                <Tooltip title={row.tooltip} placement="top">
                  {children}
                </Tooltip>
              )}
            >
              <TableCell
                align={'left'}
                sx={{ maxWidth: props.maxWidth, whiteSpace: 'nowrap', overflow: 'hidden' }}
                sortDirection={props.listParams.sort_by === row.id ? props.listParams.sort_order : false}
                onClick={row.notSortable ? (): void => {} : (): void => sortList(row.id)} // eslint-disable-line @typescript-eslint/no-empty-function
                style={{ minWidth: row.minWidth }}
              >
                {row.notSortable ? (
                  row.label
                ) : (
                  <Tooltip title="Sortera" placement="bottom-start" enterDelay={300}>
                    <TableSortLabel
                      active={props.listParams.sort_by === row.id}
                      direction={props.listParams.sort_order}
                    >
                      {row.label}
                    </TableSortLabel>
                  </Tooltip>
                )}
              </TableCell>
            </ConditionalParent>
          ))}
      </TableRow>
    </TableHead>
  );
};

export default CustomTableHead;
