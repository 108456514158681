import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { KeyboardArrowDown } from '@mui/icons-material';
import { twMerge } from 'tailwind-merge';
import { Collapse, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { convertNumToTime } from '../../Common/utilities';
import { ResidenceMonthData } from '../types';

type Props = {
  month: Dayjs;
  /** Leaving data as `undefined` is to be used only as a temporary non-functional loading skeleton state */
  data?: Omit<ResidenceMonthData, 'month'>;
};

export default function ResidenceReportedMonth({ month, data }: Props) {
  const [expanded, setExpanded] = useState(data && month.isSame(dayjs(), 'month'));

  return (
    <div className="w-full border border-black/10 shadow-sm rounded-lg overflow-hidden">
      <button
        onClick={data && (() => setExpanded(!expanded))} // Only interactable if data exists
        className="w-full h-14 bg-neutral-50 flex justify-between items-center px-4 group cursor-pointer !ring-0"
      >
        <span className="text-lg leading-none font-semibold text-black/85 capitalize">{month.format('MMMM YYYY')}</span>
        <div className="flex items-center gap-2">
          <span className="text-xs text-black/85 tracking-widest leading-none uppercase">
            {!data || data.reported_hours ? `${data?.reported_hours ?? 0} timmar rapporterade` : 'Inga timmar'}
          </span>
          <div className="text-2xl bg-transparent group-hover:bg-gray-700/10 p-2 transition rounded-lg">
            <div className={twMerge('transition w-6 h-6 flex items-center justify-center', expanded && 'rotate-180')}>
              <KeyboardArrowDown fontSize="inherit" />
            </div>
          </div>
        </div>
      </button>

      <Collapse in={expanded} appear>
        {data?.reported_hours ? (
          <TableContainer className="border-t border-t-black/10 text-xs">
            <Table>
              <TableHead>
                <TableRow className="!bg-neutral-100">
                  <TableCell className="!bg-neutral-200">
                    <div className="text-base w-52">Anställd</div>
                  </TableCell>
                  {data.work_shifts.map((ws) => (
                    <TableCell key={ws.id} className="border-l border-l-black/10 !py-2.5">
                      <div className="text-sm leading-none h-full flex flex-col justify-center w-48">
                        <p className="font-semibold capitalize mb-2">
                          {dayjs(ws.date).format('ddd D MMM YYYY')} {convertNumToTime(ws.start_time)}-
                          {convertNumToTime(ws.end_time)}
                        </p>
                        <p>{ws.activity}</p>
                      </div>
                    </TableCell>
                  ))}
                  <TableCell
                    className={`!w-full !p-0${
                      data.work_shifts.length > 0 && ' !shadow-black/10 !shadow-[inset_1px_0px_0px_0px]'
                    }`}
                  />
                </TableRow>
              </TableHead>
              <TableBody>
                {data.participants.map((p) => (
                  <TableRow key={p.id}>
                    <TableCell>
                      <div className="text-sm max-h-1lh flex flex-col justify-center">
                        <p className="!font-semibold">{p.name}</p>
                        {p.role.short_name !== 'D' && <p className="italic">{p.role.name}</p>}
                      </div>
                    </TableCell>
                    {data.work_shifts.map((ws) => {
                      const userShift = p.shifts.find((shift) => shift.work_shift_id === ws.id);
                      return (
                        <TableCell key={ws.id}>
                          <div className="text-sm leading-none w-44">
                            {userShift ? `${userShift.hours} (${userShift.salary_class.name})` : '–'}
                          </div>
                        </TableCell>
                      );
                    })}
                    <TableCell className="!p-0"></TableCell>
                  </TableRow>
                ))}
                <TableRow className="!bg-[#edf5ef]">
                  <TableCell className="!font-semibold" sx={{ fontSize: '14px !important' }}>
                    Godkänd av
                  </TableCell>
                  {data.work_shifts.map((ws) => (
                    <TableCell key={ws.id} className="!italic" sx={{ fontSize: '14px !important' }}>
                      {ws.approved_by}
                    </TableCell>
                  ))}
                  <TableCell className="!p-0" />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <p className="py-6 text-sm text-center border-t border-t-black/10">
            Inga timmar rapporterade för <span className="font-semibold">{month.format('MMMM')}</span> månad
          </p>
        )}
      </Collapse>
    </div>
  );
}
