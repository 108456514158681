import React from 'react';

import { FormDateField } from '../../Common/FormItems';
import { Button, FormControl, Grid } from '@mui/material';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
  setModalState: any;
};

export const DataDumpForm: React.FC<Props> = (props) => {
  return (
    <div>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <FormDateField
          size={12}
          fieldName={`end_date`}
          label="Slutdatum"
          placeholder="Slutdatum"
          values={props.values}
          errors={props.errors}
          onChange={props.setFieldValue}
        />
      </Grid>
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={6}>
          <FormControl variant="standard" margin="dense" fullWidth>
            <Button variant="contained" color="error" onClick={(): void => props.setModalState(false)}>
              Avbryt
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <FormControl variant="standard" margin="dense" fullWidth>
            <Button type="submit" variant="contained" color="primary">
              Skapa datadump
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
