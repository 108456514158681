import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import { ResidenceClusterInterface } from './types';
import API from '../Common/axios';

export const getResidenceClusterList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/residence_clusters', { params });
};

export const getResidenceClustersMinimalList = (): Promise<AxiosResponse> => {
  return API.get('v1/residence_clusters/minimal_list');
};

export const createResidenceCluster = (
  residence_cluster: Omit<ResidenceClusterInterface, 'residences'> & { residence_ids: number[] }
): Promise<AxiosResponse> => {
  return API.post('v1/residence_clusters', { residence_cluster });
};

export const updateResidenceCluster = (
  id: number | undefined,
  residence_cluster: Omit<ResidenceClusterInterface, 'residences'> & { residence_ids: number[] }
): Promise<AxiosResponse> => {
  return API.patch(`v1/residence_clusters/${id}`, { residence_cluster });
};

export const deleteResidenceCluster = (id: number | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/residence_clusters/${id}`);
};

export const getResidenceCluster = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/residence_clusters/${id}`);
};

export const batchDeleteResidenceClusters = (ids: (number | undefined)[]): Promise<AxiosResponse> => {
  return API.patch(`v1/residence_clusters/batch_destroy`, { residence_cluster_ids: ids });
};
