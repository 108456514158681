import React, { useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';
import { Link } from 'react-router-dom';

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { commonStyles, css } from '../../Common/styling';
import { UserInterface } from './../types';
import { getPayslips, updateUser } from './../usersApi';
import { Payslip } from '../../Common/types';
import { useNotify } from '../../Common/snackbarHooks';

type PayslipsModalProps = {
  user: UserInterface;
  isVisible: boolean;
  onClose?: () => void;
};

export default function PayslipsModal({ user, isVisible, onClose }: PayslipsModalProps) {
  const { notifyError } = useNotify();
  const [payslips, setPayslips] = useState<Payslip[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isVisible) {
      fetchPayslips();
    }
  }, [isVisible]);

  function fetchPayslips() {
    if (!user) return;
    setLoading(true);
    getPayslips(user.id)
      .then(({ data }) => setPayslips(data))
      .catch((error) => notifyError(`Det gick inte att hämta lönebesked.`))
      .finally(() => setLoading(false));
  }

  return (
    <Dialog open={isVisible} onClose={onClose} scroll="paper" fullWidth>
      <DialogTitle>Lönesbesked för {user?.first_name}</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <DialogContent className="!py-2">
          {Array.isArray(payslips) && payslips.length > 0 ? (
            payslips.map((payslip: Payslip) => (
              <DialogContentText key={payslip.name}>
                <Link to={payslip.url} target="_blank">
                  {payslip.name}
                </Link>
              </DialogContentText>
            ))
          ) : (
            <DialogContentText>Det finns inga lönebesked att visa.</DialogContentText>
          )}
        </DialogContent>
      )}
      <DialogActions className="!pb-0">
        <Button fullWidth color="error" onClick={onClose}>
          STÄNG
        </Button>
      </DialogActions>
    </Dialog>
  );
}
