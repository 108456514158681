import React from 'react';
import { CancelLink } from '../Common/ButtonLinks';
import { Formik } from 'formik';
import { ExtraHourValues, extraHourInitialValues, extraHourValidation } from './form/formValues';
import ExtraHourForm from './form/ExtraHourForm';
import { createExtraHour } from './extraHoursApi';
import { useNotify } from '../Common/snackbarHooks';
import { useNavigate } from 'react-router-dom';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';

export default function CreateExtraHour() {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();

  async function handleSubmit({ activity, date, salary_class, individual_hours, users }: ExtraHourValues) {
    await createExtraHour({
      activity: activity!,
      date: date!.format('YYYY-MM-DD'),
      salary_class_id: salary_class!.id,
      individual_hours: individual_hours!,
      extra_hours_users_attributes: users.map((u) => ({ user_id: u.id })),
    })
      .then(() => notifySuccess('Extra arbetstimme har skapats'))
      .then(() => navigate('/extra-hours'))
      .catch((error) => notifyError(`Det gick inte att skapa extra arbetstimmen: ${HandleError(error.response.data)}`));
  }

  return (
    <div>
      {/* Title */}
      <div className="flex justify-between items-center h-18 pt-3 mx-8 mb-5">
        <h1 className="text-2xl font-semibold">Lägg till extra arbetstimmar</h1>
        <CancelLink link="/salary-classes" />
      </div>
      {/* Form */}
      <Formik initialValues={extraHourInitialValues} onSubmit={handleSubmit} validationSchema={extraHourValidation}>
        {({ values, errors, handleChange, setFieldValue, handleSubmit, isSubmitting, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <ExtraHourForm
              values={values}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}
