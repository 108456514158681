import React, { PropsWithChildren } from 'react';

type DashboardItemProps = PropsWithChildren<{ title: string; className?: string }>;

export default function DashboardItem({ title, className, children }: DashboardItemProps) {
  return (
    <div className="mb-10">
      <p className="font-medium text-neutral-600 mb-3">{title}</p>
      <div className={className}>{children}</div>
    </div>
  );
}
