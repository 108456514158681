import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableHead, TableRow } from '../../Common/Table';
import { sort_order } from '../../Common/types';
import { ListParams } from '../../Common/types';
import { Grid, Table, TableBody, TableCell } from '@mui/material';
import { RotateLoader } from 'react-spinners';

import { EducationInterface } from './types';
import { useNotify } from '../../Common/snackbarHooks';
import { isEmpty } from '../../Common/utilities';
import EmptyList from '../../Common/Table/EmptyList';
import { css, commonStyles } from '../../Common/styling';
import { getEducations } from './EducationApi';

export const filterSortDefaults = {
  query: '',
  sort_by: 'ranking',
  sort_order: sort_order.asc,
  is_active: true,
};

const Educations: React.FC = () => {
  // hooks
  const rows = [
    { id: 'education', label: 'Namn' },
    { id: 'education_type', label: 'Typ' },
  ];

  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const [educations, setEducations] = useState<Array<EducationInterface>>([]);
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchEducations();
  }, [listParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // functions
  const fetchEducations = (): void => {
    setLoading(true);
    getEducations()
      .then((response) => {
        setEducations(response.data.educations);
      })
      .catch(() => notifyError('Det gick inte att hämta utbildningar'))
      .finally(() => setLoading(false));
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <h1 className={css(commonStyles.headerTextStyle)}>Utbildningar</h1>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(educations) ? (
              <EmptyList />
            ) : (
              educations.map((row: EducationInterface, idx: number) => (
                <TableRow row={row} idx={idx} key={idx} onClick={(): void => navigate(`/education/${row.id}`)}>
                  <TableCell scope="row" width="200">
                    <b>{row.name}</b>
                  </TableCell>
                  <TableCell scope="row">
                    <b>{row.education_type}</b>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Educations;
