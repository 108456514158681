import dayjs, { Dayjs } from 'dayjs';
import * as Yup from 'yup';
import { MinimalSalaryClass } from '../../SalaryClass/types';
import { UserListInterface } from '../../User/types';

export const extraHourInitialValues = {
  activity: '',
  date: null as Dayjs | null,
  individual_hours: null as number | null,
  salary_class: null as MinimalSalaryClass | null,
  users: [] as UserListInterface[],
};

export type ExtraHourValues = typeof extraHourInitialValues;

export const extraHourValidation = Yup.object({
  activity: Yup.string().required('* Krävs'),
  date: Yup.mixed()
    .test('required', '* Required', (value) => !!value)
    .test('valid-date', 'Måste vara ett giltigt datum', (value) => value?.isValid())
    .test('not-future', 'Kan inte vara i framtiden', (value) => value?.isBefore(dayjs().add(1, 'day'), 'date')),
  individual_hours: Yup.number().typeError('* Krävs').required('* Krävs'),
  salary_class: Yup.mixed().required('* Krävs'),
  users: Yup.array(Yup.mixed()).min(1, '* Krävs'),
});
