import * as Yup from 'yup';

export const salaryClassInitialValues = {
  name: '',
  short_name: '',
  edison_id: '',
};

export type SalaryClassFormValues = typeof salaryClassInitialValues;

export const salaryClassValidation = Yup.object({
  name: Yup.string().min(1, '* Krävs').required('* Krävs'),
  short_name: Yup.string().min(1, '* Krävs').required('* Krävs'),
  edison_id: Yup.number().typeError('Måste vara ett nummer').integer('Måste vara ett heltal').required('* Krävs'),
});
