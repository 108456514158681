import React from 'react';
import { EventNoteOutlined } from '@mui/icons-material';
import { useOutletContext } from 'react-router';

const ScheduleNoTeam: React.FC = () => {
  const onClick = useOutletContext<() => void>();

  return (
    <div className="flex justify-center items-center min-h-[calc(100vh-8rem)] pb-24">
      <button
        onClick={onClick}
        className="px-5 py-9 rounded-xl transition duration-300 text-black/60 hover:bg-gray-400/10"
      >
        <EventNoteOutlined fontSize="large" />
        <p className="my-4 font-medium">Välj ett team för att se deras schema</p>
      </button>
    </div>
  );
};

export default ScheduleNoTeam;
