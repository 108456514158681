import React from 'react';

import { getValue } from '../utilities';

import { FormControlLabel, Grid, Switch } from '@mui/material';

type Props = {
  size: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | undefined;
  fieldName: string;
  label: string;
  values: any;
  onChange: any;
  disabled?: boolean;
  toggle_size?: 'small' | 'medium' | undefined;
  font_size?: string;
  font_color?: string;
  style?: string;
};

const FormToggle: React.FC<Props> = (props) => {
  return (
    <Grid item xs={props.size}>
      <FormControlLabel
        label={<span className={props.style}>{props.label}</span>}
        control={
          <Switch
            size={props.toggle_size ? props.toggle_size : 'medium'}
            name={props.fieldName}
            disabled={props.disabled}
            checked={getValue(props.values, props.fieldName)}
            onChange={props.onChange}
          />
        }
      />
    </Grid>
  );
};

export default FormToggle;
