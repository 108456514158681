import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  CircularProgress,
} from '@mui/material';
import { convertNumToTime, lastLocationState } from '../../Common/utilities';
import { createUsersWorkShift, deleteUsersWorkShift, getDetailedMonth } from './../scheduleApi';
import { DetailedMonth, MinimalMonth, ScheduledUser } from '../../Common/types';
import dayjs from 'dayjs';
import { Error as ErrorIcon, GroupAdd, KeyboardArrowDown, Person } from '@mui/icons-material';
import { Link, useLocation } from 'react-router-dom';
import { useNotify } from '../../Common/snackbarHooks';

interface MonthProps {
  minimalMonth: MinimalMonth;
  teamId: string;
  defaultExpanded?: boolean;
}

const Month: React.FC<MonthProps> = ({ minimalMonth, teamId, defaultExpanded = false }) => {
  const [hours, setHours] = useState(minimalMonth);
  const [expanded, setExpanded] = useState(defaultExpanded);
  const [detailedMonth, setDetailedMonth] = useState<DetailedMonth>();
  const [loadingMonth, setLoadingMonth] = useState(true);

  const { notifyError } = useNotify();
  const location = useLocation();

  useEffect(() => {
    if (expanded && !detailedMonth) {
      getDetailedMonth(teamId, minimalMonth.month)
        .then(({ data }) => setDetailedMonth(data))
        .catch(() => notifyError('Kunde inte läsa in månaden'))
        .finally(() => setLoadingMonth(false));
    }
  }, [expanded, detailedMonth, teamId]);

  const onExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const hasHours = !!hours.not_reported_hours || !!hours.reported_hours || !!hours.scheduled_hours;
  const currentDate = dayjs().format('YYYY-MM-DD');

  const handleTimeChange = useCallback((change: number, workShift: DetailedMonth['work_shifts'][number]) => {
    const now = dayjs();
    if (workShift.reported_at) {
      setHours((hours) => ({ ...hours, reported_hours: hours.reported_hours + change }));
    } else if (dayjs(workShift.date).isAfter(now, 'day')) {
      setHours((hours) => ({ ...hours, scheduled_hours: hours.scheduled_hours + change }));
    } else {
      setHours((hours) => ({ ...hours, not_reported_hours: hours.not_reported_hours + change }));
    }
  }, []);

  return (
    <div className="w-full flex-grow shadow-std">
      <button
        className={twMerge(
          'w-full h-14 bg-white px-6 flex items-center justify-between outline-none border-none [&_.expand-icon-target]:hover:!bg-gray-100 cursor-pointer',
          expanded && 'border-solid border-0 border-b border-b-gray-200'
        )}
        onClick={onExpand}
      >
        <h2 className="text-lg font-semibold leading-none m-0 first-letter:capitalize">
          {dayjs(minimalMonth.month, 'YYYY-MM').format('MMMM YYYY')}
        </h2>
        <div className="flex items-center gap-1">
          <div className="flex text-sm items-center gap-2">
            {hasHours ? (
              <>
                <HourReport hours={hours.not_reported_hours} kind="not-reported" />
                <HourReport hours={hours.reported_hours} kind="reported" />
                <HourReport hours={hours.scheduled_hours} kind="scheduled" />
              </>
            ) : (
              <p className="m-0 leading-none uppercase">Inga timmar</p>
            )}
          </div>
          <div className="expand-icon-target text-2xl transition p-2 rounded-lg">
            <div className={twMerge('transition w-6 h-6 flex items-center', expanded && 'rotate-180')}>
              <KeyboardArrowDown fontSize="inherit" />
            </div>
          </div>
        </div>
      </button>
      {expanded && (
        <div className="w-full">
          {loadingMonth ? (
            <div className="py-3 flex justify-center">
              <CircularProgress />
            </div>
          ) : detailedMonth ? (
            <TableContainer>
              <Table className="bg-white">
                <TableHead>
                  <TableRow className="bg-gray-200">
                    <TableCell className="!p-0">
                      <div className="text-base font-bold flex gap-2 items-center px-4 py-3 w-60">
                        <Person />
                        <p>Anställd</p>
                      </div>
                    </TableCell>
                    {detailedMonth.work_shifts.map((ws) => (
                      <TableCell
                        key={ws.date}
                        className={twMerge(
                          'border-solid border-0 border-l border-slate-400/60 !p-0',
                          ws.date > currentDate && 'bg-gray-300',
                          ws.date <= currentDate && ws.reported_at && 'bg-[#99dcaa]/60',
                          ws.date <= currentDate && !ws.reported_at && 'bg-red-200/80'
                        )}
                      >
                        <Link to={`/work-shifts/${ws.id}`} state={lastLocationState(location)}>
                          <div
                            className={twMerge(
                              'font-bold leading-none px-3 py-2 w-48 text-center',
                              ws.date > currentDate && 'text-gray-800',
                              ws.date <= currentDate && ws.reported_at && 'text-[#286d3b]',
                              ws.date <= currentDate && !ws.reported_at && 'text-red-600'
                            )}
                          >
                            <p className="first-letter:uppercase underline mb-1 whitespace-nowrap">
                              {dayjs(ws.date).format('ddd D MMM YYYY')}
                            </p>
                            <p className="underline whitespace-nowrap">
                              {convertNumToTime(ws.start_time)}-{convertNumToTime(ws.end_time)}
                            </p>
                            <p
                              className="text-black font-normal mt-2 whitespace-nowrap overflow-hidden text-ellipsis"
                              title={ws.activity ?? undefined}
                            >
                              {ws.activity}
                            </p>
                          </div>
                        </Link>
                      </TableCell>
                    ))}
                    <TableCell
                      className={`!w-full !p-0 !shadow-slate-400/60${
                        detailedMonth.work_shifts.length > 0 && ' !shadow-[inset_1px_0px_0px_0px]'
                      }`}
                    />
                  </TableRow>
                </TableHead>

                <TableBody>
                  {detailedMonth.participants.map((user, index) => (
                    <UserRow
                      key={user.id}
                      user={user}
                      detailedMonth={detailedMonth}
                      alternateColor={index % 2 === 1}
                      currentDate={currentDate}
                      onTimeChange={handleTimeChange}
                    />
                  ))}
                  {detailedMonth.substitutes.length > 0 && (
                    <TableRow>
                      <TableCell colSpan={detailedMonth.work_shifts.length + 2} className="!pb-2 !pt-4">
                        <div className="text-base font-semibold flex gap-2 items-center">
                          <GroupAdd />
                          <p>Vikarier</p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                  {detailedMonth.substitutes.map((user, index) => (
                    <UserRow
                      key={user.id}
                      user={user}
                      detailedMonth={detailedMonth}
                      alternateColor={index % 2 === 1}
                      currentDate={currentDate}
                      onTimeChange={handleTimeChange}
                      substitute
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default Month;

interface UserRowProps {
  detailedMonth: DetailedMonth;
  user: ScheduledUser;
  alternateColor?: boolean;
  substitute?: boolean;
  currentDate?: string;
  onTimeChange?: (change: number, workShift: DetailedMonth['work_shifts'][number]) => void;
}

const UserRow: React.FC<UserRowProps> = ({
  detailedMonth,
  user,
  alternateColor = false,
  substitute = false,
  currentDate = dayjs().format('YYYY-MM-DD'),
  onTimeChange,
}) => {
  return (
    <TableRow key={user.id} className={alternateColor ? 'bg-gray-100' : ''}>
      <TableCell className="!p-0" title={user.name}>
        <Link to={`/users/${user.id}`}>
          <div className="text-black h-full px-4 py-1 min-h-10 flex flex-col justify-center">
            <p className="!font-semibold line-clamp-2 text-ellipsis break-words">{user.name}</p>
            {substitute && <p>{user.team}</p>}
          </div>
        </Link>
      </TableCell>
      {detailedMonth.work_shifts.map((ws) => {
        const userShift = user.shifts?.find(({ work_shift_id }) => work_shift_id === ws.id);
        const isFuture = ws.date >= currentDate;
        return (
          <TableCell key={ws.date} className="!p-0">
            <div className={twMerge('flex justify-center items-center', isFuture ? 'p-0' : 'p-4')}>
              {isFuture && !ws.reported_at ? (
                <UserWorkShiftCheckbox user={user} workShift={ws} substitute={substitute} onTimeChange={onTimeChange} />
              ) : !userShift ? (
                <p className="font-black">—</p>
              ) : userShift.confirmed ? (
                <p className="text-sm whitespace-nowrap">
                  {userShift.hours}h ({userShift.salary_class.name})
                </p>
              ) : !ws.reported_at ? (
                <p className="font-black">X</p>
              ) : (
                <div title="Ogiltig frånvaro" className="text-red-600 text-base">
                  <ErrorIcon fontSize="small" />
                </div>
              )}
            </div>
          </TableCell>
        );
      })}
      <TableCell className="!p-0"></TableCell>
    </TableRow>
  );
};

interface UserWorkShiftCheckboxProps {
  user: ScheduledUser;
  workShift: DetailedMonth['work_shifts'][number];
  substitute?: boolean;
  onTimeChange?: (change: number, workShift: DetailedMonth['work_shifts'][number]) => void;
}

const UserWorkShiftCheckbox: React.FC<UserWorkShiftCheckboxProps> = ({
  user,
  workShift,
  substitute = false,
  onTimeChange,
}) => {
  const [userShift, setUserShift] = useState(
    user.shifts?.find(({ work_shift_id }) => work_shift_id === workShift.id) ?? null
  );
  const isOrWasTempTeamLeader = useRef(userShift?.work_shift_type === 'temp_team_leader');
  const [loadingStateChange, setLoadingStateChange] = useState(false);
  const { notifyError } = useNotify();

  function updateUserShift() {
    if (loadingStateChange) return;

    setLoadingStateChange(true);
    if (userShift) {
      deleteUsersWorkShift(workShift.id, userShift.id)
        .then(() => {
          setUserShift(null);
          onTimeChange?.(-userShift.hours, workShift);
        })
        .catch(() => notifyError('Det gick inte helt att ta bort användaren från passet'))
        .finally(() => setLoadingStateChange(false));
    } else {
      createUsersWorkShift(workShift.id, {
        user_id: user.id,
        work_shift_type: isOrWasTempTeamLeader.current ? 'temp_team_leader' : substitute ? 'substitute' : 'participant',
      })
        .then(({ data }) => {
          setUserShift(data.data);
          onTimeChange?.(data.data.hours, workShift);
        })
        .catch(() => notifyError('Det gick inte att lägga till användaren på passet'))
        .finally(() => setLoadingStateChange(false));
    }
  }

  return (
    <div className="flex items-center py-1">
      {loadingStateChange ? (
        <div className="flex justify-center items-center p-[9px]">
          <CircularProgress size="1.5rem" />
        </div>
      ) : (
        <>
          <Checkbox
            color="success"
            checked={!!userShift}
            onChange={updateUserShift}
            className={twMerge('!ml-9', (!userShift || !isOrWasTempTeamLeader.current) && '!mr-9')}
          />
          {userShift && isOrWasTempTeamLeader.current && (
            <p className="whitespace-nowrap leading-none text-xs font-semibold w-9">Tf.-TL</p>
          )}
        </>
      )}
    </div>
  );
};

type HourReportKind = 'reported' | 'not-reported' | 'scheduled';

const HourReport: React.FC<{ hours: number; kind: HourReportKind }> = ({ hours, kind }) => {
  if (!hours) return null;

  const KIND_TITLE: Record<HourReportKind, string> = {
    'not-reported': 'ej rapporterade',
    reported: 'rapporterade',
    scheduled: 'schemalagda',
  };

  const KIND_BORDER_COLOR: Record<HourReportKind, string> = {
    'not-reported': 'border-red-600',
    reported: 'border-green-600',
    scheduled: 'border-gray-500',
  };

  return (
    <p
      className={twMerge(
        'm-0 leading-none uppercase border-solid pt-1 pb-0.5 px-1 border-0 border-b-2',
        KIND_BORDER_COLOR[kind]
      )}
    >
      {hours}h {KIND_TITLE[kind]}
    </p>
  );
};
