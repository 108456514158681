import React, { useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@mui/material';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { UserInterface } from './../types';
import { updateUser } from './../usersApi';

type Props = {
  user: UserInterface;
  setModalState: (modalState: boolean) => void;
};

const CommentModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);
  const [commentValue, setCommentValue] = useState(props.user.comment ?? '');

  const handleSubmit = (): void => {
    setLoading(true);
    const { role, team, ...values } = props.user;
    if (props.user.id) {
      props.user.comment = commentValue;
      updateUser(props.user.id, {
        ...values,
        comment: commentValue,
        team_id: team?.id as number,
        role_id: role?.id as number,
      })
        .then(() => {
          notifySuccess('Kommentar för användaren har uppdaterats');
        })
        .then(() => {
          setLoading(false);
          props.setModalState(false);
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera kommentar: ${error}`))
        .then(() => props.setModalState(false));
    }
  };

  return (
    <div>
      <DialogTitle>Kommentar för {props.user.first_name}</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent className="!py-2">
            <DialogContentText> Endast synlig för verksamhetsansvarig och uppåt </DialogContentText>
          </DialogContent>
          <DialogContent>
            <TextField
              fullWidth
              name="comment"
              multiline
              rows={5} // Not at all acurate, we get 2+ rows for every one set here
              placeholder={`Här kan du skriva en kommentar för ${props.user.first_name}`}
              value={commentValue}
              onChange={(newValue) => setCommentValue(newValue.target.value)}
            />
            <DialogActions className="!pb-0">
              <Button
                fullWidth
                color="error"
                onClick={(): void => {
                  props.setModalState(false);
                }}
              >
                AVBRYT
              </Button>
              <Button fullWidth color="primary" onClick={(): void => handleSubmit()}>
                SPARA
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </div>
  );
};

export default CommentModal;
