import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
  isVisible: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const EditConfirmationModal: React.FC<Props> = (props) => {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose}>
      <DialogTitle>Konfirmera inaktivering av team</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Denna uppdatering kommer inaktivera teamet. Detta innebär att avtal där detta team är det enda aktiva teamet
          kommer avslutas och framtida avtal kommer tas bort. Är avtalsparten redan inaktiveras kan ni bortse från denna
          varning.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} fullWidth color="error">
          Avbryt
        </Button>
        <Button onClick={props.onConfirm} fullWidth color="primary">
          Uppdatera
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditConfirmationModal;
