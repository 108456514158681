import React, { useState, useEffect, ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';
import { Dialog, Grid } from '@mui/material';

import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../Common/styling';
import { OptionsInterface, RoleOptionsInterface } from '../Common/types';

import UserForm from './components/UserForm';
import WelcomeEmailModal from './components/WelcomeEmailModal';
import { getTeamMinimalList } from '../Team/teamApi';
import { UserInterface } from './types';
import { validationSchema, initialValues } from './usersSchema';
import { createUser } from './usersApi';
import { getRolesMinimalList } from '../Common/commonApi';

const CreateUser: React.FC = () => {
  // hooks
  const { notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);
  const [welcomeEmailModalState, setWelcomeEmailModalState] = useState(false);
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [rolesMinimalList, setRolesMinimalList] = useState<Array<RoleOptionsInterface>>([]);
  const [teamMinimalList, setTeamMinimalList] = useState<Array<OptionsInterface>>([]);
  const [user, setUser] = useState<UserInterface>();

  // functions
  useEffect(() => {
    fetchRolesMinimalList();
    fetchTeamsMinimalList();
  }, []); // eslint-disable-line

  const fetchRolesMinimalList = (): void => {
    setLoadingRoles(true);
    getRolesMinimalList({ hide_banned: true })
      .then(({ data }) => {
        setRolesMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över roll'))
      .finally(() => setLoadingRoles(false));
  };

  // functions
  const fetchTeamsMinimalList = (): void => {
    setLoadingTeams(true);
    getTeamMinimalList({ is_active: true })
      .then(({ data }) => setTeamMinimalList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över teams'))
      .finally(() => setLoadingTeams(false));
  };

  const handleFormSubmit = ({ team, role, ...values }: typeof initialValues, actions: FormikHelpers<any>): void => {
    setLoading(true);
    createUser({ ...values, team_id: team?.id as number, role_id: role?.id as number })
      .then((response) => {
        setUser(response.data.data);
        notifySuccess('Användaren har skapats');
        setWelcomeEmailModalState(true);
      })
      .catch((error: any) => {
        notifyError(`Det gick inte att skapa användaren. \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <Grid
          container
          spacing={0}
          style={{ paddingLeft: '15px', alignItems: 'center', alignContent: 'space-between' }}
        >
          <Grid item xs={8} sm={8} md={8} lg={8} style={{ alignSelf: 'left', marginLeft: '-10px' }}>
            <h1 className={css(commonStyles.Headline6)}>Lägg till användare</h1>
          </Grid>

          <Grid
            item
            xs={4}
            sm={4}
            md={4}
            lg={4}
            style={{ alignSelf: 'center', display: 'flex', justifyContent: 'flex-end' }}
          >
            <CancelLink link={`/users`} />
          </Grid>
        </Grid>
      </div>

      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnChange={true}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <UserForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                isCreate={true}
                rolesMinimalList={rolesMinimalList}
                teamMinimalList={teamMinimalList}
                setFieldValue={setFieldValue}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>

        {(loading || loadingRoles || loadingTeams) && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading || loadingRoles || loadingTeams} />
          </div>
        )}

        {welcomeEmailModalState && (
          <Dialog
            open={welcomeEmailModalState}
            onClose={(): void => {
              setWelcomeEmailModalState(false);
            }}
            fullWidth={true}
            maxWidth="xs"
          >
            <WelcomeEmailModal user={user} setModalState={setWelcomeEmailModalState} />
          </Dialog>
        )}
      </div>
    </React.Fragment>
  );
};

export default CreateUser;
