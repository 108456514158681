import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { IconButton, TextField } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import {
  BaseSingleInputFieldProps,
  CalendarIcon,
  DateValidationError,
  FieldSection,
  UseDateFieldProps,
} from '@mui/x-date-pickers';

const DAYJS_FORMATS = ['YYYYMMDD', 'YYYYMM', 'YYYY-MM-DD', 'YYYY-MM'];

interface InputFieldProps
  extends UseDateFieldProps<Dayjs>,
    BaseSingleInputFieldProps<Dayjs | null, Dayjs, FieldSection, DateValidationError> {
  onChange: (date: Dayjs | null) => void;
  onOpen?: () => void;
}

function InputField({
  label,
  id,
  disabled,
  value,
  onChange,
  onOpen,
  InputProps: { ref } = {},
  inputProps: { 'aria-label': ariaLabel } = {},
  className,
}: InputFieldProps) {
  const [input, setInput] = useState(value?.isValid() ? value.format('YYYY-MM-DD') : '');

  useEffect(() => {
    if (!value) return setInput('');
    const curr = dayjs(input, DAYJS_FORMATS);
    if (!value.isSame(curr, 'day')) setInput(value.format('YYYY-MM-DD') ?? '');
  }, [value]);

  return (
    <TextField
      ref={ref}
      id={id}
      aria-label={ariaLabel}
      label={label}
      placeholder="YYYY-MM-DD"
      value={input}
      onChange={({ target }) => {
        setInput(target.value);
        if (!target.value) onChange(null);
        const day = dayjs(target.value, DAYJS_FORMATS);
        if (day.isValid()) onChange(day);
      }}
      disabled={disabled}
      InputProps={{
        startAdornment: (
          <IconButton onClick={onOpen}>
            <CalendarIcon />
          </IconButton>
        ),
        endAdornment: !disabled && (
          <IconButton onClick={() => onChange(null)}>
            <ClearIcon />
          </IconButton>
        ),
      }}
      className={className}
    />
  );
}

type Props = {
  label?: string;
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  minDate?: Dayjs;
  maxDate?: Dayjs;
  disabled?: boolean;
  className?: string;
};

const FilterFreeDateField: React.FC<Props> = ({ label, value, onChange, minDate, maxDate, disabled, className }) => {
  const [open, setOpen] = useState(false);
  return (
    <Stack className="h-full">
      <DatePicker
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        slots={{ field: InputField as any }}
        slotProps={{ field: { onChange, onOpen: () => setOpen(true) } as any }}
        label={label}
        views={['year', 'month', 'day']}
        value={value}
        onChange={onChange}
        sx={{ color: '#A0A0A0' }}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
      />
    </Stack>
  );
};

export default FilterFreeDateField;
