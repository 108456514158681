import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
  open: boolean;
  body: string | ReactNode;
  title: string;
  closeDialog: () => void;
};

const InfoDialog: React.FC<Props> = (props) => {
  return (
    <div>
      <Dialog
        open={props.open}
        onClose={props.closeDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" component="div">
            {props.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={props.closeDialog} autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default InfoDialog;
