import React, { useState, useEffect } from 'react';
import { RotateLoader } from 'react-spinners';

import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { UserListInterface, ContractInterface } from './../types';
import { getContracts } from './../usersApi';

type Props = {
  user: UserListInterface | undefined;
  setModalState: (modalState: boolean) => void;
};

const ContractModal: React.FC<Props> = (props) => {
  const { notifyError } = useNotify();
  const [loadingContract, setLoadingContract] = useState(true);

  const [dEmploymentContract, setDEmploymentContract] = useState<ContractInterface>({ key: '', url: '' });
  const [tlEmploymentContract, setTLEmploymentContract] = useState<ContractInterface>({ key: '', url: '' });
  const [nda, setNda] = useState<ContractInterface>({ key: '', url: '' });

  useEffect(() => {
    if (props.user) {
      handleGetContracts(props.user.id);
    }
  }, [props.user]);

  const handleGetContracts = (id: string): void => {
    setLoadingContract(true);
    getContracts(id)
      .then(({ data }) => {
        setDEmploymentContract(data.agreements.d);
        setTLEmploymentContract(data.agreements.tl);
        setNda(data.agreements.nda);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över avtalen'))
      .finally(() => setLoadingContract(false));
  };

  return (
    <div>
      <DialogTitle>Kontrakt för {props.user?.name}</DialogTitle>
      {loadingContract ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loadingContract} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            {nda.url.length == 0 && dEmploymentContract.url.length == 0 && tlEmploymentContract.url.length == 0 ? (
              <DialogContentText>Inga kontrakt finns</DialogContentText>
            ) : (
              <ul style={{ listStyle: 'none', paddingLeft: '2px' }}>
                {dEmploymentContract.url.length > 0 && (
                  <li>
                    <a target="_blank" rel="noreferrer" href={dEmploymentContract.url}>
                      {dEmploymentContract.key}
                    </a>
                    <br />
                  </li>
                )}
                {tlEmploymentContract.url.length > 0 && (
                  <li>
                    <a target="_blank" rel="noreferrer" href={tlEmploymentContract.url}>
                      {tlEmploymentContract.key}
                    </a>
                    <br />
                  </li>
                )}
                {nda.url.length > 0 && (
                  <li>
                    <a target="_blank" rel="noreferrer" href={nda.url}>
                      {nda.key}
                    </a>
                  </li>
                )}
              </ul>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              color="error"
              onClick={(): void => {
                props.setModalState(false);
              }}
            >
              STÄNG
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </div>
  );
};

export default ContractModal;
