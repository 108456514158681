import React, { ReactNode } from 'react';
import {
  Autocomplete,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  AutocompleteRenderOptionState,
  Chip,
  FormHelperText,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  UseAutocompleteProps,
} from '@mui/material';

type FormAutocompleteBoxProps<T> = {
  id?: string;
  name?: string;
  options: T[];
  disabledOptions?: T[];
  onChange?: UseAutocompleteProps<T, false, false, false>['onChange'];
  label?: string;
  placeholder?: string;
  error?: string | null;
  getOptionLabel?: (option: T) => string;
  optionEquality?: (option: T, value: T) => boolean;
  disableCloseOnSelect?: boolean;
  textFieldProps?: Omit<TextFieldProps, 'variant'>;
  loading?: boolean;
  loadingText?: string;
  color?: string;
  className?: string;
  disabled?: boolean;
  disableClearable?: boolean;
} & (
  | { type: 'single'; value?: T | null; onChange?: UseAutocompleteProps<T, false, false, false>['onChange'] }
  | {
      type: 'multiple';
      values?: T[];
      onChange?: UseAutocompleteProps<T, true, false, false>['onChange'];
      renderTags?: ((values: T[], getTagProps: AutocompleteRenderGetTagProps) => ReactNode) | 'simple' | 'chips';
      limitTags?: number;
    }
);

export default function FormAutocompleteBox<T>({
  id,
  name,
  options,
  disabledOptions,
  label,
  placeholder,
  error,
  getOptionLabel = (option: any) => {
    if (typeof option === 'object' && typeof option?.name === 'string') return option.name;
    if (typeof option === 'object' && typeof option?.label === 'string') return option.label;
    return String(option);
  },
  optionEquality = (option: T, value: T) => {
    if (typeof option === 'string' && value === 'string') return option === value;
    if (typeof option === 'object' && option !== null && typeof value === 'object' && value !== null) {
      const a = option as any;
      const b = value as any;
      if (a.id !== undefined && b.id !== undefined && a.id === b.id) return true;
    }
    return option === value;
  },
  disableCloseOnSelect,
  textFieldProps = {},
  loading,
  loadingText,
  color,
  className,
  disabled,
  disableClearable,
  ...conditional
}: FormAutocompleteBoxProps<T>) {
  const baseProps = {
    id,
    name,
    options,
    getOptionDisabled:
      disabledOptions && ((option: T) => disabledOptions.some((value) => optionEquality(option, value))),
    label,
    getOptionLabel,
    isOptionEqualToValue: optionEquality,
    renderInput: (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        variant="outlined"
        name={name}
        label={label}
        error={!!error}
        placeholder={placeholder}
        {...textFieldProps}
        className={className}
      />
    ),
    disableCloseOnSelect: disableCloseOnSelect ?? conditional.type === 'multiple',
    loading,
    loadingText,
    disabled: disabled || loading,
    disableClearable,
  };

  switch (conditional.type) {
    case 'single':
      return (
        <>
          <Autocomplete {...baseProps} multiple={false} value={conditional.value} onChange={conditional.onChange} />
          {error !== undefined && <FormHelperText error>{error || ' '}</FormHelperText>}
        </>
      );
    case 'multiple':
      const defaultRenderTags =
        conditional.renderTags === 'chips'
          ? (values: T[], getTagProps: AutocompleteRenderGetTagProps) =>
              // eslint-disable-next-line react/jsx-key
              values.map((value, index) => <Chip label={getOptionLabel(value)} {...getTagProps({ index })} />)
          : conditional.renderTags === 'simple'
          ? (values: T[]) => (
              <p className="ml-2">
                {values.length} {values.length === 1 ? 'vald' : 'valda'}
              </p>
            )
          : undefined;

      return (
        <>
          <Autocomplete
            {...baseProps}
            multiple={true}
            value={conditional.values}
            onChange={conditional.onChange}
            renderTags={typeof conditional.renderTags === 'function' ? conditional.renderTags : defaultRenderTags}
            limitTags={conditional.limitTags}
          />
          {error !== undefined && <FormHelperText error>{error || ' '}</FormHelperText>}
        </>
      );
  }
}
