import API from '../Common/axios';
import { MinimalSalaryClass, SalaryClass } from './types';

type CreateSalaryClassParams = {
  name: string;
  short_name: string;
  edison_id?: number | null;
};

type UpdateSalaryClassParams = Partial<CreateSalaryClassParams>;

export async function findSalaryClasses(params: { custom?: boolean }) {
  return API.get<{ data: SalaryClass[] }>(`v1/salary_classes`, { params });
}

export async function getSalaryClass(id: number) {
  return API.get<{ data: SalaryClass }>(`v1/salary_classes/${id}`);
}

export async function getSalaryClassesMinimalList(params: { custom?: boolean; with_name?: boolean }) {
  return API.get<{ data: MinimalSalaryClass[] }>('v1/salary_classes/minimal_list', { params });
}

export async function createSalaryClass(salary_class: CreateSalaryClassParams) {
  return API.post<{ data: SalaryClass }>('v1/salary_classes', { salary_class });
}

export async function updateSalaryClass(id: number, salary_class: UpdateSalaryClassParams) {
  return API.patch<{ data: SalaryClass }>(`v1/salary_classes/${id}`, { salary_class });
}

export async function deleteSalaryClass(id: number, destroy?: boolean) {
  return API.delete(`v1/salary_classes/${id}`, { params: { destroy: destroy || undefined } });
}
