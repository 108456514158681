import React from 'react';
import { commonStyles, css, StyleSheet } from '../../Common/styling';
import { Clear as ClearIcon } from '@mui/icons-material';
import { Fab } from '@mui/material';

const styles = StyleSheet.create({
  deleteIcon: {
    position: 'relative',
    bottom: '1px',
    paddingRight: '10px',
    paddingBottom: '1px',
    verticalAlign: 'middle',
    color: '#d0021b',
  },
});

type Props = {
  onClick: any;
  text: string;
};

const TaBortFab: React.FC<Props> = (props) => {
  return (
    <Fab
      variant="extended"
      color="inherit"
      aria-label="TaBort"
      className={css(commonStyles.selectionFabStyle2)}
      onClick={props.onClick}
    >
      <ClearIcon className={css(styles.deleteIcon)} />
      {props.text}
    </Fab>
  );
};

export default TaBortFab;
