import React, { ReactNode } from 'react';
import { FieldArray } from 'formik';

import { EducationQuestionInterface } from '../types';
import QuestionForm from './QuestionForm';
import { Add as AddIcon } from '@mui/icons-material';
import { Button, Divider, List, ListItem } from '@mui/material';

interface Props {
  values: any;
  handleChange: any;
  errors: any;
  isEdit: boolean;
}

const newQuestion: EducationQuestionInterface = {
  text: '',
};

const EducationQuestions: React.FC<Props> = (props) => {
  return (
    <React.Fragment>
      <List component="div" disablePadding>
        {
          <FieldArray
            name="education_questions"
            render={(helpers): ReactNode => (
              <>
                {props.values.education_questions?.map((question: EducationQuestionInterface, idx: number) => (
                  <React.Fragment key={idx}>
                    <p>Fråga {idx + 1}</p>
                    <QuestionForm
                      index={idx}
                      name={`education_questions.${idx}`}
                      values={props.values}
                      errors={props.errors}
                      handleChange={props.handleChange}
                      isEdit={props.isEdit}
                      remove={(): void => {
                        helpers.remove(idx);
                      }}
                    />
                    <Divider />
                  </React.Fragment>
                ))}
                {props.isEdit && (
                  <ListItem style={{ marginTop: '20px' }}>
                    <Button
                      startIcon={<AddIcon />}
                      size="small"
                      color="primary"
                      disabled={!props.isEdit}
                      onClick={(): void => {
                        helpers.push({ ...newQuestion });
                      }}
                    >
                      Ny fråga
                    </Button>
                  </ListItem>
                )}
              </>
            )}
          />
        }
      </List>
    </React.Fragment>
  );
};

export default EducationQuestions;
