import React from 'react';

export default function BouncingDots() {
  return (
    <span className="bouncing-dots">
      <span className="dot">.</span>
      <span className="dot">.</span>
      <span className="dot">.</span>
    </span>
  );
}
