import { Badge, ListItem, ListItemButton as MuiListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import { theme } from '../styling';
import { Link, useLocation } from 'react-router-dom';

const ListItemButton = withStyles({
  root: {
    backgroundColor: theme.themeWhite,
    '&.Mui-selected': {
      backgroundColor: theme.themeGreenGreyLight,
    },
  },
})(MuiListItemButton);

type Props = {
  label: string;
  link: string;
  regex?: RegExp;
  selected?: boolean;
  icon: JSX.Element;
  open: boolean;
  badge?: number;
};

export default function DrawerMenuItem({
  label,
  link,
  regex = new RegExp(`^${link}.*`),
  selected,
  icon,
  open,
  badge,
}: Props) {
  const { pathname } = useLocation();

  return (
    <Link to={link} className="text-inherit">
      <ListItem disablePadding sx={{ display: 'block' }}>
        <ListItemButton
          TouchRippleProps={{ style: { color: theme.green } }}
          selected={selected ?? regex.test(pathname)}
          sx={{
            height: 40,
            justifyContent: open ? 'initial' : 'center',
            px: open ? 2 : 2.5,
            transitionProperty: 'padding',
          }}
        >
          <Badge badgeContent={badge} color="error">
            <ListItemIcon
              sx={{
                minWidth: 0,
                justifyContent: 'center',
                color: 'black',
              }}
            >
              {icon}
            </ListItemIcon>
          </Badge>

          <ListItemText
            primary={label}
            sx={{ opacity: open ? 1 : 0, ml: open ? 3.5 : 'auto' }}
            primaryTypographyProps={{ fontSize: 14, lineHeight: 1 }}
          />
        </ListItemButton>
      </ListItem>
    </Link>
  );
}
