import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import API from '../Common/axios';

export const getInvoiceList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/invoices', { params });
};

export const deleteInvoice = (ids: number[]): Promise<AxiosResponse> => {
  return API.delete(`v1/invoices/batch_delete`, {
    params: { invoice_ids: ids },
  });
};

export const getInvoiceDetail = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/invoices/${id}`);
};

export const sendToFortnox = (ids: number[]): Promise<AxiosResponse> => {
  return API.post(`v1/invoices/batch_send_to_fortnox`, { data: { invoice_ids: ids } });
};
