import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { TableHead, TableRow } from '../../Common/Table';
import { sort_order, ListParams, AuthContextValue } from '../../Common/types';
import { Grid, Table, TableBody, TableCell } from '@mui/material';
import { RotateLoader } from 'react-spinners';

//import { StyleSheet } from 'aphrodite';
import { FaqInterface } from './types';
import { useNotify } from '../../Common/snackbarHooks';
import { isEmpty } from '../../Common/utilities';
import EmptyList from '../../Common/Table/EmptyList';
import { css, commonStyles } from '../../Common/styling';
import { CreateFab } from '../../Common/ButtonLinks';
import AuthContext from '../../Common/AuthContext';
import { getFaqs } from './FaqApi';

export const filterSortDefaults = {
  query: '',
  sort_by: 'ranking',
  sort_order: sort_order.asc,
  is_active: true,
};

const Faqs: React.FC = () => {
  // hooks
  const rows = [
    { id: 'question', label: 'Fråga', notSortable: true },
    { id: 'answer', label: 'Svar', notSortable: true },
    { id: 'ranking', numeric: true, label: 'Prioritering', notSortable: true },
    { id: 'empty1', label: '', notSortable: true },
    { id: 'show', label: 'Visa', notSortable: true },
    { id: 'empty2', label: '', notSortable: true },
  ];

  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const [faqs, setFaqs] = useState<Array<FaqInterface>>([]);
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [loading, setLoading] = useState(true);
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  useEffect(() => {
    const fetchFaqs = (): void => {
      setLoading(true);
      getFaqs()
        .then((data) => {
          setFaqs(data.data.faqs);
        })
        .catch(() => notifyError('Det gick inte att hämta listan över FAQ'))
        .finally(() => setLoading(false));
    };
    fetchFaqs();
  }, [listParams]); // eslint-disable-line react-hooks/exhaustive-deps

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>FAQ</h1>
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead rows={rows} listParams={listParams} setGetListParams={setGetListParams} />

          <TableBody>
            {isEmpty(faqs) ? (
              <EmptyList />
            ) : (
              faqs.map((row: FaqInterface, idx: number) => (
                <TableRow
                  row={row}
                  idx={idx}
                  key={idx}
                  onClick={(): void =>
                    hasPermissions(['admin', 'operational_leader', 'operational_manager'])
                      ? navigate(`/faq/${row.id}`)
                      : navigate(`/faq`)
                  }
                >
                  <TableCell scope="row">
                    <b>{row.question}</b>
                  </TableCell>
                  <TableCell align="left">{row.answer}</TableCell>
                  <TableCell align="right">{row.ranking}</TableCell>
                  <TableCell align="left"></TableCell>
                  <TableCell align="left">{row.show ? 'ja' : 'nej'}</TableCell>
                  <TableCell align="left"></TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && <CreateFab link="create" />}
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Faqs;
