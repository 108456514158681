import React from 'react';

import { commonStyles, css } from '../../Common/styling';
import { Delete as DeleteIcon } from '@mui/icons-material';
import { Fab } from '@mui/material';

type Props = {
  onClick: any;
  text: string;
};

const RemoveFab: React.FC<Props> = (props) => {
  return (
    <Fab
      variant="extended"
      color="error"
      aria-label="Remove"
      className={css(commonStyles.selectionFab)}
      onClick={props.onClick}
    >
      <DeleteIcon style={{ marginRight: '10px' }} />
      {props.text}
    </Fab>
  );
};

export default RemoveFab;
