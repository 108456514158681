import React from 'react';

import { commonStyles, css } from '../../Common/styling';
import { Edit as EditIcon } from '@mui/icons-material';
import { Fab } from '@mui/material';

type Props = {
  onClick: any;
  text: string;
};

const UpdateFab: React.FC<Props> = (props: { onClick: any; text: string }) => {
  return (
    <Fab
      variant="extended"
      color="primary"
      aria-label="Edit"
      className={css(commonStyles.selectionFab)}
      onClick={props.onClick}
    >
      <EditIcon style={{ marginRight: '10px' }} />
      {props.text}
    </Fab>
  );
};

export default UpdateFab;
