import React, { ReactElement } from 'react';
import { Formik } from 'formik';

import { initialValues, validationSchema } from './dataDumpSchema';
import { useNotify } from '../../Common/snackbarHooks';
import { exportAgreementOverview } from '../agreementApi';
import { DataDumpForm } from './DataDumpForm';
import { DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
  setModalState: any;
};

const ExportModal: React.FC<Props> = (props) => {
  // hooks
  const { notifySuccess, notifyError } = useNotify();

  // functions
  const handleFormSubmit = (values: any): void => {
    exportAgreementOverview(values)
      .then(() =>
        notifySuccess(
          'Nu kommer datadump för avtal genereras. Det tar några minuter och du får ett mejl så snart det är klart.'
        )
      )
      .catch(() => notifyError('Exporten gick inte igenom'))
      .finally(() => props.setModalState(false));
  };

  // render
  return (
    <div>
      <DialogTitle>Datadump för avtal</DialogTitle>
      <DialogContent>
        <DialogContentText>Välj vilka datum som ska inkluderas i exporten</DialogContentText>
      </DialogContent>
      <DialogContent>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values): void => handleFormSubmit(values)}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <DataDumpForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                setFieldValue={setFieldValue}
                setModalState={props.setModalState}
              />
            </form>
          )}
        </Formik>
      </DialogContent>
    </div>
  );
};

export default ExportModal;
