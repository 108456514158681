import { Button, Checkbox, Divider, FormControl, Grid, Switch, Tooltip } from '@mui/material';
import React, { useContext, useMemo, useState } from 'react';
import AuthContext from '../../Common/AuthContext';
import { FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { AuthContextValue, OptionsInterface, RoleOptionsInterface } from '../../Common/types';
import { getValue } from '../../Common/utilities';
import { UserInterface } from '../types';
import { initialValues } from '../usersSchema';

type Props = {
  values: typeof initialValues;
  user?: UserInterface;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  submitDisabled?: boolean;
  isCreate: boolean;
  rolesMinimalList: Array<RoleOptionsInterface>;
  teamMinimalList: Array<OptionsInterface>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  removal?: () => void;
};

const UserForm: React.FC<Props> = (props) => {
  const { profile, hasPermissions } = useContext(AuthContext) as AuthContextValue;
  const [nda, setNda] = useState(
    props.values.employment_documents_attributes
      .map((x: any) => x.document_type == 'nda' && x.id !== null)
      .includes(true)
  );
  const [participantAgreement, setParticipantAgreement] = useState(
    props.values.employment_documents_attributes
      .map((x: any) => x.document_type == 'd_agreement' && x.id !== null)
      .includes(true)
  );
  const [teamleaderAgreement, setTeamleaderAgreement] = useState(
    props.values.employment_documents_attributes
      .map((x: any) => x.document_type == 'tl_agreement' && x.id !== null)
      .includes(true)
  );

  const [d_education, setD_education] = useState(props.values.user_log_attributes['d_education_at'] ? true : false);
  const [tl_education, setTl_education] = useState(props.values.user_log_attributes['tl_education_at'] ? true : false);
  const [inSubstitutePool, setInSubstitutePool] = useState(
    props.values.user_log_attributes['substitute_pool_at'] ? true : false
  );

  const disabledRoles = useMemo(() => {
    if (hasPermissions(['admin'])) {
      return [];
    } else if (profile.id === props.user?.id) {
      return props.rolesMinimalList.filter(
        (r: RoleOptionsInterface) => r.permission_level < profile.role.permission_level
      );
    } else {
      return props.rolesMinimalList.filter(
        (r: RoleOptionsInterface) => r.permission_level <= profile.role.permission_level
      );
    }
  }, [profile, props.user, props.rolesMinimalList]);

  function getEmploymentDocumentIndex(type: string) {
    let index = props.values.employment_documents_attributes.indexOf(
      props.values.employment_documents_attributes.filter((x: any) => x.document_type == type)[0]
    );
    if (index < 0) {
      index = props.values.employment_documents_attributes.length;
    }
    return index;
  }

  function padTo2Digits(num: number) {
    return num.toString().padStart(2, '0');
  }

  function formatDate(date: Date) {
    return (
      [date.getFullYear(), padTo2Digits(date.getMonth() + 1), padTo2Digits(date.getDate())].join('-') +
      ' ' +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes()), padTo2Digits(date.getSeconds())].join(':')
    );
  }

  return (
    <div style={{ minWidth: '900px', maxWidth: '100%' }}>
      <Grid container spacing={2} alignItems="center" alignContent="space-between">
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <FormTextField
            required={true}
            size={12}
            fieldName="first_name"
            label="Förnamn på användaren"
            placeholder="Förnamn på användaren"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <FormTextField
            size={12}
            fieldName="surname"
            label="Efternamn på användaren"
            placeholder="Efternamn på användaren"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="!-mt-2 !mb-1.5">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" alignContent="space-between">
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" margin="normal" required fullWidth>
              <FormAutocompleteBox
                type="single"
                id="role"
                name="role"
                label="Roll"
                options={props.rolesMinimalList}
                disabledOptions={disabledRoles}
                optionEquality={(option, value) => option.id === value.id}
                value={props.values.role}
                onChange={(_, value) => props.setFieldValue('role', value)}
                error={props.errors.role ?? null}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <Grid item xs={12}>
            <FormControl variant="standard" margin="normal" required fullWidth>
              <FormAutocompleteBox
                type="single"
                id="team"
                name="team"
                label="Team"
                options={props.teamMinimalList}
                optionEquality={(option, value) => option.id === value.id}
                value={props.values.team}
                onChange={(_, value) => props.setFieldValue('team', value)}
                error={props.errors.team ?? null}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <FormTextField
            required={true}
            size={12}
            fieldName="email"
            label="E-post"
            placeholder="E-post"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} className="!-mt-2 !mb-1.5">
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Divider />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" alignContent="space-between">
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormTextField
            size={12}
            fieldName="person_nr"
            label="Personnummer"
            placeholder="Personnummer"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            disabled={
              !(
                hasPermissions(['admin', 'operational_leader', 'operational_manager']) || profile?.id === props.user?.id
              )
            }
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormTextField
            size={12}
            fieldName="mobile"
            label="Mobil"
            placeholder="Mobil"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormTextField
            size={12}
            fieldName="salary_detail_attributes.clearing_number"
            label="Clearingnummer"
            placeholder="Clearingnummer"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            disabled={
              !(
                hasPermissions(['admin', 'operational_leader', 'operational_manager']) || profile?.id === props.user?.id
              )
            }
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormTextField
            size={12}
            fieldName="salary_detail_attributes.account_number"
            label="Kontonummer"
            placeholder="Kontonummer"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            disabled={
              !(
                hasPermissions(['admin', 'operational_leader', 'operational_manager']) || profile?.id === props.user?.id
              )
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} alignItems="center" alignContent="space-between">
        <Grid item xs={6} sm={6} md={6} lg={6}>
          <FormTextField
            size={12}
            fieldName="address"
            label="Gatuadress"
            placeholder="Gatuadress"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormTextField
            size={12}
            fieldName="zip_code"
            label="Postnummer"
            placeholder="Postnummer"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={3} sm={3} md={3} lg={3}>
          <FormTextField
            size={12}
            fieldName="city"
            label="Postort"
            placeholder="Postort"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12} md={12} lg={12} className="!pt-5 pb-3">
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={0} alignItems="center" alignContent="space-between" style={{ fontSize: '12px' }}>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <b>Status</b>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'right' }}>
          <span>
            <Checkbox
              color="primary"
              disabled={!hasPermissions(['admin', 'operational_leader', 'operational_manager'])}
              onChange={(): void => {
                setNda(!nda);
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('nda')}].signed_at`,
                  !nda ? formatDate(new Date()) : null
                );
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('nda')}].document_type`,
                  'nda'
                );
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('nda')}]._destroy`,
                  !nda ? 0 : 1
                );
                if (props.user?.employment_documents?.[getEmploymentDocumentIndex('nda')].id) {
                  props.setFieldValue(
                    `employment_documents_attributes[${getEmploymentDocumentIndex('nda')}].id`,
                    props.user?.employment_documents[getEmploymentDocumentIndex('nda')].id
                  );
                }
              }}
              checked={nda}
            />
            Tystnadspliktsavtal
          </span>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'right', paddingRight: '40px' }}>
          <span>
            <Checkbox
              color="primary"
              disabled={!hasPermissions(['admin', 'operational_leader', 'operational_manager'])}
              onChange={(): void => {
                setParticipantAgreement(!participantAgreement);
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('d_agreement')}].signed_at`,
                  !participantAgreement ? formatDate(new Date()) : null
                );
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('d_agreement')}].document_type`,
                  'd_agreement'
                );
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('d_agreement')}]._destroy`,
                  !participantAgreement ? 0 : 1
                );
                if (props.user?.employment_documents?.[getEmploymentDocumentIndex('d_agreement')].id) {
                  props.setFieldValue(
                    `employment_documents_attributes[${getEmploymentDocumentIndex('d_agreement')}].id`,
                    props.user.employment_documents[getEmploymentDocumentIndex('d_agreement')].id
                  );
                }
              }}
              checked={participantAgreement}
            />
            D-avtal
          </span>
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={1}>
          <span>
            <Checkbox
              color="primary"
              disabled={!hasPermissions(['admin', 'operational_leader', 'operational_manager'])}
              onClick={(): void => {
                setD_education(!d_education);
                props.setFieldValue('user_log_attributes.d_education_at', !d_education ? formatDate(new Date()) : null);
              }}
              checked={d_education}
            />
            D-utb
          </span>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'right', paddingRight: '35px' }}>
          <span>
            <Checkbox
              color="primary"
              disabled={!hasPermissions(['admin', 'operational_leader', 'operational_manager'])}
              onChange={(): void => {
                setTeamleaderAgreement(!teamleaderAgreement);
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('tl_agreement')}].signed_at`,
                  !teamleaderAgreement ? formatDate(new Date()) : null
                );
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('tl_agreement')}].document_type`,
                  'tl_agreement'
                );
                props.setFieldValue(
                  `employment_documents_attributes[${getEmploymentDocumentIndex('tl_agreement')}]._destroy`,
                  !teamleaderAgreement ? 0 : 1
                );
                if (props.user?.employment_documents?.[getEmploymentDocumentIndex('tl_agreement')].id) {
                  props.setFieldValue(
                    `employment_documents_attributes[${getEmploymentDocumentIndex('tl_agreement')}].id`,
                    props.user.employment_documents[getEmploymentDocumentIndex('tl_agreement')].id
                  );
                }
              }}
              checked={teamleaderAgreement}
            />
            TL-avtal
          </span>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <span>
            <Checkbox
              color="primary"
              disabled={!hasPermissions(['admin', 'operational_leader', 'operational_manager'])}
              onClick={(): void => {
                setTl_education(!tl_education);
                props.setFieldValue(
                  'user_log_attributes.tl_education_at',
                  !tl_education ? formatDate(new Date()) : null
                );
              }}
              checked={tl_education}
            />
            TL-utb
          </span>
        </Grid>
        <Grid item xs={2} sm={2} md={2} lg={2}>
          <span>
            <Checkbox
              color="primary"
              disabled={!hasPermissions(['admin', 'operational_leader', 'operational_manager'])}
              onClick={(): void => {
                setInSubstitutePool(!inSubstitutePool);
                props.setFieldValue(
                  'user_log_attributes.substitute_pool_at',
                  !inSubstitutePool ? formatDate(new Date()) : null
                );
              }}
              checked={inSubstitutePool}
            />
            Vikariepoolen
          </span>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" className="!-mt-2">
        <Grid item xs={12} sm={12} md={12} lg={12} className="!pt-5 !pb-2.5">
          <Divider />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <FormTextField
            size={12}
            fieldName="person_attributes.name"
            label="Användarens vårdnadshavare/nödkontakt"
            placeholder="Namn på vårdnadshavare/nödkontakt"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <FormTextField
            size={12}
            fieldName="person_attributes.mobile"
            label="Telefon till vårdnadshavare/nödkontakt"
            placeholder="Telefon till vårdnadshavare/nödkontakt"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center" style={{ marginTop: '20px', marginBottom: '5px' }}>
        <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '5px', paddingBottom: '5px' }}>
          <Divider />
        </Grid>
      </Grid>
      {!props.isCreate && (
        <Grid container spacing={0} alignItems="center" alignContent="space-between" style={{ fontSize: '12px' }}>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <span>Skapad: {props.user?.created_at}</span>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'right', paddingRight: '25px' }}>
            <span>Godkänd: {props.values.user_log_attributes.approved_at}</span>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'right', paddingRight: '25px' }}>
            <Tooltip title={props.values.user_log_attributes.banned_by?.name ?? ''} placement="bottom-start">
              <span>Slutat: {props.values.user_log_attributes.banned_at}</span>
            </Tooltip>
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} style={{ textAlign: 'right', paddingRight: '10px' }}>
            <span>Jämkat: {getValue(props.values, 'salary_detail_attributes.tax_equalization_at')}</span>
            {(hasPermissions(['admin']) ||
              (hasPermissions(['operational_leader', 'operational_manager', 'regional_manager']) &&
                profile?.id === props.user?.id)) && (
              <>
                <Switch
                  size="small"
                  name={'salary_detail_attributes.tax_equalize'}
                  checked={getValue(props.values, 'salary_detail_attributes.tax_equalize')}
                  onChange={props.handleChange}
                />
              </>
            )}
          </Grid>
          <Grid item xs={3} sm={3} md={3} lg={3} style={{ textAlign: 'right', paddingRight: '10px' }}>
            {hasPermissions(['admin']) && (
              <>
                <span>Jämkningsdata vid export:</span>
                <Switch
                  size="small"
                  name={'salary_detail_attributes.tax_equalization_export'}
                  checked={getValue(props.values, 'salary_detail_attributes.tax_equalization_export')}
                  onChange={props.handleChange}
                />
              </>
            )}
          </Grid>
        </Grid>
      )}

      {!props.isCreate && (
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '5px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '24px', paddingBottom: '5px' }}>
            <Divider />
          </Grid>
        </Grid>
      )}

      {!props.isCreate && (
        <Grid container spacing={0} alignItems="center" alignContent="space-between" style={{ fontSize: '12px' }}>
          <Grid item xs={2} sm={2} md={2} lg={2}>
            <span>TL: {props.values.user_log_attributes.team_leader_at}</span>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            style={{ textAlign: 'left', paddingRight: '25px', paddingLeft: '5px' }}
          >
            <span>RC: {props.values.user_log_attributes.regional_manager_at}</span>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            style={{ textAlign: 'left', paddingRight: '30px', paddingLeft: '22px' }}
          >
            <span>VA: {props.values.user_log_attributes.operational_manager_at}</span>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            style={{ textAlign: 'left', paddingRight: '5px', paddingLeft: '35px' }}
          >
            <span>VL: {props.values.user_log_attributes.operational_leader_at}</span>
          </Grid>
          <Grid
            item
            xs={2}
            sm={2}
            md={2}
            lg={2}
            style={{ textAlign: 'left', paddingRight: '10px', paddingLeft: '35px' }}
          >
            <span>Admin: {props.values.user_log_attributes.admin_at}</span>
          </Grid>
          <Grid item xs={2} sm={2} md={2} lg={2} style={{ textAlign: 'right', paddingRight: '10px' }}>
            {hasPermissions(['admin']) && props.values.role?.name === 'Regionchef' && (
              <>
                <span>Visa RC info:</span>
                <Switch
                  size="small"
                  name={'user_log_attributes.show_rc_info'}
                  checked={getValue(props.values, 'user_log_attributes.show_rc_info')}
                  onChange={props.handleChange}
                />
              </>
            )}
          </Grid>
        </Grid>
      )}

      {!props.isCreate && (
        <Grid container spacing={2} className="!-mt-1 !mb-2">
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Divider />
          </Grid>
        </Grid>
      )}

      <Grid container spacing={0} alignItems="center" style={{ marginBottom: '10px' }}>
        {!props.isCreate ? (
          <Grid item xs={5} sm={5} md={5} lg={5}>
            <Tooltip
              title={
                <b style={{ fontSize: '12px', fontWeight: '400' }}>
                  För att ändra anställningsstatus använd Avsluta/Återställ användare i användarlistan.
                </b>
              }
              placement="top-start"
              style={{ paddingRight: '20px' }}
              disableInteractive
            >
              <span>
                {props.values.user_log_attributes.banned_at ? 'Användaren är inte anställd' : 'Användaren är anställd'}
              </span>
            </Tooltip>
          </Grid>
        ) : (
          <Grid item xs={5} sm={5} md={5} lg={5} />
        )}

        <Grid item xs={2}>
          <FormControl variant="standard" margin="dense" required fullWidth>
            <Button type="submit" variant="contained" disabled={props.submitDisabled}>
              {props.isCreate && 'Lägg till'}
              {!props.isCreate && 'Spara'}
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={5}></Grid>
      </Grid>
    </div>
  );
};

export default UserForm;
