import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  CircularProgress,
  TextField,
} from '@mui/material';
import React from 'react';
import { twMerge } from 'tailwind-merge';
import { createFilterOptions } from '@mui/material/Autocomplete';

interface FilterMultipleAutocompleteProps<T extends { id: any }> {
  id?: string;
  options: T[];
  values?: T[];
  onChange?: (
    event: React.SyntheticEvent,
    option: T[],
    reason: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<T>
  ) => void;
  onInputChange?: (inputValue: string) => void;
  groupBy?: (value: T) => string;
  getOptionLabel: (option: T) => string;
  /**
   * How the component should filter alternatives
   * @default onInputChange ? 'none' : 'default'
   */
  filtering?: 'default' | 'none';
  label?: string;
  placeholder?: string;
  tagRender?: ((tags: T[]) => React.ReactNode) | null;
  loading?: boolean;
  disabled?: boolean;
  disableCloseOnSelect?: boolean;
  className?: string;
}

export default function FilterMultipleAutocomplete<T extends { id: any }>({
  id,
  options,
  values,
  onChange,
  onInputChange,
  groupBy,
  getOptionLabel,
  filtering = onInputChange ? 'none' : 'default',
  label,
  placeholder,
  tagRender = ({ length }) => `${length} ${length === 1 ? 'vald' : 'valda'}`,
  loading = false,
  disabled = false,
  disableCloseOnSelect,
  className,
}: FilterMultipleAutocompleteProps<T>) {
  return (
    <Autocomplete
      id={id}
      className={twMerge(className)}
      multiple
      options={loading ? [] : options}
      value={values}
      getOptionLabel={getOptionLabel}
      groupBy={groupBy}
      disableClearable
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      isOptionEqualToValue={(a, b) => a.id === b.id}
      renderTags={tagRender !== null ? tagRender : () => null}
      filterOptions={filtering === 'default' ? createFilterOptions({ ignoreAccents: false }) : (options) => options}
      onChange={onChange}
      onInputChange={onInputChange && ((_, value) => onInputChange(value))}
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          {getOptionLabel(option)}
        </li>
      )}
      loading={loading}
      renderInput={({ InputProps, ...params }) => (
        <TextField
          {...params}
          label={label}
          name={id}
          onKeyDown={(event) => {
            if (event.key === 'Backspace' || event.key === 'Delete') {
              event.stopPropagation();
            }
          }}
          InputProps={{
            ...InputProps,
            placeholder,
            endAdornment: (
              <>
                {loading && (
                  <span className="mr-8 h-[1em]">
                    <CircularProgress size="1em" />
                  </span>
                )}
                {InputProps.endAdornment}
              </>
            ),
          }}
          variant="outlined"
        />
      )}
    />
  );
}
