import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
  isVisible: boolean;
  onConfirm: () => void;
  onClose: () => void;
};

const EditConfirmationModal: React.FC<Props> = (props) => {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose}>
      <DialogTitle>Konfirmera inaktivering av boende</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Denna uppdatering kommer inaktivera boendet. Detta skulle innebära att alla tillhörande team kommer
          inaktiveras. De avtal som är kopplade kommer avslutas och framtida avtal kommer tas bort. Är boendet redan
          inaktiverat kan ni bortse från denna varning.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} fullWidth color="error">
          Avbryt
        </Button>
        <Button onClick={props.onConfirm} fullWidth color="primary">
          Uppdatera
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditConfirmationModal;
