import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import { updateEmployeeAgreement, getEmployeeAgreement } from './EmployeeAgreementAPI';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../../Common/styling';
import { useNotify } from '../../Common/snackbarHooks';
import EmployeeAgreementForm from './EmployeeAgreementForm';
import { BackLink } from '../../Common/ButtonLinks';
import { EmployeeAgreementInterface } from './types';

const initialEmployeeAgreementValues = {
  type_of: '',
  content: '',
  id: 0,
};

const EditEmployeeAgreement: React.FC = () => {
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const { id } = useParams() as { id: string };
  const [loading, setLoading] = useState(true);
  const [employeeAgreement, setEmployeeAgreement] =
    useState<EmployeeAgreementInterface>(initialEmployeeAgreementValues);

  useEffect(() => {
    setLoading(true);

    getEmployeeAgreement(id)
      .then(({ data }) => {
        setEmployeeAgreement(data.employee_agreement_template);
      })
      .catch(() => notifyError('Det gick inte att hämta avtalsmallen'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // functions
  const handleFormSubmit = (values: EmployeeAgreementInterface, actions: FormikHelpers<any>): void => {
    handleUpdateEmployeeAgreement(values, actions.setSubmitting);
  };

  const handleUpdateEmployeeAgreement = (values: EmployeeAgreementInterface, setSubmitting: any): void => {
    updateEmployeeAgreement(employeeAgreement.id, values)
      .then(() => notifySuccess('Avtalsmallen är uppdaterad'))
      .then(() => navigate('/employee-agreements'))
      .catch((error) => {
        notifyError(`Det gick inte att uppdatera avtalsmallen : ${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/employee-agreements'} />
            <h1 className={css(commonStyles.headerTextStyle)}>
              Redigera avtalsmall: &quot;{employeeAgreement.type_of}&quot;
            </h1>
          </div>

          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={employeeAgreement}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, setSubmitting }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <EmployeeAgreementForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    isCreate={false}
                    submitDisabled={isSubmitting || !isValid}
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditEmployeeAgreement;
