import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { Button, Divider, Grid, Paper, Typography } from '@mui/material';
import { BackLink } from '../Common/ButtonLinks';
import { useNotify } from '../Common/snackbarHooks';
import { StyleSheet, commonStyles, css } from '../Common/styling';
import { formatDate, isNotEmpty, monthTranslator } from '../Common/utilities';
import { InvoiceDetailInterface } from '../ResidenceGroup/types';
import { deleteInvoice, getInvoiceDetail, sendToFortnox } from './SavedInvoicesApi';
import { InvoiceRowsDetails } from './components/InvoiceRowsDetails';
import { TextView } from './components/TextView';
import { InvoiceInterface } from './types';

const styles = StyleSheet.create({
  deleteButton: {
    color: 'red',
    backgroundColor: 'white',
  },
});

const SavedInvoiceDetail: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notifySuccess, notifyError, notifyInfo } = useNotify();
  const [invoice, setInvoice] = useState({} as InvoiceInterface);
  const [invoiceDetail, setInvoiceDetail] = useState({} as InvoiceDetailInterface);
  const [invoiceRows, setInvoiceRows] = useState([] as any);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getInvoiceDetail(id)
      .then(({ data }) => {
        setInvoice(data.data);
        setInvoiceDetail(data.data.invoice_detail);
        setInvoiceRows(Object.values(groupByTitle(data.data.invoice_rows)));
      })
      .catch(() => notifyError('Det gick inte att hämta fakturainformationen'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line

  // functions
  const groupBy =
    (key: string): any =>
    (array: any[]): any =>
      array.reduce((objectsByKeyValue, obj) => {
        const value = obj[key];
        objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
        return objectsByKeyValue;
      }, {});

  const groupByTitle = groupBy('title');

  const deleteCreatedInvoice = (): void => {
    deleteInvoice([parseInt(id)])
      .then(() => notifyInfo('Fakturan har tagits bort'))
      .then(() => navigate('/saved-invoices'))
      .catch(() => notifyError('Det gick inte att ta bort fakturan'));
  };

  const sendCreatedInvoice = (): void => {
    sendToFortnox([parseInt(id)])
      .then(() => notifyInfo('Nu kommer fakturan skickas till Fortnox. Du får ett mejl så snart det är klart.'))
      .then(() => navigate('/saved-invoices'))
      .catch(() => notifyError('Det gick inte att skicka fakturan till FortNox'));
  };

  interface TypeObject {
    ContinuousAgreement: string;
    FixedAgreement: string;
    [key: string]: string;
  }
  const type: TypeObject = {
    ContinuousAgreement: 'Löpande',
    FixedAgreement: 'Anpassad',
  };

  // render
  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/saved-invoices'} />
            <h1 className={css(commonStyles.headerTextStyle)}>Faktura för {invoice.residence_name}</h1>
          </div>

          <div className={css(commonStyles.formContainer)} style={{ marginTop: '32px' }}>
            <div>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={`${invoice.start_date} - ${invoice.end_date}`} valueTitle="Period" />
                <TextView size={6} value={formatDate(invoice.created_at)} valueTitle="Skapad" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={3} value={monthTranslator(invoice.start_date.substring(5, 7))} valueTitle="Månad" />
                <TextView size={3} value={type[invoice.agreement_type]} valueTitle="Rutin" />
                <TextView size={3} value={invoice.residence_group_name} valueTitle="Avtalspart" />
                <TextView size={3} value={invoice.residence_name} valueTitle="Boende" />
              </Grid>
              <Divider className="!mb-6" />
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={invoiceDetail.recipient} valueTitle="Fakturamottagare" />
                <TextView size={6} value={invoiceDetail.address_line_1} valueTitle="Adress" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={invoiceDetail.address_line_2} valueTitle="Ytterliggare adressuppgifter" />
                <TextView size={3} value={invoiceDetail.zip_code} valueTitle="Postnummer" />
                <TextView size={3} value={invoiceDetail.city} valueTitle="Ort" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={invoiceDetail.reference} valueTitle="Referens" />
                <TextView size={6} value={invoiceDetail?.gln_number || ''} valueTitle="GLN-nummer" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={12} value={invoice.note} valueTitle="Referens" />
              </Grid>
              <Divider className="!mb-6" />
              <Grid
                container
                style={{ padding: '16px', backgroundColor: 'rgba(237, 244, 254, 1)', marginBottom: '5px' }}
              >
                <Grid item xs={5}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Beskrivning</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Antal</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Enhet</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Pris / enhet</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Total (exkl. moms)</Typography>
                  </Paper>
                </Grid>
              </Grid>
              {invoiceRows && isNotEmpty(invoiceRows) && <InvoiceRowsDetails rows={invoiceRows} />}

              <Divider className="!my-6" />
              <Grid container style={{ marginBottom: '24px' }}>
                <Grid item xs={5} />
                <Grid item xs={2}>
                  <Button variant="contained" fullWidth onClick={(): void => sendCreatedInvoice()}>
                    SKICKA TILL FORTNOX
                  </Button>
                </Grid>
                <Grid item xs={3} />
                {invoice.deletable && (
                  <Grid item xs={2}>
                    <Button
                      variant="contained"
                      fullWidth
                      className={css(styles.deleteButton)}
                      onClick={(): void => deleteCreatedInvoice()}
                    >
                      ÅNGRA SKAPANDET
                    </Button>
                  </Grid>
                )}
              </Grid>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SavedInvoiceDetail;
