import React, { useEffect, useMemo, useState } from 'react';
import { BackLink, CancelLink } from '../Common/ButtonLinks';
import { useNavigate, useParams } from 'react-router-dom';
import { getSalaryClass, updateSalaryClass } from './salaryClassApi';
import BouncingDots from '../Common/BouncingDots';
import { SalaryClass } from './types';
import SalaryClassForm from './form/SalaryClassForm';
import { Formik, FormikHelpers } from 'formik';
import { SalaryClassFormValues, salaryClassInitialValues, salaryClassValidation } from './form/formValues';
import { useSnackbar } from 'notistack';
import { useNotify } from '../Common/snackbarHooks';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';

export default function EditSalaryClass() {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();
  const [salaryClass, setSalaryClass] = useState<SalaryClass>();
  const [loading, setLoading] = useState(true);
  const params = useParams();
  const salaryClassId = useMemo(() => {
    const id = Number(params.id);
    return isFinite(id) ? id : null;
  }, [params.id]);

  useEffect(() => {
    if (!salaryClassId) return;
    getSalaryClass(salaryClassId)
      .then(({ data }) => setSalaryClass(data.data))
      .finally(() => setLoading(false));
  }, [salaryClassId]);

  async function handleSubmit({ edison_id, ...values }: SalaryClassFormValues) {
    if (!salaryClassId) return;
    await updateSalaryClass(salaryClassId, { ...values, edison_id: edison_id ? Number(edison_id) : null })
      .then(() => notifySuccess('Löneart har uppdaterats'))
      .then(() => navigate('/salary-classes'))
      .catch((error) => notifyError(`Det gick inte att uppdatera lönearten: \n${HandleError(error.response.data)}`));
  }

  return (
    <div>
      {/* Title */}
      <div className="flex items-center h-18 pt-3 mx-8 mb-5">
        <BackLink link="/salary-classes" />
        <h1 className="text-2xl font-semibold">
          Redigera löneart {loading ? <BouncingDots /> : `${salaryClass?.short_name}-Lön`}
        </h1>
      </div>
      {/* Form */}
      {salaryClass && (
        <Formik
          initialValues={{
            name: salaryClass.name,
            short_name: salaryClass.short_name,
            edison_id: String(salaryClass.edison_id ?? ''),
          }}
          onSubmit={handleSubmit}
          validationSchema={salaryClassValidation}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, dirty }) => (
            <form onSubmit={handleSubmit}>
              <SalaryClassForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                salaryClass={salaryClass}
                isSubmitting={isSubmitting}
                isValid={isValid}
                dirty={dirty}
              />
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}
