import React, { useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { setLocalStorageItem, getLocalStorageItem, removeLocalStorageItem } from './localStorageUtils';
import { authenticate, getProfile } from '../Login/loginApi';
import { AuthContextValue, AuthProfile } from './types';
import { commonStyles, css } from '../Common/styling';
import { Snackbar } from '@mui/material';
import { AxiosError } from 'axios';

export const AuthContext = React.createContext({});

type Props = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<Props> = ({ children }) => {
  // hooks
  const [loadAuth, setLoadAuth] = useState({ loading: true, isAuth: false });
  const [profile, setProfile] = useState<AuthProfile>({
    id: '',
    first_name: '',
    surname: '',
    email: '',
    role: {
      id: 0,
      name: '',
      short_name: '',
      permission_level: 0,
    },
    mobile: '',
  });
  const [open, setOpen] = useState<string | null>(null);

  useEffect(() => {
    setLoadAuth((prev) => ({ ...prev, loading: true }));
    if (getLocalStorageItem('JWT')) {
      loadProfile();
    } else {
      setLoadAuth((prev) => ({ ...prev, loading: false }));
    }
  }, []);

  // functions

  // Authenticate with back-end, then set JWT in local
  // storage and retrieve currently authenticated user.
  const login = (credentials: { email: string; password: string }): void => {
    setLoadAuth((prev) => ({ ...prev, loading: true }));
    authenticate(credentials)
      .then((res) => {
        setLocalStorageItem('JWT', res.data.auth_token);
        loadProfile();
      })
      .catch((error: Error) => {
        setLoadAuth({ loading: false, isAuth: false });
        if (!(error instanceof AxiosError)) return setOpen('Kunde inte kontakta servern, försök igen senare');

        switch (error.response?.status) {
          case 401:
            return setOpen('Inloggningen misslyckades, kontrollera e-post och lösenord');
          default:
            return setOpen('Inloggningen misslyckades');
        }
      });
  };

  const hasPermissions = (roles: Array<string>): boolean => {
    return profile && profile.role ? roles.includes(profile.role.name) : false;
  };

  // Remove JWT from local storage. This simple authentication
  // scheme does not support back-end token invalidation.
  const logout = (): void => {
    removeLocalStorageItem('JWT');
    setLoadAuth({ loading: false, isAuth: false });
  };

  const loadProfile = (): void => {
    getProfile()
      .then(({ data }) => {
        setLoadAuth({ loading: false, isAuth: true });
        setProfile(data.data);
      })
      .catch(() => {
        setLoadAuth({ loading: false, isAuth: false });
      });
  };

  const defaultContext: AuthContextValue = {
    ...loadAuth,
    profile,
    login,
    logout,
    hasPermissions,
  };

  // render
  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
        message={open}
        open={!!open}
        onClose={() => setOpen(null)}
      />
      {loadAuth.loading && (
        <div className={css(commonStyles.loginSpinner)}>
          <RotateLoader loading={loadAuth.loading} />
        </div>
      )}
      {!loadAuth.loading && <AuthContext.Provider value={defaultContext}>{children}</AuthContext.Provider>}
    </React.Fragment>
  );
};

export default AuthContext;
