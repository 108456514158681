import * as Yup from 'yup';

import { OptionsInterface, RoleOptionsInterface, sort_order } from '../Common/types';

export const initialJobOpeningValues = {
  title: 'Ledig teamledartjänst',
  content: '',
  raw_content: '',
  residences: [] as OptionsInterface[],
  roles: [] as RoleOptionsInterface[],
  deletable: false,
  editable: false,
  max_travel_time: 0.5,
};

export const validationSchema = Yup.object().shape({
  title: Yup.string().required('* Titel är ett obligatoriskt fält'),
  content: Yup.string().required('* Nyhetstext är ett obligatoriskt fält'),
  role: Yup.array().of(Yup.object()).min(1, '* Minst en roll måste väljas'),
  residences: Yup.array().of(Yup.object()).min(1, '* Boende är ett obligatoriskt fält'),
});

export const paginationDefaults = { page: 1, page_size: 30 };

export const filterSortDefaultsAdminVaVl = {
  query: '',
  sort_by: 'created_at',
  sort_order: sort_order.desc,
  hide_deleted: true,
  hide_others: false,
};

export const filterSortDefaults = {
  query: '',
  sort_by: 'created_at',
  sort_order: sort_order.desc,
  hide_deleted: true,
  hide_others: true,
};
