import React, { ReactElement } from 'react';
import { Fab } from '@mui/material';
import { css } from '../styling';
import { Link } from 'react-router-dom';

type Props = {
  onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  to: string;
  disabled?: boolean | undefined;
  text: string | ReactElement<any, any>;
  icon: ReactElement<any, any>;
  styles: any;
  label: string;
};

const QuickLinkAction: React.FC<Props> = (props) => {
  return (
    <Fab
      variant="extended"
      color="inherit"
      aria-label={props.label}
      className={css(props.styles)}
      onClick={props.onClick}
      disabled={props.disabled !== undefined ? props.disabled : false}
    >
      <Link to={props.to} className="px-8 py-1.5 text-inherit">
        {props.icon}
        {props.text}
      </Link>
    </Fab>
  );
};

export default QuickLinkAction;
