import React, { useState, useEffect } from 'react';
import { RotateLoader } from 'react-spinners';

import { Checkbox, Dialog, Grid, Table, TableBody, TableCell } from '@mui/material';

import BatchHideMissedWorktimesModal from './components/BatchHideMissedWorktimesModal';

import { filterSortDefaults } from './MissedWorktimeSchema';
import { paginationDefaults } from '../Common/commonSchema';
import { getMissedWorkShifts } from './missedWorktimesApi';
import { TableHead, TableRow } from '../Common/Table';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { HideFab } from '../Common/ButtonLinks';
import EmptyList from '../Common/Table/EmptyList';
import { isEmpty, isNotEmpty } from '../Common/utilities';
import { ListParams } from '../Common/types';
import { WorkShiftInterface } from '../WorkShift/types';

const MissedWorktimes: React.FC = () => {
  // hooks
  const { notifyError } = useNotify();
  const [selectedMissedWorktimes, setSelectedMissedWorktimes] = useState<Array<WorkShiftInterface>>([]);
  const [missedWorktimesList, setMissedWorktimesList] = useState<Array<WorkShiftInterface>>([]);
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [hideModalState, setHideModalState] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMissedWorktimesList();
  }, [listParams]); // eslint-disable-line

  // functions
  const fetchMissedWorktimesList = (): void => {
    setLoading(true);
    getMissedWorkShifts({ ...listParams, ...paginationDefaults })
      .then(({ data }) => {
        setMissedWorktimesList(data.data);
        setSelectedMissedWorktimes([]);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över avtalsparter'))
      .finally(() => setLoading(false));
  };

  const isChecked = (id: number | undefined): boolean => {
    return selectedMissedWorktimes.some((element: WorkShiftInterface) => element.id === id);
  };

  const allRowsAreChecked = (): boolean => {
    return isNotEmpty(missedWorktimesList) && missedWorktimesList.length === selectedMissedWorktimes.length;
  };

  const clickCheckbox = (e: React.ChangeEvent<HTMLInputElement>, row: WorkShiftInterface): void => {
    const newSelectedList = e.target.checked
      ? [...selectedMissedWorktimes, row]
      : selectedMissedWorktimes.filter((a) => a.id !== row.id);

    setSelectedMissedWorktimes(newSelectedList);
  };

  const checkAll = (e: React.ChangeEvent<HTMLInputElement>): void => {
    e.target.checked ? setSelectedMissedWorktimes(missedWorktimesList) : setSelectedMissedWorktimes([]);
  };

  // render
  const rows = [
    { id: 'team_name', label: 'Team', notSortable: true },
    { id: 'day', label: 'Datum', notSortable: true },
    { id: 'hours_worked', numeric: true, label: 'Antal timmar', notSortable: true },
    { id: 'approver_name', label: 'Verifierad av', notSortable: true },
  ];

  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Kontroll arbetspass</h1>
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead
            rows={rows}
            listParams={listParams}
            setGetListParams={setGetListParams}
            checkboxComponent={<Checkbox checked={allRowsAreChecked()} onChange={checkAll} />}
          />

          <TableBody>
            {isEmpty(missedWorktimesList) ? (
              <EmptyList alternativeText="Alla arbetspass är kopplade till avtal, du kan gå vidare till hantera" />
            ) : (
              missedWorktimesList.map((row: WorkShiftInterface, idx: number) => (
                <TableRow row={row} idx={idx} key={idx} onClick={undefined}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={isChecked(row.id)} onChange={(e): void => clickCheckbox(e, row)} />
                  </TableCell>
                  <TableCell>
                    <b>{row.team?.name}</b>
                  </TableCell>
                  <TableCell align="left">
                    <p>{row.date}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p>{row.hours}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p>{row.approved_by ? row.approved_by : 'Inte angivet'}</p>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        {hideModalState && (
          <Dialog open={hideModalState} onClose={(): void => setHideModalState(false)} fullWidth={true} maxWidth="md">
            <BatchHideMissedWorktimesModal
              selectedMissedWorktimes={selectedMissedWorktimes}
              fetchMissedWorktimesList={fetchMissedWorktimesList}
              setModalState={setHideModalState}
            />
          </Dialog>
        )}
        {isNotEmpty(selectedMissedWorktimes) && (
          <div className={css(commonStyles.selectionFabContainer)}>
            <HideFab onClick={(): void => setHideModalState(true)} text="DÖLJ ARBETSPASS" />
          </div>
        )}

        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default MissedWorktimes;
