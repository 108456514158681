import React, { ReactElement } from 'react';
import dayjs from 'dayjs';

import { FieldArray, FieldArrayRenderProps, FormikErrors, FormikHandlers } from 'formik';
import { Add as AddIcon, Clear as ClearIcon } from '@mui/icons-material';
import { Button, IconButton } from '@mui/material';
import FormFreeDateField from '../../Common/FormFreeItems/FormFreeDateField';
import { AgreementDetails, PricePeriodInterface } from '../types';
import { AgreementValues } from '../agreementSchema';
import { getValue } from '../../Common/utilities';
import FormFreeTextField from '../../Common/FormFreeItems/FormFreeTextField';

type Props = {
  agreement: AgreementDetails;
  errors: FormikErrors<AgreementValues>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  isEdit?: boolean;
};

export default function AddPricePeriods({ agreement, errors, handleChange, setFieldValue, isEdit }: Props) {
  const emptyPricePeriod = {
    start_date: dayjs(),
    end_date: dayjs(),
    hourly_rate: 0,
  };

  return (
    <FieldArray
      name="price_periods"
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
        <div className="space-y-8 mb-8">
          {agreement.price_periods?.map((row: PricePeriodInterface, index: number, { length }) => (
            <div className="flex justify-between gap-6" key={index}>
              <div className="flex gap-6 xl:gap-8">
                <div className="flex flex-col !w-52">
                  <FormFreeDateField
                    label="Prisperiod från"
                    onlyValid={false}
                    size={null}
                    value={
                      getValue(agreement, `price_periods.${index}.start_date`)
                        ? dayjs(getValue(agreement, `price_periods.${index}.start_date`))
                        : null
                    }
                    minDate={dayjs(agreement.start_date)}
                    maxDate={dayjs(agreement.end_date)}
                    onChange={(day) => setFieldValue(`price_periods.${index}.start_date`, day?.format('YYYY-MM-DD'))}
                    error={getValue(errors, `price_periods.${index}.start_date`)}
                  />
                </div>
                <div className="border-t border-neutral-400 w-4 -mx-4 mt-[26px] xl:-mx-6" />
                <div className="flex flex-col !w-52">
                  <FormFreeDateField
                    label="Prisperiod till"
                    onlyValid={false}
                    size={null}
                    value={
                      getValue(agreement, `price_periods.${index}.end_date`)
                        ? dayjs(getValue(agreement, `price_periods.${index}.end_date`))
                        : null
                    }
                    minDate={dayjs(agreement.start_date)}
                    maxDate={dayjs(agreement.end_date)}
                    InputLabelProps={{ shrink: true }}
                    onChange={(day) => setFieldValue(`price_periods.${index}.end_date`, day?.format('YYYY-MM-DD'))}
                    error={getValue(errors, `price_periods.${index}.end_date`)}
                  />
                </div>
                <div className="flex flex-col !w-48">
                  <FormFreeTextField
                    size={null}
                    name={`price_periods.${index}.hourly_rate`}
                    label="Timpris (kr)"
                    placeholder="Timpris (kr)"
                    values={agreement}
                    errors={errors}
                    InputLabelProps={{ shrink: true }}
                    onChange={handleChange}
                  />
                </div>
                {index > 0 && (
                  <div className="mt-[7px]">
                    <IconButton color="secondary" size="small" onClick={(): void => arrayHelpers.remove(index)}>
                      <ClearIcon className="text-neutral-600" />
                    </IconButton>
                  </div>
                )}
                {index + 1 === length && agreement.is_active && (
                  <div className="mt-[7px]">
                    <Button
                      variant="outlined"
                      color="secondary"
                      startIcon={<AddIcon className="text-neutral-600" />}
                      onClick={(): void => arrayHelpers.push(emptyPricePeriod)}
                    >
                      <span className="text-neutral-600">NY PRISPERIOD</span>
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      )}
    />
  );
}
