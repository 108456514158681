import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import API from '../Common/axios';

export const getSentInvoicesList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/invoices', { params });
};

// TODO: is this used at all?
export const getSentInvoiceDetail = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/invoices/${id}`);
};
