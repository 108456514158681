import React from 'react';
import { CancelLink } from '../Common/ButtonLinks';
import SalaryClassForm from './form/SalaryClassForm';
import { Formik } from 'formik';
import { salaryClassInitialValues, SalaryClassFormValues, salaryClassValidation } from './form/formValues';
import { createSalaryClass } from './salaryClassApi';
import { useNotify } from '../Common/snackbarHooks';
import { useNavigate } from 'react-router-dom';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';

export default function CreateSalaryClass() {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();

  async function handleSubmit({ edison_id, ...values }: SalaryClassFormValues) {
    await createSalaryClass({ ...values, edison_id: edison_id ? Number(edison_id) : null })
      .then(() => notifySuccess('Löneart har skapats'))
      .then(() => navigate('/salary-classes'))
      .catch((error) => notifyError(`Det gick inte att skapa lönearten: \n${HandleError(error.response.data)}`));
  }

  return (
    <div>
      {/* Title */}
      <div className="flex justify-between items-center h-18 pt-3 mx-8 mb-5">
        <h1 className="text-2xl font-semibold">Lägg till löneart</h1>
        <CancelLink link="/salary-classes" />
      </div>
      {/* Form */}
      <Formik initialValues={salaryClassInitialValues} onSubmit={handleSubmit} validationSchema={salaryClassValidation}>
        {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, dirty }) => (
          <form onSubmit={handleSubmit}>
            <SalaryClassForm
              values={values}
              errors={errors}
              handleChange={handleChange}
              isSubmitting={isSubmitting}
              isValid={isValid}
              dirty={dirty}
            />
          </form>
        )}
      </Formik>
    </div>
  );
}
