import React, { ReactElement, useEffect, useMemo, useState } from 'react';

import { FieldArray, FieldArrayRenderProps, FormikErrors, FormikHandlers } from 'formik';
import { AgreementDetails, AgreementTeam, TeamAgreementDetails } from '../types';
import { Button, Collapse, IconButton, TextField } from '@mui/material';
import { getTeamAgreement } from '../agreementApi';
import { Add as AddIcon, Clear as ClearIcon, Group, KeyboardArrowDown } from '@mui/icons-material';
import { twMerge } from 'tailwind-merge';
import FormFreeDateField from '../../Common/FormFreeItems/FormFreeDateField';
import dayjs from 'dayjs';
import FormFreeTextField from '../../Common/FormFreeItems/FormFreeTextField';
import { AgreementValues } from '../agreementSchema';
import { getValue } from '../../Common/utilities';

type Props = {
  agreement: AgreementDetails;
  errors: FormikErrors<AgreementValues>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export default function Teams({ agreement, errors, handleChange, setFieldValue }: Props) {
  return (
    <FieldArray
      name="teams"
      render={(arrayHelpers: FieldArrayRenderProps): ReactElement => (
        <div className="space-y-8 mb-8">
          {agreement.teams.map((team, index) => (
            <TeamAgreement
              key={index}
              index={index}
              agreement={agreement}
              team={team}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
            />
          ))}
        </div>
      )}
    />
  );
}

type TeamAgreementProps = {
  index: number;
  agreement: AgreementDetails;
  team: AgreementTeam;
  errors: FormikErrors<AgreementValues>;
  handleChange: FormikHandlers['handleChange'];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

function TeamAgreement({ index, agreement, team, errors, handleChange, setFieldValue }: TeamAgreementProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [teamAgreementDetails, setTeamAgreementDetails] = useState<TeamAgreementDetails>();
  const [teamAgreementShiftDetailsFirst, setTeamAgreementShiftDetailsFirst] = useState<
    { date: string; invoice_id: number; hours: number; type: 'WorkShift' | 'WorkHourCorrection' }[]
  >([]);
  const [teamAgreementShiftDetailsLast, setTeamAgreementShiftDetailsLast] = useState<
    { date: string; invoice_id: number; hours: number; type: 'WorkShift' | 'WorkHourCorrection' }[]
  >([]);
  const teamAgreement = useMemo(() => team.team_agreement, [team]);

  useEffect(() => {
    if (isOpen && teamAgreementDetails === undefined && teamAgreement) {
      getTeamAgreement(teamAgreement.id).then(({ data }) => {
        setTeamAgreementDetails(data.data);
        const shiftDetails = [...data.data.work_shifts, ...data.data.work_hour_corrections].sort((a, b) =>
          a.date > b.date ? 1 : -1
        );
        if (shiftDetails.length) {
          const half = Math.floor(shiftDetails.length / 2);
          setTeamAgreementShiftDetailsFirst(shiftDetails.slice(0, half));
          setTeamAgreementShiftDetailsLast(shiftDetails.slice(half, shiftDetails.length));
        }
      });
    }
  }, [isOpen]);

  return (
    <div>
      <h2 className="flex items-center text-xl text-neutral-600">
        <Group />
        <span className="font-bold mx-2">Team:</span>
        <span>{team.name}</span>
      </h2>

      <div className="rounded-md border border-neutral-400 bg-neutral-100">
        <div className="flex justify-between p-4 gap-6">
          {teamAgreement || isVisible ? (
            <div className="flex gap-6 xl:gap-8">
              <div className="flex flex-col !w-28 2xl:!w-36">
                <FormFreeTextField
                  size={null}
                  name={`teams.${index}.team_agreement.startup_fee`}
                  label="Uppstart (kr)"
                  placeholder="Uppstart (kr)"
                  values={agreement}
                  errors={errors}
                  disabled={teamAgreement?.startup_fee_is_payed || !team.is_active}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </div>
              <div className="flex flex-col !w-52">
                <FormFreeDateField
                  label="Avtalsperiod från"
                  onlyValid={false}
                  size={null}
                  value={
                    getValue(agreement, `teams.${index}.team_agreement.start_date`) === null
                      ? null
                      : getValue(agreement, `teams.${index}.team_agreement.start_date`)
                      ? dayjs(getValue(agreement, `teams.${index}.team_agreement.start_date`))
                      : null
                  }
                  InputLabelProps={{ shrink: true }}
                  onChange={(day) =>
                    setFieldValue(`teams.${index}.team_agreement.start_date`, day?.format('YYYY-MM-DD'))
                  }
                  error={getValue(errors, `teams.${index}.team_agreement.start_date`)}
                  disabled={!team.is_active}
                />
              </div>
              <div className="border-t border-neutral-400 w-4 -mx-4 mt-[26px] xl:-mx-6" />
              <div className="flex flex-col !w-52">
                <FormFreeDateField
                  label="Avtalsperiod till"
                  onlyValid={false}
                  size={null}
                  value={
                    getValue(agreement, `teams.${index}.team_agreement.end_date`) === null
                      ? null
                      : getValue(agreement, `teams.${index}.team_agreement.end_date`)
                      ? dayjs(getValue(agreement, `teams.${index}.team_agreement.end_date`))
                      : null
                  }
                  InputLabelProps={{ shrink: true }}
                  onChange={(day) => setFieldValue(`teams.${index}.team_agreement.end_date`, day?.format('YYYY-MM-DD'))}
                  error={getValue(errors, `teams.${index}.team_agreement.end_date`)}
                  disabled={!team.is_active}
                />
              </div>
              <div className="flex flex-col !w-28 2xl:!w-36">
                <FormFreeTextField
                  size={null}
                  name={`teams.${index}.team_agreement.budgeted_hours`}
                  label="Timpott (tim)"
                  placeholder="Timpott (tim)"
                  values={agreement}
                  errors={errors}
                  disabled={!team.is_active || (teamAgreement && !isVisible)}
                  InputLabelProps={{ shrink: true }}
                  onChange={handleChange}
                />
              </div>
            </div>
          ) : (
            team.is_active && (
              <div className="flex items-center gap-6 xl:gap-8 text-neutral-600">
                <span className="font-bold mx-2">Avtal saknas</span>
                <div className="text-[#439676]">
                  <Button
                    variant="outlined"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={(): void => {
                      setFieldValue(`teams.${index}.team_agreement.startup_fee`, 0);
                      setFieldValue(`teams.${index}.team_agreement.start_date`, agreement.start_date);
                      setFieldValue(`teams.${index}.team_agreement.end_date`, agreement.end_date);
                      setFieldValue(`teams.${index}.team_agreement.budgeted_hours`, agreement.budgeted_hours);
                      setIsVisible(true);
                    }}
                  >
                    <span>LÄGG TILL AVTAL</span>
                  </Button>
                </div>
              </div>
            )
          )}
          {teamAgreement && !isVisible && (
            <div className="flex justify-between gap-4">
              <div className="space-y-2 min-w-36 2xl:min-w-40">
                <div className="flex justify-between leading-none text-xs 2xl:text-sm text-right">
                  <span className="font-medium">Fakturerat</span>
                  <span>{teamAgreement?.invoiced_hours}h</span>
                </div>
                <div className="flex justify-between leading-none text-xs 2xl:text-sm text-right">
                  <span className="font-medium">Inrapporterat</span>
                  <span>{teamAgreement?.reported_hours}h</span>
                </div>
              </div>
              <div>
                <IconButton onClick={() => setIsOpen(!isOpen)}>
                  <KeyboardArrowDown className={twMerge('!transition', isOpen && '!rotate-180')} fontSize="inherit" />
                </IconButton>
              </div>
            </div>
          )}
        </div>
        <Collapse in={isOpen}>
          <div className="overflow-y-scroll max-h-80 p-4 bg-subtleGreen text-neutral-600">
            <div className="grid grid-cols-3">
              <div>
                <span className="flex justify-center font-bold mb-4">Underliggande fakturor</span>
                {teamAgreementDetails?.invoices?.length ? (
                  <div className="grid grid-cols-[0.8fr_0.2fr] 2xl:grid-cols-[2fr_1fr] gap-y-2 leading-none text-xs font-normal px-5 2xl:px-14 2xl:text-base">
                    {teamAgreementDetails?.invoices.map((invoice) => (
                      <>
                        <div className="grid grid-cols-2 gap-x-0">
                          <span className="text-left">{invoice.created_at}</span>
                          <span>Faktura {invoice.id}</span>
                        </div>
                        <span className="text-right">{invoice.invoiced_hours}h</span>
                      </>
                    ))}
                  </div>
                ) : (
                  <span className="flex p-4 justify-center italic text-xs font-normal 2xl:px-14 2xl:text-base">
                    Inga fakturor att visa
                  </span>
                )}
              </div>
              <div className="col-span-2">
                <span className="flex justify-center font-bold mb-4">Underliggande pass och korringeringar</span>
                {teamAgreementDetails?.work_shifts.length || teamAgreementDetails?.work_hour_corrections.length ? (
                  <div className="grid grid-cols-2 border-l border-l-black/10">
                    <div className="grid grid-cols-[2fr_0.1fr] 2xl:grid-cols-[2fr_0.1fr] gap-y-2 leading-none text-xs font-normal px-5 2xl:px-14 2xl:text-base">
                      {teamAgreementShiftDetailsFirst?.map((item) => (
                        <>
                          <span className="text-left">
                            {item.type === 'WorkShift' ? 'Pass' : 'Korrigering'} {item.date}{' '}
                            {item.invoice_id && '(' + item.invoice_id + ')'}
                          </span>

                          <span className="text-right">{item.hours}h</span>
                        </>
                      ))}
                    </div>
                    <div className="grid grid-cols-[2fr_0.1fr] 2xl:grid-cols-[2fr_0.1fr] gap-y-2 leading-none text-xs font-normal px-5 2xl:px-14 2xl:text-base">
                      {teamAgreementShiftDetailsLast?.map((item) => (
                        <>
                          <span className="text-left">
                            {item.type === 'WorkShift' ? 'Pass' : 'Korrigering'} {item.date}{' '}
                            {item.invoice_id && '(' + item.invoice_id + ')'}
                          </span>
                          <span className="text-right">{item.hours}h</span>
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  <span className="flex p-4 justify-center italic text-xs font-normal 2xl:px-14 2xl:text-base">
                    Inga pass eller korrigeringar att visa
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="p-4 border-t border-neutral-400">
            <div className="grid grid-cols-3">
              <div className="flex justify-end font-bold px-5 2xl:px-14">
                <span>Summa:</span>
                <span className="ml-4">
                  {teamAgreementDetails?.invoices.reduce((sum, i) => sum + i.invoiced_hours, 0)}h
                </span>
              </div>
              <div></div>
              <div className="flex justify-end font-bold px-5 2xl:px-14">
                <span>Summa:</span>
                <span className="ml-4">
                  {(teamAgreementDetails?.work_shifts.reduce((sum, ws) => sum + ws.hours, 0) ?? 0) +
                    (teamAgreementDetails?.work_hour_corrections.reduce((sum, whc) => sum + whc.hours, 0) ?? 0)}
                  h
                </span>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
}
