import { Button, CircularProgress, Divider, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import { SalaryClassFormValues } from './formValues';
import { SalaryClass } from '../types';
import { FormikErrors, FormikHandlers } from 'formik';
import { FormTextField } from '../../Common/FormItems';
import { deleteSalaryClass } from '../salaryClassApi';
import { useNotify } from '../../Common/snackbarHooks';
import { useNavigate } from 'react-router-dom';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';

type SalaryClassFormProps = {
  values: SalaryClassFormValues;
  errors?: FormikErrors<SalaryClassFormValues>;
  handleChange: FormikHandlers['handleChange'];
  salaryClass?: SalaryClass;
  isSubmitting?: boolean;
  isValid?: boolean;
  dirty?: boolean;
};

export default function SalaryClassForm({
  values,
  errors,
  handleChange,
  salaryClass,
  isSubmitting,
  isValid,
  dirty,
}: SalaryClassFormProps) {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();
  const [loadingArchive, setLoadingArchive] = useState<'hard' | 'soft' | null>(null);

  function archiveSalaryClass(destroy?: boolean) {
    if (!salaryClass) return;
    setLoadingArchive(destroy ? 'hard' : 'soft');
    deleteSalaryClass(salaryClass.id, destroy)
      .then(() => {
        notifySuccess('Arkiverade löneart');
        navigate('/salary-classes');
      })
      .catch((error) =>
        notifyError(`Det gick inte att ${destroy ? 'ta bort' : 'arkivera'} lönearten: ${HandleError(error)}`)
      )
      .finally(() => setLoadingArchive(null));
  }

  return (
    <div className="mx-8">
      <Grid container spacing={2} rowSpacing={-2}>
        <FormTextField
          size={6}
          label="Namn på lönearten"
          placeholder="Namn"
          fieldName="name"
          disabled={salaryClass && !salaryClass?.custom}
          errors={errors}
          onChange={handleChange}
          values={values}
        />
        <FormTextField
          size={6}
          label="Förkortning på lönearten"
          placeholder="Förkortning"
          fieldName="short_name"
          disabled={salaryClass && !salaryClass?.custom}
          errors={errors}
          onChange={handleChange}
          values={values}
        />
        <FormTextField
          size={6}
          label="Edison-ID för lönearten"
          placeholder="Edison-ID"
          fieldName="edison_id"
          disabled={salaryClass && !salaryClass?.custom}
          errors={errors}
          onChange={handleChange}
          values={values}
        />
      </Grid>

      <Divider className="!my-8" />

      <Grid container spacing={2}>
        <Grid item xs={5} />
        <Grid item xs={2}>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid || !dirty || isSubmitting || (salaryClass && !salaryClass?.custom)}
            fullWidth
            type="submit"
          >
            {isSubmitting ? (
              <CircularProgress size="1rem" className="!my-1" color="inherit" />
            ) : salaryClass ? (
              'Spara'
            ) : (
              'Lägg till'
            )}
          </Button>
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={2}>
          {salaryClass && (
            <Button
              color="error"
              variant="outlined"
              fullWidth
              disabled={!!loadingArchive || (salaryClass && !salaryClass?.custom)}
              onClick={() => archiveSalaryClass(false)}
            >
              {loadingArchive === 'soft' ? (
                <CircularProgress size="1rem" className="!my-1" color="inherit" />
              ) : (
                'Arkivera'
              )}
            </Button>
          )}
          {salaryClass?.hard_deletable && (
            <Button
              color="error"
              variant="outlined"
              fullWidth
              disabled={!!loadingArchive || (salaryClass && !salaryClass?.custom)}
              className="!mt-4"
              onClick={() => archiveSalaryClass(true)}
            >
              {loadingArchive === 'hard' ? (
                <CircularProgress size="1rem" className="!my-1" color="inherit" />
              ) : (
                'Ta bort'
              )}
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
