import API from '../Common/axios';
import { Interview, InterviewDetails } from './types';

type FindInterviewsParams = {
  query?: string;
  filter_from_date?: string;
  filter_to_date?: string;
  filter_team?: number;
  hide_exported?: boolean;
  sort_by?: string;
  sort_order?: 'asc' | 'desc';
  page?: number;
};

export async function findInterviews(params: FindInterviewsParams = {}) {
  return API.get<{ data: Interview[]; meta: { count: number } }>('v1/interviews', { params });
}

export async function getInterview(id: number) {
  return API.get<{ data: InterviewDetails }>(`v1/interviews/${id}`);
}

type CreateInterviewParams = {
  team_id: number;
  date: string;
  amount: number;
  type: Interview['type'];
  attending_tl?: string | null;
  attending_rc?: string | null;
  training?: boolean;
  training_overflow?: boolean;
};

export async function createInterview(interview: CreateInterviewParams) {
  return API.post<{ data: InterviewDetails }>('v1/interviews', { interview });
}

type UpdateInterviewParams = CreateInterviewParams;

export async function updateInterview(id: number, interview: UpdateInterviewParams) {
  return API.patch<{ data: InterviewDetails }>(`v1/interviews/${id}`, { interview });
}

export async function deleteInterview(id: number) {
  return API.delete(`v1/interviews/${id}`);
}

type TrainingsPerformedParams = { user: string; type: 'tl' | 'rc' };
export async function trainingsPerformed(params: TrainingsPerformedParams) {
  return API.get<number>('v1/interviews/trainings_performed', { params });
}
