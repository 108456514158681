import React, { useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { updateImportantNews } from './../newsApi';
import { NewsInterface } from './../types';

type Props = {
  news: NewsInterface;
  setModalState: (modalState: boolean) => void;
  fetchNewsList: () => void;
  setSelectedNews: (news: NewsInterface[]) => void;
  setQuickActionNews: (news: NewsInterface[]) => void;
};

const ImportantNewsModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [newsId, setNewsId] = useState<number>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setNewsId(props.news.id);
    setLoading(false);
  }, []); // eslint-disable-line

  const handleSubmit = (): void => {
    setLoading(true);
    if (newsId) {
      updateImportantNews(newsId, { important: true })
        .then(() => notifySuccess('Nyheten har markerats till viktig'))
        .then(() => {
          setLoading(false);
          props.fetchNewsList();
          props.setSelectedNews([]);
          props.setQuickActionNews([]);
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera nyhet: ${error}`))
        .then(() => props.setModalState(false));
    }
  };

  return (
    <div>
      <DialogTitle>Viktig nyhet</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              Vill du göra nyheten till en &quot;viktig&quot;? Endast en nyhet kan vara &quot;viktig&quot; åt gången. En
              &quot;viktig nyhet&quot; visas för alla roller högst upp i flödet.
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogActions>
              <Button
                fullWidth
                color="error"
                onClick={(): void => {
                  props.setModalState(false);
                  props.setQuickActionNews([]);
                }}
              >
                NEJ
              </Button>
              <Button fullWidth color="primary" onClick={(): void => handleSubmit()}>
                JA
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </div>
  );
};

export default ImportantNewsModal;
