import React, { useEffect, useMemo, useState } from 'react';
import { CreateFab } from '../Common/ButtonLinks';
import { FormControlLabel, Switch } from '@mui/material';
import { SalaryClass } from './types';
import { findSalaryClasses } from './salaryClassApi';
import { useQuery } from '../Common/utilities';
import { matchSorter } from 'match-sorter';
import SearchIcon from '@mui/icons-material/Search';
import { BaseFilterPopover } from '../Common/FilterPopover';
import { RotateLoader } from 'react-spinners';
import TableBox, { TableBoxColumn } from '../Common/Table/TableBox';

const tableColumns: TableBoxColumn[] = [
  { id: 'name', label: 'Namn', width: '4xl' },
  { id: 'short_name', label: 'Förkortning', width: 'md' },
  { id: 'edison_id', label: 'Edison-ID', width: 'md' },
];

const orderKeys = ['name', 'short_name', 'edison_id'];

export default function SalaryClasses() {
  const [loading, setLoading] = useState(true);
  const [salaryClasses, setSalaryClasses] = useState<SalaryClass[]>([]);
  const [query, setQuery] = useQuery({
    search: (val) => val,
    showArchived: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
    sortBy: (val) => (orderKeys.includes(val!) ? (val as keyof SalaryClass) : undefined),
    sortOrder: (val) => (val === 'asc' ? ('asc' as const) : val === 'desc' ? ('desc' as const) : undefined),
  });

  const filteredSalaryClasses = useMemo(() => {
    let filtered = salaryClasses;
    if (!query.showArchived) filtered = filtered.filter(({ deleted_at }) => !deleted_at);
    if (query.search) filtered = matchSorter(filtered, query.search, { keys: ['name', 'edison_id'] });

    if (!query.sortBy || !orderKeys.includes(query.sortBy) || !query.sortOrder) return filtered;

    const ordering = query.sortOrder === 'asc' ? 1 : -1;
    return filtered.toSorted((a, b) => {
      const colA = query.sortBy === 'edison_id' ? Number(a[query.sortBy]) : a[query.sortBy!];
      const colB = query.sortBy === 'edison_id' ? Number(b[query.sortBy]) : b[query.sortBy!];

      return colA! > colB! ? ordering : colA! < colB! ? -ordering : 0;
    });
  }, [salaryClasses, query.search, query.showArchived, query.sortBy, query.sortOrder]);

  useEffect(() => {
    findSalaryClasses({ custom: true })
      .then(({ data }) => setSalaryClasses(data.data))
      .finally(() => setLoading(false));
  }, []);

  function updateSorting(col: keyof SalaryClass) {
    return setQuery({ sortBy: col, sortOrder: query.sortBy !== col || query.sortOrder === 'desc' ? 'asc' : 'desc' });
  }

  return (
    <div className="min-h-full">
      {/* Title */}
      <div className="h-16 p-4 bg-gray-100">
        <h1 className="text-2xl font-semibold">Lönearter</h1>
      </div>
      {/* Filters */}
      <div className="bg-white flex justify-between h-13">
        <div className="relative w-full">
          <SearchIcon className="absolute top-3.5 bottom-3.5 left-2.5" />
          <input
            className="pl-11 py-3.5 outline-none w-full"
            placeholder="Sök löneart"
            value={query.search ?? ''}
            onChange={({ target }) => setQuery({ search: target.value || undefined })}
          />
        </div>
        <BaseFilterPopover>
          <div className="flex justify-between items-center">
            <FormControlLabel
              control={
                <Switch
                  checked={query.showArchived ?? false}
                  onChange={(_, checked) => setQuery({ showArchived: checked })}
                />
              }
              label="Visa arkiverade"
              labelPlacement="start"
            />
          </div>
        </BaseFilterPopover>
      </div>
      {/* Content */}
      <TableBox
        columns={tableColumns}
        content={filteredSalaryClasses}
        rowDefinition={(sc) => ({
          key: sc.id,
          defaultLink: `/salary-classes/${sc.id}`,
          deleted: !!sc.deleted_at,
          cols: [sc.name, sc.short_name, sc.edison_id ?? '–'],
        })}
        sorting={{
          sortBy: query.sortBy,
          sortOrder: query.sortOrder,
          handleSortChange: updateSorting as (col: string) => void,
        }}
        fullWidth
      />

      {loading && (
        <div className="fixed top-1/2 left-1/2">
          <RotateLoader />
        </div>
      )}
      <CreateFab link="/salary-classes/create" />
    </div>
  );
}
