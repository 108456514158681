import { FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';
import React, { useEffect, useState } from 'react';

import { css, commonStyles } from '../styling';
import { getValue } from '../utilities';

type Props = {
  fieldName: string;
  label: string;
  values: any;
  defaultValue?: number;
  onChange: any;
  errors: any;
  disabled?: boolean;
  minutesOnly?: boolean;
  hours?: number;
  skip?: Array<string>;
  style?: any;
  required?: boolean;
};

const FormHourMinutePicker: React.FC<Props> = (props) => {
  const [hours, setHours] = useState(24);
  const [minutes, setMinutes] = useState<Array<{ value: number; display: string }>>([
    { display: '00', value: 0 },
    { display: '15', value: 0.25 },
    { display: '30', value: 0.5 },
    { display: '45', value: 0.75 },
  ]);
  const [options, setOptions] = useState<Array<{ value: number; display: string }>>([]);
  const [skip, setSkip] = useState<Array<string>>([]);

  useEffect(() => {
    if (props.hours) {
      setHours(props.hours);
    }

    if (props.skip) {
      setSkip(props.skip);
    }
  }, []);

  useEffect(() => {
    const hoursArray = [];
    const optionsArray: Array<{ value: number; display: string }> = [];

    for (let i = 0; i < hours; i++) {
      if (i < 10) hoursArray.push({ display: `0${i}`, value: i });
      else hoursArray.push({ display: `${i}`, value: i });
    }

    hoursArray.forEach((hour) => {
      minutes.map((minute) => {
        if (skip.includes(`${hour.display}:${minute.display}`)) {
          return;
        }
        optionsArray.push({ value: hour.value + minute.value, display: `${hour.display}:${minute.display}` });
      });
    });
    setOptions(optionsArray);
  }, [hours, skip]);

  return (
    <React.Fragment>
      <FormControl fullWidth disabled={props.disabled} margin="normal">
        <InputLabel>{props.required ? `${props.label} *` : props.label}</InputLabel>
        <Select
          value={getValue(props.values, props.fieldName) ?? props.defaultValue ?? ''}
          name={props.fieldName}
          label={props.required ? `${props.label} *` : props.label}
          onChange={props.onChange}
          MenuProps={{
            classes: { paper: css(commonStyles.dropdownStyle) },
          }}
          error={!!getValue(props.errors, props.fieldName)}
          style={props.style ? props.style : {}}
        >
          {options.map((e: { value: number; display: string }) => (
            <MenuItem value={e.value} key={e.value}>
              {e.display}
            </MenuItem>
          ))}
        </Select>
        {getValue(props.errors, props.fieldName) && (
          <FormHelperText error>{getValue(props.errors, props.fieldName)}</FormHelperText>
        )}
      </FormControl>
    </React.Fragment>
  );
};

export default FormHourMinutePicker;
