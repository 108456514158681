import React from 'react';

import dayjs, { Dayjs } from 'dayjs';
import FilterDatePicker from '../../Common/FilterPopover/FilterDatePicker';

type Props = {
  setGetListParams: any;
  listParams: any;
};

export const FilterPopover: React.FC<Props> = (props) => {
  // functions
  const setYear = (val: Dayjs | null): void => {
    if (!val) return;
    const date_after = val.startOf('year').format('YYYY-MM-DD');
    const date_before = val.endOf('year').format('YYYY-MM-DD');
    props.setGetListParams({
      ...props.listParams,
      date_after: date_after,
      date_before: date_before,
    });
  };

  // render
  return (
    // <DatePicker
    //   views={['year']}
    //   onChange={(e): void => setYear(e)}
    //   value={dayjs(props.listParams.date_after)}
    //   maxDate={dayjs(props.listParams.year_after)}
    //   // InputProps={{ disableUnderline: true }}
    //   slots={{field: (props) => }}

    // />
    <FilterDatePicker
      views={['year']}
      value={dayjs(props.listParams.date_after)}
      onChange={setYear}
      maxDate={dayjs(props.listParams.year_after)}
      showArrow={false}
      className="w-22 !bg-transparent"
    />
  );
};
