import React, { useState } from 'react';

import { paginationDefaults } from '../commonSchema';
import dayjs, { Dayjs } from 'dayjs';
import { Clear as ClearIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { getValue } from '../utilities';

type Props = {
  fieldName: string;
  label: string;
  listParams: any;
  setGetListParams: any;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
};

const FilterDateField: React.FC<Props> = (props) => {
  // hooks
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(getValue(props.listParams, props.fieldName));

  // functions
  const onDateChange = (date: Dayjs | null): void => {
    if (props.setPagination) {
      props.setPagination({
        page: 1,
        page_size: props.paginationDefaults ? props.paginationDefaults.page_size : paginationDefaults.page_size,
      });
    }
    if (date) {
      if (date.isValid()) {
        const newDate = date.format('YYYY-MM-DD');
        props.setGetListParams({ ...props.listParams, [props.fieldName]: newDate });
        setValue(newDate);
      }
    } else {
      // if the datepicker is cleared the date is null
      props.setGetListParams({ ...props.listParams, [props.fieldName]: date });
      setValue(date);
    }
  };

  // render
  return (
    <div>
      <div>{props.label}</div>
      <div>
        <DesktopDatePicker
          format="YYYY-MM-DD"
          value={value ? dayjs(value) : null}
          onChange={onDateChange}
          onAccept={onDateChange}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          slotProps={{
            field: {
              InputProps: {
                endAdornment: (
                  <IconButton onClick={() => onDateChange(null)}>
                    <ClearIcon />
                  </IconButton>
                ),
              },
            },
            textField: { margin: 'normal' },
            inputAdornment: { position: 'start', className: 'ml-3.5' },
          }}
        />
      </div>
    </div>
  );
};

export default FilterDateField;
