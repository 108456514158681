import React from 'react';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNotify } from '../../Common/snackbarHooks';
import { createAllInvoices, createInvoices } from '../managementApi';

type Props = {
  invoiceDetails: any;
  setModalState: any;
  callback: any;
  setLoading: any;
};

const InvoiceModal: React.FC<Props> = (props) => {
  // hooks
  const { notifyError, notifyInfo } = useNotify();

  // functions
  const reloadTimeout = (ids: number[], timeout: number): void => {
    setTimeout(() => {
      props.callback(ids);
    }, timeout * 1000);
  };

  const handleSubmit = (): void => {
    const ids = props.invoiceDetails.map((element: any) => element.id);
    props.setLoading(true);
    createInvoices(ids)
      .then(() => {
        props.callback(ids);
      })
      .catch((e) => {
        if (e.code && e.code === 'ECONNABORTED') {
          notifyInfo('Detta kan ta lång tid, sidan kommer uppdateras om en stund');
          reloadTimeout(ids, ids.length * 0.5);
        } else {
          notifyError('Det gick inte att skapa fakturorna');
        }
      })
      .finally(() => props.setLoading(false));

    props.setModalState(false);
  };

  const handleSubmitForAll = (): void => {
    props.setLoading(true);
    createAllInvoices()
      .then(() => {
        props.callback();
      })
      .catch(() => {
        notifyError('Det gick inte att skapa fakturorna');
      })
      .finally(() => props.setLoading(false));

    props.setModalState(false);
  };

  const getDialogTitle = (): any => {
    if (!props.invoiceDetails) {
      return <DialogTitle>{'Bekräfta skapandet av fakturor för samtliga boenden'}</DialogTitle>;
    } else {
      return props.invoiceDetails.length === 1 ? (
        <DialogTitle>{`Bekräfta skapandet av en faktura för ${props.invoiceDetails[0].residence.name}`}</DialogTitle>
      ) : (
        <DialogTitle>{`Bekräfta skapandet av fakturor för ${props.invoiceDetails.length} boenden`}</DialogTitle>
      );
    }
  };

  // render
  return (
    <div>
      {getDialogTitle()}
      <DialogContent>
        <DialogContentText>Om du skulle ångra detta går det att göra under vyn Skapade fakturor.</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button fullWidth color="error" onClick={(): void => props.setModalState(false)}>
          AVBRYT
        </Button>
        <Button
          color="primary"
          fullWidth
          onClick={(): void => (props.invoiceDetails ? handleSubmit() : handleSubmitForAll())}
        >
          SPARA
        </Button>
      </DialogActions>
    </div>
  );
};

export default InvoiceModal;
