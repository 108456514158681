import React, { useContext, useState } from 'react';

import {
  createAnswer,
  createEducationPage,
  createQuestion,
  deleteEducationPage,
  deleteQuestion,
  updateEducationPage,
} from '../EducationApi';

import { Add as AddIcon } from '@mui/icons-material';
import { Button, Card, FormControl, Grid } from '@mui/material';
import axios from 'axios';
import AuthContext from '../../../Common/AuthContext';
import { HandleError } from '../../../Common/ErrorHandling/ErrorHelper';
import { useNotify } from '../../../Common/snackbarHooks';
import { commonStyles, css } from '../../../Common/styling';
import { AuthContextValue } from '../../../Common/types';
import MarkupLegend from '../../MarkupLegend';
import { EducationPageInterface } from '../types';
import EducationPage from './EducationPage';

type Props = {
  educationId: number;
  educationPages: Array<EducationPageInterface>;
};

const basePage: EducationPageInterface = {
  title: '',
  subtitle: '',
  text: '',
  youtube_url: '',
  estimated_time: 0,
};

const EducationPages: React.FC<Props> = (props) => {
  const [showAdd, setShowAdd] = useState(false);
  const [educationPages, setEducationPages] = useState(props.educationPages);
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  const { notifyError, notifySuccess, notifyInfo } = useNotify();

  const deletePage = async (id: number): Promise<boolean> => {
    if (id === -1) {
      setShowAdd(false);
      return true;
    } else {
      try {
        await deleteEducationPage(id);
        notifyInfo('Utbildningssidan har tagits bort');
        setEducationPages(educationPages.filter((p) => p.id !== id));
        return true;
      } catch (e) {
        notifyError('Det gick inte att ta bort utbildningssidan');
        return false;
      }
    }
  };

  const createPage = async (values: EducationPageInterface, position?: number): Promise<boolean> => {
    try {
      const page = await createEducationPage(props.educationId, values);
      const pages = [...educationPages, page.data.education_page];
      if (position !== undefined) {
        setEducationPages(await movePage(-1, position, pages));
      } else {
        setEducationPages(pages);
      }
      notifySuccess('Utbildningssidan skapades');
      setShowAdd(false);

      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        notifyError(`Det gick inte att skapa utbildningssidan : ${HandleError(error.response?.data)}`);
      } else {
        notifyError(`Det gick inte att skapa utbildningssidan.`);
      }
      return false;
    }
  };

  const updatePage = async (
    newPage: EducationPageInterface,
    orderedPages?: Array<EducationPageInterface>
  ): Promise<boolean> => {
    const currentPages = orderedPages || educationPages;

    const oldPage = currentPages?.find((page) => page.id === newPage.id);

    try {
      await Promise.all(oldPage?.education_questions?.map((oldQuestion) => deleteQuestion(oldQuestion.id!)) || []);
      await Promise.all(
        newPage.education_questions?.map(async (newQuestion, index) => {
          newQuestion.rank = index;
          const createdQuestion = (await createQuestion(newPage.id!, newQuestion)).data.education_question;

          newPage.education_questions![index] = createdQuestion;

          return Promise.all(
            newQuestion.education_answers?.map((answer, answerIndex) => {
              answer.rank = answerIndex;
              return createAnswer(createdQuestion.id!, answer);
            }) || []
          );
        }) || []
      );

      const page = { ...newPage };
      page.education_questions = [];
      const updatedPage = (await updateEducationPage(newPage.id!, page)).data.education_page;

      const pages = [...currentPages];
      const index = pages.findIndex((p) => p.id === newPage.id);

      pages[index] = updatedPage;

      setEducationPages(pages);

      notifySuccess('Utbildningssidan har uppdaterats');
      return true;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        notifyError(`Det gick inte att uppdatera utbildningssidan : ${HandleError(error.response?.data)}`);
      } else {
        notifyError(`Det gick inte att uppdatera utbildningssidan.`);
      }
      return false;
    }
  };

  const movePage = async (
    from: number,
    to: number,
    pageArray?: Array<EducationPageInterface>
  ): Promise<Array<EducationPageInterface>> => {
    let pages = pageArray || [...educationPages];

    if (from === -1) {
      from = pages.length - 1;
    }

    const element = pages.splice(from, 1)[0];

    if (to === -1) {
      pages = [...pages, element];
    } else {
      pages.splice(to, 0, element);
    }

    try {
      await Promise.all(
        pages
          .map((page, index): [EducationPageInterface, number] => [page, index])
          .filter(([page, index]: [EducationPageInterface, number]) => page.rank !== index)
          .map(([page, index]) => {
            page.rank = index;
            return updateEducationPage(page.id!, page);
          })
      );
    } catch (e) {
      notifyError('Uppdatering av sidordning misslyckades');
    }
    return pages;
  };

  return (
    <React.Fragment>
      <div>
        <div className={css(commonStyles.formContainer)}>
          <h2>Utbildningssidor</h2>
          <div style={{ marginBottom: '20px' }}>
            <MarkupLegend text="Sidtexter går att formattera med märkspråk" hideVideo={true} />
          </div>

          {educationPages.map((page: EducationPageInterface, idx: number) => (
            <EducationPage
              key={idx}
              index={idx}
              educationId={props.educationId}
              page={page}
              pages={educationPages}
              isCreate={false}
              movePage={movePage}
              deletePage={deletePage}
              updatePage={updatePage}
            />
          ))}
          {showAdd && (
            <Card style={{ marginTop: '20px' }}>
              <EducationPage
                key={-1}
                index={-1}
                educationId={props.educationId}
                page={basePage}
                pages={educationPages}
                isCreate={true}
                deletePage={deletePage}
                createPage={createPage}
              />
            </Card>
          )}
          {
            <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
              <Grid item xs={5} />
              <Grid item xs={2}>
                <FormControl variant="standard" margin="dense" required fullWidth>
                  <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    color="primary"
                    disabled={showAdd || !hasPermissions(['admin', 'operational_leader'])}
                    onClick={(): void => {
                      setShowAdd(true);
                    }}
                  >
                    Skapa ny sida
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          }
        </div>
      </div>
    </React.Fragment>
  );
};

export default EducationPages;
