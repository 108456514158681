import React from 'react';
import PushPinIcon from '@mui/icons-material/PushPin';
import { commonStyles, css, StyleSheet, theme } from '../../Common/styling';
import { Fab } from '@mui/material';

const styles = StyleSheet.create({
  importantIcon: {
    marginRight: '10px',
    color: theme.themeGreen,
    fontSize: '1rem',
  },
});

type Props = {
  onClick: any;
  text: string;
};

const ImportantFab: React.FC<Props> = (props) => {
  return (
    <Fab
      variant="extended"
      color="inherit"
      aria-label="Important"
      className={css(commonStyles.selectionFabStyle2)}
      onClick={props.onClick}
    >
      <PushPinIcon className={css(styles.importantIcon)} />
      {props.text}
    </Fab>
  );
};

export default ImportantFab;
