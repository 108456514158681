import React from 'react';

import { BaseLanguageFilterPopover, LanguageFilterCheckbox } from '../../Common/FilterPopover';
import { LanguageInterface } from '../types';
import { ListParams } from '../../Common/types';
import { paginationDefaults } from '../../Common/commonSchema';
import { Button } from '@mui/material';

type Props = {
  options: LanguageInterface[];
  listParams: ListParams;
  selected: number[] | undefined;
  setGetListParams: (p: ListParams) => void;
  fieldName: string;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
};

const LanguageFilter: React.FC<Props> = (props) => {
  const [languageList, setLanguageList] = React.useState<Array<number>>(props.selected ? props.selected : []);
  const [allUnchecked, setAllUnchecked] = React.useState(true);

  const getChecked = (id: number): boolean => {
    const params = props.listParams[props.fieldName as keyof ListParams];
    if (Array.isArray(params) && params.includes(id)) {
      return true;
    } else if (typeof params == 'number' && params === id) {
      return true;
    }
    return false;
  };
  function handleClearAllClick() {
    if (props.setPagination) {
      props.setPagination({
        page: 1,
        page_size: props.paginationDefaults ? props.paginationDefaults.page_size : paginationDefaults.page_size,
      });
    }
    setAllUnchecked(true);
    setLanguageList([]);
    props.setGetListParams({
      ...props.listParams,
      [props.fieldName]: [],
    });
  }
  const handleChange = (checked: boolean, name: string, id: number) => {
    setAllUnchecked(false);
    // when language filter changes, reset pagination to page 1
    if (props.setPagination) {
      props.setPagination({
        page: 1,
        page_size: props.paginationDefaults ? props.paginationDefaults.page_size : paginationDefaults.page_size,
      });
    }
    if (checked) {
      languageList.push(id);
      props.setGetListParams({
        ...props.listParams,
        [props.fieldName]: languageList,
      });
    } else {
      const index = languageList.indexOf(id);
      if (index > -1) {
        languageList.splice(index, 1);
      }
      props.setGetListParams({
        ...props.listParams,
        [props.fieldName]: languageList,
      });
    }
  };

  return (
    <BaseLanguageFilterPopover empty={languageList.length == 0}>
      <Button
        color="secondary"
        style={{ marginLeft: '-9px', backgroundColor: 'transparent' }}
        onClick={handleClearAllClick}
      >
        <i>Rensa</i>
      </Button>
      {props.options.map((language: LanguageInterface) => (
        <LanguageFilterCheckbox
          checked={getChecked(language.id)}
          id={language.id}
          key={language.id}
          fieldName={props.fieldName}
          label={language.name}
          handleChange={handleChange}
          allUnchecked={allUnchecked}
        />
      ))}
    </BaseLanguageFilterPopover>
  );
};

export default LanguageFilter;
