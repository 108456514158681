import React from 'react';

import { BaseFilterPopover } from '../../Common/FilterPopover';
import { Checkbox, FormControlLabel } from '@mui/material';

type Props = {
  showInactive: boolean | undefined;
  setShowInactive: (val: boolean) => void;
};

export default function TeamsFilterPopover({ showInactive, setShowInactive }: Props) {
  return (
    <BaseFilterPopover>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa inaktiva team
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Checkbox checked={showInactive ?? false} onChange={(_, checked) => setShowInactive(checked)} />}
        />
      </div>
    </BaseFilterPopover>
  );
}
