import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { DashboardTeam, DashboardWorkShift } from '../types';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import { convertNumToTime, lastLocationState } from '../../Common/utilities';
import TlIcon from './TeamLeaderIcon';
import { MinimalUser } from '../../User/types';
import AttendanceIcon from './AttendanceIcon';
import { Link, useLocation } from 'react-router-dom';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIconTypeMap } from '@mui/material';
import { CalendarViewWeek, MoreHoriz, PushPin, Visibility } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

type DashboardWorkShiftCardProps = {
  team: DashboardTeam;
  workShift: DashboardWorkShift;
};

export default function DashboardWorkShiftCard({ team, workShift }: DashboardWorkShiftCardProps) {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);

  const currentDate = dayjs().format('YYYY-MM-DD');

  return (
    <>
      {workShift && (
        <div className="rounded-2xl shadow-md bg-white overflow-hidden">
          <div className="flex items-center justify-between pl-4 pr-2 bg-gray-100 border-solid border-b border-[#dadada] overflow-hidden">
            <p title={team.name} className="text-black my-3 font-semibold text-sm break-all line-clamp-1">
              {team.name}
            </p>
            <div className="flex">
              <IconButton size="small" onClick={(event) => setMenuAnchor(event.currentTarget)}>
                <MoreHoriz fontSize="inherit" />
              </IconButton>
              <Menu anchorEl={menuAnchor} open={!!menuAnchor} onClose={() => setMenuAnchor(null)}>
                <MenuLink to={`/teams/${team.id}`} title="Team" icon={Visibility} />
                <MenuLink
                  to={`/schedule/${team.id}?month=${dayjs(workShift.date).format('YYYY-MM')}`}
                  title="Schema"
                  icon={CalendarViewWeek}
                />
                <MenuLink to={`/work-shifts/${workShift.id}`} title="Pass" icon={PushPin} />
              </Menu>
            </div>
          </div>
          <div className="py-3 px-4 space-y-3">
            <div className="flex items-center gap-2 overflow-hidden">
              <AccessTimeIcon fontSize="small" style={{ color: 'black' }} />
              <p className="first-letter:uppercase text-black text-sm max-h-5">
                {dayjs(workShift.date).format('ddd D MMM')} {convertNumToTime(workShift.start_time)}-
                {convertNumToTime(workShift.end_time)}
              </p>
            </div>
            <div className="flex items-center gap-2 overflow-hidden text-sm">
              <TlIcon fontSize="small" />
              {workShift.team_leaders.length === 0 && workShift.temp_team_leaders.length === 0 ? (
                <p className="text-red-500 font-semibold max-h-5">TL: Saknas</p>
              ) : (
                <p className="text-black break-all line-clamp-1">
                  {!!workShift.team_leaders.length &&
                    `TL: ${workShift.team_leaders
                      .map((tl) => `${tl.name.split(' ')[0]} ${tl.name.split(' ')[1]?.charAt(0)}`.trim())
                      .join(',')}`}
                  {!!workShift.temp_team_leaders.length &&
                    `Tf. TL: ${workShift.temp_team_leaders
                      .map((tl) => `${tl.name.split(' ')[0]} ${tl.name.split(' ')[1]?.charAt(0)}`.trim())
                      .join(',')}`}
                </p>
              )}
            </div>
            <div className="flex items-center gap-2 overflow-hidden text-sm">
              <AttendanceIcon fontSize="small" />
              {workShift.date <= currentDate && !workShift.reported_at && (
                <p className="text-red-500 font-semibold line-clamp-1">Ej inrapporterat</p>
              )}
              {((workShift.date <= currentDate && workShift.reported_at) || workShift.date > currentDate) && (
                <p
                  className={twMerge(
                    'break-all line-clamp-1',
                    workShift.attendance >= team.standard_attendance && 'text-black',
                    workShift.attendance < team.standard_attendance - 1 &&
                      workShift.date <= currentDate &&
                      'text-red-500 font-semibold'
                  )}
                >
                  {workShift.attendance} / {team.standard_attendance}{' '}
                  {workShift.date <= currentDate && workShift.reported_at && 'närvarande'}
                  {workShift.date > currentDate && 'schemalagda'}
                </p>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

type MenuLinkProps = {
  to: string;
  title: string;
  // eslint-disable-next-line
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string };
};

function MenuLink({ to, title, icon: Icon }: MenuLinkProps) {
  const location = useLocation();
  return (
    <Link to={to} state={lastLocationState(location)} className="text-inherit">
      <MenuItem>
        <ListItemIcon>
          <Icon fontSize="small" />
        </ListItemIcon>
        <ListItemText>{title}</ListItemText>
      </MenuItem>
    </Link>
  );
}
