import { ThemeOptions } from '@mui/material';
import appTheme from './appTheme';

// Configuration for Material UI theme feature.
// https://mui.com/material-ui/customization/theming/
const theme: ThemeOptions = {
  palette: {
    primary: {
      light: appTheme.themeGreenLight,
      main: appTheme.themeGreen,
      dark: appTheme.greenDark,
      contrastText: appTheme.white,
    },
    secondary: { main: '#323232' },
    // primary: {
    //   light: appTheme.redLight,
    //   main: appTheme.red,
    //   dark: appTheme.redDarker,
    //   contrastText: appTheme.white,
    // },
    // secondary: {
    //   light: appTheme.themeGreenLight,
    //   main: appTheme.themeGreen,
    //   dark: appTheme.greenDark,
    //   contrastText: appTheme.white,
    // },
    error: {
      light: '#d0021b',
      main: '#d0021b',
      dark: '#d0021b',
      contrastText: appTheme.white,
    },
  },
};

export default theme;
