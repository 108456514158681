import React from 'react';
import { ListParams } from '../types';
import { paginationDefaults } from '../commonSchema';
import { FormControlLabel, Switch } from '@mui/material';

type Props = {
  fieldName: string;
  label: string;
  listParams: ListParams;
  setGetListParams: (p: ListParams) => void;
  setSelectedRowItems?: (p: []) => void;
  setQuickActionItem?: (p: []) => void;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
};

const FilterSwitch: React.FC<Props> = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {props.label}
      <FormControlLabel
        control={
          <Switch
            checked={!props.listParams[props.fieldName as keyof ListParams]}
            onChange={(e): void => {
              if (props.setPagination) {
                props.setPagination({
                  page: 1,
                  page_size: props.paginationDefaults
                    ? props.paginationDefaults.page_size
                    : paginationDefaults.page_size,
                });
              }
              props.setGetListParams({ ...props.listParams, [props.fieldName]: !e.target.checked });
              if (props.setSelectedRowItems) {
                props.setSelectedRowItems([]);
              }
              if (props.setQuickActionItem) {
                props.setQuickActionItem([]);
              }
            }}
          />
        }
        label=""
        labelPlacement="start"
      />
    </div>
  );
};

export default FilterSwitch;
