import axios from 'axios';
import { getLocalStorageItem, removeLocalStorageItem } from '../Common/localStorageUtils';
import { Residence, ResidenceMonthData, ResidenceScheduledWorkShift } from './types';
import { cookieManager } from '../Common/utilities';

// Instantiate HTTP service
const residenceAPI = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  timeout: 25000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

// Attach JWT to outgoing requests if present in local storage
residenceAPI.interceptors.request.use((request) => {
  const token = cookieManager.get('residence_token');
  if (token) {
    request.headers['Authorization'] = `Bearer ${token}`;
  }
  return request;
});

// Add a 401 response interceptor
residenceAPI.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const allowed_urls = ['authenticate_residence'];
    const allowed = allowed_urls.includes(error.config.url);
    if (error.response.status === 401 && !allowed) {
      cookieManager.remove('residence_token');
      window.location.replace('/boende');
    }
    return Promise.reject(error);
  }
);

export async function authenticateResidencePage(password: string) {
  return await residenceAPI.post<{ auth_token: string }>('authenticate_residence', { password });
}

export async function getResidence() {
  return await residenceAPI.get<{ data: Residence }>('/residence_page/residence');
}

export async function getScheduledWorkShifts() {
  return await residenceAPI.get<{ data: ResidenceScheduledWorkShift[] }>('/residence_page/scheduled_work_shifts');
}

export async function getTeamReportedWorkshifts(teamId: number) {
  return await residenceAPI.get<{ data: ResidenceMonthData[] }>(`/residence_page/reported_work_shifts/${teamId}`);
}

export async function getResidenceSchedulePdf() {
  return await residenceAPI.get<Buffer>('/residence_page/download_schedule', { responseType: 'arraybuffer' });
}
