import { ChevronRight, Warning } from '@mui/icons-material';
import { ButtonBase, CircularProgress, Collapse, Typography } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { DashboardEvents } from '../types';
import { Link } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import DashboardItem from './DashboardItem';
import DashboardLoading from './DashboardLoading';

type EventProps<T> = {
  amount: number;
  title: string;
  entries: T[];
  rowRender: (row: T) => { title: string; link: string };
};

function Event<T>({ amount, title, entries, rowRender }: EventProps<T>) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <ButtonBase className="!p-4 !w-full !flex !justify-between" onClick={() => setExpanded(!expanded)}>
        <div className="w-full flex items-center gap-2">
          <Warning fontSize="small" color="error" />
          <Typography sx={{ color: 'error.main', fontWeight: 'bold' }}>{amount}st</Typography>
          <span>{title}</span>
        </div>
        <ChevronRight className={twMerge('!transition-transform', expanded && '!rotate-90')} />
      </ButtonBase>
      <Collapse in={expanded}>
        {entries.map((entry) => {
          const render = rowRender(entry);
          return (
            <Link to={render.link} key={render.title}>
              <ButtonBase className="!w-full !text-black !text-xs !py-4 !px-6 !justify-between !border-solid !border-t !border-[#bbb]">
                {render.title}
                <ChevronRight fontSize="small" />
              </ButtonBase>
            </Link>
          );
        })}
      </Collapse>
    </>
  );
}

type Props = { events?: DashboardEvents };

export default function DashboardEventsCard({ events }: Props) {
  const eventsStatus = useMemo(() => {
    if (!events) return 'loading';
    const values = Object.values(events);
    if (values.every((val) => !val)) return 'none';
    if (values.every((val) => !val?.length)) return 'all-ok';
    return 'events';
  }, [events]);

  return (
    ['loading', 'events'].includes(eventsStatus) && (
      <DashboardItem title="Händelser">
        {eventsStatus === 'loading' ? (
          <DashboardLoading className="h-20" />
        ) : (
          <div className="rounded-2xl shadow-md bg-white overflow-hidden divide-[#bbb]">
            {events!.unconfirmed_users?.length && (
              <Event
                amount={events!.unconfirmed_users.length}
                title="Ej godkända deltagare efter 5v"
                entries={events!.unconfirmed_users}
                rowRender={(row) => ({ title: `${row.name}, ${row.team}`, link: `/users/${row.id}` })}
              />
            )}
          </div>
        )}
      </DashboardItem>
    )
  );
}
