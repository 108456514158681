import { FieldArray } from 'formik';
import React, { Dispatch, SetStateAction } from 'react';
import dayjs from 'dayjs';

import { Button, Divider, Grid } from '@mui/material';

import { ScheduledParticipantInterface } from '../types';
import { commonStyles, css } from '../../Common/styling';
import { OptionsInterface } from '../../Common/types';
import { TeamInterface } from '../../Team/types';
import { MinimalWorkShiftUserInterface } from '../../User/types';
import { Clear as ClearIcon } from '@mui/icons-material';

interface Props {
  values: any;
  handleChange: any;
  errors: any;
  isCreate: boolean;
  setFieldValue: any;
  team: TeamInterface;
  salaryClassesMinimalList: OptionsInterface[];
  participants: MinimalWorkShiftUserInterface[];
  substitutes: MinimalWorkShiftUserInterface[];
  tempTeamLeaders: MinimalWorkShiftUserInterface[];
  setParticipants: Dispatch<SetStateAction<MinimalWorkShiftUserInterface[]>>;
  setSubstitutes: Dispatch<SetStateAction<MinimalWorkShiftUserInterface[]>>;
  setTempTeamLeaders: Dispatch<SetStateAction<MinimalWorkShiftUserInterface[]>>;
}

export const FutureUsersWorkShifts: React.FC<Props> = (props: Props) => {
  return (
    <React.Fragment>
      {props.values.scheduled_participants.filter(
        (userWorkShift: ScheduledParticipantInterface) => userWorkShift._destroy === 0
      ).length > 0 && (
        <React.Fragment>
          <FieldArray
            name="scheduled_participants"
            render={() => (
              <Grid container spacing={2}>
                {props.values.scheduled_participants.filter(
                  (userWorkShift: ScheduledParticipantInterface) =>
                    userWorkShift.work_shift_type === 'participant' && userWorkShift._destroy === 0
                ).length > 0 && (
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <h1 className={css(commonStyles.headerTextStyle)}>Schemalagda</h1>
                    <div style={{ marginTop: '10px' }}>
                      {props.values.scheduled_participants.map(
                        (userWorkShift: ScheduledParticipantInterface, index: number) => (
                          <React.Fragment key={index}>
                            {userWorkShift.work_shift_type === 'participant' && userWorkShift._destroy === 0 && (
                              <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                  {userWorkShift.user.name}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                  <Button
                                    variant="text"
                                    color="error"
                                    onClick={(): void => {
                                      if (userWorkShift) {
                                        props.setFieldValue(`scheduled_participants[${index}]._destroy`, 1);
                                        const newParticipants = props.participants.filter(
                                          (participant: MinimalWorkShiftUserInterface) =>
                                            participant.id !== userWorkShift.user.id
                                        );
                                        props.setParticipants(newParticipants);
                                      }
                                    }}
                                  >
                                    <ClearIcon className={css(commonStyles.deleteIcon)} />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </Grid>
                )}
                {props.values.scheduled_participants.filter(
                  (userWorkShift: ScheduledParticipantInterface) =>
                    userWorkShift.work_shift_type === 'substitute' && userWorkShift._destroy === 0
                ).length > 0 && (
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <h1 className={css(commonStyles.headerTextStyle)}>Vikarier</h1>
                    <div style={{ marginTop: '10px' }}>
                      {props.values.scheduled_participants.map(
                        (userWorkShift: ScheduledParticipantInterface, index: number) => (
                          <React.Fragment key={index}>
                            {userWorkShift.work_shift_type === 'substitute' && userWorkShift._destroy === 0 && (
                              <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                  {userWorkShift.user.name}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                  <Button
                                    variant="text"
                                    color="error"
                                    onClick={(): void => {
                                      if (userWorkShift) {
                                        props.setFieldValue(`scheduled_participants[${index}]._destroy`, 1);
                                        const newSubstitutes = props.substitutes.filter(
                                          (substitute: MinimalWorkShiftUserInterface) =>
                                            substitute.id !== userWorkShift.user.id
                                        );
                                        props.setSubstitutes(newSubstitutes);
                                      }
                                    }}
                                  >
                                    <ClearIcon className={css(commonStyles.deleteIcon)} />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </Grid>
                )}
                {props.values.scheduled_participants.filter(
                  (userWorkShift: ScheduledParticipantInterface) =>
                    userWorkShift.work_shift_type === 'temp_team_leader' && userWorkShift._destroy === 0
                ).length > 0 && (
                  <Grid item xs={4} sm={4} md={4} lg={4}>
                    <h1 className={css(commonStyles.headerTextStyle)}>Tillfällig teamledare</h1>
                    <div style={{ marginTop: '10px' }}>
                      {props.values.scheduled_participants.map(
                        (userWorkShift: ScheduledParticipantInterface, index: number) => (
                          <React.Fragment key={index}>
                            {userWorkShift.work_shift_type === 'temp_team_leader' && userWorkShift._destroy === 0 && (
                              <Grid container spacing={2} style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={8} sm={8} md={8} lg={8}>
                                  {userWorkShift.user.name}
                                </Grid>
                                <Grid item xs={1} sm={1} md={1} lg={1}>
                                  <Button
                                    variant="text"
                                    color="error"
                                    onClick={(): void => {
                                      if (userWorkShift) {
                                        props.setFieldValue(`scheduled_participants[${index}]._destroy`, 1);
                                        const newTempTeamLeaders = props.tempTeamLeaders.filter(
                                          (tempTeamLeader: MinimalWorkShiftUserInterface) =>
                                            tempTeamLeader.id !== userWorkShift.user.id
                                        );
                                        props.setTempTeamLeaders(newTempTeamLeaders);
                                      }
                                    }}
                                  >
                                    <ClearIcon className={css(commonStyles.deleteIcon)} />
                                  </Button>
                                </Grid>
                              </Grid>
                            )}
                          </React.Fragment>
                        )
                      )}
                    </div>
                  </Grid>
                )}
              </Grid>
            )}
          />
          <Grid container spacing={2}>
            <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              {props.values.created_at && (
                <p>
                  <i>
                    Skapat {dayjs(props.values.created_at).format('D MMM YYYY [kl] HH:mm')}{' '}
                    {props.values.created_by && <span>av {props.values.created_by.name}</span>}
                  </i>
                </p>
              )}
              {props.values.updated_at && (
                <p>
                  <i>
                    Uppdaterat {dayjs(props.values.updated_at).format('D MMM YYYY [kl] HH:mm')}{' '}
                    {props.values.updated_by && <span>av {props.values.updated_by.name}</span>}
                  </i>
                </p>
              )}
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: '20px', marginTop: '20px' }} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
