import React, { ReactNode } from 'react';

import { FormTextField } from '../../../Common/FormItems';
import { Field, FieldArray } from 'formik';
import { EducationAnswerInterface } from '../types';
import { Add as AddIcon, Delete as TrashIcon } from '@mui/icons-material';
import { Button, Checkbox, FormControlLabel, IconButton, ListItem, ListItemSecondaryAction } from '@mui/material';

type Props = {
  index: number;
  name: string;
  values: any;
  handleChange: any;
  errors: any;
  isEdit: boolean;
  remove?: () => void;
};

const renderCheckbox = (input: any): ReactNode => (
  <Checkbox
    color="primary"
    name={input.field.name}
    checked={input.field.value}
    onChange={input.field.onChange}
    disabled={input.disabled}
  />
);

const QuestionForm: React.FC<Props> = (props) => {
  return (
    <div>
      <ListItem role={undefined}>
        <FormTextField
          className="w-full"
          fieldName={`${props.name}.text`}
          label="Frågetext"
          placeholder="question"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          disabled={!props.isEdit}
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="delete"
            size="large"
            disabled={!props.isEdit}
            onClick={(): void => {
              props.remove?.();
            }}
          >
            <TrashIcon />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
      <FieldArray
        name={`${props.name}.education_answers`}
        render={(helpers): ReactNode => {
          const name = `${props.name}.education_answers`;
          return (
            <>
              {props.values.education_questions[props.index].education_answers?.map(
                (answer: EducationAnswerInterface, idx: number) => (
                  <div key={idx}>
                    <ListItem style={{ paddingLeft: '4em' }} key={props.name}>
                      <FormTextField
                        className="w-full"
                        fieldName={`${name}.${idx}.text`}
                        label="Svarstext"
                        placeholder="question"
                        values={props.values}
                        errors={props.errors}
                        onChange={props.handleChange}
                        disabled={!props.isEdit}
                      />
                      <FormControlLabel
                        control={
                          <Field name={`${name}.${idx}.correct`} component={renderCheckbox} disabled={!props.isEdit} />
                        }
                        label="Rätt?"
                        labelPlacement="start"
                        disabled={!props.isEdit}
                      />
                      <IconButton
                        edge="end"
                        aria-label="delete"
                        size="large"
                        disabled={!props.isEdit}
                        onClick={(): void => {
                          helpers.remove(idx);
                        }}
                      >
                        <TrashIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                )
              )}
              {props.isEdit && (
                <ListItem style={{ paddingLeft: '4em', marginTop: '20px' }}>
                  <Button
                    startIcon={<AddIcon />}
                    size="small"
                    color="primary"
                    disabled={!props.isEdit}
                    onClick={(): void => {
                      helpers.push({ text: '', correct: false });
                    }}
                  >
                    Nytt svar
                  </Button>
                </ListItem>
              )}
            </>
          );
        }}
      />
    </div>
  );
};

export default QuestionForm;
