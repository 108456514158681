import * as Yup from 'yup';

import { sort_order } from '../Common/types';

export const initialValues = {};

// TODO: why empty validation schema
export const validationSchema = Yup.object().shape({});

export const paginationDefaults = { page: 1, page_size: 10 };

export const filterSortDefaults = {
  query: '',
  sort_by: 'residence_group_name',
  sort_order: sort_order.asc,
  is_active: true,
};
