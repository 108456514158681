import { Link } from 'react-router-dom';
import React from 'react';
import { Fab } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import { StyleSheet, css, theme } from '../styling';

const styles = StyleSheet.create({
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.white,
  },

  fab: {
    position: 'fixed',
    bottom: '32px',
    right: '32px',
  },
});

type Props = {
  link: string;
  state?: any;
};

const CreateFab: React.FC<Props> = (props) => {
  return (
    <Link to={props.link} state={props.state} className={css(styles.linkWrapper)}>
      <Fab color="primary" className={css(styles.fab)} aria-label="Add">
        <AddIcon />
      </Fab>
    </Link>
  );
};

export default CreateFab;
