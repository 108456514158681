import { Formik } from 'formik';
import React, { ReactElement, useEffect, useState } from 'react';

import { Button, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { FormDateField, FormSelect, FormTextField } from '../../Common/FormItems';
import { TextLabel } from '../../Common/Text';
import { useNotify } from '../../Common/snackbarHooks';
import { getTeamMinimalList } from '../../Team/teamApi';
import { postAgreementCorrection } from '../managementApi';
import { MakeCorrectionInterface } from '../types';
import { initialValues, validationSchema } from './makeCorrectionSchema';

type Props = {
  team: any;
  agreement: any;
  setModalState: any;
  correctionCallback?: any;
};

const MakeCorrectionsModal: React.FC<Props> = (props) => {
  // hooks
  const { notifyError, notifySuccess } = useNotify();
  const [correctionState, setCorrectionState] = useState(initialValues as MakeCorrectionInterface);
  const [teamList, setTeamList] = useState([] as any);

  useEffect(() => {
    setCorrectionState({ ...correctionState, date: props.agreement.start_date });
    if (props.team) {
      setCorrectionState({ ...correctionState, team_id: props.team.id });
    } else {
      fetchTeamList(props.agreement.residence.id);
    }
  }, []); // eslint-disable-line

  // functions
  const fetchTeamList = (residence_id: number): void => {
    getTeamMinimalList({ residence_id: [residence_id], is_active: true })
      .then(({ data }) => setTeamList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över teams'));
  };

  const handleSubmit = (value: any): void => {
    postAgreementCorrection({ ...value, agreement_id: props.agreement.id })
      .then(() => {
        if (props.correctionCallback) {
          props.correctionCallback(props.agreement.id);
        }
        notifySuccess('En hantering har gjorts');
        props.setModalState(false);
      })
      .catch((error: any) => notifyError(`Det gick inte att göra hanteringen : ${HandleError(error.response.data)}`));
  };

  // render
  return (
    <div>
      <DialogTitle>Lägg till korrigering på {props.agreement.residence.name}</DialogTitle>
      <Formik
        enableReinitialize
        initialValues={correctionState}
        validationSchema={validationSchema}
        onSubmit={(values): void => handleSubmit(values)}
      >
        {({ values, errors, handleChange, handleSubmit, setFieldValue, isSubmitting }): ReactElement => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              {props.team ? (
                <span>{`Här kan du lägga till en korrigeringsrad för teamet ${props.team.name}`}</span>
              ) : (
                <Grid container>
                  <TextLabel size={7} value="Välj vilket team denna korrigering är för" />

                  <FormSelect
                    size={5}
                    fieldName="team_id"
                    label="Team"
                    values={values}
                    errors={errors}
                    onChange={handleChange}
                    options={teamList}
                  />
                </Grid>
              )}
            </DialogContent>
            <DialogContent>
              <Grid container spacing={2}>
                <FormSelect
                  size={8}
                  fieldName="type"
                  label="Typ av korrigering"
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                  options={[
                    { id: 'TeamBudgetCorrection', name: 'Ändra timpott' },
                    { id: 'WorkHourCorrection', name: 'Ändra arbetade timmar' },
                  ]}
                />
                <FormTextField
                  size={4}
                  fieldName="value"
                  label="Timmar"
                  placeholder="Timmar som ska korrigeras"
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />
              </Grid>
              <Grid container spacing={4}>
                <FormTextField
                  size={12}
                  fieldName="notes"
                  label="Beskriv korrigeringen"
                  placeholder="Beskriv din korrigeringen"
                  values={values}
                  errors={errors}
                  onChange={handleChange}
                />
              </Grid>

              {values.type === 'WorkHourCorrection' && (
                <Grid container spacing={4}>
                  <FormDateField
                    size={6}
                    fieldName="date"
                    label="När gäller detta?"
                    placeholder="När gäller detta?"
                    values={values}
                    errors={errors}
                    onChange={setFieldValue}
                    maxDate={props.agreement.end_date ? props.agreement.end_date : props.agreement.agreement.end_date}
                    minDate={
                      props.agreement.start_date ? props.agreement.start_date : props.agreement.agreement.start_date
                    }
                  />
                </Grid>
              )}
            </DialogContent>

            <DialogActions>
              <Button fullWidth color="error" onClick={(): void => props.setModalState(false)}>
                AVBRYT
              </Button>
              <Button fullWidth color="primary" type="submit" disabled={isSubmitting}>
                SPARA
              </Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default MakeCorrectionsModal;
