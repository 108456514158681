import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import { updateFuture, deleteFuture, getFuture } from './FutureAPI';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../../Common/styling';
import { useNotify } from '../../Common/snackbarHooks';
import FutureForm from './FutureForm';
import { BackLink } from '../../Common/ButtonLinks';
import { FutureInterface } from './types';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

const initialFutureValues = {
  title: '',
  content: '',
  ranking: 1,
  show: true,
  id: 0,
};

const EditFuture: React.FC = () => {
  const navigate = useNavigate();
  const { notifyError, notifySuccess, notifyInfo } = useNotify();
  const { id } = useParams() as { id: string };
  const [loading, setLoading] = useState(true);
  const [future, setFuture] = useState<FutureInterface>(initialFutureValues);
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    setLoading(true);

    getFuture(id)
      .then(({ data }) => {
        setFuture(data.future);
      })
      .catch(() => notifyError('Det gick inte att hämta Framtid'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // functions
  const handleDeleteConfirm = (): void => {
    setConfirmDialog(false);
    deleteFuture(parseInt(id))
      .then(() => notifyInfo('Framtid har tagits bort'))
      .then(() => navigate('/future'))
      .catch(() => notifyError('Det gick inte att ta bort Framtid'));
  };

  const handleFormSubmit = (values: FutureInterface, actions: FormikHelpers<any>): void => {
    handleUpdateFuture(values, actions.setSubmitting);
  };

  const handleUpdateFuture = (values: FutureInterface, setSubmitting: any): void => {
    updateFuture(future.id, values)
      .then(() => notifySuccess('Future är uppdaterad'))
      .then(() => navigate('/future'))
      .catch((error) => {
        notifyError(`Det gick inte att uppdatera Framtid : ${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  const handleDeleteFuture = (): void => {
    setConfirmDialog(true);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/future'} />
            <h1 className={css(commonStyles.headerTextStyle)}>Redigera Framtid: &quot;{future.title}&quot;</h1>
          </div>

          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={future}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, setSubmitting }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <FutureForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    isCreate={false}
                    submitDisabled={isSubmitting || !isValid}
                    removal={handleDeleteFuture}
                  />
                  <DeleteConfirmationModal
                    onConfirm={(): void => handleDeleteConfirm()}
                    onClose={(): void => {
                      setConfirmDialog(false);
                      setSubmitting(false);
                    }}
                    isVisible={confirmDialog}
                    name="Framtid"
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditFuture;
