import React, { useContext } from 'react';

import { Button, Divider, FormControl, Grid } from '@mui/material';
import AuthContext from '../../../Common/AuthContext';
import { FormTextField } from '../../../Common/FormItems';
import { AuthContextValue } from '../../../Common/types';
import { EducationType } from '../types';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  submitDisabled: boolean;
};

const EducationForm: React.FC<Props> = (props) => {
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }} alignItems="center">
        <Grid item xs={8}>
          <FormTextField
            size={12}
            fieldName="name"
            label="Namn"
            placeholder="Namn på utbildningen"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            multiline={false}
          />
        </Grid>
        <Grid item xs={2}>
          <FormTextField
            size={12}
            fieldName="education_type"
            label="Utbildningstyp"
            placeholder={EducationType.Participant}
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
            multiline={false}
            disabled={true}
          />
        </Grid>
        <Grid item xs={2}>
          <FormControl variant="standard" margin="dense" required fullWidth>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={props.submitDisabled || !hasPermissions(['admin', 'operational_leader'])}
            >
              Spara
            </Button>
          </FormControl>
        </Grid>
      </Grid>
      <Divider className="!mb-5" />
    </div>
  );
};

export default EducationForm;
