import React, { useState } from 'react';

import { Help as HelpIcon } from '@mui/icons-material';
import { Box, Grid, IconButton, Tooltip } from '@mui/material';
import { FormTextField } from '../../../Common/FormItems';
import InfoDialog from '../../../Common/InfoDialog';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  submitDisabled: boolean;
  isEdit: boolean;
  isCreate: boolean;
};

const EducationPageForm: React.FC<Props> = (props) => {
  const editable = (): boolean => props.isEdit || props.isCreate;
  const [showYoutubeInfo, setShowYoutubeInfo] = useState(false);

  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={12}
          fieldName="title"
          label="Titel"
          placeholder="Sidrubrik"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={false}
          disabled={!editable()}
        />
        <FormTextField
          size={12}
          fieldName="subtitle"
          label="Underrubrik"
          placeholder="Underrubrik"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={false}
          disabled={!editable()}
        />
        <FormTextField
          size={12}
          fieldName="text"
          label="Sidtext (kan formateras med märkspråk)"
          placeholder="Sidtext"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={true}
          disabled={!editable()}
        />
        <FormTextField
          size={11}
          fieldName="youtube_url"
          label="ID till youtubevideo"
          placeholder="Youtube videoID"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={false}
          disabled={!editable()}
        />
        <Box display="flex" alignItems="center">
          <Tooltip title="Vart hittar jag video-ID?" disableInteractive>
            <IconButton
              size="large"
              onClick={(): void => setShowYoutubeInfo(true)}
              component="span"
              style={{ height: 'max-content' }}
            >
              <HelpIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <FormTextField
          size={12}
          fieldName="estimated_time"
          label="Uppskattad tidsåtgång för sidan (minuter)"
          placeholder="10"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={false}
          disabled={!editable()}
        />
      </Grid>
      <InfoDialog
        title="Youtube video-ID"
        open={showYoutubeInfo}
        body={
          <div>
            <p>Exempel på video-ID i länkar: (understruket)</p>
            <ul>
              <li>
                https://www.youtube.com/watch?v=<u>LXb3EKWsInQ</u>
              </li>
              <li>
                https://www.youtube.com/v/<u>LXb3EKWsInQ</u>
              </li>
              <li>
                https://youtu.be/<u>LXb3EKWsInQ</u>
              </li>
              <li>
                https://www.youtube.com/watch?v=<u>LXb3EKWsInQ</u>&t=1s
              </li>
            </ul>
          </div>
        }
        closeDialog={(): void => setShowYoutubeInfo(false)}
      />
    </div>
  );
};

export default EducationPageForm;
