import React, { useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { batchDeleteResidenceClusters } from './../residenceClusterApi';
import { ResidenceClusterInterface } from './../types';

type Props = {
  residenceClusters: ResidenceClusterInterface[];
  setModalState: (modalState: boolean) => void;
  fetchResidenceClustersList: () => void;
  setSelectedResidenceClusters: (residenceClusters: ResidenceClusterInterface[]) => void;
  setQuickActionResidenceCluster: (residenceClusters: ResidenceClusterInterface[]) => void;
};

const DeleteResidenceClusterModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [residenceClusterIds, setResidenceClusterIds] = useState<number[]>();
  const [loading, setLoading] = useState(true);

  /// Get the news ids into a list
  useEffect(() => {
    setLoading(true);
    const ids: number[] = [];
    props.residenceClusters.forEach((residenceCluster) => {
      if (residenceCluster.id) ids.push(residenceCluster.id);
    });
    setResidenceClusterIds(ids);
    setLoading(false);
  }, [props.residenceClusters]); // eslint-disable-line

  const handleSubmit = (): void => {
    setLoading(true);
    if (residenceClusterIds) {
      batchDeleteResidenceClusters(residenceClusterIds)
        .then(() => notifySuccess('Grupperna har uppdaterats'))
        .then(() => {
          setLoading(false);
          props.fetchResidenceClustersList();
          props.setSelectedResidenceClusters([]);
          props.setQuickActionResidenceCluster([]);
        })
        .catch((error: any) => notifyError(`Det gick inte att uppdatera grupperna: ${error}`))
        .then(() => props.setModalState(false));
    }
  };

  return (
    <div>
      <DialogTitle>Ta bort grupp</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              Vill du ta bort de {props.residenceClusters.length} grupper som du valt?{' '}
            </DialogContentText>
          </DialogContent>
          <DialogContent>
            <DialogActions>
              <Button
                fullWidth
                color="error"
                onClick={(): void => {
                  props.setModalState(false);
                  props.setQuickActionResidenceCluster([]);
                }}
              >
                NEJ
              </Button>
              <Button fullWidth color="primary" onClick={(): void => handleSubmit()}>
                JA
              </Button>
            </DialogActions>
          </DialogContent>
        </React.Fragment>
      )}
    </div>
  );
};

export default DeleteResidenceClusterModal;
