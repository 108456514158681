import dayjs from 'dayjs';
import { sort_order } from '../Common/types';

export const filterSortDefaults = {
  query: '',
  sort_by: 'fortnox_transfer_date',
  sort_order: sort_order.asc,
  sent_to_fortnox: true,
  date_after: dayjs().startOf('year').format('YYYY-MM-DD'),
  date_before: dayjs().endOf('year').format('YYYY-MM-DD'),
  year_after: dayjs().add(1, 'year').startOf('year').format('YYYY-MM-DD'),
};
