import React, { useState, useCallback } from 'react';
import ResidencePageCard from './ResidencePageCard';
import { CalendarMonth } from '@mui/icons-material';
import { Residence, ResidenceScheduledWorkShift } from '../types';
import TableBox, { TableBoxColumn } from '../../Common/Table/TableBox';
import dayjs from 'dayjs';
import { convertNumToTime, downloadFile } from '../../Common/utilities';
import { Button, CircularProgress, Tooltip } from '@mui/material';
import ResidencePageLoading from './ResidencePageLoading';
import ConditionalParent from '../../Common/ConditionalParent';
import { FileDownloadOutlined, Search } from '@mui/icons-material';
import { getResidenceSchedulePdf } from '../residencePageApi';

const tableColumns: TableBoxColumn[] = [
  { id: 'date', label: 'Datum', width: '2xl' },
  { id: 'team', label: 'Team', width: 'lg' },
  { id: 'activity', label: 'Aktivitet', width: '2xl' },
];

type Props = {
  residence: Residence;
  workShifts?: ResidenceScheduledWorkShift[];
};

export default function ResidenceScheduledWorkShifts({ residence, workShifts }: Props) {
  const [loadingPdf, setLoadingPdf] = useState(false);

  const downloadPdf = useCallback(() => {
    if (loadingPdf) return;

    setLoadingPdf(true);
    getResidenceSchedulePdf()
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        downloadFile(blob, `Schema ${residence.name} ${dayjs().format('YYYY-MM-DD')}.pdf`);
      })
      .finally(() => {
        setLoadingPdf(false);
      });
  }, [loadingPdf]);

  return (
    <ResidencePageCard>
      <div className="flex justify-between items-start mb-6">
        <div>
          <div className="flex items-center gap-3 mb-4">
            <CalendarMonth sx={{ fontSize: 24 }} className="!text-black/55" />
            <h2 className="text-lg text-black/60 font-semibold">Schemalagda pass</h2>
          </div>
          <p className="text-sm">Här kan du se alla pass som vi har schemalagt hos er.</p>
        </div>
        <ConditionalParent
          on={!workShifts || !workShifts?.length}
          parent={(children) => (
            <Tooltip title={!workShifts ? 'Laddar...' : 'Inga schemalagda pass'}>
              <span>{children}</span>
            </Tooltip>
          )}
        >
          <Button
            variant="outlined"
            color="primary"
            disabled={!workShifts || !workShifts?.length || loadingPdf}
            endIcon={loadingPdf ? <CircularProgress size="20px" /> : <FileDownloadOutlined />}
            onClick={downloadPdf}
          >
            Ladda ned som pdf
          </Button>
        </ConditionalParent>
      </div>

      {workShifts?.length ? (
        <TableBox
          variant="residence"
          columns={tableColumns}
          content={workShifts}
          rowDefinition={(row) => ({
            key: row.id,
            cols: [
              <p className="font-semibold" key="team">
                {dayjs(row.date).format('D MMM YYYY')} {row.start_time ? convertNumToTime(row.start_time) : ''} -{' '}
                {row.end_time ? convertNumToTime(row.end_time) : ''}
              </p>,
              row.team,
              row.activity,
            ],
          })}
        />
      ) : workShifts ? (
        <div className="flex w-full justify-center text-sm font-medium">Inga schemalagda pass</div>
      ) : (
        <ResidencePageLoading className="h-52" />
      )}
    </ResidencePageCard>
  );
}
