import { AxiosResponse } from 'axios';

import API from '../Common/axios';

export const authenticate = (credentials: { email: string; password: string }): Promise<AxiosResponse> => {
  return API.post('authenticate', credentials);
};

export const getProfile = (): Promise<AxiosResponse> => {
  return API.get('v1/profile');
};

export const requestResetPassword = (params: { email: string }): Promise<AxiosResponse> => {
  return API.post('v1/users/request_reset_password', params);
};
