import React from 'react';

import { css } from 'aphrodite';

import { Button, Divider, FormControl, Grid, Tooltip } from '@mui/material';
import { FormTextField, FormToggle } from '../../Common/FormItems';
import { commonStyles } from '../../Common/styling';
import { isNotEmpty } from '../../Common/utilities';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  isCreate: boolean;
  navigate?: any;
  removal?: () => void;
  forceUpdate?: () => void;
  submitDisabled: boolean;
};

const ResidenceGroupForm: React.FC<Props> = (props) => {
  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="name"
          label="Namn på avtalsparten"
          placeholder="Namn på avtalsparten"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>
      <Divider className="!mb-5" />
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="invoice_detail.recipient"
          label="Namnet på fakturamottagaren"
          placeholder="FakturaMottagare"
          required={true}
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={6}
          fieldName="invoice_detail.address_line_1"
          label="Fakturaadress till avtalsparten"
          placeholder="Fakturaadress"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="invoice_detail.address_line_2"
          label="Ytterligare adressinformation"
          placeholder="Adress rad 2"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={3}
          fieldName="invoice_detail.zip_code"
          label="Postnummer till avtalsparten"
          placeholder="Postnummer"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={3}
          fieldName="invoice_detail.city"
          label="Ort till avtalsparten"
          placeholder="Ort"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="invoice_detail.reference"
          label="Referensperson till avtalsparten"
          placeholder="Referensperson"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        {/* <FormTextField
          size={6}
          fieldName="invoice_detail.gln_number"
          label="GLN-nummer till avtalsparten"
          placeholder="GLN-nummer"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        /> */}
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="invoice_detail.electronic_reference"
          label="Elektroniskreferens till avtalsparten"
          placeholder="Elektroniskreferens"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormTextField
          size={6}
          fieldName="invoice_detail.org_number"
          label="Org-nummer till avtalsparten"
          placeholder="Org-nummer"
          required={false}
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
      </Grid>
      <Divider className="!mb-5" />
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <FormTextField
          size={6}
          fieldName="notes"
          label="Här kan du skriva en anteckning"
          placeholder="Anteckning"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
        />
        <FormToggle
          size={6}
          fieldName="default_only_for_locals"
          label="Kommunspärr"
          values={props.values}
          onChange={props.handleChange}
        />
      </Grid>
      <Divider className="!mb-5" />
      {!props.isCreate && isNotEmpty(props.values.residences_with_own_invoice_details) && (
        <div>
          <Grid item xs={12} style={{ marginBottom: '20px', textAlign: 'center' }}>
            {props.values.residences_with_own_invoice_details.map((item: any, index: number) => (
              <React.Fragment key={item.id}>
                <span
                  onClick={(): void => props.navigate(item.id)}
                  className={css(commonStyles.hover, commonStyles.pLink)}
                >
                  {item.name}
                </span>
                {index + 1 < props.values.residences_with_own_invoice_details.length && <span>, </span>}
              </React.Fragment>
            ))}
            <span> har egna fakturauppgifter</span>
          </Grid>
          <Divider className="!mb-5" />
        </div>
      )}
      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <FormToggle
          size={5}
          fieldName="is_active"
          label="Avtalsparten är aktiv"
          values={props.values}
          onChange={props.handleChange}
        />
        <Grid item xs={2}>
          <FormControl variant="standard" margin="dense" required fullWidth>
            <Button type="submit" variant="contained" disabled={props.submitDisabled}>
              {props.isCreate && <>Lägg till</>}
              {!props.isCreate && props.values.editable && <>Spara</>}
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={3} />
        {!props.isCreate && props.values.deletable && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" color="error" required fullWidth>
              <Button
                variant="outlined"
                onClick={(): void => {
                  if (props.removal) props.removal();
                }}
              >
                Ta Bort
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
      {!props.isCreate && props.values.editable && (
        <Grid container spacing={2} direction="row-reverse" className="mb-2">
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Tooltip
                title={<p style={{ fontSize: '1rem' }}>Kör om fortnox inte är synchroniserat</p>}
                disableInteractive
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={(): void => {
                    if (props.forceUpdate) props.forceUpdate();
                  }}
                >
                  Uppdatera Fortnox
                </Button>
              </Tooltip>
            </FormControl>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ResidenceGroupForm;
