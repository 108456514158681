import { FilterList as FilterListIcon } from '@mui/icons-material';
import { IconButton, Paper, Popover, Tooltip } from '@mui/material';
import { StyleSheet, css } from 'aphrodite';
import React, { useState } from 'react';

const styles = StyleSheet.create({
  filterWrapper: {
    padding: '16px',
    alignItems: 'center',
  },
});

type Props = {
  children: React.ReactNode;
};

const BaseFilterPopover: React.FC<Props> = (props) => {
  // hooks
  const [filterProps, setFilterProps] = useState({ buttonRef: null, open: false });

  // functions
  const openFilter = (e: any): void => {
    setFilterProps({ ...filterProps, open: true, buttonRef: e.currentTarget });
  };

  const closeFilter = (): void => {
    setFilterProps({ ...filterProps, open: false, buttonRef: null });
  };

  // render
  return (
    <div style={{ display: 'flex', marginRight: '25px', marginLeft: '5px' }}>
      <Tooltip title="Filtrera" disableInteractive>
        <IconButton aria-label="Filter list" size="large" onClick={openFilter}>
          <FilterListIcon />
        </IconButton>
      </Tooltip>

      <Popover
        open={filterProps.open}
        anchorEl={filterProps.buttonRef}
        onClose={closeFilter}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Paper className={css(styles.filterWrapper)}>{props.children}</Paper>
      </Popover>
    </div>
  );
};

export default BaseFilterPopover;
