import React, { useState, ReactElement } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { commonStyles, css } from '../../../Common/styling';
import EducationPageForm from './EducationPageForm';
import { EducationPageInterface } from '../types';
import DeleteConfirmationModal from '../../DeleteConfirmationModal';
import EducationQuestions from './EducationQuestions';
import { educationPageSchema } from '../educationSchema';
import OrderPages from './OrderPages';
import ConfirmationModal from '../../ConfirmationModal';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import {
  Accordion,
  AccordionActions,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@mui/material';

interface Props {
  key: number;
  index: number;
  isCreate: boolean;
  educationId: number;
  page: EducationPageInterface;
  pages: Array<EducationPageInterface>;
  movePage?: (from: number, to: number) => Promise<Array<EducationPageInterface>>;
  createPage?: (page: EducationPageInterface, position?: number) => Promise<boolean>;
  updatePage?: (page: EducationPageInterface, orderedPages?: Array<EducationPageInterface>) => Promise<boolean>;
  deletePage?: (pageId: number) => Promise<boolean>;
}

const EducationPage: React.FC<Props> = (props) => {
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const pageFormId = `pageInformationForm${props.page.id || 0}`;
  const [moveTarget, setMoveTarget] = useState(props.index);
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleDeleteConfirm = (): void => {
    setConfirmDialog(false);
    setEdit(false);
    props.deletePage?.(props.page.id || -1);
  };

  const handleFormSubmit = async (values: EducationPageInterface, actions: FormikHelpers<any>): Promise<void> => {
    setEdit(false);

    if (props.isCreate) {
      props.createPage?.(values, moveTarget !== -1 ? moveTarget : undefined);
    } else {
      let orderedPages;
      if (moveTarget !== props.index) {
        orderedPages = await props.movePage?.(props.index, moveTarget);
      }
      props.updatePage?.(values, orderedPages).then((success) => setEdit(!success));
    }
    actions.setSubmitting(false);
  };

  const handleDeleteEducationPage = (): void => {
    setConfirmDialog(true);
  };

  const pageTitle = (): string => (props.index !== undefined ? `${props.index + 1}: ` : '') + props.page.title;

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        validateOnMount={true}
        initialValues={props.page}
        validationSchema={educationPageSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          handleChange,
          handleSubmit,
          isSubmitting,
          isValid,
          setSubmitting,
          resetForm,
          dirty,
        }): ReactElement => (
          <>
            <Accordion defaultExpanded={props.isCreate}>
              {!props.isCreate && (
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  style={{ pointerEvents: edit ? 'none' : 'all' }}
                  // IconButtonProps={{ disabled: edit }}
                >
                  <div>
                    <Typography>{pageTitle()}</Typography>
                    <Typography variant="caption">{props.page.subtitle}</Typography>
                  </div>
                </AccordionSummary>
              )}
              {props.isCreate && (
                <AccordionSummary>
                  <Typography>Ny sida</Typography>
                </AccordionSummary>
              )}
              <div className={css(commonStyles.formContainer)}>
                <Form id={pageFormId} placeholder={undefined} onSubmit={handleSubmit}>
                  <EducationPageForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    submitDisabled={isSubmitting || !isValid}
                    isEdit={edit}
                    isCreate={props.isCreate}
                  />
                  <OrderPages
                    setMoveTarget={setMoveTarget}
                    pages={props.pages}
                    currentIndex={props.index}
                    disabled={!edit && !props.isCreate}
                  />

                  <DeleteConfirmationModal
                    onConfirm={(): void => handleDeleteConfirm()}
                    onClose={(): void => {
                      setConfirmDialog(false);
                      setSubmitting(false);
                    }}
                    isVisible={confirmDialog}
                    name="utbildningssidan"
                  />
                  <div style={{ paddingBottom: '20px', paddingLeft: '0px !important' }}>
                    {props.isCreate ? (
                      <Typography style={{ marginLeft: '64px' }} variant="h6">
                        Du kan skapa frågor och svar efter att den nya sidan är sparad
                      </Typography>
                    ) : (
                      <Card>
                        <CardHeader title="Frågor och svar" />
                        <CardContent>
                          <EducationQuestions
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            isEdit={edit}
                          />
                        </CardContent>
                      </Card>
                    )}
                  </div>
                </Form>
              </div>
              <AccordionActions>
                {(edit || props.isCreate) && (
                  <>
                    <Button color="primary" variant="contained" type="submit" form={pageFormId} disabled={!isValid}>
                      Spara
                    </Button>
                    <Button
                      style={{ marginRight: '20px' }}
                      color="secondary"
                      variant="outlined"
                      onClick={handleDeleteEducationPage}
                    >
                      Ta bort
                    </Button>
                  </>
                )}
                {!props.isCreate && (
                  <Button
                    onClick={(): void => {
                      if (edit && dirty) {
                        setConfirmVisible(true);
                      } else {
                        setEdit(!edit);
                      }
                    }}
                    color="secondary"
                  >
                    {edit ? 'Avbryt' : 'Redigera'}
                  </Button>
                )}
              </AccordionActions>
            </Accordion>
            <ConfirmationModal
              isVisible={confirmVisible}
              title="Osparade ändringar"
              message="Är du säker att du inte vill spara dina ändringar?"
              confirmButtonText="Ja"
              onConfirm={(): void => {
                resetForm();
                setEdit(false);
                setConfirmVisible(false);
              }}
              onClose={(): void => setConfirmVisible(false)}
            />
          </>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default EducationPage;
