import { AxiosResponse } from 'axios';
import API from '../../Common/axios';
import {
  EducationAnswerInterface,
  EducationInterface,
  EducationPageInterface,
  EducationQuestionInterface,
} from './types';

export const getEducations = (): Promise<AxiosResponse> => {
  return API.get('v1/educations');
};

export const getEducation = (id: number): Promise<AxiosResponse> => {
  return API.get(`v1/educations/${id}`);
};

export const updateEducation = (id: number, education: EducationInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/educations/${id}`, { education });
};

export const getEducationPage = (id: number): Promise<AxiosResponse> => {
  return API.get(`v1/education_pages/${id}`);
};

export const createEducationPage = (
  education_id: number,
  education_page: EducationPageInterface
): Promise<AxiosResponse> => {
  return API.post(`v1/educations/${education_id}/education_pages`, { education_page });
};

export const updateEducationPage = (id: number, education_page: EducationPageInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/education_pages/${id}`, { education_page });
};

export const deleteEducationPage = (id: number): Promise<AxiosResponse> => {
  return API.delete(`v1/education_pages/${id}`);
};

export const createQuestion = (
  page_id: number,
  education_question: EducationQuestionInterface
): Promise<AxiosResponse> => {
  return API.post(`v1/education_pages/${page_id}/education_questions`, { education_question });
};

export const deleteQuestion = (id: number): Promise<AxiosResponse> => {
  return API.delete(`v1/education_questions/${id}`);
};

export const createAnswer = (
  questionId: number,
  education_answer: EducationAnswerInterface
): Promise<AxiosResponse> => {
  return API.post(`v1/education_questions/${questionId}/education_answers`, { education_answer });
};
