import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';

import { createJobApplication, getLanguagesMinimalList } from './jobApplicationApi';
import { publicValidationSchemaD, publicValidationSchemaTL, initialValues } from './jobApplicationSchema';
import { useNotify } from '../Common/snackbarHooks';
import JobApplicationFormPublic from './components/JobApplicationFormPublic';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import TermsNoteModal from './components/TermsNoteModal';

type Props = {
  type: string;
};

const CreateJobApplicationPublic: React.FC<Props> = (props) => {
  // hooks
  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const [languageMinimalList, setLanguageMinimalList] = useState([]);
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [showTermsNoteModal, setShowTermsNoteModal] = useState(false);

  useEffect(() => {
    fetchLanguagesMinimalList();
  }, []); // eslint-disable-line

  // functions
  const fetchLanguagesMinimalList = (): void => {
    getLanguagesMinimalList()
      .then(({ data }) => {
        setLanguageMinimalList(data.data);
      })
      .catch(() => notifyError('Det gick inte att hämta listan över språk'));
  };

  const handleFormSubmit = (
    { languages, team, manually_tagged_residences, graduate_year, birth_date, ...values }: typeof initialValues,
    actions: FormikHelpers<any>
  ): void => {
    if (termsAccepted) {
      createJobApplication(
        {
          type: props.type === 'participant' ? 'ParticipantJobApplication' : 'TeamLeaderJobApplication',
          language_ids: languages.map((language) => language.id as number),
          graduate_year: graduate_year ?? undefined,
          birth_date: birth_date!,
          ...values,
        },
        true
      )
        .then(() => navigate(`/${props.type === 'participant' ? 'deltagare' : 'teamledare'}/skickad`))
        .catch((error: any) => {
          notifyError(`Det gick inte att skicka ansökan: \n${HandleError(error.response.data)}`);
          actions.setSubmitting(false);
        });
    } else {
      setShowTermsNoteModal(true);
      actions.setSubmitting(false);
    }
  };

  // render
  return (
    <div>
      <div>
        <Formik
          initialValues={initialValues}
          validationSchema={props.type === 'participant' ? publicValidationSchemaD : publicValidationSchemaTL}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({
            values,
            errors,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting,
            isValid,
            dirty,
          }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <JobApplicationFormPublic
                type={props.type}
                values={values}
                handleChange={handleChange}
                errors={errors}
                languageMinimalList={languageMinimalList}
                isCreate={true}
                setFieldValue={setFieldValue}
                termsAccepted={termsAccepted}
                setTermsAccepted={setTermsAccepted}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>
      </div>
      {showTermsNoteModal && (
        <TermsNoteModal showTermsNoteModal={showTermsNoteModal} setShowTermsNoteModal={setShowTermsNoteModal} />
      )}
    </div>
  );
};

export default CreateJobApplicationPublic;
