import React, { ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router-dom';

import { createFuture } from './FutureAPI';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../../Common/styling';
import { useNotify } from '../../Common/snackbarHooks';
import FutureForm from './FutureForm';
import { CancelLink } from '../../Common/ButtonLinks';
import { FutureInterface } from './types';

const initialFutureValues = {
  title: '',
  content: '',
  ranking: 1,
  show: true,
  id: 0,
};
const CreateFuture: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();

  // functions
  const handleFormSubmit = (values: FutureInterface, actions: FormikHelpers<any>): void => {
    createFuture(values)
      .then(() => notifySuccess('Framtid har skapats'))
      .then(() => navigate('/future'))
      .catch((error) => {
        notifyError(`Det gick inte att skapa Framtid : ${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      });
  };
  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Lägg till Framtid</h1>
        <CancelLink link={'/future'} />
      </div>

      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialFutureValues}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, dirty }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <FutureForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                isCreate={true}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateFuture;
