import API from '../Common/axios';
import { DashboardEvents, DashboardNews, DashboardTeamWorkShifts } from './types';

type DashboardNewsParams = {
  important: boolean;
  page?: number;
  page_size?: number;
};

export async function getDashboardNews({ important, page = 1, page_size = important ? 1 : 4 }: DashboardNewsParams) {
  return API.get<{ data: DashboardNews[] }>('/v1/dashboard/news', { params: { important, page, page_size } });
}

export async function getWorkShifts() {
  return API.get<{ work_shifts: DashboardTeamWorkShifts[] }>('v1/dashboard/work_shifts');
}

export async function getDashboardEvents() {
  return API.get<DashboardEvents>('/v1/dashboard/events');
}
