import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

import { StyleSheet, css, theme } from '../styling';
import { Cancel as CancelIcon } from '@mui/icons-material';

const styles = StyleSheet.create({
  cancelLink: {
    color: theme.black,
  },
});

type Props = {
  link: string;
  goBack?: boolean;
};

const CancelLink: React.FC<Props> = ({ link, goBack = true }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Link
      to={link}
      onClick={
        goBack && location.key !== 'default'
          ? (event) => {
              event.preventDefault();
              navigate(-1);
            }
          : undefined
      }
      className={css(styles.cancelLink)}
    >
      <CancelIcon fontSize="large" />
    </Link>
  );
};

export default CancelLink;
