import * as Yup from 'yup';
import { MinimalTeam } from '../../Team/types';
import dayjs, { Dayjs } from 'dayjs';
import { MinimalUser } from '../../User/types';
import { Interview } from '../types';

export const interviewInitialValues = {
  team: null as MinimalTeam | null,
  date: null as Dayjs | null,
  amount: '',
  type: '' as Interview['type'] | '',
  attending_tl: null as MinimalUser | null,
  attending_rc: null as MinimalUser | null,
  training: false,
};

export type InterviewValues = typeof interviewInitialValues;

export const interviewValidation = Yup.object().shape({
  team: Yup.mixed().required('* Krävs'),
  date: Yup.mixed()
    .test('required', '* Required', (value) => !!value)
    .test('valid-date', 'Måste vara ett giltigt datum', (value) => value?.isValid())
    .test('not-future', 'Kan inte vara i framtiden', (value) => value?.isBefore(dayjs().add(1, 'day'), 'date')),
  amount: Yup.number()
    .typeError('Måste vara ett nummer')
    .integer('Måste vara ett heltal')
    .min(1, 'Måste vara mellan 1 och 20')
    .max(20, 'Måste vara mellan 1 och 20')
    .required('* Krävs'),
  type: Yup.mixed().oneOf(['ParticipantInterview', 'TeamLeaderInterview'], '* Krävs').required('* Krävs'),
  attending_tl: Yup.mixed()
    .test('one-attendee-required', '* Krävs', function (value) {
      if ((this as any)?.parent.type === 'TeamLeaderInterview') return true;
      if ((this as any)?.parent.attending_rc) return true;
      return !!value;
    })
    .test('training_attendee', '* Krävs för utbildning', function (value) {
      if (!(this as any)?.parent.training || (this as any)?.parent.type !== 'ParticipantInterview') return true;
      return !!value;
    }),
  attending_rc: Yup.mixed().test('one-attendee-required', '* Krävs', function (value) {
    if ((this as any)?.parent.attending_tl) return true;
    return !!value;
  }),
});
