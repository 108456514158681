import { StyleSheet } from 'aphrodite';
import theme from './appTheme';

// Styling classes which are re-used multiple times
// through the app.
export default StyleSheet.create({
  mainViewWrapper: {
    position: 'relative',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  centerImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    '@media (min-width: 400px)': {
      width: '90%',
    },
    '@media (min-width: 700px)': {
      width: '80%',
    },
    '@media (min-width: 900px)': {
      width: '450px',
      paddingRight: '15%',
      paddingLeft: '15%',
    },
    paddingTop: '55px',
    marginBottom: '-15px',
  },

  internalFormContentContainer: {
    minWidth: '550px',
    maxWidth: '2000px',
    textAlign: 'left',
  },

  publicFormContentContainer: {
    paddingRight: '15%',
    paddingLeft: '15%',
    minWidth: '550px',
    maxWidth: '2000px',
    textAlign: 'left',
  },

  publicFormTitle: {
    fontSize: '26px',
    marginBottom: '3px',
    textAlign: 'center',
  },

  publicFormTextField: {
    maxWidth: '1000px',
  },

  listViewWrapper: {
    minWidth: '1000px',
    maxWidth: '1500px',
  },

  stretch: {
    flexGrow: 1,
  },

  infoString: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px',
  },

  tableWrapper: {
    backgroundColor: theme.white,
    fontSize: '0.7rem',
  },

  table: {
    width: '100%',
  },

  clickable: {
    cursor: 'pointer',
  },

  quickActionCell: {
    fontSize: '0.7rem',
    width: '35px',
    minWidth: '35px',
    maxWidth: '35px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  tableCell: {
    fontSize: '0.7rem',
    width: '70px',
    minWidth: '50px',
    maxWidth: '70px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  tableCellSmaller: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '0.7rem',
    width: '10px',
    minWidth: '10px',
    maxWidth: '10px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  tableCellSmall: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '0.7rem',
    width: '25px',
    minWidth: '25px',
    maxWidth: '25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  tableCellSmallNoPadding: {
    padding: '0px',
    fontSize: '0.7rem',
    width: '25px',
    minWidth: '25px',
    maxWidth: '25px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  tableCellLarge: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '0.7rem',
    width: '100px',
    minWidth: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  tableCellLargeNoPadding: {
    padding: '0px',
    fontSize: '0.7rem',
    width: '100px',
    minWidth: '100px',
    maxWidth: '100px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  tableCellLarge2: {
    fontSize: '0.7rem',
    width: '100px',
    minWidth: '150px',
    maxWidth: '150px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  tableCellLarger: {
    paddingTop: '0px',
    paddingBottom: '0px',
    fontSize: '0.7rem',
    width: '220px',
    minWidth: '220px',
    maxWidth: '220px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    textAlign: 'left',
  },

  headerTextStyle: {
    fontSize: '1.5rem' /* 24px */,
    lineHeight: '2rem' /* 32px */,
    fontWeight: 600,
  },

  headerTextStyleWithPadding: {
    fontSize: '18px',
    paddingTop: '10px',
    margin: '12px 0',
    fontWeight: 700,
  },

  dropdownStyle: {
    height: '40%',
  },

  formContainer: {
    paddingLeft: '32px',
    paddingRight: '32px',
  },

  createViewHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '26px 32px 21px 32px',
    minWidth: '900px',
    maxWidth: '100%',
  },

  editViewHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '26px 32px 21px 32px',
  },

  blueRow: {
    background: theme.blueLight,
  },

  redThemeRow: {
    background: theme.themeRedLight,
  },

  themeGreenGreyLightRow: {
    background: theme.themeGreenGreyLight,
  },

  greyRow: {
    background: theme.greyLight,
  },

  whiteRow: {
    background: 'white',
  },

  hover: {
    cursor: 'pointer',
  },

  pLink: {
    color: theme.linkBlue,
  },

  greenLink: {
    color: theme.themeGreen,
    textDecoration: 'none',
    backgroundCOlor: 'transparent',
  },

  greenRow: {
    background: theme.themeGreenLight,
  },

  archivedRow: {
    background: '#c3c3c3',
  },

  importantRow: {
    background: '#fffcee',
  },

  newsRowHeight: {
    height: '80px',
  },

  redRow: {
    background: theme.redLighter,
  },

  dropdownSelectedRow: {
    background: '#B6E4CA',
  },

  textRed: {
    color: theme.red,
  },

  textRedBold: {
    color: theme.red,
    fontWeight: 'bold',
  },

  textGreen: {
    color: theme.green,
  },

  text4: {
    fontSize: '14px',
    lineHeight: '18px',
    letterSpacing: '0.15px',
    color: 'rgb(0, 0, 0, 0.87)',
    alignContent: 'center',
  },

  spinner: {
    position: 'fixed',
    top: '50%',
    left: '50%',
  },

  loginSpinner: {
    position: 'fixed',
    top: '50%',
    left: '50%',
  },

  deleteIcon: {
    position: 'relative',
    bottom: '1px',
    paddingBottom: '1px',
    verticalAlign: 'middle',
    color: '#d0021b',
  },

  editIcon: {
    color: '#47bf8f',
    width: '24px',
    height: '24px',
  },

  lockIcon: {
    color: 'rgba(0, 0, 0, 0.38)',
    width: '24px',
    height: '24px',
  },

  disableSnackWrap: {
    flexWrap: 'nowrap',
  },

  addLineBreaks: {
    whiteSpace: 'pre-wrap',
  },

  selectionFabContainer: {
    position: 'fixed',
    left: '250px',
    right: '0px',
    display: 'flex',
    justifyContent: 'center',
    bottom: '64px',
  },

  selectionFab: {
    margin: '5px',
    marginBottom: '0px',
  },

  selectionFabContainerStyle2: {
    fontSize: '0.8rem',
    background: '#fff',
    borderRadius: '5px',
    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)',
    zIndex: 1,
    height: '56px',
    minWidth: '400px',
    maxWidth: '700px',
    justifyContent: 'space-evenly',
    marginTop: '10px',
    marginBottom: '10px',
    verticalAlign: 'middle',
    position: 'fixed',
    bottom: '32px',
  },

  selectionFabStyle2: {
    marginTop: '5px',
    width: '200px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'sticky',
    justifyContent: 'flex-start',
  },

  selectionFabStyle3: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'sticky',
    justifyContent: 'flex-start',
    textTransform: 'none',
  },

  selectionFabStyle3NoPadding: {
    padding: '0px',
    backgroundColor: 'transparent',
    boxShadow: 'none',
    position: 'sticky',
    justifyContent: 'flex-start',
    textTransform: 'none',
  },

  batchRemoveListElement: {
    justifyContent: 'space-between',
    flexDir: 'row',
    display: 'flex',
    flex: '1',
  },

  headlineWrapper: {
    display: 'flex',
    // paddingLeft: '10px',
    // paddingRight: '10px',
    // paddingTop: '5px',
    // paddingBottom: '5px',
    height: '4rem',
    padding: '1rem',
    alignItems: 'center',
  },

  greyText: {
    color: 'grey',
  },

  Subtitle1: {
    width: '180px',
    height: '20px',
    margin: '0 0 0 8px',
    fontSize: '14px',
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.52',
    letterSpacing: '0.15px',
    color: 'rgba(0, 0, 0, 0.65)',
  },

  Headline6: {
    fontSize: '18px',
    lineHeight: '55px',
    fontWeight: 500,
    fontStyle: 'normal',
    color: 'rgba(0, 0, 0, 0.87)',
  },

  searchBarWrapper: {
    minHeight: '50px',
    display: 'flex',
    padding: '2px 10px',
    alignItems: 'center',
    minWidth: '1000px',
    maxWidth: '100%',
    backgroundColor: 'white',
  },

  filtersBarWrapper: {
    minHeight: '50px',
    display: 'flex',
    padding: '2px 10px',
    alignItems: 'center',
    minWidth: '1000px',
    maxWidth: '100%',
  },

  filtersContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    minHeight: '45px',
    justifyContent: 'space-between',
  },
});
