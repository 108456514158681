import { AxiosResponse } from 'axios';
import API from '../../Common/axios';
import { FaqInterface } from './types';

export const createFaq = (faq: FaqInterface): Promise<AxiosResponse> => {
  return API.post('v1/faqs', { faq });
};

export const getFaqs = (): Promise<AxiosResponse> => {
  return API.get('v1/faqs');
};

export const getFaq = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/faqs/${id}`);
};

export const updateFaq = (id: number, faq: FaqInterface): Promise<AxiosResponse> => {
  return API.patch(`v1/faqs/${id}`, { faq });
};

export const deleteFaq = (id: number): Promise<AxiosResponse> => {
  return API.delete(`v1/faqs/${id}`);
};
