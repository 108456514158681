import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import { updateFaq, deleteFaq, getFaq } from './FaqApi';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../../Common/styling';
import { useNotify } from '../../Common/snackbarHooks';
import FaqForm from './FaqForm';
import { BackLink } from '../../Common/ButtonLinks';
import { FaqInterface } from './types';
import DeleteConfirmationModal from '../DeleteConfirmationModal';

const initialFaqValues = {
  question: '',
  answer: '',
  ranking: 1,
  show: true,
  id: 0,
};

const EditFaq: React.FC = () => {
  const navigate = useNavigate();
  const { notifyError, notifySuccess, notifyInfo } = useNotify();
  const { id } = useParams() as { id: string };
  const [loading, setLoading] = useState(true);
  const [faq, setFaq] = useState<FaqInterface>(initialFaqValues);
  const [confirmDialog, setConfirmDialog] = useState(false);

  useEffect(() => {
    setLoading(true);

    getFaq(id)
      .then(({ data }) => {
        setFaq(data.faq);
      })
      .catch(() => notifyError('Det gick inte att hämta FAQ'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // functions
  const handleDeleteConfirm = (): void => {
    setConfirmDialog(false);
    deleteFaq(parseInt(id))
      .then(() => notifyInfo('FAQ har tagits bort'))
      .then(() => navigate('/faq'))
      .catch(() => notifyError('Det gick inte att ta bort FAQ'));
  };

  const handleFormSubmit = (values: FaqInterface, actions: FormikHelpers<any>): void => {
    handleUpdateFaq(values, actions.setSubmitting);
  };

  const handleUpdateFaq = (values: FaqInterface, setSubmitting: any): void => {
    updateFaq(faq.id, values)
      .then(() => notifySuccess('FAQ är uppdaterad'))
      .then(() => navigate('/faq'))
      .catch((error) => {
        notifyError(`Det gick inte att uppdatera FAQ : ${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  const handleDeleteFaq = (): void => {
    setConfirmDialog(true);
  };

  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/faq'} />
            <h1 className={css(commonStyles.headerTextStyle)}>Redigera FAQ: &quot;{faq.question}&quot;</h1>
          </div>

          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={faq}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, setSubmitting }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <FaqForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    isCreate={false}
                    submitDisabled={isSubmitting || !isValid}
                    removal={handleDeleteFaq}
                  />
                  <DeleteConfirmationModal
                    onConfirm={(): void => handleDeleteConfirm()}
                    onClose={(): void => {
                      setConfirmDialog(false);
                      setSubmitting(false);
                    }}
                    isVisible={confirmDialog}
                    name="FAQ"
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditFaq;
