import React, { useState, useContext } from 'react';

import { StyleSheet, css, theme } from '../Common/styling';
import { resetPassword } from './usersApi';
import { useNotify } from '../Common/snackbarHooks';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../Common/AuthContext';
import { AuthContextValue } from '../Common/types';
import { LockOutlined as LockOutlinedIcon } from '@mui/icons-material';
import { Avatar, Button, FormControl, Input, InputLabel, Paper, Typography } from '@mui/material';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';

const styles = StyleSheet.create({
  loginView: {
    backgroundColor: theme.themeGreenGreyLight,
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  loginCard: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    maxWidth: '340px',
  },

  avatar: {
    margin: '10px',
  },

  loginButton: {
    marginTop: '40px',
  },
});

const ResetPasswordPage: React.FC = () => {
  // hooks
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotify();
  const { logout, isAuth } = useContext(AuthContext) as AuthContextValue;

  const getUrlParameter = (key: string): string => {
    key = key.replace(/[[]/, '\\[').replace(/[\]]/, '\\]');
    const regex = new RegExp('[\\?&]' + key + '=([^&#]*)');
    const results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
  };

  const handleSubmit = (): void => {
    if (password === confirmPassword) {
      resetPassword({
        token: getUrlParameter('token'),
        password: password,
      })
        .then(() => {
          // Log the previous user out, if they have authenticated so that
          // the new user does not accidentally login to a wrong account after password reset.
          if (isAuth) {
            logout();
          }
          notifySuccess('Lösenordet är ändrat');
        })
        .catch((error) => {
          notifyError(`Det gick inte att uppdatera lösenordet. \n${HandleError(error.response.data)}`);
        })
        .finally(() => navigate('/login'));
    } else {
      notifyError('Lösenorden matchar inte');
    }
  };

  return (
    <div className={css(styles.loginView)}>
      <Paper className={css(styles.loginCard)}>
        <Avatar className={css(styles.avatar)}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography variant="h5">Sätt lösenordet</Typography>
        <div>
          <FormControl variant="standard" margin="normal" required fullWidth>
            <InputLabel htmlFor="email">Nya lösenordet</InputLabel>
            <Input
              id="new_password"
              name="new_password"
              value={password}
              onChange={(e): void => setPassword(e.target.value)}
              type="password"
            />
          </FormControl>
          <FormControl variant="standard" margin="normal" required fullWidth>
            <InputLabel htmlFor="password">Bekräfta lösenordet</InputLabel>
            <Input
              name="confirm_new_password"
              type="password"
              id="confirm_new_password"
              value={confirmPassword}
              onChange={(e): void => setConfirmPassword(e.target.value)}
            />
          </FormControl>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            className={css(styles.loginButton)}
            onClick={handleSubmit}
          >
            Ändra lösenordet
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default ResetPasswordPage;
