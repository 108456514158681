import React from 'react';
import { commonStyles, css } from '../../Common/styling';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const Terms: React.FC = () => {
  return (
    <div>
      <Grid container spacing={2} justifyContent="flex-start" className={css(commonStyles.publicFormContentContainer)}>
        <Grid className="!mt-10" item xs={12} sm={12} md={12} lg={12}>
          <h1 className={css(commonStyles.publicFormTitle)}>INTEGRITETSPOLICY</h1>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            På Ung Omsorg värnar vi om din personliga integritet och eftersträvar alltid en hög nivå av dataskydd (till
            exempel skulle vi aldrig sälja dina personuppgifter till ett annat företag). Denna integritetspolicy
            förklarar hur vi samlar in och använder din personliga information. Den beskriver också vilka rättigheter du
            har och hur du kan använda dem.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Integritetspolicyn och rutinerna som beskrivs här följer dataskyddsförordningen (GDPR). Mer information om
            förordningen finns på{' '}
            <a href="https://www.imy.se/" rel="noreferrer" target="_blank">
              {' '}
              Integritetsskyddsmyndighetens hemsida
            </a>
            .
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>Vad är en personuppgift och vad är behandling av personuppgifter?</b>
          <p>
            Personuppgifter är all slags information som direkt eller indirekt kan hänföras till en fysisk person. Till
            exempel kan bilder och ljudupptagningar som behandlas i en dator vara personuppgifter även om inga namn
            nämns. Krypterade uppgifter och olika slags elektroniska identiteter (exempelvis IP-nummer) är
            personuppgifter om de kan kopplas till fysiska personer.
          </p>
          <p>
            Behandling av personuppgifter är allt som sker med personuppgifterna. Varje åtgärd som vidtas med
            personuppgifter utgör en behandling, oberoende av om den utförs automatiserat eller ej. Exempel på vanliga
            behandlingar är insamling, registrering, organisering, strukturering, lagring, bearbetning, överföring och
            radering.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>Vem är ansvarig för de personuppgifter vi behandlar?</b>
          <p>
            <b>Ung Omsorg kärnverksamhet</b> omfattar inte hantering av personuppgifter, men vi hanterar personuppgifter
            som ett led i våra stödfunktioner. Personuppgifter delas med myndigheter i enlighet med lagkrav och
            personuppgiftsbiträden. Följande roller finns hos oss:
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>Personuppgiftsansvarig</b>
          <p>Ung Omsorg Sverige AB (556763-8050)</p>
          <p>Barnhusgatan 3</p>
          <p>111 23 Stockholm</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>Personuppgiftsbiträde</b>
          <p>Skry AB (556843-0184)</p>
          <p>Drottninggatan 78</p>
          <p>111 36 Stockholm</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Skry driver och utvecklar på uppdrag av oss vårt IT-system UngIntra som hanterar arbetsansökningar och
            personal. UngIntra kräver inloggning och hanterar olika roller av användare som endast kan ta del av lagrade
            personuppgifter i den utsträckning det krävs för att kunna fullgöra sin arbetsroll. Systemet är skyddat av
            brandväggar och antivirus enligt ”data protection by design” och utformningen motsvarar kraven för principen
            ”privacy by design”. 
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>Skry AB har undertecknat ett personbiträdesavtal enligt förordningen.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>Dataskyddsombud</b>
          <p>Ej utsett då verksamhetens karaktär ej kräver detta.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>Om förändringar i integritetspolicyn</b>
          <p>
            Vi kan komma att göra ändringar i vår integritetspolicy. Den senaste versionen av policyn finns alltid på
            vår webbplats{' '}
            <a href="https://ungomsorg.se/" rel="noreferrer" target="_blank">
              https://ungomsorg.se/
            </a>
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>Vilka personuppgifter samlar vi in om dig och i vilket syfte? När du söker jobb hos oss</b>
          <p>
            För att administrera din intresseanmälan, lagrar vi vissa av dina personuppgifter i vår intressedatabas. Du
            samtycker till behandling genom att acceptera våra ’villkor’ på ansökningssidan som är i enlighet med denna
            Integritetspolicy. När du samtycker till dessa villkor, samtycker du även till att vi behandlar dina
            personuppgifter.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="!text-left">
          <b>De personuppgifter vi kan lagra från din intresseanmälan är:</b>
          <ul className="!list-disc">
            <li>Namn</li>
            <li>Kontaktuppgifter (e-post och telefonnummer)</li>
            <li>Bostadsadress</li>
            <li>Personnummer</li>
            <li>Uppgifter som du registrerade självmant och frivilligt uppger</li>
            <li>Innehåll som du själv publicerar, s.k. användargenererat innehåll</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Behandlingen av dina personuppgifter är nödvändig för att tillgodose vårt och ditt intresse för hanteringen
            av din intresseanmälan och en framtida rekrytering. Detta så kallade berättigade intresse tillsammans med
            det ingångna avtalet utgör en rättslig grund för oss att spara dina personuppgifter.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Uppgifterna sparas i 24 månader efter avslutad rekrytering om du inte får jobbet. Vi behöver behålla dina
            uppgifter en tid ifall du återkommer med frågor kring din rekrytering samt för att kunna se historik i våra
            system. Om du inte kallas till en rekrytering sparas dina uppgifter i 5 år från ansökningsdatum.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>När du är kund eller potentiell kund till oss</b>
          <p>
            Vi försöker i möjligaste mån att inhämta ditt samtycke innan vi börjar behandlar dina personuppgifter. Du
            har när som helst rätt att återkalla ditt samtycke. Vi kommer då inte längre att behandla dina
            personuppgifter eller inhämta nya, under förutsättning att det inte behövs för att fullgöra våra
            skyldigheter enligt avtal eller lag. Tänk på att återkallelse av samtycke kan innebära att vi inte kan
            fullgöra de skyldigheter vi har i förhållande till dig.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="!text-left">
          <b>Vi får också tillgång till dina personuppgifter på följande sätt:</b>
          <ul className="!list-disc">
            <li>Uppgifter som du tillhandahåller oss direkt</li>
            <li>Uppgifter som vi får från offentliga register</li>
            <li>Uppgifter som vi får när du som kund anlitar oss</li>
            <li>Uppgifter som vi får när du kontaktar oss, besöker oss eller på annat sätt tar kontakt med oss</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>När vi använder dina personuppgifter för att utvärdera vårt arbete</b>
          <p>Vi analyserar och utvärderar löpande vårt arbete i syfte att utveckla och förbättra våra tjänster.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Vi kan till exempel skicka ut en enkät för att samla in våra kunders åsikter och önskemål. Baserat på de
            uppgifter vi samlar in (till exempel befattning och region) gör vi en analys som kan resultera i att du
            sorteras in i en grupp (kundsegment), men analysen görs aldrig på individnivå. Insikterna från analysen
            ligger till grund för vårt arbete med att utveckla kvalitet i de tjänster som erbjuds.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="!text-left">
          <b>De personuppgifter vi lagrar är:</b>
          <ul className="!list-disc">
            <li>Befattning</li>
            <li>Namn</li>
            <li>Organisation</li>
            <li>Epost och telefonnummer</li>
            <li>Korrespondens och feedback avseende våra tjänster</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Behandlingen är nödvändig för att tillgodose vårt och våra kunders berättigade intresse av att utvärdera,
            utveckla och förbättra våra tjänster och system. Vi har gjort bedömningen att detta berättigade intresse i
            dessa fall väger tyngre än skyddet för din personliga integritet.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Behandling av personuppgifter</b>
          </p>
          <p>
            <b>Från vilka källor hämtar vi dina personuppgifter?</b>
          </p>
          <p>
            De uppgifter vi har om dig är enbart de du själv lämnat till oss, som vi samlat in från dig utifrån dina
            registreringar, eller som vi får från offentliga register.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <b>När du skickar e-post till oss hanterar vi innehållet enligt vår e-postpolicy. Policyn säger att:</b>
          <ul className="!list-disc">
            <li>
              Om meddelandet innehåller känsliga personuppgifter, till exempel uppgifter om hälsa, religiös åskådning
              eller politiska åsikter, tas det omedelbart bort och inget av det sparas.
            </li>
            <li>
              Om du kontaktar oss spontant via e-post och du inte redan finns i vårt register, samtycker du till att vi
              behandlar informationen i ditt mejl. Du kan när som helst begära att bli bortglömd och att tidigare
              korrespons raderas.{' '}
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Vilka kan vi komma att dela dina personuppgifter med?</b>
          </p>
          <p>
            I de fall det är nödvändigt delar vi dina personuppgifter med företag som är underleverantörer till oss.
            Dessa underleverantörer kallas för personuppgiftsbiträden. Ett personuppgiftsbiträde är ett företag som
            behandlar information för vår räkning och enligt våra instruktioner. Vi har personuppgiftsbiträden som
            hjälper oss med:
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="!text-left">
          <ul className="!list-disc">
            <li>
              IT-tjänster (företag som hanterar nödvändig drift, teknisk support och underhåll av våra IT-lösningar).
              Till dessa överförs IP-adresser, mejladresser, kontaktuppgifter, personnummer, samt fakturerings- och
              betalningsinformation.
            </li>
            <li>
              Företag som tillhandahåller betallösningar. Till dessa överförs fakturerings-och betalningsinformation.
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            När dina personuppgifter delas med personuppgiftsbiträden sker det för de ändamål vi har angivit. Vi har
            skriftliga avtal med alla personuppgiftsbiträden där de garanterar säkerheten för de personuppgifter som
            behandlas och åtar sig att följa våra säkerhetskrav samt begränsningar och krav som gäller internationell
            överföring av personuppgifter.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>Vi förmedlar inte dina personuppgifter till tredje part.</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Var behandlar vi dina personuppgifter?</b>
          </p>
          <p>
            Vi strävar alltid efter att dina personuppgifter ska behandlas inom Sverige. Går inte det, väljer vi
            lösningar inom EU.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Om du interagerar med oss i sociala medier innebär det att en överföring av dina personuppgifter, till
            exempel din bild och ditt namn, kommer att ske till tredjeland utanför EU/EES-området, mer specifikt till
            USA och Kina. Detta sker automatiskt när du är aktiv på sociala medieplattformar och är därmed ingenting Ung
            Omsorg kan påverka. Överföringen sker i enlighet med gällande integritetsskyddslagstiftning inklusive GDPR.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            I det tredje landet gäller dock inte GDPR, vilket kan innebära en ökad risk i integritetshänseende avseende
            bland annat möjligheten för myndigheter i tredje land att få tillgång till dina personuppgifter och för dina
            möjligheter att utöva kontroll över personuppgifterna. Överföringen är nödvändig för att du ska kunna
            kontakta oss på sociala medier. Överföringen mellan oss och sociala medietjänsterna baseras på
            standardavtalsklausuler och kompletteras med tekniska och organisatoriska skyddsåtgärder.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Hur länge sparar vi dina personuppgifter?</b>
          </p>
          <p>
            Vi sparar aldrig dina personuppgifter längre än vad som är nödvändigt. Se mer om de specifika
            lagringsperioderna under respektive rubrik nedan.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Vad har du för rättigheter som registrerad?</b>
          </p>
          <p>
            Den som önskar göra en framställan enligt sina rättigheter kan göra det genom att kontakta vem som helst i
            ledande befattning hos oss enligt kontaktuppgifter på vår hemsida, eller genom mejl till vår allmänna adress
            info@ungomsorg.se.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Information om dina rättigheter finns även på{' '}
            <a href="https://www.imy.se/" rel="noreferrer" target="_blank">
              Integritetsskyddsmyndighetens hemsida
            </a>
            .
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Rätt att återkalla samtycke</b>
          </p>
          <p>
            Om du lämnat ditt samtycke till oss för behandling av dina personuppgifter har du rätt att när som helst
            återkalla ditt samtycke. Du kan återkalla ditt samtycke genom att kontakta oss på ovan kontaktuppgifter.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Rätt till rättelse</b>
          </p>
          <p>
            Du kan begära att dina personuppgifter rättas utan onödigt dröjsmål ifall uppgifterna är felaktiga. Inom
            ramen för det angivna ändamålet har du också rätt att komplettera eventuellt ofullständiga personuppgifter.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Rätt till radering (rätten att bli bortglömd)</b>
          </p>
          <p>Du kan begära radering av personuppgifter vi behandlar om dig ifall:</p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} className="!text-left">
          <ul className="!list-disc">
            <li>Uppgifterna inte längre är nödvändiga för de ändamål för vilka de har samlats in eller behandlats.</li>
            <li>
              Du invänder mot en intresseavvägning vi har gjort baserat på berättigat intresse och ditt skäl för
              invändning väger tyngre än vårt berättigade intresse.
            </li>
            <li>Behandlingen av personuppgifterna har skett på ett olagligt sätt.</li>
            <li>Du drar tillbaka ditt samtycke.</li>
          </ul>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Tänk på att vi kan ha rätt att neka din begäran ifall det finns legala skyldigheter som hindrar oss från att
            omedelbart radera vissa personuppgifter. Dessa skyldigheter kommer från bokförings- och skattelagstiftning
            och innebär att vissa uppgifter kan lagras till dess att vi inte är skyldiga att lagra dem längre.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Rätt till dataportabilitet</b>
          </p>
          <p>
            Du har rätt att få ut dina personuppgifter i ett strukturerat, allmänt använt och maskinläsbart format i
            syfte att använda dina personuppgifter på annat håll. Denna rättighet gäller endast i det fall Ung Omsorg
            behandlar personuppgifterna med stöd av ditt samtycke eller för att uppfylla ett avtal. En annan
            förutsättning för dataportabilitet är att överföringen är tekniskt möjlig och kan ske automatiserat. Denna
            möjlighet bedöms i fall till fall.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Rätt att klaga</b>
          </p>
          <p>
            Du har rätt att inge klagomål till Integritetsskyddsmyndigheten om du anser att behandlingen av
            personuppgifter som avser dig strider mot Dataskyddslagregler. För mer information och kontaktuppgifter till
            IMY, vänligen se www.imy.se.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Hur skyddas dina personuppgifter?</b>
          </p>
          <p>
            Vi använder IT-system för att skydda sekretessen, integriteten och tillgången till dina personuppgifter. Vi
            har vidtagit särskilda tekniska och organisatoriska säkerhetsåtgärder för att skydda dina personuppgifter
            mot olovlig eller obehörig behandling (såsom olovlig tillgång, förlust, förstörelse eller skada). Endast de
            personer som faktiskt behöver behandla dina personuppgifter för att vi ska kunna uppfylla våra angivna
            ändamål har tillgång till dem.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>Vad innebär det att Integritetsmyndigheten är tillsynsmyndighet?</b>
          </p>
          <p>
            Integritetsskyddsmyndigheten (IMY) är ansvarig för att övervaka tillämpningen av lagstiftningen. Den som
            anser att ett företag hanterar personuppgifter på ett felaktigt sätt kan lämna in ett klagomål till IMY.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Om en personuppgiftsincident uppkommer är vi skyldiga att anmäla det till IMY. En incident är en händelse
            som leder till oavsiktlig eller olaglig förstöring, förlust eller ändring av dina personuppgifter.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            Det kan också vara fråga om en personuppgiftsincident ifall händelsen leder till obehörigt röjande av eller
            obehörig åtkomst till de behandlade personuppgifterna. Incidenten ska anmälas till IMY inom 72 timmar från
            det den upptäcktes.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <p>
            <b>När lämnar vi ut dina personuppgifter?</b>
          </p>
          <p>
            Vår utgångspunkt är att inte lämna ut dina personuppgifter till tredje part om du inte har samtyckt till det
            eller om det inte är nödvändigt för att uppfylla våra förpliktelser enligt avtal eller lag. I de fall vi
            lämnar ut personuppgifter till tredje part upprättar vi sekretessavtal samt säkerställer att
            personuppgifterna behandlas på ett betryggande sätt.
          </p>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12}></Grid>
      </Grid>
    </div>
  );
};

export default Terms;
