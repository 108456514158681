import React from 'react';

import { Button, FormControl, Grid } from '@mui/material';
import FormTextField from '../../../Common/FormItems/FormTextField';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  setFieldValue: any;
};

const ProfilePasswordForm: React.FC<Props> = (props) => {
  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }} alignItems="center">
        <FormTextField
          size={3}
          fieldName="old_password"
          label="Nuvarande lösenord"
          placeholder="Nuvarande lösenord"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          type="password"
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }} alignItems="center">
        <FormTextField
          size={3}
          fieldName="new_password"
          label="Nytt lösenord"
          placeholder="Nytt lösenord"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          type="password"
        />
        <FormTextField
          size={3}
          fieldName="confirm_new_password"
          label="Bekräfta nytt lösenord"
          placeholder="Bekräfta nytt lösenord"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          type="password"
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }} alignItems="center">
        <Grid item xs={2}>
          <FormControl variant="standard" margin="dense" required fullWidth>
            <Button type="submit" variant="contained" color="primary">
              Byt lösenord
            </Button>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default ProfilePasswordForm;
