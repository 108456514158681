import * as Yup from 'yup';

export const initialValues = {
  type: '',
  value: 0,
  notes: '',
  date: new Date(),
  agreement_id: undefined,
  team_id: undefined,
};

export const validationSchema = Yup.object().shape({
  team_id: Yup.string().required('* Obligatoriskt fält'),
  type: Yup.string().required('* Obligatoriskt fält'),
  value: Yup.number().typeError('* Måste vara en siffra').required('*  Får ej vara tom'),
  date: Yup.date().when('type', { is: 'WorkHourCorrection', then: Yup.date().required() }),
  notes: Yup.string().required('* Obligatoriskt fält'),
  // residence_group_id: Yup.number().required(),
});
