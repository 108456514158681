import React from 'react';

import { paginationDefaults } from '../commonSchema';
import { Checkbox } from '@mui/material';

type Props = {
  fieldName: string;
  label: string;
  listParams: any;
  setGetListParams: any;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
};

const FilterCheckbox: React.FC<Props> = (props) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      {props.label}
      <Checkbox
        checked={!props.listParams[props.fieldName]}
        onChange={(e): void => {
          if (props.setPagination) {
            props.setPagination({
              page: 1,
              page_size: props.paginationDefaults ? props.paginationDefaults.page_size : paginationDefaults.page_size,
            });
          }
          props.setGetListParams({ ...props.listParams, [props.fieldName]: !e.target.checked });
        }}
      />
    </div>
  );
};

export default FilterCheckbox;
