import React from 'react';
import { commonStyles, css } from '../styling';
import { TableRow } from '@mui/material';

type Props = {
  row: any;
  idx: number;
  id?: string;
  onClick?: any;
  children: React.ReactNode;
  customRoot?: any;
};

const CustomTableRow: React.FC<Props> = (props) => {
  const customClass = (): string => {
    return props.customRoot
      ? props.customRoot
      : props.idx % 2 !== 0
      ? css(commonStyles.greyRow)
      : css(commonStyles.whiteRow);
  };

  return (
    <TableRow
      id={props.id}
      className={props.onClick ? css(commonStyles.hover) : ''}
      classes={{ root: customClass() }}
      onClick={props.onClick ? props.onClick : (): void => {}} // eslint-disable-line @typescript-eslint/no-empty-function
    >
      {props.children}
    </TableRow>
  );
};

export default CustomTableRow;
