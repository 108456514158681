import { StyleSheet, css } from 'aphrodite';
import React, { useState } from 'react';
import { isNotEmpty } from '../../Common/utilities';
import { Error as ErrorIcon } from '@mui/icons-material';
import {
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';

const styles = StyleSheet.create({
  residenceListWrapper: {
    width: '100%',
    backgroundColor: 'white',
    maxHeight: '600px',
  },
});

type Props = {
  options: any;
  values: any;
  handleChange: any;
  setFieldValue: any;
  setModalState: any;
};

export const AddTeamsToAgreementModal: React.FC<Props> = (props) => {
  // hooks
  const [dialogResidenceState, setDialogResidenceState] = useState(props.values.residences);

  // function
  const isChecked = (item: any): boolean => {
    return dialogResidenceState.some((residence: any) => residence.id === item.id);
  };

  const handleToggle = (item: any): void => {
    if (isChecked(item)) {
      const residences = dialogResidenceState.filter((residence: any) => residence.id !== item.id);
      setDialogResidenceState(residences);
    } else {
      setDialogResidenceState([...dialogResidenceState, item]);
    }
  };

  const selectAll = (e: any): void => {
    if (e.target.checked) {
      setDialogResidenceState(props.options.filter((residence: any) => residence.teams.length > 0));
    } else {
      setDialogResidenceState([]);
    }
  };

  // render
  return (
    <div>
      <DialogTitle>Välj boenden att skapa avtal för</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <div style={{ paddingLeft: '16px' }}>
          <Checkbox
            checked={
              dialogResidenceState.length ===
              props.options.filter((residence: any) => residence.teams.length > 0).length
            }
            onClick={(e): void => selectAll(e)}
          />{' '}
          Välj alla boenden
        </div>
        <DialogContentText style={{ marginTop: '8px' }}>{dialogResidenceState.length} boenden valda</DialogContentText>
      </DialogContent>
      <DialogContent>
        {props.options.length > 0 && (
          <List className={css(styles.residenceListWrapper)}>
            {props.options.map((element: { id: number; name: string; teams: any }) =>
              isNotEmpty(element.teams) ? (
                <ListItemButton key={element.id} role={undefined} dense onClick={(): void => handleToggle(element)}>
                  {element.teams.length > 0 && <Checkbox checked={isChecked(element)} tabIndex={-1} disableRipple />}
                  {element.teams.length === 0 && (
                    <Tooltip title={<b style={{ fontSize: '1.25em' }}>Saknar Team</b>} disableInteractive>
                      <ErrorIcon color="error" />
                    </Tooltip>
                  )}
                  <ListItemText primary={`${element.name} `} />
                </ListItemButton>
              ) : (
                <Tooltip key={element.id} title={<b style={{ fontSize: '1.25em' }}>Saknar Team</b>} disableInteractive>
                  <ListItemButton role={undefined} dense>
                    <Checkbox disabled tabIndex={-1} disableRipple indeterminate />
                    <ListItemText primary={`${element.name} `} />
                  </ListItemButton>
                </Tooltip>
              )
            )}
          </List>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          fullWidth
          onClick={(): void => {
            setDialogResidenceState([]);
            props.setModalState(false);
          }}
        >
          Nej
        </Button>
        <Button
          color="primary"
          fullWidth
          onClick={(): void => {
            props.setFieldValue('residences', dialogResidenceState);
            setDialogResidenceState([]);
            props.setModalState(false);
          }}
        >
          Ja
        </Button>
      </DialogActions>
    </div>
  );
};
