import React from 'react';
import InventoryIcon from '@mui/icons-material/Inventory';
import { commonStyles, css, StyleSheet } from '../../Common/styling';
import { Fab } from '@mui/material';

const styles = StyleSheet.create({
  archiveIcon: {
    marginRight: '10px',
    fontSize: '1rem',
  },
});

type Props = {
  onClick: any;
  text: string;
};

const ArchiveFab: React.FC<Props> = (props) => {
  return (
    <Fab
      variant="extended"
      color="inherit"
      aria-label="Arkivera"
      className={css(commonStyles.selectionFabStyle2)}
      onClick={props.onClick}
    >
      <InventoryIcon className={css(styles.archiveIcon)} />
      {props.text}
    </Fab>
  );
};

export default ArchiveFab;
