import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
  isVisible: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  message: string;
  title: string;
  closeButtonText?: string;
  confirmButtonText?: string;
};

const ConfirmationModal: React.FC<Props> = (props) => {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} fullWidth color="error">
          {props.closeButtonText || 'Avbryt'}
        </Button>
        <Button onClick={props.onConfirm} fullWidth color="primary">
          {props.confirmButtonText || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
