import React from 'react';

import ConfirmationModal from './ConfirmationModal';

type Props = {
  isVisible: boolean;
  onConfirm: () => void;
  onClose: () => void;
  name: string;
};

const DeleteConfirmationModal: React.FC<Props> = (props) => {
  return (
    <ConfirmationModal
      isVisible={props.isVisible}
      onClose={props.onClose}
      onConfirm={props.onConfirm}
      title="Bekräfta"
      message={`Är du säker att du vill ta bort ${props.name}?`}
    />
  );
};

export default DeleteConfirmationModal;
