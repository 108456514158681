import { Variant } from '@mui/material/styles/createTypography';
import { OptionsObject, useSnackbar } from 'notistack';

// type NotifyFunctions = {
//   notifySuccess: (text: string, options?: any) => void;
//   notifyError: (text: string, options?: any) => void;
//   notifyInfo: (text: string, options?: any) => void;
//   closeNotify: (key: string | number) => void;
// };

type OverrideVariant = { variant?: 'default' | 'success' | 'error' | 'info' | 'warning' };

export const useNotify = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const notifySuccess = (text: string, options: Omit<OptionsObject<'success'>, 'variant'> & OverrideVariant = {}) => {
    return enqueueSnackbar(text, {
      variant: 'success',
      ...options,
    });
  };

  const notifyError = (text: string, options: Omit<OptionsObject<'error'>, 'variant'> & OverrideVariant = {}) => {
    return enqueueSnackbar(text, {
      variant: 'error',
      ...options,
    });
  };

  const notifyInfo = (text: string, options: Omit<OptionsObject<'info'>, 'variant'> & OverrideVariant = {}) => {
    return enqueueSnackbar(text, {
      variant: 'info',
      ...options,
    });
  };

  const closeNotify = (key: string | number): void => {
    closeSnackbar(key);
  };

  return { notifySuccess, notifyError, notifyInfo, closeNotify };
};
