import * as Yup from 'yup';

import { OptionsInterface, sort_order } from '../Common/types';

export const initialValues = {
  name: '',
  residences: [] as OptionsInterface[],
};

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('* Namn är ett obligatoriskt fält'),
  residences: Yup.array().of(Yup.object()).min(1, '* Boende är ett obligatoriskt fält'),
});

export const filterSortDefaults = {
  query: '',
  sort_by: 'created_at',
  sort_order: sort_order.asc,
};

export const paginationDefaults = { page: 1, page_size: 30 };
