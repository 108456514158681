import React, { useEffect, useMemo, useState } from 'react';
import { BackLink, CancelLink } from '../Common/ButtonLinks';
import { Formik, FormikHelpers } from 'formik';
import { InterviewValues, interviewInitialValues, interviewValidation } from './form/formValues';
import InterviewForm from './form/InterviewForm';
import { createInterview, getInterview, trainingsPerformed, updateInterview } from './interviewApi';
import { useNotify } from '../Common/snackbarHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { InterviewDetails } from './types';
import { getDefaultsWithActualValues } from '../Common/utilities';
import { Button, Tooltip } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import dayjs from 'dayjs';
import { MAX_TRAININGS } from './form/interviewConfig';

export default function EditInterview() {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();
  const [interview, setInterview] = useState<InterviewDetails>();
  const params = useParams();
  const interviewId = useMemo(() => {
    const id = Number(params.id);
    return isFinite(id) ? id : null;
  }, [params.id]);

  useEffect(() => {
    if (!interviewId) return;
    getInterview(interviewId).then(({ data }) => setInterview(data.data));
  }, [interviewId]);

  async function handleSubmit({ team, date, amount, type, attending_tl, attending_rc, training }: InterviewValues) {
    if (!interviewId || !interview) return;

    if (training) {
      const trainingUser = type === 'ParticipantInterview' ? attending_tl! : attending_rc!;
      let previousAmount = await trainingsPerformed({
        type: type === 'ParticipantInterview' ? 'tl' : 'rc',
        user: trainingUser.id,
      }).then(({ data }) => data);
      if (interview.training) {
        const lastTrainingUser = interview.type === 'ParticipantInterview' ? interview.tl! : interview.rc!;
        if (interview.type === type && lastTrainingUser.id === trainingUser.id) {
          previousAmount -= interview.amount;
        }
      }

      if (previousAmount + Number(amount) > MAX_TRAININGS) {
        return notifyError(
          `Kan inte uppdatera intervjun eftersom det skulle ge ${
            trainingUser.name
          } för många upplärningstillfällen. Din ändring skulle ge hen ${
            previousAmount + Number(amount)
          } av ${MAX_TRAININGS} tillåtna.`
        );
      }
    }

    await updateInterview(interviewId, {
      team_id: team!.id,
      date: date!.format('YYYY-MM-DD'),
      amount: Number(amount),
      type: type as 'ParticipantInterview' | 'TeamLeaderInterview',
      attending_tl: attending_tl?.id ?? null,
      attending_rc: attending_rc?.id ?? null,
      training,
    })
      .then(() => notifySuccess('Intervju har uppdaterats'))
      .then(() => navigate('/interviews'))
      .catch((error) => notifyError(`Det gick inte att uppdatera intervjun: ${HandleError(error.response.data)}`));
  }

  return (
    <div>
      {/* Title */}
      <div className="flex items-center justify-between h-18 pt-3 mx-8 mb-5">
        <div className="flex items-center">
          <BackLink link="/interviews" />
          <h1 className="text-2xl font-semibold">Redigera intervju</h1>
        </div>
        {interview?.locked_at && (
          <div className="flex justify-center items-center">
            <span className="mr-3.5 text-sm font-semibold tracking-wide text-neutral-500/90">Exporterad</span>
            <div className="flex justify-end pr-2">
              <Tooltip title={`Exporterad vid: ${dayjs(interview.locked_at).format('YYYY-MM-DD')}`}>
                <Button className="!min-h-12 !max-h-12 !min-w-12 !max-w-12 !bg-black/5" disabled>
                  <LockOutlinedIcon className="!text-black/40 !w-6 !h-6" />
                </Button>
              </Tooltip>
            </div>
          </div>
        )}
      </div>
      {/* Form */}
      {interview && (
        <Formik
          initialValues={getDefaultsWithActualValues(interviewInitialValues, {
            ...interview,
            attending_tl: interview?.tl,
            attending_rc: interview?.rc,
            date: dayjs(interview.date, 'YYYY-MM-DD'),
          })}
          onSubmit={handleSubmit}
          validationSchema={interviewValidation}
        >
          {({ values, errors, handleChange, setFieldValue, handleSubmit, isSubmitting, isValid, dirty }) => (
            <form onSubmit={handleSubmit}>
              <InterviewForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                interview={interview}
                isValid={isValid}
                dirty={dirty}
              />
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}
