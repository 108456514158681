import React from 'react';
import { StyleSheet, css, theme } from '../styling';
import { Equalizer as EqualizerIcon } from '@mui/icons-material';
import { Fab } from '@mui/material';

const styles = StyleSheet.create({
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
    color: theme.white,
  },

  fab: {
    position: 'fixed',
    bottom: '96px',
    right: '32px',
  },
});

type Props = {
  onClick: any;
};

const ExportFab: React.FC<Props> = (props) => {
  return (
    <Fab onClick={props.onClick} color="primary" aria-label="Add" classes={{ root: css(styles.fab) }}>
      <EqualizerIcon />
    </Fab>
  );
};

export default ExportFab;
