import { Button, Divider, FormControl, Grid } from '@mui/material';
import React, { useMemo, useState } from 'react';
import ConfirmationDialog from '../../Common/Dialogs/ConfirmationDialog';
import { FormTextField } from '../../Common/FormItems';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { commonStyles, css } from '../../Common/styling';
import { OptionsInterface } from '../../Common/types';
import { initialValues } from '../regionSchema';
import { RegionInterface } from '../types';

type Props = {
  values: typeof initialValues;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T = string | React.ChangeEvent<any>>(field: T): T extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  errors: Record<string, any>;
  submitDisabled: boolean;
  isCreate: boolean;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  removal?: () => void;
  residenceMinimalList: OptionsInterface[];
  rcMinimalList: OptionsInterface[];
  vaMinimalList: OptionsInterface[];
};

const RegionForm: React.FC<Props> = (props) => {
  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(false);

  const confirmDialogMessage = (region: RegionInterface | Record<string, never>): string => {
    if (region.active_teams_count !== undefined && region.name) {
      if (region.active_teams_count > 0) {
        return `Regionen ${region.name} har aktiva teams. Alla boenden, teams och användare som är kopplat till regionen kommer att inaktiveras. Är du säker på att du vill arkivera regionen ${region.name}?`;
      } else {
        return `Vill du arkivera regionen ${region.name} som du valt?`;
      }
    } else {
      return '';
    }
  };

  const newResidences = useMemo(
    () =>
      (props.values.residences as (OptionsInterface & { region?: { id: number; name: string } })[] | undefined)?.filter(
        ({ region }) => !!region
      ),
    [props.values.residences]
  );

  return (
    <div style={{ minWidth: '900px', maxWidth: '100%' }}>
      <Grid container spacing={2}>
        <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingTop: '0px' }}>
          <FormTextField
            size={12}
            fieldName="name"
            label="Namn på regionen"
            placeholder="Namn på regionen"
            values={props.values}
            errors={props.errors}
            onChange={props.handleChange}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingTop: '0px' }}>
          <Grid item xs={12}>
            <FormControl variant="standard" margin="normal" required fullWidth>
              <FormAutocompleteBox
                type="single"
                id="va_id"
                name="operational_manager_id"
                label="Verksamhetsansvarig"
                options={props.vaMinimalList}
                value={props.values.operational_manager}
                getOptionLabel={(option) => option.name}
                onChange={(_, value) => props.setFieldValue('operational_manager', value)}
                error={props.errors.operational_manager ?? null}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <div>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingTop: '0px' }}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="single"
                  id="rc_id"
                  name="regional_manager_id"
                  label="Regionchef"
                  options={props.rcMinimalList}
                  value={props.values.regional_manager}
                  onChange={(_, value) => props.setFieldValue('regional_manager', value)}
                  getOptionLabel={(option) => option.name}
                  optionEquality={(option, value) => option.id === value.id}
                  error={props.errors.regional_manager ?? null}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={6} style={{ paddingTop: '0px' }}>
            <Grid item xs={12}>
              <FormControl variant="standard" margin="normal" required fullWidth>
                <FormAutocompleteBox
                  type="multiple"
                  id="residence_ids"
                  name="residence_ids"
                  label="Välj ett eller flera boenden"
                  options={props.residenceMinimalList}
                  values={props.values.residences as OptionsInterface[]}
                  onChange={(_, value) => {
                    props.setFieldValue('residences', value);
                  }}
                  limitTags={2}
                  getOptionLabel={(option) => option.name}
                  optionEquality={(option, value) => option.id === value.id}
                  error={props.errors.regional_manager ?? null}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <Divider />
          </Grid>
          <Grid item xs={12} style={{ marginBottom: '20px', marginTop: '20px' }}>
            <div className={css(commonStyles.text4)} style={{ textAlign: 'center', marginBottom: '40px' }}>
              {props.values.residences.length === 0
                ? 'Inga boenden kopplade ännu.'
                : props.values.residences
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((r: { name: any }) => r.name)
                    .join(', ')}
            </div>

            {newResidences
              ?.sort(({ name: a }, { name: b }) => (a > b ? 1 : -1))
              .map((r: any) => (
                <div
                  key={r.id}
                  className={css(commonStyles.text4, commonStyles.textRed)}
                  style={{ textAlign: 'center', marginTop: '10px' }}
                >
                  {r.name} flyttas till {props.values.name ? props.values.name : 'regionen'} från {r.region.name}
                </div>
              ))}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} style={{ paddingTop: '20px', paddingBottom: '20px' }}>
            <Divider />
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
          <Grid item xs={5} />
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button type="submit" variant="contained" disabled={props.submitDisabled}>
                {props.isCreate ? 'Lägg till' : 'Spara'}
              </Button>
            </FormControl>
          </Grid>
          <Grid item xs={3} />
          {!props.isCreate && props.values.deletable && (
            <Grid item xs={2}>
              <FormControl variant="standard" margin="dense" required fullWidth>
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={(): void => {
                    if (props.removal) setConfirmationDialogVisible(true);
                  }}
                >
                  Arkivera
                </Button>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </div>

      <ConfirmationDialog
        isVisible={confirmationDialogVisible}
        onClose={(): void => {
          setConfirmationDialogVisible(false);
        }}
        onConfirm={(): void => {
          setConfirmationDialogVisible(false);
          if (props.removal) props.removal();
        }}
        title="Arkivera region"
        message={confirmDialogMessage(props.values)}
      />
    </div>
  );
};

export default RegionForm;
