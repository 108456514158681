import React, { useState } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { stateToHTML } from 'draft-js-export-html';
import { convertToRaw, convertFromHTML, ContentState } from 'draft-js';
import themeStyles from '../styling/materialTheme';
import InfoDialog from '../InfoDialog';
import { Help as HelpIcon } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Theme,
  ThemeOptions,
  ThemeProvider,
  createTheme,
} from '@mui/material';

const style = {
  palette: {
    primary: {
      main: '#e71818',
    },
  },
};

const defaultTheme: Theme = createTheme(themeStyles as ThemeOptions, style as ThemeOptions);

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      placeHolder: {
        paddingBottom: '20px',
        position: 'relative',
      },
      toolbar: {
        borderBottom: '1px solid gray',
      },
      container: {
        border: '1px solid gray',
        borderRadius: '4px',
        padding: '14px',
      },
    },
  },
});

const fromHtml = (html?: string): string => {
  const contentHTML = convertFromHTML(html || '');
  const state = ContentState.createFromBlockArray(contentHTML.contentBlocks, contentHTML.entityMap);
  return JSON.stringify(convertToRaw(state));
};

type Props = {
  subheader: string;
  label: string;
  signature?: string;
  controls: string[];
  initialValue: string;
  fieldName: string;
  handleChange: (state: any) => void;
};

const RichTextEditor: React.FC<Props> = (props) => {
  const [initialValue] = useState(fromHtml(props.initialValue));
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);

  const handleChange = (state: any): void => {
    props.handleChange({
      target: {
        name: props.fieldName,
        value: stateToHTML(state.getCurrentContent()),
      },
    });
  };

  return (
    <Card>
      <CardHeader
        style={{ paddingBottom: 0 }}
        subheader={
          <>
            {props.subheader}{' '}
            <IconButton size="large" onClick={(): void => setInfoDialogOpen(true)}>
              <HelpIcon />
            </IconButton>
          </>
        }
      />
      <CardContent>
        <ThemeProvider theme={defaultTheme}>
          <style>
            {
              // Adds spacing between paragraphs, not easily configurable in mui-rte.
              `div > div.public-DraftStyleDefault-block.public-DraftStyleDefault-ltr { margin-bottom: 1.5em; }`
            }
          </style>
          <MUIRichTextEditor
            defaultValue={initialValue}
            label={props.label}
            controls={props.controls}
            onChange={handleChange}
          />
        </ThemeProvider>
        <InfoDialog
          open={infoDialogOpen}
          title="Formatering"
          body="Returknappen ger en ny paragraf, flera radbrytningar
            i rad formateras om till endast en radbrytning när texten sparas."
          closeDialog={(): void => setInfoDialogOpen(false)}
        />
        <p>{props.signature}</p>
      </CardContent>
    </Card>
  );
};

export default RichTextEditor;
