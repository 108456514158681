import React, { useEffect, useMemo, useState } from 'react';
import { BackLink } from '../Common/ButtonLinks';
import { Formik, FormikHelpers } from 'formik';
import { ExtraHourValues, extraHourInitialValues, extraHourValidation } from './form/formValues';
import ExtraHourForm from './form/ExtraHourForm';
import { getExtraHour, updateExtraHour } from './extraHoursApi';
import { useNotify } from '../Common/snackbarHooks';
import { useNavigate, useParams } from 'react-router-dom';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import { ExtraHourDetails } from './types';
import { getDefaultsWithActualValues } from '../Common/utilities';
import { Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import dayjs from 'dayjs';

export default function EditExtraHour() {
  const { notifySuccess, notifyError } = useNotify();
  const navigate = useNavigate();
  const [extraHour, setExtraHour] = useState<ExtraHourDetails>();
  const params = useParams();
  const extraHourId = useMemo(() => {
    const id = Number(params.id);
    return isFinite(id) ? id : null;
  }, [params.id]);

  useEffect(() => {
    if (!extraHourId) return;
    getExtraHour(extraHourId).then(({ data }) => setExtraHour(data.data));
  }, [extraHourId]);

  async function handleSubmit({ activity, date, salary_class, individual_hours, users }: ExtraHourValues) {
    if (!extraHour) return;
    const removedUsers = extraHour.extra_hours_users.filter(({ user }) => !users.some((u) => u.id === user.id));

    await updateExtraHour(extraHour.id, {
      activity: activity,
      date: date?.format('YYYY-MM-DD') ?? undefined,
      salary_class_id: salary_class?.id,
      individual_hours: individual_hours ?? undefined,
      extra_hours_users_attributes: [
        ...users
          .filter(({ id }) => !extraHour.extra_hours_users.some(({ user }) => user.id === id))
          .map((u) => ({ user_id: u.id })),
        ...removedUsers.map((ehu) => ({ id: ehu.id, _destroy: true })),
      ],
    })
      .then(() => notifySuccess('Extra arbetstimmen har skapats'))
      .then(() => navigate('/extra-hours'))
      .catch((error) =>
        notifyError(`Det gick inte att redigera extra arbetstimmen: ${HandleError(error.response.data)}`)
      );
  }

  return (
    <div>
      {/* Title */}
      <div className="flex items-center justify-between h-18 pt-3 mx-8 mb-5">
        <div className="flex items-center">
          <BackLink link="/extra-hours" />
          <h1 className="text-2xl font-semibold">Redigera extra arbetstimmar</h1>
        </div>
        {extraHour?.locked_at && (
          <div className="flex justify-center items-center">
            <span className="mr-3.5 text-sm font-semibold tracking-wide text-neutral-500/90">Exporterad</span>
            <div className="flex justify-end pr-2">
              <Button className="!min-h-12 !max-h-12 !min-w-12 !max-w-12 !bg-black/5" disabled>
                <LockOutlinedIcon className="!text-black/40 !w-6 !h-6" />
              </Button>
            </div>
          </div>
        )}
      </div>
      {/* Form */}
      {extraHour && (
        <Formik
          initialValues={getDefaultsWithActualValues(extraHourInitialValues, {
            ...extraHour,
            users: extraHour.extra_hours_users.map(({ user }) => user),
            date: dayjs(extraHour.date, 'YYYY-MM-DD'),
          })}
          onSubmit={handleSubmit}
          validationSchema={extraHourValidation}
        >
          {({ values, errors, handleChange, setFieldValue, handleSubmit, isSubmitting, isValid, dirty }) => (
            <form onSubmit={handleSubmit}>
              <ExtraHourForm
                values={values}
                errors={errors}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
                isSubmitting={isSubmitting}
                isValid={isValid}
                extraHour={extraHour}
                dirty={dirty}
              />
            </form>
          )}
        </Formik>
      )}
    </div>
  );
}
