import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import { JobOpeningInterface } from './types';
import API from '../Common/axios';

export const getJobOpeningList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/job_openings', { params });
};

export const createJobOpening = (job_opening: {
  content: string;
  deletable: boolean;
  editable: boolean;
  max_travel_time: number;
  rawContent: string;
  residence_ids: number[];
  role_ids: number;
  title: string;
}): Promise<AxiosResponse> => {
  return API.post('v1/job_openings', { job_opening });
};

export const updateJobOpening = (
  id: number | undefined,
  job_opening: Partial<{
    title: string;
    content: string;
    raw_content: string;
    author_id: string;
    role_ids: number[];
    residence_ids: number[];
  }>
): Promise<AxiosResponse> => {
  return API.patch(`v1/job_openings/${id}`, { job_opening });
};

export const getJobOpening = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/job_openings/${id}`);
};

export const deleteJobOpening = (id: number | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/job_openings/${id}`);
};

export const batchDeleteJobOpening = (ids: (number | undefined)[]): Promise<AxiosResponse> => {
  return API.patch(`v1/job_openings/batch_destroy`, { job_opening_ids: ids });
};

export const batchRestoreJobOpening = (ids: (number | undefined)[]): Promise<AxiosResponse> => {
  return API.patch(`v1/job_openings/batch_restore`, { job_opening_ids: ids });
};

export const getJobOpeningNotification = (): Promise<AxiosResponse> => {
  return API.get('v1/job_openings/notifications');
};
