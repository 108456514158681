import { StyleSheet, css } from 'aphrodite';
import React from 'react';

import { Button, Paper } from '@mui/material';

const styles = StyleSheet.create({
  linkWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  fab: {
    position: 'absolute',
    bottom: '32px',
    left: '50%',
    width: '400px',
    height: '60px',
    transform: 'translate(-50%, 0)',
  },
});

type Props = {
  delete: any;
  send: any;
};

export const UpdateFab: React.FC<Props> = (props) => {
  return (
    <Paper classes={{ root: css(styles.fab) }} className={css(styles.linkWrapper)}>
      <Button
        fullWidth
        variant="outlined"
        color="primary"
        style={{ margin: '16px' }}
        onClick={(): void => props.send()}
      >
        TILL FORTNOX
      </Button>
      <Button
        fullWidth
        variant="outlined"
        color="error"
        style={{ margin: '16px' }}
        onClick={(): void => props.delete()}
      >
        ÅNGRA
      </Button>
    </Paper>
  );
};
