import React from 'react';

import { Button, Divider, FormControl, Grid } from '@mui/material';
import { FormTextField, FormToggle } from '../../Common/FormItems';
import MarkupLegend from '../MarkupLegend';

type Props = {
  values: any;
  handleChange: any;
  errors: any;
  submitDisabled: boolean;
  isCreate: boolean;
  removal?: () => void;
};

const FutureForm: React.FC<Props> = (props) => {
  return (
    <div>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={12}
          fieldName="title"
          label="Titel"
          placeholder="Titel"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={true}
        />
      </Grid>
      <Grid container spacing={0} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={12}
          fieldName="content"
          label="Text"
          placeholder="Text"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={true}
        />
        <MarkupLegend />
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormTextField
          size={2}
          fieldName="ranking"
          label="Prioritering"
          placeholder="t. ex. 1"
          values={props.values}
          errors={props.errors}
          onChange={props.handleChange}
          multiline={true}
        />
      </Grid>
      <Grid container spacing={2} style={{ marginBottom: '20px' }}>
        <FormToggle size={4} fieldName="show" label="Visa" values={props.values} onChange={props.handleChange} />
      </Grid>

      <Divider className="!mb-5" />

      <Grid container spacing={2} alignItems="center" style={{ marginBottom: '20px' }}>
        <Grid item xs={5} />
        <Grid item xs={2}>
          <FormControl variant="standard" margin="dense" required fullWidth>
            <Button type="submit" variant="contained" disabled={props.submitDisabled}>
              {props.isCreate ? 'Lägg till' : 'Spara'}
            </Button>
          </FormControl>
        </Grid>
        <Grid item xs={3} />
        {!props.isCreate && (
          <Grid item xs={2}>
            <FormControl variant="standard" margin="dense" required fullWidth>
              <Button
                variant="outlined"
                color="secondary"
                onClick={(): void => {
                  if (props.removal) props.removal();
                }}
              >
                Ta Bort
              </Button>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default FutureForm;
