import { useNavigate } from 'react-router-dom';
import React, { ReactElement } from 'react';
import { Formik, FormikHelpers } from 'formik';

import { validationSchema, initialValues } from './residenceGroupSchema';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import ResidenceGroupForm from './components/ResidenceGroupForm';
import { createResidenceGroup } from './residenceGroupApi';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { CancelLink } from '../Common/ButtonLinks';
import { ResidenceGroupInterface } from './types';

const CreateResidenceGroup: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifySuccess, notifyError } = useNotify();

  // functions
  const handleFormSubmit = (values: ResidenceGroupInterface, actions: FormikHelpers<any>): void => {
    createResidenceGroup(values)
      .then(() => notifySuccess('Avtalsparten har skapats'))
      .then(() => navigate('/residence-groups'))
      .catch((error: any) => {
        notifyError(`Det gick inte att skapa avtalsparten: \n${HandleError(error.response.data)}`);
        actions.setSubmitting(false);
      });
  };

  // render
  return (
    <React.Fragment>
      <div className={css(commonStyles.createViewHeader)}>
        <h1 className={css(commonStyles.headerTextStyle)}>Lägg till avtalspart</h1>
        <CancelLink link={'/residence-groups'} />
      </div>

      <div className={css(commonStyles.formContainer)}>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions): void => {
            handleFormSubmit(values, actions);
          }}
        >
          {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, dirty }): ReactElement => (
            <form onSubmit={handleSubmit}>
              <ResidenceGroupForm
                values={values}
                handleChange={handleChange}
                errors={errors}
                isCreate={true}
                submitDisabled={isSubmitting || !isValid || !dirty}
              />
            </form>
          )}
        </Formik>
      </div>
    </React.Fragment>
  );
};

export default CreateResidenceGroup;
