import React, { useContext } from 'react';
import { BaseFilterPopover, FilterSwitch } from '../../Common/FilterPopover';
import { AuthContextValue, ListParams } from '../../Common/types';
import AuthContext from '../../Common/AuthContext';

type Props = {
  setGetListParams: (p: ListParams) => void;
  setSelectedRowItems: (p: []) => void;
  setQuickActionItem: (p: []) => void;
  listParams: ListParams;
};

const FilterPopover: React.FC<Props> = (props) => {
  const { hasPermissions } = useContext(AuthContext) as AuthContextValue;

  return (
    <BaseFilterPopover>
      <FilterSwitch
        fieldName="hide_deleted"
        label="Visa arkiverade nyheter"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setSelectedRowItems={props.setSelectedRowItems}
        setQuickActionItem={props.setQuickActionItem}
      />
      {hasPermissions(['admin', 'operational_leader', 'operational_manager']) && (
        <FilterSwitch
          fieldName="show_others"
          label="Visa endast dina nyheter"
          listParams={props.listParams}
          setGetListParams={props.setGetListParams}
          setSelectedRowItems={props.setSelectedRowItems}
          setQuickActionItem={props.setQuickActionItem}
        />
      )}
    </BaseFilterPopover>
  );
};

export default FilterPopover;
