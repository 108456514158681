import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RotateLoader } from 'react-spinners';
import { Formik, FormikHelpers } from 'formik';

import { getResidenceGroup, updateResidenceGroup, deleteResidenceGroup, synchWithFortnox } from './residenceGroupApi';
import { validationSchema, initialValues } from './residenceGroupSchema';
import EditConfirmationModal from './components/EditConfirmationModal';
import { HandleError } from '../Common/ErrorHandling/ErrorHelper';
import ResidenceGroupForm from './components/ResidenceGroupForm';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import { BackLink } from '../Common/ButtonLinks';
import { ResidenceGroupInterface } from './types';

const EditResidenceGroup: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const { notifySuccess, notifyError, notifyInfo } = useNotify();
  const [oneTimeValues, setOneTimeValues] = useState({} as ResidenceGroupInterface);
  const [residenceGroupState, setResidenceGroupState] = useState<ResidenceGroupInterface>(initialValues);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    getResidenceGroup(id)
      .then(({ data }) => setResidenceGroupState(data.data))
      .catch(() => notifyError('Det gick inte att hämta avtalsparten'))
      .finally(() => setLoading(false));
  }, []); // eslint-disable-line

  // functions
  const handleFormSubmit = (values: ResidenceGroupInterface, actions: FormikHelpers<any>): void => {
    if (values.is_active) {
      handleUpdateResidenceGroup(values, actions.setSubmitting);
    } else {
      setOneTimeValues(values);
      setConfirmDialog(true);
    }
  };

  const handleEditConfirm = (values: ResidenceGroupInterface, setSubmitting: any): void => {
    setConfirmDialog(false);
    handleUpdateResidenceGroup(values, setSubmitting);
  };

  const handleUpdateResidenceGroup = (values: ResidenceGroupInterface, setSubmitting: any): void => {
    setLoading(true);
    notifyInfo('Det här kan ta ett tag om det finns många boenden under avtalsparten');
    updateResidenceGroup(id, values)
      .then(() => notifySuccess('Avtalsparten är sparad'))
      .then(() => navigate('/residence-groups/'))
      .catch((error) => {
        notifyError(`Avtalsparten gick inte att spara : ${HandleError(error.response.data)}`);
        setSubmitting(false);
      })
      .finally(() => setLoading(false));
  };

  const handleDeleteResidenceGroup = (): void => {
    deleteResidenceGroup(parseInt(id)) // TODO: why here we parse to int and elsewhere no?
      .then(() => notifyInfo('Avtalsparten har tagits bort'))
      .then(() => navigate('/residence-groups'))
      .catch(() => notifyError('Det gick inte att ta bort avtalsparten'));
  };

  const navigateToResidence = (id: number): void => {
    navigate(`/residences/${id}`);
  };

  const forceSynchFortnox = (): void => {
    synchWithFortnox(id)
      .then(() => notifySuccess('Boendena har uppdateras'))
      .catch(() => notifyError('Det gick inte uppdatera boendena'));
  };

  // render
  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/residence-groups'} />
            <h1 className={css(commonStyles.headerTextStyle)}>Redigera {residenceGroupState.name}</h1>
          </div>
          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={residenceGroupState}
              validationSchema={validationSchema}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
            >
              {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, setSubmitting }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <ResidenceGroupForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    isCreate={false}
                    navigate={navigateToResidence}
                    removal={handleDeleteResidenceGroup}
                    forceUpdate={forceSynchFortnox}
                    submitDisabled={isSubmitting || !isValid}
                  />
                  <EditConfirmationModal
                    onConfirm={(): void => handleEditConfirm(oneTimeValues, setSubmitting)}
                    onClose={(): void => {
                      setConfirmDialog(false);
                      setSubmitting(false);
                    }}
                    isVisible={confirmDialog}
                  />
                </form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default EditResidenceGroup;
