import React from 'react';
import { BaseFilterPopover } from '../../Common/FilterPopover';
import { FormControlLabel, Switch } from '@mui/material';

type Props = {
  showExported: boolean | undefined;
  setShowExported: (val: boolean) => void;
};

export default function InterviewsFilterPopover({ showExported, setShowExported }: Props) {
  return (
    <BaseFilterPopover>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        Visa exporterade
        <FormControlLabel
          label=""
          labelPlacement="start"
          control={<Switch checked={showExported ?? true} onChange={(_, checked) => setShowExported(checked)} />}
        />
      </div>
    </BaseFilterPopover>
  );
}
