import * as Yup from 'yup';
import { OptionsInterface, sort_order } from '../Common/types';
import { MinimalUser } from '../User/types';

export const initialValues = {
  id: 0,
  name: '',
  operational_manager: undefined as MinimalUser | undefined,
  regional_manager: undefined as MinimalUser | undefined,
  residences: [] as OptionsInterface[],
  deletable: false,
};

export const validationSchema = Yup.object().shape({
  id: Yup.number(),
  name: Yup.string().required('* Namn är ett obligatoriskt fält'),
});

export const paginationDefaults = { page: 1, page_size: 30 };

export const filterSortDefaults = {
  query: '',
  sort_by: 'operational_manager_name',
  sort_order: sort_order.asc,
  filter_operational_manager: null,
};
