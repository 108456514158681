import React, { useState, useEffect } from 'react';
import { RotateLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

import { Table, TableBody, TableCell, Checkbox, Grid, MenuItem } from '@mui/material';

import { formatDate, isEmpty, isNotEmpty, monthTranslator } from '../Common/utilities';
import { MenuTableCell, LoadMoreButton, TableHead, TableRow } from '../Common/Table';
import { deleteInvoice, getInvoiceList, sendToFortnox } from './SavedInvoicesApi';
import { filterSortDefaults } from './SavedInvoicesSchema';
import { paginationDefaults } from '../Common/commonSchema';
import { ListParams, ListPagination } from '../Common/types';
import { commonStyles, css } from '../Common/styling';
import { useNotify } from '../Common/snackbarHooks';
import EmptyList from '../Common/Table/EmptyList';
import { SearchBar } from '../Common/SearchBar/';
import { UpdateFab } from './components/UpdateFab';
import { InvoiceInterface } from './types';

const SavedInvoices: React.FC = () => {
  // hooks
  const navigate = useNavigate();
  const { notifyError, notifySuccess, notifyInfo } = useNotify();
  const [selectedInvoices, setSelectedInvoices] = useState([] as InvoiceInterface[]);
  const [pagination, setPagination] = useState(paginationDefaults as ListPagination);
  const [listParams, setGetListParams] = useState(filterSortDefaults as ListParams);
  const [invoiceList, setInvoiceList] = useState([] as InvoiceInterface[]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setPagination(paginationDefaults);
    fetchSavedInvoicesList();
  }, [listParams]); // eslint-disable-line

  // functions
  const fetchSavedInvoicesList = (): void => {
    setLoading(true);
    getInvoiceList({ ...listParams, ...paginationDefaults })
      .then(({ data }) => setInvoiceList(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan av fakturor'))
      .finally(() => setLoading(false));
  };

  const handleRemove = (id?: number): void => {
    if (id)
      deleteInvoice([id])
        .then(() => notifyInfo('Fakturan har tagits bort'))
        .then(() => fetchSavedInvoicesList())
        .catch(() => notifyError('Det gick inte att ta bort fakturan'));
    else
      deleteInvoice(selectedInvoices.map((e: any) => e.id))
        .then(() => notifyInfo('Fakturorna har tagits bort'))
        .then(() => setSelectedInvoices([]))
        .then(() => fetchSavedInvoicesList())
        .catch(() => notifyError('Det gick inte att ta bort fakturorna'));
  };

  const clickCheckbox = (event: any, row: InvoiceInterface): void => {
    event.stopPropagation();
    if (event.target.checked) {
      setSelectedInvoices([...selectedInvoices, row]);
    } else {
      const newSelected = selectedInvoices.filter((agreement: any) => agreement.id !== row.id);
      setSelectedInvoices(newSelected);
    }
  };

  const selectAll = (e: any): void => {
    if (e.target.checked) {
      const invoicableInvoices = invoiceList.filter((invoice) => invoice.deletable);
      setSelectedInvoices(invoicableInvoices);
    } else setSelectedInvoices([]);
  };

  const isChecked = (id: number | undefined): boolean => {
    return selectedInvoices.some((element) => element.id === id);
  };

  const sendInvoicesFortNox = (id?: number): void => {
    setLoading(true);
    if (id)
      sendToFortnox([id])
        .then(() => notifyInfo('Nu kommer fakturan skickas till Fortnox. Du får ett mejl så snart det är klart.'))
        .then(() => setSelectedInvoices([]))
        .then(() => fetchSavedInvoicesList())
        .catch(() => notifyError('Det gick inte att skicka fakturan till FortNox'))
        .finally(() => setLoading(false));
    else {
      sendToFortnox(selectedInvoices.map((e: any) => e.id))
        .then(() => notifyInfo('Nu kommer fakturorna skickas till Fortnox. Du får ett mejl så snart det är klart.'))
        .then(() => setSelectedInvoices([]))
        .then(() => fetchSavedInvoicesList())
        .catch((e) => notifyError('Det gick inte att skicka fakturorna till FortNox'))
        .finally(() => setLoading(false));
    }
  };

  // render
  const rows = [
    { id: 'residence_name', label: 'Boende' },
    { id: 'residence_group_name', label: 'Avtalspart' },
    { id: 'type', label: 'Faktueringsrutin' },
    { id: 'startup_fee', numeric: true, label: 'Uppstart (kr)' },
    { id: 'start_date', label: 'Månad' },
    { id: 'total_value', numeric: true, label: 'Summa (kr exkl. moms)' },
    { id: 'created_at', label: 'Skapad' },
    { id: 'quick_action', label: '', notSortable: true },
  ];

  interface TypeObject {
    ContinuousAgreement: string;
    FixedAgreement: string;
    [key: string]: string;
  }
  const type: TypeObject = {
    ContinuousAgreement: 'Löpande',
    FixedAgreement: 'Anpassad',
  };

  return (
    <React.Fragment>
      <div className={css(commonStyles.listViewWrapper)}>
        <Grid container className={css(commonStyles.headlineWrapper, commonStyles.greyRow)}>
          <Grid item xs={10} sm={10} md={10} lg={10}>
            <h1 className={css(commonStyles.headerTextStyle)}>Skapade fakturor</h1>
          </Grid>
        </Grid>
        <Grid container className={css(commonStyles.searchBarWrapper)}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <SearchBar setGetListParams={setGetListParams} listParams={listParams} />
          </Grid>
        </Grid>

        <Table classes={{ root: css(commonStyles.tableWrapper) }}>
          <TableHead
            rows={rows}
            listParams={listParams}
            setGetListParams={setGetListParams}
            checkboxComponent={
              <Checkbox
                checked={
                  isNotEmpty(invoiceList) &&
                  invoiceList.filter((invoice) => invoice.deletable).length === selectedInvoices.length
                }
                onClick={(e): void => selectAll(e)}
              />
            }
          />

          <TableBody>
            {isEmpty(invoiceList) ? (
              <EmptyList />
            ) : (
              invoiceList.map((row: InvoiceInterface, idx: number) => (
                <TableRow row={row} idx={idx} key={idx} onClick={(): void => navigate(`/saved-invoices/${row.id}`)}>
                  <TableCell padding="checkbox">
                    {row.deletable && (
                      <Checkbox onClick={(e): void => clickCheckbox(e, row)} checked={isChecked(row.id)} />
                    )}
                  </TableCell>
                  <TableCell>
                    <b>{row.residence_name}</b>
                  </TableCell>
                  <TableCell align="left">
                    <p>{row.residence_group_name}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p>{type[row.agreement_type]}</p>
                  </TableCell>
                  <TableCell align="right">
                    <p>{row.startup_fee}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p>{monthTranslator(row.start_date.substring(5, 7)) + ' ' + row.start_date.substring(0, 4)}</p>
                  </TableCell>
                  <TableCell align="right">
                    <p>{row.total_value}</p>
                  </TableCell>
                  <TableCell align="left">
                    <p>{formatDate(row.created_at)}</p>
                  </TableCell>
                  <MenuTableCell>
                    <MenuItem onClick={(): void => handleRemove(row.id)}>Ta bort skapad faktura</MenuItem>
                  </MenuTableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>

        <LoadMoreButton
          loadList={getInvoiceList}
          state={invoiceList}
          setState={setInvoiceList}
          params={listParams}
          pagination={pagination}
          setPagination={setPagination}
        />

        {isNotEmpty(invoiceList) && isNotEmpty(selectedInvoices) && (
          <UpdateFab delete={handleRemove} send={sendInvoicesFortNox} />
        )}
        {loading && (
          <div className={css(commonStyles.spinner)}>
            <RotateLoader loading={loading} />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SavedInvoices;
