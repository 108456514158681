import { CircularProgress } from '@mui/material';
import React from 'react';
import { twMerge } from 'tailwind-merge';

export default function ResidencePageLoading({ className }: { className?: string }) {
  return (
    <div className={twMerge('flex w-full items-center justify-center', className)}>
      <CircularProgress />
    </div>
  );
}
