import React, { ReactElement } from 'react';
import { Fab } from '@mui/material';
import { css } from '../styling';

type Props = {
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  disabled?: boolean | undefined;
  text: string | ReactElement<any, any>;
  icon: ReactElement<any, any>;
  styles: any;
  label: string;
};

const QuickAction: React.FC<Props> = (props) => {
  return (
    <Fab
      variant="extended"
      color="inherit"
      aria-label={props.label}
      className={css(props.styles)}
      onClick={props.onClick}
      disabled={props.disabled !== undefined ? props.disabled : false}
    >
      {props.icon}
      {props.text}
    </Fab>
  );
};

export default QuickAction;
