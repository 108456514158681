import React from 'react';

import { theme } from '../Common/styling';
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';

type Props = {
  text?: string;
  hideVideo?: boolean;
};

const MarkupLegend: React.FC<Props> = (props) => {
  return (
    <Accordion
      sx={{
        background: theme.greyLight,
        boxShadow: '0 1px 1px 1px rgba(228, 233, 237, 1)',
        borderRadius: 0,
        border: 0,
        color: 'grey',
      }}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography style={{ fontSize: 'small' }}>
          <b>{props.text || 'Du kan formattera texten ovanför med märkspråk'}</b>
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography style={{ fontSize: 'small' }} component="div">
          ***<b>Fet text</b>***
          <p>
            :::<i>Kursiv text</i>:::
          </p>
          <p>
            !!!<span style={{ fontSize: 'x-large' }}>Stor text</span>!!!
          </p>
          <p>
            ___<span style={{ color: '#d91d1c' }}>Viktig text</span>___
          </p>
          <p>
            ***:::!!!___
            <b>
              <i>
                <span style={{ fontSize: 'x-large', color: '#d91d1c' }}>Kombinerade</span>
              </i>
            </b>
            ___!!!:::***
          </p>
          <p>
            +++
            <a href="$1" target="_blank">
              http://ungintra.ungomsorg.se/files/utbildning.pdf
            </a>
            +++ Klickbar länk
          </p>
          <p>===http://www.example.com/image.png=== Infogar en bild i texten</p>
          {!props.hideVideo && (
            <>
              <p>[[[https://www.youtube.com/watch?v=QH2-TGUlwu4]]] Infogar en youtubevideo i texten</p>
              <p>[[[https://www.youtube.com/embed/QH2-TGUlwu4]]] Infogar en youtubevideo i texten</p>
              <p>[[[https://youtu.be/QH2-TGUlwu4]]] Infogar en youtubevideo i texten</p>
            </>
          )}
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default MarkupLegend;
