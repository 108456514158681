import { Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import logo from '../Common/logo.png';
import ResidencePageCard from './components/ResidencePageCard';
import ResidenceReported from './components/ResidenceReported';
import { removeLocalStorageItem } from '../Common/localStorageUtils';
import ResidenceInfo from './components/ResidenceInfo';
import { getResidence, getScheduledWorkShifts } from './residencePageApi';
import { Residence, ResidenceScheduledWorkShift } from './types';
import { RotateLoader } from 'react-spinners';
import ResidenceScheduledWorkShifts from './components/ResidenceScheduledWorkShifts';
import { cookieManager } from '../Common/utilities';
import { removeResidenceLoginCookie } from './residenceLoginCookie';

type Props = {
  setAuthenticated: (val: boolean) => void;
};

export default function ResidencePage({ setAuthenticated }: Props) {
  const [residence, setResidence] = useState<Residence>();
  const [scheduledWorkShifts, setScheduledWorkShifts] = useState<ResidenceScheduledWorkShift[]>();

  useEffect(() => {
    getResidence().then(({ data }) => setResidence(data.data));
    getScheduledWorkShifts().then(({ data }) => setScheduledWorkShifts(data.data));
  }, []);

  function signOut() {
    removeResidenceLoginCookie();
    setAuthenticated(false);
  }

  return (
    <main className="bg-subtleGreen min-h-dvh w-full pt-8 px-8 pb-10">
      {residence ? (
        <>
          <div className="relative w-full mb-6">
            <div className="absolute inset-0 flex items-center justify-start sm:justify-center">
              <img src={logo} alt="Logo" width="200px" />
            </div>
            <div className="flex items-center justify-end">
              <Button variant="outlined" color="secondary" onClick={signOut}>
                Logga ut
              </Button>
            </div>
          </div>

          <div className="max-w-6xl mx-auto">
            <ResidenceInfo residence={residence} />
            <ResidenceReported residence={residence} />
            <ResidenceScheduledWorkShifts residence={residence} workShifts={scheduledWorkShifts} />
          </div>
        </>
      ) : (
        <div className="fixed top-1/2 left-1/2">
          <RotateLoader />
        </div>
      )}
    </main>
  );
}
