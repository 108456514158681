import React from 'react';
import { TextField, Tabs, Tab, Typography, Box } from '@mui/material';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

type Props = {
  emails: string;
  mobiles: string;
  statistics: string;
};

const InfoTabs: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          // TODO: MUI V5 add
          // textColor="primary"
          // indicatorColor="primary"

          // TODO: MUI V5 remove
          sx={{ '& .MuiTabs-indicator': { 'background-color': '#ad2220' } }}
          variant="fullWidth"
          value={value}
          onChange={handleChange}
        >
          <Tab
            // TODO: MUI V5 remove
            sx={{ '&.Mui-selected': { color: '#ad2220' } }}
            label="E-POST"
            {...a11yProps(0)}
          />
          <Tab
            // TODO: MUI V5 remove
            sx={{ '&.Mui-selected': { color: '#ad2220' } }}
            label="MOBILTELEFON"
            {...a11yProps(1)}
          />
          <Tab
            // TODO: MUI V5 remove
            sx={{ '&.Mui-selected': { color: '#ad2220' } }}
            label="STATISTIK"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <TextField
          fullWidth
          autoFocus={true}
          disabled={false}
          name={'emails'}
          label={''}
          multiline={true}
          rows={14}
          placeholder={''}
          value={props.emails}
          variant="outlined"
          onFocus={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            event.target.select();
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TextField
          fullWidth
          autoFocus={true}
          disabled={false}
          name={'mobiles'}
          label={''}
          multiline={true}
          rows={14}
          placeholder={''}
          value={props.mobiles}
          variant="outlined"
          onFocus={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            event.target.select();
          }}
        />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <TextField
          fullWidth
          autoFocus={true}
          disabled={false}
          name={'statistics'}
          label={''}
          multiline={true}
          rows={14}
          placeholder={''}
          value={props.statistics}
          variant="outlined"
          onFocus={(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => {
            event.target.select();
          }}
        />
      </TabPanel>
    </Box>
  );
};

export default InfoTabs;
