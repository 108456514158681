import React, { useEffect, useState } from 'react';
import { RotateLoader } from 'react-spinners';

import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { UserListInterface } from '../types';
import { sendResetPasswordEmail } from '../usersApi';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';

type Props = {
  user: UserListInterface | undefined;
  setModalState: (modalState: boolean) => void;
};

const SendResetPasswordEmailModal: React.FC<Props> = (props) => {
  const { notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (): void => {
    setLoading(true);
    if (props.user) {
      sendResetPasswordEmail(props.user.id)
        .then(() => {
          notifySuccess('Nytt lösenord skickat!');
        })
        .catch(() => notifyError('Det gick inte att skicka det nya lösenordet.'))
        .finally(() => {
          setLoading(false);
          props.setModalState(false);
        });
    }
  };

  return (
    <div>
      <DialogTitle>Skicka nytt lösenord</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              <p>Är du säker på att du vill skicka nytt lösenord för {props.user?.name}?</p>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              color="error"
              onClick={(): void => {
                props.setModalState(false);
              }}
            >
              NEJ
            </Button>
            <Button
              fullWidth
              color="primary"
              onClick={(): void => {
                handleSubmit();
              }}
            >
              JA
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </div>
  );
};

export default SendResetPasswordEmailModal;
