import { AxiosResponse } from 'axios';

import { ListParams } from '../Common/types';
import { NewsInterface } from './types';
import API from '../Common/axios';

export const getNewsList = (params: ListParams): Promise<AxiosResponse> => {
  return API.get('v1/news', { params });
};

export const createNews = (
  news: Omit<NewsInterface, 'id' | 'residences' | 'roles'> & { residence_ids: number[]; role_ids: number[] }
): Promise<AxiosResponse> => {
  return API.post('v1/news', { news });
};

export const updateNews = (
  id: number | undefined,
  news: Omit<NewsInterface, 'residences' | 'roles'> & { residence_ids: number[]; role_ids: number[] }
): Promise<AxiosResponse> => {
  return API.patch(`v1/news/${id}`, { news });
};

export const updateImportantNews = (id: number | undefined, news: { important: boolean }): Promise<AxiosResponse> => {
  return API.patch(`v1/news/${id}`, { news });
};

export const getNews = (id: string): Promise<AxiosResponse> => {
  return API.get(`v1/news/${id}`);
};

export const deleteNews = (id: number | undefined): Promise<AxiosResponse> => {
  return API.delete(`v1/news/${id}`);
};

export const batchDeleteNews = (ids: (number | undefined)[]): Promise<AxiosResponse> => {
  return API.patch(`v1/news/batch_destroy`, { news_ids: ids });
};

export const batchRestoreNews = (ids: (number | undefined)[]): Promise<AxiosResponse> => {
  return API.patch(`v1/news/batch_restore`, { news_ids: ids });
};
