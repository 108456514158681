import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { useFormik } from 'formik';
import { MinimalTeam } from '../types';
import { getTeamExportData, getTeamMinimalList } from '../teamApi';
import FormAutocompleteBox from '../../Common/FormItems/FormAutocompleteBox';
import { PersonInterface } from '../../Common/types';
import dayjs from 'dayjs';
import { arrayify, downloadFile } from '../../Common/utilities';
import * as Yup from 'yup';
import { theme } from '../../Common/styling';

const INITIAL_VALUES = {
  name: true,
  email: true,
  mobile: true,
  teams: [] as MinimalTeam[],
};

type ExportModalProps = {
  isOpen: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  filterOperationalManager?: string;
  filterResidenceGroup?: number;
  filterRegion?: number;
};

function personRow(row: string[], values: typeof INITIAL_VALUES, emails: Set<string>, person: PersonInterface) {
  if (values.name) row.push(!person?.name ? '' : `${person.name}${emails.has(person?.email) ? ' (DUBBLETT)' : ''}`);
  if (values.email) row.push((emails.has(person?.email) ? '' : person?.email) || '');
  if (values.mobile) row.push((emails.has(person?.email) ? '' : person?.mobile) || '');
  if (person?.email) emails.add(person.email);
}

export default function ExportModal({
  isOpen,
  setIsOpen,
  filterOperationalManager,
  filterResidenceGroup,
  filterRegion,
}: ExportModalProps) {
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [loadingChoosen, setLoadingChoosen] = useState(true);
  const [teams, setTeams] = useState<MinimalTeam[]>();

  const f = useFormik({
    initialValues: INITIAL_VALUES,
    onSubmit: handleExport,
    validationSchema: Yup.object({
      teams: Yup.array().min(1, 'Måste välja minst ett team'),
    }),
  });

  useEffect(() => {
    if (!isOpen || teams) return;

    getTeamMinimalList({ is_active: true })
      .then(({ data }) => {
        setTeams(data.data);
      })
      .finally(() => setLoadingTeams(false));
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;
    if (!filterOperationalManager && !filterResidenceGroup && !filterRegion) {
      f.setFieldValue('teams', []);
      setLoadingChoosen(false);
      return;
    }

    setLoadingChoosen(true);
    getTeamMinimalList({
      filter_operational_manager: filterOperationalManager,
      filter_residence_group: filterResidenceGroup,
      filter_region: filterRegion,
      is_active: true,
    })
      .then(({ data }) => {
        f.setFieldValue('teams', data.data);
      })
      .finally(() => setLoadingChoosen(false));
  }, [isOpen, filterOperationalManager, filterResidenceGroup, filterRegion]);

  async function handleExport(values: typeof INITIAL_VALUES) {
    const exportData = await getTeamExportData(values.teams).then(({ data }) =>
      data.data.map(({ people_attributes, ...team }) => {
        const ambassadors: PersonInterface[] = [];
        const residenceManagers: PersonInterface[] = [];
        people_attributes.forEach((person) => {
          if (person.person_type === 'ambassador') ambassadors.push(person);
          if (person.person_type === 'residence_manager') residenceManagers.push(person);
        });
        return { ...team, ambassadors, residenceManagers };
      })
    );

    const maxResidenceManagers = Math.max(...exportData.map((t) => t.residenceManagers.length));
    const maxAmbassadors = Math.max(...exportData.map((t) => t.ambassadors.length));

    const csvValues = [['Boende']];
    for (let i = 0; i < maxResidenceManagers; i++) {
      if (values.name) csvValues[0].push('VC Namn');
      if (values.email) csvValues[0].push('VC Mejl');
      if (values.mobile) csvValues[0].push('VC Tele');
    }
    for (let i = 0; i < maxAmbassadors; i++) {
      if (values.name) csvValues[0].push('UOA Namn');
      if (values.email) csvValues[0].push('UOA Mejl');
      if (values.mobile) csvValues[0].push('UOA Tele');
    }

    const emails = new Set<string>();
    exportData.forEach((team) => {
      const row = [team.name];
      for (let i = 0; i < maxResidenceManagers; i++) {
        personRow(row, values, emails, team.residenceManagers[i]);
      }
      for (let i = 0; i < maxAmbassadors; i++) {
        personRow(row, values, emails, team.ambassadors[i]);
      }
      csvValues.push(row);
    });

    const blob = new Blob([csvValues.map((row) => row.join(',')).join('\n')], { type: 'text/csv' });
    downloadFile(blob, `Export ${values.teams.length} team ${dayjs().format('YYYY-MM-DD')}.csv`);
  }

  const handleClose = () => setIsOpen?.(false);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm">
      <DialogTitle>Datadump för personuppgifter</DialogTitle>
      <DialogContent>
        <form onSubmit={f.handleSubmit} className="pt-2">
          <div className="flex gap-2 items-start">
            <FormControl className="grow">
              <FormAutocompleteBox
                type="multiple"
                label="Team för export"
                options={teams ?? []}
                values={f.values.teams ?? []}
                onChange={(_, values) => f.setFieldValue('teams', arrayify(values))}
                renderTags="simple"
                loading={loadingTeams || loadingChoosen}
                error={(f.errors.teams as string) ?? null}
              />
            </FormControl>
            <Button variant="outlined" onClick={teams && (() => f.setFieldValue('teams', teams))} className="h-[53px]">
              Välj alla
            </Button>
          </div>

          <DialogContentText>
            Vilka personuppgifter vill du exportera för VC och UOA på de valda teamen?
          </DialogContentText>
          <div className="flex flex-col px-2">
            <FormControlLabel
              control={
                <Checkbox
                  name="name"
                  // Change with MUI5 update
                  style={f.values.name ? { color: theme.green, borderColor: theme.green } : undefined}
                  // color="secondary"
                  checked={f.values.name}
                  onChange={f.handleChange}
                />
              }
              label="Namn"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="email"
                  // Change with MUI5 update
                  style={f.values.email ? { color: theme.green, borderColor: theme.green } : undefined}
                  // color="secondary"
                  checked={f.values.email}
                  onChange={f.handleChange}
                />
              }
              label="Mejladress"
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="mobile"
                  // Change with MUI5 update
                  style={f.values.mobile ? { color: theme.green, borderColor: theme.green } : undefined}
                  // color="secondary"
                  checked={f.values.mobile}
                  onChange={f.handleChange}
                />
              }
              label="Telefonnummer"
            />
          </div>

          <div className="w-full grid grid-cols-2 gap-4 mt-3">
            <Button
              type="button"
              variant="contained"
              onClick={handleClose}
              // Change with MUI5 update
              color="error"
              // color="primary"
            >
              Avbryt
            </Button>
            <Button
              type="submit"
              variant="contained"
              // Change with MUI5 update
              color="success"
              // color="secondary"
            >
              {!f.isSubmitting ? 'Exportera datadump' : <CircularProgress size="1em" color="inherit" />}
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
}
