import React, { useEffect, useState, useMemo, useContext, useCallback, useRef } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Button, CircularProgress } from '@mui/material';
import { getTeamMinimalList, getTeamSchedulePdf } from '../Team/teamApi';
import { useNotify } from '../Common/snackbarHooks';
import { FileDownloadOutlined, Search } from '@mui/icons-material';
import { FilterAutocompleteBox } from '../Common/FilterPopover';
import { MinimalTeam } from '../Team/types';
import FilterDatePicker from '../Common/FilterPopover/FilterDatePicker';
import dayjs from 'dayjs';
import BouncingDots from '../Common/BouncingDots';
import AuthContext from '../Common/AuthContext';
import { AuthContextValue } from '../Common/types';
import { BackLink } from '../Common/ButtonLinks';
import { decode } from 'base-64';
import { downloadFile, stringifySearchParams, useQuery } from '../Common/utilities';
import ScheduleFilterPopover from './components/FilterPopover';

const ScheduleOutline: React.FC = () => {
  const navigate = useNavigate();
  const { notifyError } = useNotify();
  const { profile } = useContext(AuthContext) as AuthContextValue;
  const [teams, setTeams] = useState<MinimalTeam[]>([]);
  const [loadingTeams, setLoadingTeams] = useState(true);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const { teamId } = useParams();
  const teamPickerRef = useRef<HTMLDivElement>(null);
  const [teamPickerOpen, setTeamPickerOpen] = useState(false);

  const team = useMemo(() => {
    if (!teamId || !teams) return undefined;
    return teams.find((team) => team.id === Number(teamId));
  }, [teams, teamId]);

  const [query, setQuery] = useQuery(
    {
      month: (val) => (dayjs(val, 'YYYY-MM').isValid() ? dayjs(val, 'YYYY-MM') : undefined),
      from: (val) => val,
      showInactive: (val) => (val === 'true' ? true : val === 'false' ? false : undefined),
      firstMonth: (val) => (dayjs(val, 'YYYY-MM').isValid() ? dayjs(val, 'YYYY-MM') : undefined),
      lastMonth: (val) => (dayjs(val, 'YYYY-MM').isValid() ? dayjs(val, 'YYYY-MM') : undefined),
    },
    { backConverter: { month: (val) => val?.format('YYYY-MM') } }
  );

  useEffect(() => {
    setLoadingTeams(true);

    getTeamMinimalList({
      filter_regional_manager: profile.role.short_name === 'RC' ? profile.id : undefined,
    })
      .then(({ data }) => setTeams(data.data))
      .catch(() => notifyError('Det gick inte att hämta listan över teams'))
      .finally(() => setLoadingTeams(false));
  }, []);

  const focusTeamPicker = useCallback(() => {
    teamPickerRef.current?.focus();
    setTeamPickerOpen(true);
  }, []);

  const downloadPdf = useCallback(() => {
    if (!team) return;

    setLoadingPdf(true);
    getTeamSchedulePdf(team.id)
      .then(({ data }) => {
        const blob = new Blob([data], { type: 'application/pdf' });
        downloadFile(blob, `Schema ${team.name} ${dayjs().startOf('month').format('YYYY-MM-DD')}.pdf`);
      })
      .finally(() => {
        setLoadingPdf(false);
      });
  }, [team]);

  return (
    <div className="min-h-full bg-gray-100">
      <div className="h-16 p-4 flex items-center">
        {query.from && <BackLink link={decode(query.from)} />}
        <h1 className="text-2xl font-semibold">
          Schema
          {teamId && (
            <>
              {' för '}
              {team ? team.name : <BouncingDots />}
            </>
          )}
        </h1>
        {team && <p className="text-2xl font-semibold text-gray-600 ml-2">(std {team.standard_attendance})</p>}
      </div>
      <div className="bg-white flex justify-between h-13 shadow-std">
        <FilterAutocompleteBox
          ref={teamPickerRef}
          loading={loadingTeams}
          options={query.showInactive ? teams : teams.filter(({ is_active }) => is_active)}
          value={team ?? null}
          groupBy={(option) => (option.is_active ? 'Aktiva team' : 'Inaktiva team')}
          onChange={(_, option) => {
            if (!Array.isArray(option)) {
              if (option?.id === team?.id) return;
              if (!option) return;
              const queryString = stringifySearchParams(
                {
                  month: query.month?.format('YYYY-MM'),
                  from: query.from ?? undefined,
                  showInactive: query.showInactive ? query.showInactive.toString() : undefined,
                  firstMonth: query.firstMonth?.format('YYYY-MM'),
                  lastMonth: query.lastMonth?.format('YYYY-MM'),
                },
                true
              );
              navigate(`/schedule/${option.id}${queryString}`);
            }
          }}
          placeholder="Välj team"
          getOptionLabel={(team) => team.name}
          isOpen={teamPickerOpen}
          setIsOpen={setTeamPickerOpen}
          startAdornment={
            <span className="pr-1">
              <Search />
            </span>
          }
          className="w-80"
        />
        <div className="flex">
          <FilterDatePicker
            placeholder="Välj datum"
            value={query.month ?? null}
            onChange={(date) => setQuery({ month: date?.format('YYYY-MM') ?? undefined })}
            views={['month', 'year']}
            actions={['clear']}
            className="w-48"
          />
          <Button
            variant="text"
            className="!rounded-none"
            disabled={!team}
            endIcon={loadingPdf ? <CircularProgress size="20px" /> : <FileDownloadOutlined />}
            onClick={downloadPdf}
          >
            Ladda ner
          </Button>
          <div className="flex">
            <ScheduleFilterPopover
              showInactive={query.showInactive}
              setShowInactive={(val) => setQuery({ showInactive: val })}
            />
          </div>
        </div>
      </div>

      <Outlet context={focusTeamPicker} />
    </div>
  );
};

export default ScheduleOutline;
