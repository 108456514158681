export enum EducationType {
  Participant = 'Deltagare',
  TeamLeader = 'Teamledare',
}

export interface EducationInterface {
  id: number;
  name: string;
  education_pages?: Array<EducationPageInterface>;
  education_type: string;
}

export interface EducationPageInterface {
  id?: number;
  title: string;
  subtitle: string;
  text: string;
  youtube_url: string;
  estimated_time: number;
  education_questions?: Array<EducationQuestionInterface>;
  education_id?: number;
  rank?: number;
}

export interface EducationQuestionInterface {
  id?: number;
  text: string;
  education_answers?: Array<EducationAnswerInterface>;
  education_page_id?: number;
  rank?: number;
}

export interface EducationAnswerInterface {
  id?: number;
  text: string;
  correct: boolean;
  education_question_id?: number;
  rank?: number;
}
