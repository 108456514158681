import React, { useState, useEffect, ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, FormikHelpers } from 'formik';
import { RotateLoader } from 'react-spinners';

import { updateEducation, getEducation } from './EducationApi';
import { HandleError } from '../../Common/ErrorHandling/ErrorHelper';
import { commonStyles, css } from '../../Common/styling';
import { useNotify } from '../../Common/snackbarHooks';
import EducationForm from './components/EducationForm';
import { BackLink } from '../../Common/ButtonLinks';
import { EducationInterface, EducationType } from './types';
import EducationPages from './components/EducationPages';
import { educationSchema } from './educationSchema';

const initialEducationValues = {
  name: '',
  id: 0,
  education_type: EducationType.Participant,
};

const EditEducation: React.FC = () => {
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const { id } = useParams() as { id: string };
  const [loading, setLoading] = useState(true);
  const [education, setEducation] = useState<EducationInterface>(initialEducationValues);

  const loadEducation = (): void => {
    setLoading(true);

    getEducation(parseInt(id))
      .then(({ data }) => {
        setEducation(data.education);
      })
      .catch(() => notifyError('Det gick inte att hämta utbildningen'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadEducation();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleFormSubmit = (values: EducationInterface, actions: FormikHelpers<any>): void => {
    handleUpdateEducation(values, actions.setSubmitting);
  };

  const handleUpdateEducation = (values: EducationInterface, setSubmitting: any): void => {
    updateEducation(education.id, values)
      .then(() => notifySuccess('Utbildningen är uppdaterad'))
      .then(() => navigate('/education'))
      .catch((error) => {
        notifyError(`Det gick inte att uppdatera utbildningen : ${HandleError(error.response.data)}`);
        setSubmitting(false);
      });
  };

  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/education'} />
            <h1 className={css(commonStyles.headerTextStyle)}>Redigera utbildning: &quot;{education.name}&quot;</h1>
          </div>

          <div className={css(commonStyles.formContainer)}>
            <Formik
              enableReinitialize
              validateOnMount={true}
              initialValues={education}
              onSubmit={(values, actions): void => {
                handleFormSubmit(values, actions);
              }}
              validationSchema={educationSchema}
            >
              {({ values, errors, handleChange, handleSubmit, isSubmitting, isValid, setSubmitting }): ReactElement => (
                <form onSubmit={handleSubmit}>
                  <EducationForm
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    submitDisabled={isSubmitting || !isValid}
                  />
                </form>
              )}
            </Formik>
          </div>
          <EducationPages educationPages={education.education_pages || []} educationId={parseInt(id)} />
        </div>
      )}
    </React.Fragment>
  );
};

export default EditEducation;
