import * as Yup from 'yup';

const lintDate = (e: Date): string => {
  const month = e.getMonth() < 10 ? 0 + '' + (e.getMonth() + 1) : e.getMonth() + 1;
  const day = e.getDate() < 10 ? 0 + '' + e.getDate() : e.getDate();
  return e.getFullYear() + '-' + month + '-' + day;
};
export const initialValues = {
  end_date: lintDate(new Date()),
};

export const validationSchema = Yup.object().shape({
  end_date: Yup.date()
    .nullable()
    .default(null)
    .required('* Slutdatum är ett obligatoriskt fält')
    .typeError('* Ogiltigt datum'),
});
