import { FieldArray } from 'formik';
import React, { ReactElement, Dispatch, SetStateAction } from 'react';

import ClearIcon from '@mui/icons-material/Clear';

import { Button, Divider, FormControl, Grid } from '@mui/material';

import { FormHourMinutePicker, FormSelect } from '../../Common/FormItems';
import { ScheduledParticipantInterface } from '../types';
import { commonStyles, css } from '../../Common/styling';
import { OptionsInterface } from '../../Common/types';
import { TeamInterface } from '../../Team/types';
import { MinimalWorkShiftUserInterface } from '../../User/types';

interface Props {
  values: any;
  handleChange: any;
  errors: any;
  isCreate: boolean;
  setFieldValue: any;
  team: TeamInterface;
  salaryClassesMinimalList: OptionsInterface[];
  participants: MinimalWorkShiftUserInterface[];
  substitutes: MinimalWorkShiftUserInterface[];
  tempTeamLeaders: MinimalWorkShiftUserInterface[];
  setParticipants: Dispatch<SetStateAction<MinimalWorkShiftUserInterface[]>>;
  setSubstitutes: Dispatch<SetStateAction<MinimalWorkShiftUserInterface[]>>;
  setTempTeamLeaders: Dispatch<SetStateAction<MinimalWorkShiftUserInterface[]>>;
  disabled: boolean;
}

export const PastUsersWorkShifts: React.FC<Props> = (props: Props) => {
  const salaryClassOptions = (role: string): OptionsInterface[] => {
    return props.salaryClassesMinimalList.map((salaryClass: OptionsInterface) => {
      return {
        id: salaryClass.id,
        name: salaryClass.name,
        disabled: salaryClass.name === 'RC' && ['D', 'TL'].includes(role) ? true : false,
      };
    });
  };

  return (
    <React.Fragment>
      {props.values.scheduled_participants.filter(
        (userWorkShift: ScheduledParticipantInterface) => userWorkShift._destroy === 0
      ).length > 0 && (
        <React.Fragment>
          <FieldArray
            name="scheduled_participants"
            render={(): ReactElement => (
              <React.Fragment>
                {props.values.scheduled_participants.filter(
                  (userWorkShift: ScheduledParticipantInterface) =>
                    userWorkShift.work_shift_type === 'participant' && userWorkShift._destroy === 0
                ).length > 0 && (
                  <React.Fragment>
                    <h1 className={css(commonStyles.headerTextStyle)}>Schemalagda</h1>
                    <div style={{ marginTop: '20px' }}>
                      {props.disabled ? (
                        <React.Fragment>
                          {props.values.scheduled_participants
                            .sort((x: ScheduledParticipantInterface, y: ScheduledParticipantInterface) =>
                              x.confirmed === y.confirmed ? 0 : x.confirmed ? -1 : 1
                            )
                            .map((userWorkShift: ScheduledParticipantInterface, index: number) => (
                              <React.Fragment key={index}>
                                {userWorkShift.work_shift_type === 'participant' && userWorkShift._destroy === 0 && (
                                  <Grid
                                    container
                                    spacing={2}
                                    style={userWorkShift._destroy !== 0 ? { display: 'none' } : {}}
                                  >
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      style={userWorkShift.confirmed ? { marginTop: '17px' } : {}}
                                    >
                                      {userWorkShift.user.name}{' '}
                                      {!userWorkShift.confirmed && (
                                        <span style={{ color: 'red' }}>- Ogiltig frånvaro</span>
                                      )}
                                    </Grid>
                                    {userWorkShift.confirmed && (
                                      <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                        <FormHourMinutePicker
                                          fieldName={`scheduled_participants.${index}.hours`}
                                          label="Arbetade timmar"
                                          values={props.values}
                                          defaultValue={props.team.standard_workday}
                                          errors={props.errors}
                                          onChange={props.handleChange}
                                          hours={10}
                                          disabled={userWorkShift.confirmed}
                                          style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                        />
                                      </Grid>
                                    )}
                                    {userWorkShift.confirmed && (
                                      <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                        <FormSelect
                                          size={12}
                                          fieldName={`scheduled_participants.${index}.salary_class_id`}
                                          label="Löneart"
                                          values={props.values}
                                          errors={props.errors}
                                          onChange={props.handleChange}
                                          options={salaryClassOptions(userWorkShift.user.role)}
                                          disabled={userWorkShift.confirmed}
                                          style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                        />
                                      </Grid>
                                    )}
                                    {userWorkShift.confirmed && (
                                      <Grid item xs={2} sm={2} md={2} lg={2} style={{ paddingTop: '7px' }}>
                                        <FormControl fullWidth margin="normal">
                                          <Button
                                            color="primary"
                                            variant={userWorkShift.confirmed ? 'contained' : 'outlined'}
                                            style={{ marginRight: '10px' }}
                                            disabled={true}
                                          >
                                            OK
                                          </Button>
                                        </FormControl>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                              </React.Fragment>
                            ))}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {props.values.scheduled_participants.map(
                            (userWorkShift: ScheduledParticipantInterface, index: number) => (
                              <React.Fragment key={index}>
                                {userWorkShift.work_shift_type === 'participant' && userWorkShift._destroy === 0 && (
                                  <Grid
                                    container
                                    spacing={2}
                                    style={userWorkShift._destroy !== 0 ? { display: 'none' } : {}}
                                  >
                                    <Grid item xs={3} sm={3} md={3} lg={3} style={{ marginTop: '17px' }}>
                                      {userWorkShift.user.name}{' '}
                                    </Grid>

                                    <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                      <FormHourMinutePicker
                                        fieldName={`scheduled_participants.${index}.hours`}
                                        label="Arbetade timmar"
                                        values={props.values}
                                        defaultValue={props.team.standard_workday}
                                        errors={props.errors}
                                        onChange={props.handleChange}
                                        hours={10}
                                        disabled={userWorkShift.confirmed}
                                        style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                      />
                                    </Grid>

                                    <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                      <FormSelect
                                        size={12}
                                        fieldName={`scheduled_participants.${index}.salary_class_id`}
                                        label="Löneart"
                                        values={props.values}
                                        errors={props.errors}
                                        onChange={props.handleChange}
                                        options={salaryClassOptions(userWorkShift.user.role)}
                                        disabled={userWorkShift.confirmed}
                                        style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                      />
                                    </Grid>

                                    <Grid item xs={2} sm={2} md={2} lg={2} style={{ paddingTop: '7px' }}>
                                      <FormControl fullWidth margin="normal">
                                        <Button
                                          color="primary"
                                          variant={userWorkShift.confirmed ? 'contained' : 'outlined'}
                                          style={{ marginRight: '10px' }}
                                          onClick={() => {
                                            props.setFieldValue(
                                              `scheduled_participants[${index}].confirmed`,
                                              !userWorkShift.confirmed
                                            );
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginTop: '7px' }}>
                                      <Button
                                        variant="text"
                                        color="error"
                                        style={{ marginBottom: '15px' }}
                                        onClick={(): void => {
                                          if (userWorkShift) {
                                            props.setFieldValue(`scheduled_participants[${index}]._destroy`, 1);
                                            if (userWorkShift.work_shift_type === 'participant') {
                                              const newParticipants = props.participants.filter(
                                                (participant: MinimalWorkShiftUserInterface) =>
                                                  participant.id !== userWorkShift.user.id
                                              );
                                              props.setParticipants(newParticipants);
                                            } else {
                                              if (userWorkShift.work_shift_type === 'temp_team_leader') {
                                                const newTempTeamLeaders = props.tempTeamLeaders.filter(
                                                  (tempTeamLeader: MinimalWorkShiftUserInterface) =>
                                                    tempTeamLeader.id !== userWorkShift.user.id
                                                );
                                                props.setTempTeamLeaders(newTempTeamLeaders);
                                              }
                                            }
                                          }
                                        }}
                                      >
                                        <ClearIcon className={css(commonStyles.deleteIcon)} />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )}

                {props.values.scheduled_participants.filter(
                  (userWorkShift: ScheduledParticipantInterface) =>
                    userWorkShift.work_shift_type === 'substitute' && userWorkShift._destroy === 0
                ).length > 0 && (
                  <React.Fragment>
                    <div style={{ marginTop: '30px' }}></div>
                    <h1 className={css(commonStyles.headerTextStyle)}>Vikarier</h1>
                    <div style={{ marginTop: '20px' }}>
                      {props.disabled ? (
                        <React.Fragment>
                          {props.values.scheduled_participants
                            .sort((x: ScheduledParticipantInterface, y: ScheduledParticipantInterface) =>
                              x.confirmed === y.confirmed ? 0 : x.confirmed ? -1 : 1
                            )
                            .map((userWorkShift: ScheduledParticipantInterface, index: number) => (
                              <React.Fragment key={index}>
                                {userWorkShift.work_shift_type === 'substitute' && userWorkShift._destroy === 0 && (
                                  <Grid
                                    container
                                    spacing={2}
                                    style={userWorkShift._destroy !== 0 ? { display: 'none' } : {}}
                                  >
                                    <Grid
                                      item
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      style={userWorkShift.confirmed ? { marginTop: '17px' } : {}}
                                    >
                                      {userWorkShift.user.name}{' '}
                                      {!userWorkShift.confirmed && (
                                        <span style={{ color: 'red' }}>- Ogiltig frånvaro</span>
                                      )}
                                    </Grid>
                                    {userWorkShift.confirmed && (
                                      <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                        <FormHourMinutePicker
                                          fieldName={`scheduled_participants.${index}.hours`}
                                          label="Arbetade timmar"
                                          values={props.values}
                                          defaultValue={props.team.standard_workday}
                                          errors={props.errors}
                                          onChange={props.handleChange}
                                          hours={10}
                                          disabled={userWorkShift.confirmed || props.disabled}
                                          style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                        />
                                      </Grid>
                                    )}
                                    {userWorkShift.confirmed && (
                                      <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                        <FormSelect
                                          size={12}
                                          fieldName={`scheduled_participants.${index}.salary_class_id`}
                                          label="Löneart"
                                          values={props.values}
                                          errors={props.errors}
                                          onChange={props.handleChange}
                                          options={salaryClassOptions(userWorkShift.user.role)}
                                          disabled={userWorkShift.confirmed || props.disabled}
                                          style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                        />
                                      </Grid>
                                    )}
                                    {userWorkShift.confirmed && (
                                      <Grid item xs={2} sm={2} md={2} lg={2} style={{ paddingTop: '7px' }}>
                                        <FormControl fullWidth margin="normal">
                                          <Button
                                            color="primary"
                                            variant={userWorkShift.confirmed ? 'contained' : 'outlined'}
                                            style={{ marginRight: '10px' }}
                                            disabled={props.disabled}
                                          >
                                            OK
                                          </Button>
                                        </FormControl>
                                      </Grid>
                                    )}
                                    {userWorkShift.confirmed && (
                                      <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginTop: '7px' }}>
                                        <Button
                                          variant="text"
                                          color="error"
                                          disabled={props.disabled}
                                          style={{ marginBottom: '15px' }}
                                          onClick={(): void => {
                                            if (userWorkShift) {
                                              props.setFieldValue(`scheduled_participants[${index}]._destroy`, 1);

                                              if (userWorkShift.work_shift_type === 'substitute') {
                                                const newSubstitutes = props.substitutes.filter(
                                                  (substitute: MinimalWorkShiftUserInterface) =>
                                                    substitute.id !== userWorkShift.user.id
                                                );
                                                props.setSubstitutes(newSubstitutes);
                                              }
                                            }
                                          }}
                                        >
                                          <ClearIcon className={css(commonStyles.deleteIcon)} />
                                        </Button>
                                      </Grid>
                                    )}
                                  </Grid>
                                )}
                              </React.Fragment>
                            ))}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {props.values.scheduled_participants.map(
                            (userWorkShift: ScheduledParticipantInterface, index: number) => (
                              <React.Fragment key={index}>
                                {userWorkShift.work_shift_type === 'substitute' && userWorkShift._destroy === 0 && (
                                  <Grid
                                    container
                                    spacing={2}
                                    style={userWorkShift._destroy !== 0 ? { display: 'none' } : {}}
                                  >
                                    <Grid item xs={3} sm={3} md={3} lg={3} style={{ marginTop: '17px' }}>
                                      {userWorkShift.user.name}{' '}
                                    </Grid>

                                    <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                      <FormHourMinutePicker
                                        fieldName={`scheduled_participants.${index}.hours`}
                                        label="Arbetade timmar"
                                        values={props.values}
                                        defaultValue={props.team.standard_workday}
                                        errors={props.errors}
                                        onChange={props.handleChange}
                                        hours={10}
                                        disabled={userWorkShift.confirmed}
                                        style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                      />
                                    </Grid>

                                    <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                      <FormSelect
                                        size={12}
                                        fieldName={`scheduled_participants.${index}.salary_class_id`}
                                        label="Löneart"
                                        values={props.values}
                                        errors={props.errors}
                                        onChange={props.handleChange}
                                        options={salaryClassOptions(userWorkShift.user.role)}
                                        disabled={userWorkShift.confirmed}
                                        style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                      />
                                    </Grid>

                                    <Grid item xs={2} sm={2} md={2} lg={2} style={{ paddingTop: '7px' }}>
                                      <FormControl fullWidth margin="normal">
                                        <Button
                                          color="primary"
                                          variant={userWorkShift.confirmed ? 'contained' : 'outlined'}
                                          style={{ marginRight: '10px' }}
                                          onClick={() => {
                                            props.setFieldValue(
                                              `scheduled_participants[${index}].confirmed`,
                                              !userWorkShift.confirmed
                                            );
                                          }}
                                        >
                                          OK
                                        </Button>
                                      </FormControl>
                                    </Grid>

                                    <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginTop: '7px' }}>
                                      <Button
                                        variant="text"
                                        color="error"
                                        style={{ marginBottom: '15px' }}
                                        onClick={(): void => {
                                          if (userWorkShift) {
                                            props.setFieldValue(`scheduled_participants[${index}]._destroy`, 1);

                                            if (userWorkShift.work_shift_type === 'substitute') {
                                              const newSubstitutes = props.substitutes.filter(
                                                (substitute: MinimalWorkShiftUserInterface) =>
                                                  substitute.id !== userWorkShift.user.id
                                              );
                                              props.setSubstitutes(newSubstitutes);
                                            }
                                          }
                                        }}
                                      >
                                        <ClearIcon className={css(commonStyles.deleteIcon)} />
                                      </Button>
                                    </Grid>
                                  </Grid>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )}

                {props.values.scheduled_participants.filter(
                  (userWorkShift: ScheduledParticipantInterface) =>
                    userWorkShift.work_shift_type === 'temp_team_leader' && userWorkShift._destroy === 0
                ).length > 0 && (
                  <React.Fragment>
                    <div style={{ marginTop: '30px' }}></div>
                    <h1 className={css(commonStyles.headerTextStyle)}>Tillfällig teamledare</h1>
                    <div style={{ marginTop: '20px' }}>
                      {props.disabled ? (
                        <React.Fragment>
                          {props.values.scheduled_participants
                            .sort((x: ScheduledParticipantInterface, y: ScheduledParticipantInterface) =>
                              x.confirmed === y.confirmed ? 0 : x.confirmed ? -1 : 1
                            )
                            .map((userWorkShift: ScheduledParticipantInterface, index: number) => (
                              <React.Fragment key={index}>
                                {userWorkShift.work_shift_type === 'temp_team_leader' &&
                                  userWorkShift._destroy === 0 && (
                                    <Grid
                                      container
                                      spacing={2}
                                      style={userWorkShift._destroy !== 0 ? { display: 'none' } : {}}
                                    >
                                      <Grid
                                        item
                                        xs={3}
                                        sm={3}
                                        md={3}
                                        lg={3}
                                        style={userWorkShift.confirmed ? { marginTop: '17px' } : {}}
                                      >
                                        {userWorkShift.user.name}{' '}
                                        {!userWorkShift.confirmed && (
                                          <span style={{ color: 'red' }}>- Ogiltig frånvaro</span>
                                        )}
                                      </Grid>
                                      {userWorkShift.confirmed && (
                                        <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                          <FormHourMinutePicker
                                            fieldName={`scheduled_participants.${index}.hours`}
                                            label="Arbetade timmar"
                                            values={props.values}
                                            defaultValue={props.team.standard_workday}
                                            errors={props.errors}
                                            onChange={props.handleChange}
                                            hours={10}
                                            disabled={userWorkShift.confirmed || props.disabled}
                                            style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                          />
                                        </Grid>
                                      )}
                                      {userWorkShift.confirmed && (
                                        <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                          <FormSelect
                                            size={12}
                                            fieldName={`scheduled_participants.${index}.salary_class_id`}
                                            label="Löneart"
                                            values={props.values}
                                            errors={props.errors}
                                            onChange={props.handleChange}
                                            options={salaryClassOptions(userWorkShift.user.role)}
                                            disabled={userWorkShift.confirmed || props.disabled}
                                            style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                          />
                                        </Grid>
                                      )}
                                      {userWorkShift.confirmed && (
                                        <Grid item xs={2} sm={2} md={2} lg={2} style={{ paddingTop: '7px' }}>
                                          <FormControl fullWidth margin="normal">
                                            <Button
                                              color="primary"
                                              variant={userWorkShift.confirmed ? 'contained' : 'outlined'}
                                              style={{ marginRight: '10px' }}
                                              disabled={props.disabled}
                                            >
                                              OK
                                            </Button>
                                          </FormControl>
                                        </Grid>
                                      )}
                                      {userWorkShift.confirmed && (
                                        <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginTop: '7px' }}>
                                          <Button
                                            variant="text"
                                            color="error"
                                            disabled={props.disabled}
                                            style={{ marginBottom: '15px' }}
                                            onClick={(): void => {
                                              if (userWorkShift) {
                                                props.setFieldValue(`scheduled_participants[${index}]._destroy`, 1);

                                                if (userWorkShift.work_shift_type === 'temp_team_leader') {
                                                  const newTempTeamLeaders = props.tempTeamLeaders.filter(
                                                    (tempTeamLeader: MinimalWorkShiftUserInterface) =>
                                                      tempTeamLeader.id !== userWorkShift.user.id
                                                  );
                                                  props.setSubstitutes(newTempTeamLeaders);
                                                }
                                              }
                                            }}
                                          >
                                            <ClearIcon className={css(commonStyles.deleteIcon)} />
                                          </Button>
                                        </Grid>
                                      )}
                                    </Grid>
                                  )}
                              </React.Fragment>
                            ))}
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {props.values.scheduled_participants.map(
                            (userWorkShift: ScheduledParticipantInterface, index: number) => (
                              <React.Fragment key={index}>
                                {userWorkShift.work_shift_type === 'temp_team_leader' &&
                                  userWorkShift._destroy === 0 && (
                                    <Grid
                                      container
                                      spacing={2}
                                      style={userWorkShift._destroy !== 0 ? { display: 'none' } : {}}
                                    >
                                      <Grid item xs={3} sm={3} md={3} lg={3} style={{ marginTop: '17px' }}>
                                        {userWorkShift.user.name}{' '}
                                      </Grid>

                                      <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                        <FormHourMinutePicker
                                          fieldName={`scheduled_participants.${index}.hours`}
                                          label="Arbetade timmar"
                                          values={props.values}
                                          defaultValue={props.team.standard_workday}
                                          errors={props.errors}
                                          onChange={props.handleChange}
                                          hours={10}
                                          disabled={userWorkShift.confirmed}
                                          style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                        />
                                      </Grid>

                                      <Grid item xs={3} sm={3} md={3} lg={3} style={{ paddingTop: '0px' }}>
                                        <FormSelect
                                          size={12}
                                          fieldName={`scheduled_participants.${index}.salary_class_id`}
                                          label="Löneart"
                                          values={props.values}
                                          errors={props.errors}
                                          onChange={props.handleChange}
                                          options={salaryClassOptions(userWorkShift.user.role)}
                                          disabled={userWorkShift.confirmed}
                                          style={userWorkShift.confirmed ? { backgroundColor: '#e9f7f1' } : {}}
                                        />
                                      </Grid>

                                      <Grid item xs={2} sm={2} md={2} lg={2} style={{ paddingTop: '7px' }}>
                                        <FormControl fullWidth margin="normal">
                                          <Button
                                            color="primary"
                                            variant={userWorkShift.confirmed ? 'contained' : 'outlined'}
                                            style={{ marginRight: '10px' }}
                                            onClick={() => {
                                              props.setFieldValue(
                                                `scheduled_participants[${index}].confirmed`,
                                                !userWorkShift.confirmed
                                              );
                                            }}
                                          >
                                            OK
                                          </Button>
                                        </FormControl>
                                      </Grid>

                                      <Grid item xs={1} sm={1} md={1} lg={1} style={{ marginTop: '7px' }}>
                                        <Button
                                          variant="text"
                                          color="error"
                                          style={{ marginBottom: '15px' }}
                                          onClick={(): void => {
                                            if (userWorkShift) {
                                              props.setFieldValue(`scheduled_participants[${index}]._destroy`, 1);

                                              if (userWorkShift.work_shift_type === 'temp_team_leader') {
                                                const newTempTeamLeaders = props.tempTeamLeaders.filter(
                                                  (tempTeamLeader: MinimalWorkShiftUserInterface) =>
                                                    tempTeamLeader.id !== userWorkShift.user.id
                                                );
                                                props.setTempTeamLeaders(newTempTeamLeaders);
                                              }
                                            }
                                          }}
                                        >
                                          <ClearIcon className={css(commonStyles.deleteIcon)} />
                                        </Button>
                                      </Grid>
                                    </Grid>
                                  )}
                              </React.Fragment>
                            )
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          />
          <Divider style={{ marginTop: '20px', marginBottom: '20px' }} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
