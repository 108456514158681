import React, { useState, useEffect } from 'react';
import MUIRichTextEditor from 'mui-rte';
import { EntityInstance, EditorState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import themeStyles from '../styling/materialTheme';
import { Card, CardContent, CardHeader, Theme, ThemeOptions, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/styles';

const style = {
  palette: {
    primary: {
      main: '#e71818',
    },
  },
};

const defaultTheme: Theme = createTheme(themeStyles as ThemeOptions, style as ThemeOptions);

Object.assign(defaultTheme, {
  overrides: {
    MUIRichTextEditor: {
      placeHolder: {
        paddingBottom: '20px',
        position: 'relative',
      },
      toolbar: {
        borderBottom: '1px solid gray',
      },
      container: {
        border: '1px solid gray',
        borderRadius: '4px',
        padding: '14px',
      },
    },
  },
});

type Props = {
  disabled?: boolean;
  subheader: string;
  label: string;
  signature?: string;
  controls: string[];
  initialValue: string;
  type?: string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  fieldName: string;
  handleChange: (state: any) => void;
};

const options = {
  entityStyleFn: (entity: EntityInstance) => {
    const entityType = entity.getType().toLowerCase();
    const data = entity.getData();
    if (entity.getType().toLowerCase() === 'image' && data.type == 'video') {
      return {
        element: 'iframe',
        attributes: {
          src: data.url,
        },
      };
    } else if (entityType === 'image') {
      return {
        element: 'img',
        attributes: {
          src: data.url,
        },
      };
    } else if (entityType === 'link') {
      let url = data.url;
      try {
        new URL(data.url);
      } catch (error) {
        if (error instanceof TypeError) {
          url = 'https://' + data.url;
        }
      }
      return {
        element: 'a',
        attributes: {
          href: url,
          target: '_blank',
        },
      };
    }
  },
};

const RichTextEditorNews: React.FC<Props> = (props) => {
  const [initialValue, setInitialValue] = useState(props.initialValue);

  // functions
  useEffect(() => {
    setInitialValue(props.initialValue);
  }, [props.type]); // eslint-disable-line

  const handleChange = (state: EditorState): void => {
    props.setFieldValue('content', stateToHTML(state.getCurrentContent(), options));
    props.handleChange({
      target: {
        name: props.fieldName,
        value: JSON.stringify(convertToRaw(state.getCurrentContent())),
      },
    });
  };

  return (
    <Card>
      <CardHeader style={{ paddingBottom: 0 }} subheader={<>{props.subheader} </>} />
      <CardContent>
        <ThemeProvider theme={defaultTheme}>
          <MUIRichTextEditor
            readOnly={props.disabled}
            defaultValue={initialValue}
            label={props.label}
            controls={props.controls}
            onChange={handleChange}
          />
        </ThemeProvider>
        <p>{props.signature}</p>
      </CardContent>
    </Card>
  );
};

export default RichTextEditorNews;
