import React, { useState } from 'react';
import { RotateLoader } from 'react-spinners';
import { useNavigate } from 'react-router-dom';

import { DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { UserInterface } from './../types';
import { useNotify } from '../../Common/snackbarHooks';
import { commonStyles, css } from '../../Common/styling';
import { sendWelcomeEmail } from '../usersApi';

type Props = {
  user: UserInterface | undefined;
  setModalState: (modalState: boolean) => void;
};

const WelcomeEmailModal: React.FC<Props> = (props) => {
  const navigate = useNavigate();
  const { notifyError, notifySuccess } = useNotify();
  const [loading, setLoading] = useState(false);

  const handleSubmit = (): void => {
    setLoading(true);
    if (props.user && props.user.id) {
      sendWelcomeEmail(props.user.id)
        .then(() => {
          notifySuccess('Välkomstmail skickad');
        })
        .catch(() => notifyError('Det gick inte att skicka välkomstmailet.'))
        .finally(() => {
          setLoading(false);
          props.setModalState(false);
          navigate(`/users`);
        });
    }
  };

  return (
    <div>
      <DialogTitle>Skicka välkomstmail</DialogTitle>
      {loading ? (
        <div style={{ minHeight: '120px' }}>
          <DialogContent>
            <div className={css(commonStyles.spinner)}>
              <RotateLoader loading={loading} />
            </div>
          </DialogContent>
        </div>
      ) : (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              {props.user?.email ? (
                <p>
                  Vill du skicka ett välkomstmail med lösenord till användaren {props.user?.first_name}{' '}
                  {props.user?.surname}?
                </p>
              ) : (
                <div>
                  <p>Obs! Det går inte att skicka välkomstmailet eftersom användaren saknar e-postadress.</p>
                  <p>
                    Kontrollera först att användaren har en e-postadress och använd sedan &quot;Skicka
                    välkomstmail&quot; under trepunktsmenyn till höger i användarlistan.
                  </p>
                </div>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              fullWidth
              color="error"
              onClick={(): void => {
                if (props.user && !props.user?.email) {
                  navigate(`/users/${props.user?.id}`);
                } else {
                  navigate(`/users`);
                }
                props.setModalState(false);
              }}
            >
              {props.user?.email ? 'NEJ' : 'AVBRYT'}
            </Button>
            <Button fullWidth disabled={!props.user?.email} color="primary" onClick={(): void => handleSubmit()}>
              {props.user?.email ? 'JA' : ''}
            </Button>
          </DialogActions>
        </React.Fragment>
      )}
    </div>
  );
};

export default WelcomeEmailModal;
