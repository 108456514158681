import React, { useEffect, useRef, useState } from 'react';
import { DashboardNews } from '../types';
import { twMerge } from 'tailwind-merge';
import { Button } from '@mui/material';
import { AccessTime, Approval } from '@mui/icons-material';
import dayjs from 'dayjs';

type Props = {
  news: DashboardNews;
};

export default function DashboardNewsCard({ news }: Props) {
  const contentRef = useRef<HTMLDivElement>(null);
  const [expanded, setExpanded] = useState(false);
  const [canExpand, setCanExpand] = useState(false);

  useEffect(() => {
    if (!contentRef.current) return;

    const resizeObserver = new ResizeObserver(() => setCanExpand((contentRef.current?.clientHeight ?? 0) > 208));

    resizeObserver.observe(contentRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div className="rounded-2xl shadow-md p-6 pt-5 bg-white">
      <h2 className="text-xl font-semibold leading-6 mb-3">{news.title}</h2>
      <div className={twMerge('relative min-h-10 max-h-52 overflow-hidden', expanded && 'max-h-none')}>
        {canExpand && !expanded && (
          <div className="absolute inset-0 pointer-events-none shadow-[inset_0px_-80px_20px_-40px_#fff]" />
        )}
        <div ref={contentRef} className="text-sm text-black/85" dangerouslySetInnerHTML={{ __html: news.content }} />
      </div>
      {canExpand && !expanded && (
        <div className="-mt-6 -mb-2">
          <Button variant="text" onClick={() => setExpanded(true)}>
            Läs mer
          </Button>
        </div>
      )}
      <div className="flex gap-4 mt-4 text-xs leading-none font-semibold">
        <div className="flex items-center gap-1.5">
          <Approval sx={{ fontSize: 14 }} />
          <span>
            {news.author.first_name} {news.author.surname[0]}
          </span>
        </div>
        <div className="flex items-center gap-1.5">
          <AccessTime sx={{ fontSize: 14 }} />
          <span>{dayjs(news.published_at).format('YYYY-MM-DD')}</span>
        </div>
      </div>
    </div>
  );
}
