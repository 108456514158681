import React, { useState } from 'react';

import { EducationPageInterface } from '../types';
import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';

type Props = {
  pages: Array<EducationPageInterface>;
  currentIndex: number;
  disabled: boolean;
  setMoveTarget: (target: number) => void;
};

const OrderPages: React.FC<Props> = (props) => {
  const [target, setTarget] = useState(props.currentIndex);

  const handleTargetChange = (event: any): void => {
    setTarget(event.target.value);
    props.setMoveTarget(event.target.value);
  };

  const disabled = (): boolean => props.pages.length < 2;

  return (
    <Box display="flex" alignItems="center" style={{ marginBottom: '20px' }}>
      {!disabled() ? (
        <>
          <FormControl style={{ flex: 1 }}>
            <InputLabel>Position</InputLabel>
            <Select
              variant="outlined"
              disabled={props.disabled}
              name="moveTarget"
              value={target}
              onChange={handleTargetChange}
              label="Sida"
              autoWidth={true}
            >
              {props.pages.map((page, index) => (
                <MenuItem key={index} value={index}>
                  {index === props.currentIndex ? 'Oförändrad' : `Framför ${index + 1}: ${page.title}`}
                </MenuItem>
              ))}
              <MenuItem value={-1}>Längst ned</MenuItem>
            </Select>
          </FormControl>
        </>
      ) : (
        <Typography style={{ marginRight: '12px' }}>Du kan ordna sidor när det finns mer än en sida </Typography>
      )}
    </Box>
  );
};

export default OrderPages;
