import API from '../Common/axios';
import { ExtraHour, ExtraHourDetails } from './types';

type FindExtraHoursParams = {
  query?: string;
  filter_user?: string;
  filter_salary_class?: number;
  filter_from_date?: string;
  filter_to_date?: string;
  hide_exported?: boolean;
  sort_by?: string;
  sort_order?: 'asc' | 'desc';
  page?: number;
};

export async function findExtraHours(params: FindExtraHoursParams = {}) {
  return API.get<{ data: ExtraHour[]; meta: { count: number } }>('v1/extra_hours', { params });
}

export async function getExtraHour(id: number) {
  return API.get<{ data: ExtraHourDetails }>(`v1/extra_hours/${id}`);
}

type CreateExtraHourParams = {
  activity: string;
  date: string;
  individual_hours: number;
  salary_class_id: number;
  extra_hours_users_attributes: { user_id?: string; id?: number; _destroy?: boolean }[];
};

export async function createExtraHour(extra_hour: CreateExtraHourParams) {
  return API.post<{ data: ExtraHourDetails }>('v1/extra_hours', { extra_hour });
}

type UpdateExtraHourParams = Partial<CreateExtraHourParams>;

export async function updateExtraHour(id: number, extra_hour: UpdateExtraHourParams) {
  return API.patch<{ data: ExtraHourDetails }>(`v1/extra_hours/${id}`, { extra_hour });
}

export async function deleteExtraHour(id: number) {
  return API.delete(`v1/extra_hours/${id}`);
}
