import React from 'react';
import {
  BaseFilterPopover,
  FilterSwitch,
  CalledFilterSwitch,
  DeletedFilterSwitch,
  ReservedFilterSwitch,
} from '../../Common/FilterPopover';
import { ListParams } from '../../Common/types';
import { Divider } from '@mui/material';

type Props = {
  setGetListParams: (p: ListParams) => void;
  setSelectedRowItems: (p: []) => void;
  setQuickActionItem: (p: []) => void;
  listParams: ListParams;
  type: string;
  setPagination?: (p: { page: number; page_size: number }) => void;
  paginationDefaults?: { page: number; page_size: number };
};

const FilterPopover: React.FC<Props> = (props) => {
  return (
    <BaseFilterPopover>
      {props.type === 'participant' && (
        <FilterSwitch
          fieldName="hide_plus16old"
          label="Visa 16-åringar och äldre"
          listParams={props.listParams}
          setGetListParams={props.setGetListParams}
          setSelectedRowItems={props.setSelectedRowItems}
          setQuickActionItem={props.setQuickActionItem}
          setPagination={props.setPagination}
          paginationDefaults={props.paginationDefaults}
        />
      )}

      {props.type === 'team_leader' && (
        <FilterSwitch
          fieldName="hide_graduating_within_year"
          label="Visa 3:or och äldre"
          listParams={props.listParams}
          setGetListParams={props.setGetListParams}
          setSelectedRowItems={props.setSelectedRowItems}
          setQuickActionItem={props.setQuickActionItem}
          setPagination={props.setPagination}
          paginationDefaults={props.paginationDefaults}
        />
      )}

      <FilterSwitch
        fieldName="hide_plus2years"
        label="Ansökningar äldre än 2 år"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setSelectedRowItems={props.setSelectedRowItems}
        setQuickActionItem={props.setQuickActionItem}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />

      <FilterSwitch
        fieldName="show_only_for_locals"
        label="Ta bort kommunspärr"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setSelectedRowItems={props.setSelectedRowItems}
        setQuickActionItem={props.setQuickActionItem}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />

      <Divider className="!my-[15px]" />

      <CalledFilterSwitch
        fieldName="hide_called"
        label="Kallad"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setSelectedRowItems={props.setSelectedRowItems}
        setQuickActionItem={props.setQuickActionItem}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />

      <DeletedFilterSwitch
        fieldName="hide_deleted"
        label="Arkiverad"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setSelectedRowItems={props.setSelectedRowItems}
        setQuickActionItem={props.setQuickActionItem}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />

      <ReservedFilterSwitch
        fieldName="hide_reserved"
        label="Reserv"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setSelectedRowItems={props.setSelectedRowItems}
        setQuickActionItem={props.setQuickActionItem}
        setPagination={props.setPagination}
        paginationDefaults={props.paginationDefaults}
      />
    </BaseFilterPopover>
  );
};

export default FilterPopover;
