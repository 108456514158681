import { Search as SearchIcon } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';

type InterviewsSearchBarProps = {
  value?: string;
  onChange?: (value: string) => void;
};

export default function InterviewsSearchBar({ value, onChange }: InterviewsSearchBarProps) {
  const [input, setInput] = useState(value ?? '');

  useEffect(() => {
    setInput(value ?? '');
  }, [value]);

  return (
    <div className="relative w-full">
      <SearchIcon className="absolute top-3.5 bottom-3.5 left-2.5" />
      <input
        className="pl-11 py-3.5 outline-none w-full"
        placeholder="Sök team eller närvarande"
        value={input}
        onChange={({ target }) => setInput(target.value)}
        onBlur={() => {
          if (input !== value) onChange?.(input);
        }}
        onKeyDown={(event) => {
          if (event.key !== 'Enter') return;
          if (input !== value) onChange?.(input);
        }}
      />
    </div>
  );
}
