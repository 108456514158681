import React from 'react';
import { BaseFilterPopover, FilterSwitch } from '../../Common/FilterPopover';
import { ListParams } from '../../Common/types';

type Props = {
  setGetListParams: (p: ListParams) => void;
  setSelectedRowItems: (p: []) => void;
  setQuickActionItem: (p: []) => void;
  listParams: ListParams;
};

const FilterPopover: React.FC<Props> = (props) => {
  return (
    <BaseFilterPopover>
      <FilterSwitch
        fieldName="hide_deleted"
        label="Visa arkiverade annonser"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setSelectedRowItems={props.setSelectedRowItems}
        setQuickActionItem={props.setQuickActionItem}
      />
      <FilterSwitch
        fieldName="hide_others"
        label="Visa även andras annonser"
        listParams={props.listParams}
        setGetListParams={props.setGetListParams}
        setSelectedRowItems={props.setSelectedRowItems}
        setQuickActionItem={props.setQuickActionItem}
      />
    </BaseFilterPopover>
  );
};

export default FilterPopover;
