import React, { useState, useEffect } from 'react';
import { RotateLoader } from 'react-spinners';
import { useParams } from 'react-router-dom';
import { InvoiceRowsDetails } from '../SavedInvoices/components/InvoiceRowsDetails';
import { formatDate, isNotEmpty, monthTranslator } from '../Common/utilities';
import { getInvoiceDetail } from '../SavedInvoices/SavedInvoicesApi';
import { InvoiceDetailInterface } from '../ResidenceGroup/types';
import { commonStyles, css } from '../Common/styling';
import { BackLink } from '../Common/ButtonLinks';
import { TextView } from './components/TextView';
import { InvoiceInterface } from './types';
import { Divider, Grid, Paper, Typography } from '@mui/material';

const SentInvoiceDetail: React.FC = () => {
  // hooks
  const { id } = useParams() as { id: string };
  const [invoice, setInvoice] = useState({} as InvoiceInterface);
  const [invoiceDetail, setInvoiceDetail] = useState({} as InvoiceDetailInterface);
  const [invoiceRows, setInvoiceRows] = useState([] as any);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const groupBy =
      (key: string): any =>
      (array: any[]): any =>
        array.reduce((objectsByKeyValue, obj) => {
          const value = obj[key];
          objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
          return objectsByKeyValue;
        }, {});
    const groupByTitle = groupBy('title');

    setLoading(true);
    getInvoiceDetail(id)
      .then(({ data }) => {
        setInvoice(data.data);
        setInvoiceDetail(data.data.invoice_detail);
        setInvoiceRows(Object.values(groupByTitle(data.data.invoice_rows)));
      })
      .finally(() => setLoading(false));
  }, [id]);

  // render
  interface TypeObject {
    ContinuousAgreement: string;
    FixedAgreement: string;
    [key: string]: string;
  }
  const type: TypeObject = {
    ContinuousAgreement: 'Löpande',
    FixedAgreement: 'Anpassad',
  };

  return (
    <React.Fragment>
      {loading && (
        <div className={css(commonStyles.spinner)}>
          <RotateLoader loading={loading} />
        </div>
      )}{' '}
      {!loading && (
        <div>
          <div className={css(commonStyles.editViewHeader)}>
            <BackLink link={'/sent-invoices'} />
            <h1 className={css(commonStyles.headerTextStyle)}>Faktura för {invoice.residence_name}</h1>
          </div>

          <div className={css(commonStyles.formContainer)} style={{ marginTop: '32px' }}>
            <div>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={invoice.fortnox_id} valueTitle="FakturaNr" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={`${invoice.start_date} - ${invoice.end_date}`} valueTitle="Period" />
                <TextView size={6} value={formatDate(invoice.created_at)} valueTitle="Skapad" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={3} value={monthTranslator(invoice.start_date.substring(5, 7))} valueTitle="Månad" />
                <TextView size={3} value={type[invoice.agreement_type]} valueTitle="Rutin" />
                <TextView size={3} value={invoice.residence_group_name} valueTitle="Avtalspart" />
                <TextView size={3} value={invoice.residence_name} valueTitle="Månad" />
              </Grid>
              <Divider className="!mb-6" />
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={invoiceDetail.recipient} valueTitle="Fakturamottagare" />
                <TextView size={6} value={invoiceDetail.address_line_1} valueTitle="Adress" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={invoiceDetail.address_line_2} valueTitle="Ytterliggare adressuppgifter" />
                <TextView size={3} value={invoiceDetail.zip_code} valueTitle="Postnummer" />
                <TextView size={3} value={invoiceDetail.city} valueTitle="Ort" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={6} value={invoiceDetail.reference} valueTitle="Referens" />
                <TextView size={6} value={invoiceDetail.gln_number || ''} valueTitle="GLN-nummer" />
              </Grid>
              <Grid container spacing={2} style={{ marginBottom: '24px' }}>
                <TextView size={12} value={invoice.note} valueTitle="Referens" />
              </Grid>
              <Divider className="!mb-6" />
              <Grid
                container
                style={{ padding: '16px', backgroundColor: 'rgba(237, 244, 254, 1)', marginBottom: '5px' }}
              >
                <Grid item xs={5}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Beskrivning</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Antal</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Enhet</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Pris / enhet</Typography>
                  </Paper>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1}>
                  <Paper elevation={0} square>
                    <Typography style={{ backgroundColor: 'rgba(237, 244, 254, 1)' }}>Total (exkl. moms)</Typography>
                  </Paper>
                </Grid>
              </Grid>
              {invoiceRows && isNotEmpty(invoiceRows) && <InvoiceRowsDetails rows={invoiceRows} />}
              <Divider className="!my-6" />
              <Grid container style={{ marginBottom: '24px' }}>
                <Grid item xs={12}>
                  <Paper elevation={0}>
                    <Typography align="center" color="secondary" variant="h5">
                      Fakturan skickades till FortNox {invoice.fortnox_transfer_date}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default SentInvoiceDetail;
