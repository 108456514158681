import * as Yup from 'yup';

export const newUserValues = {
  first_name: '',
  surname: '',
  role: 'operational_leader',
  email: '',
};

export const profileValues = {
  first_name: '',
  surname: '',
};

export const passwordValues = {
  old_password: '',
  new_password: '',
  confirm_new_password: '',
};

export const newUserValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('* Namn är ett obligatoriskt fält'),
  surname: Yup.string().required('* Efternamn är ett obligatoriskt fält'),
  role: Yup.string().required('* Roll måste väljas'),
  email: Yup.string()
    .required('* Email är ett obligatoriskt fält')
    .matches(/[^@]+@[^.]+..+/, '* Emailen måste skrivas på {}@{}.{} format'),
});

export const profileValidationSchema = Yup.object().shape({
  first_name: Yup.string().required('* Namn är ett obligatoriskt fält'),
  surname: Yup.string().required('* Efternamn är ett obligatoriskt fält'),
  roles: Yup.string().required('* Minst en roll måste väljas'),
});

export const passwordValidationSchema = Yup.object().shape({
  old_password: Yup.string().required('* Gamla lösenordet måste fyllas i'),
  new_password: Yup.string()
    .required('* Nya lösenordet måste fyllas i')
    .min(8, '* Lösenordet måste minst vara 8 tecken')
    .max(20, '* Lösenordet får max vara 20 tecken'),
  confirm_new_password: Yup.string()
    .oneOf([Yup.ref('new_password')], '* Det nya lösenordet matchar inte')
    .required('* Bekräfta det nya lösenordet'),
});
