import React from 'react';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';

interface Props {
  isVisible: boolean;
  onConfirm?: () => void;
  onClose?: () => void;
  message: string;
  title: string;
  closeButtonText?: string;
  confirmButtonText?: string;
}

const ConfirmationDialog: React.FC<Props> = (props: Props) => {
  return (
    <Dialog open={props.isVisible} onClose={props.onClose}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{props.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button fullWidth color="error" onClick={props.onClose}>
          {props.closeButtonText || 'Avbryt'}
        </Button>
        <Button fullWidth color="primary" onClick={props.onConfirm}>
          {props.confirmButtonText || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
