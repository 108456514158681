import React from 'react';
import logo from '../../Common/logo.png';
import { commonStyles, css } from '../../Common/styling';
import { Grid } from '@mui/material';

type Props = {
  type: string;
};

const ApplicationSentConfirmation: React.FC<Props> = (props) => {
  return (
    <div className="pl-[15%] pr-[15%] mx-8 mb-5">
      {/* Title */}
      <div className="flex flex-col pt-8 space-y-8">
        <div className="self-center">
          <img className="w-[300px]" src={logo} alt="Logo" />
        </div>
      </div>
      {/* Content */}
      <div className="flex mt-8">
        <span>Tack för din ansökan!</span>
      </div>
      <div className="flex mt-8">
        <span>Vad roligt att du valt att söka till Ung Omsorg!</span>
      </div>
      <div className="flex mt-8">
        <span>
          Nu står du i kö och kommer att kallas till intervju när det är din tur. All information finns på din mejl. Läs
          igenom mejlet direkt så att du vet vad som händer härnäst.
        </span>
      </div>
      <div className="flex mt-8">
        <span>
          Har du inte fått ett mejl? Kika först in i “skräpposten”, vänta annars en dag. Har du fortfarande inte fått
          ett mejl kan du kontakta <a href="mailto:info@ungomsorg.se">info@ungomsorg.se</a> för att se om ansökan kommit
          in.
        </span>
      </div>
      <div className="flex mt-8">
        <span>
          <a href={props.type === 'participant' ? '/deltagare' : '/teamledare'}>Lämna in ansökan för en ny person</a>
        </span>
      </div>
      <div className="flex mt-8">
        <span>
          <a href="https://ungomsorg.se/">Tillbaka till ungomsorg.se</a>
        </span>
      </div>
    </div>
  );
};

export default ApplicationSentConfirmation;
