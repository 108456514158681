import * as Yup from 'yup';

export const educationSchema = Yup.object().shape({
  name: Yup.string().required('* Namn är ett obligatoriskt fält'),
});

const educationAnswerSchema = Yup.object().shape({
  text: Yup.string().required('* Svarstext är ett obligatoriskt fält'),
  correct: Yup.bool().required(),
});

const educationQuestionSchema = Yup.object().shape({
  text: Yup.string().required('* Frågetext är ett obligatoriskt fält'),
  education_answers: Yup.array().of(educationAnswerSchema),
});

export const educationPageSchema = Yup.object().shape({
  title: Yup.string().required('* Titel är ett obligatoriskt fält'),
  youtube_url: Yup.string(),
  estimated_time: Yup.number()
    .typeError('* Måste vara ett nummer')
    .required('* Uppskattad tid är obligatorisk')
    .positive('* Måste vara positivt')
    .integer('* Måste vara ett heltal'),
  education_questions: Yup.array().of(educationQuestionSchema),
});
