// Values for app-specific styles such as colours
// text dimension and spacing.
const theme = {
  brandGrey: '#545960',
  brandGreyDark: '#212121',

  greyLight: '#F5F5F5',
  grey: '#D9D9D9',

  black: '#000000',
  white: '#FFFFFF',

  blueLight: '#ECF4FF',
  linkBlue: '#006f80',

  redLighter: '#F8BEC8',
  redLight: '#BD4E4C',
  red: '#AD2220',
  redMedium: '#AA0000', // same red as in old UngIntra
  redDark: '#9F1816',
  redDarker: '#791716',

  greenLight: '#33AB78',
  green: '#009657',
  greenDark: '#00693C',

  themeGreen: '#439676',
  themeGreenLight: 'rgba(67, 150, 118, 0.15)',
  themeGreenGreyLight: 'rgba(135, 163, 147, 0.25)',
  themeRedLight: 'rgba(255, 106, 110, 0.15)',
  themeWhite: '#ffffff',
  themeBlackTextBody: 'rgba(0, 0, 0, 0.87)',
};

export default theme;
