import axios from 'axios';

import { getLocalStorageItem, removeLocalStorageItem } from './localStorageUtils';

// Instantiate HTTP service
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_PATH,
  timeout: 25000,
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
});

// Attach JWT to outgoing requests if present in local storage
axiosInstance.interceptors.request.use((request) => {
  if (getLocalStorageItem('JWT')) {
    request.headers['Authorization'] = `Bearer ${getLocalStorageItem('JWT')}`;
  }
  return request;
});

// Add a 401 response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const allowedUrls = ['v1/users/reset_password', 'authenticate'];
    const allowed = allowedUrls.includes(error.config.url);
    if (error.response.status === 401 && !allowed) {
      removeLocalStorageItem('JWT');
      window.location.replace('/');
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
