import { Link, useLocation, useNavigate } from 'react-router-dom';
import React from 'react';

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { StyleSheet, css, theme } from '../styling';

const styles = StyleSheet.create({
  backLink: {
    color: 'white',
    width: '30px',
    height: '30px',
    backgroundColor: theme.black,
    borderRadius: '15px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: '20px',
  },
});

type Props = {
  link: string;
  goBack?: boolean;
};

const BackLink: React.FC<Props> = ({ link, goBack = true }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Link
      to={link}
      onClick={
        goBack && location.key !== 'default'
          ? (event) => {
              event.preventDefault();
              navigate(-1);
            }
          : undefined
      }
      className={css(styles.backLink)}
    >
      <KeyboardArrowLeftIcon fontSize="medium" />
    </Link>
  );
};

export default BackLink;
